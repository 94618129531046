import {createElement} from "react";
import { Route } from "react-router-dom";
import { ROUTES_ADD } from "../../_constants";

function WrappedMaiRouter(routerConfig: any, store: any) {
  const allRoutes: any[] = [];
  let routesPaths = {};
  const renderRoutes = function renderRoutes(routes: IRoute[] , middlewares: any[] = [], path: string = "") {
    const rootMiddlewares = middlewares;
    const rootPath = path;
    routes.forEach(function (_ref) {
      const path = _ref.path,
        component = _ref.component,
        _ref$middlewares = _ref.middlewares,
        middlewares = _ref$middlewares === void 0 ? [] : _ref$middlewares,
        children = _ref.children;
      if (component) {
        const routePath = routerConfig.basepath ? routerConfig.basepath + rootPath + path : rootPath + path;
        routesPaths = { ...routesPaths, [component.displayName as string]: routePath };
        allRoutes.push(createElement(Route, {
          exact: true,
          key: routePath,
          component: [...rootMiddlewares, ...middlewares].reduce(function (comp, middleware) {
            return middleware(comp);
          }, component),
          path: routePath
        }));
      }
      if (children) {
        renderRoutes(children, middlewares, path);
      }
    });
  };
  renderRoutes(routerConfig.routes);
  store.dispatch({
    type: ROUTES_ADD,
    payload: routesPaths
  });
  return allRoutes;
}
 export const RouteBuilder = WrappedMaiRouter;
// ================================================================================================
//=== dsp routing without mandal lib !! it works but should be tested so that I dont use it for now ===