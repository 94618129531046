import './style.scss'
import { Box, Divider, Typography } from "@mui/material";
import InputRow from "../../../../../global/components/InputRow";
import { useState } from "react";
import { IntCheckbox, LoadingButton } from "../../../../../global";

 export interface ISendData {
  type: string;
  email?: string;
  date?: string;
}

interface IProps {
  title: string;
  userId: string;
  loading: boolean;
  invoice: any;
  description?: string;
  leftBtnText: string;
  rightBtnText: string;
  onConfirm(data: ISendData ): void;
  onCancel(): void;
  t: any;
}

const initialSendData = {
  type: '',
  email: '',
  date: '',
}

const REFERER_SELECT_LIST: {title: string, value: string}[] = [
  { title: "Bank Transfer", value: 'bank_transfer'},
  { title: "Stripe", value: 'stripe'},
  { title: "Paystack", value: 'paystack'},
  { title: "Other", value: 'other'},
];

const SendInvoiceModal = (props: IProps) => {
  const {t, title, leftBtnText,invoice, rightBtnText, userId, loading, onCancel, onConfirm} = props;

  const [sendData, setSendData] = useState<ISendData>({...initialSendData, email: invoice?.email });

  return (
    <div id="modal-root">
      <div className="overlay">
        <div className="success-modal" style={{width: '530px', borderRadius: '24px'}}>
          <div className="success-modal-content">
            <div>
              <div className="text-center api-response-modal-heading ">
                <div className="api-response-message">{title}</div>
              </div>
              <Divider />
              <div>
                <div className="api-response-modal-description">
                  <Box  className="send-invoice-modal-checkbox">
                    <IntCheckbox onChange={(value) => setSendData({...sendData, type: value ? 'email' : 'internal'})} value={sendData.type === 'email'}/>
                    <Typography>{t('sendByEmail')}</Typography>
                  </Box>
                  {sendData.type === 'email' ? (<InputRow
                    className={'send-invoice-modal-input'}
                    label={t('emailLabel')}
                    input={{
                      name: "email",
                      value: sendData.email,
                      onChange: (e) => setSendData({...sendData, email: e.target.value})
                    }}
                  />) : null}
                  <Divider sx={{width: '92%', marginLeft: '4%'}}/>
                  <Box  className="send-invoice-modal-checkbox">
                    <IntCheckbox onChange={(value) => setSendData({...sendData, type: value ? 'internal' : 'email'})} value={sendData.type === 'internal'}/>
                    <Typography>{t('sendInternal')}</Typography>
                  </Box>
                  {sendData.type === 'internal' ? (<InputRow
                    className={'send-invoice-modal-input'}
                    label={t('dateLabel')}
                    input={{
                      name: "date",
                      value: sendData.date,
                      onChange: (e) => setSendData({...sendData, date: e.target.value})
                    }}
                  />) : null}
                </div>
                <br/>
                <div role="presentation"
                     style={{ alignItems: 'center', display: 'flex', flex: '1 1 0%', justifyContent: 'end', padding: '24px' }}>
                  <button onClick={onCancel} type="button" className="mai-button" style={{ backgroundColor: '#fafbfb', color: '#999999', width: '30%' }}>
                    <span>{leftBtnText}</span>
                  </button>
                  {loading ? (<div className={`mai-button fill-blue`} style={{height: '32px'}}>
                    <LoadingButton
                      size="small"
                      className={!sendData.type ? `mai-button disabled` : `mai-button fill-blue`}
                      onClick={() => onConfirm(sendData)}
                      loading={loading}
                      variant="contained"
                      disabled={!sendData.type || loading }
                    >
                      {rightBtnText}
                    </LoadingButton>
                  </div>) : (
                      <LoadingButton
                        size="small"
                        className={!sendData.type ? `mai-button disabled` : `mai-button fill-blue`}
                        onClick={() => onConfirm(sendData)}
                        loading={loading}
                        variant="contained"
                        disabled={!sendData.type || loading }
                      >
                        {rightBtnText}
                      </LoadingButton>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SendInvoiceModal