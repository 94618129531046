import { Theme } from "@mui/material";

export const style = {
  fieldInput: {
    width: 320,

    'input': {
      padding: '8.5px 14px',

      "&.Mui-disabled": {
        backgroundColor: '#e9ecef !important'
      }
    }
  },
  fieldLabel: {
    color: 'rgba(41,47,65,.5)',
    textTransform: 'uppercase',
    fontSize: 14,
    letterSpacing: '.8px',
    lineHeight: '16px',
  },
  field: (theme: Theme) => ({
    gap: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  }),
  form: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    marginTop: theme.spacing(8),
  }),
  tos: {
    display: 'flex',
    alignItems: 'center',
  },
  tosLabel: {
    color: 'rgba(41,47,65,.5)',
    fontSize: 14,
    letterSpacing: '.8px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    display: 'flex',
    gap: 1,
  },
  termsOfServiceLink: {
    color: 'primary.main',
    textDecoration: 'underline',
  },
  createAccount: {
    borderRadius: 5,
  }
}