export const style = {
  tableContainer: {
    minHeight: 'calc(100vh - 150px)'
  },
  headCell: {
    color: '#292f41',
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  tableBody: {
    position: 'relative',
  },
};