import { defineMessages } from "react-intl";

export default defineMessages({
  adminSearchPlaceholder: {
    id: "Admin.adminSearchPlaceholder",
    defaultMessage: "Enter Keywords to search {whiteLabelName}",
  },
  adminSearchAll: {
    id: "Admin.adminSearchAll",
    defaultMessage: "All",
  },
  adminSearchAccounts: {
    id: "Admin.adminSearchAccounts",
    defaultMessage: "Accounts",
  },
  adminSearchCampaigns: {
    id: "Admin.adminSearchCampaigns",
    defaultMessage: "Campaigns",
  },
  reasonRejectPlaceholder: {
    id: "Admin.reasonRejectPlaceholder",
    defaultMessage: "This has been rejected due to",
  },
  verified: {
    id: "Admin.verified",
    defaultMessage: "Verified",
  },
  rejected: {
    id: "Admin.rejected",
    defaultMessage: "Rejected",
  },
  accountVerified: {
    id: "Admin.accountVerified",
    defaultMessage: "The account is verified.",
  },
  accountLegalEntityVerified: {
    id: "Admin.accountLegalEntityVerified",
    defaultMessage: "The legal entity's account has been approved.",
  },
  accountLegalEntityRejected: {
    id: "Admin.accountLegalEntityRejected",
    defaultMessage: "The legal entity's account has been rejected.",
  },
  adminLinksLegalEntity: {
    id: "Admin.adminLinksLegalEntity",
    defaultMessage: "Legal Entity",
  },
  legalEntityName: {
    id: "Admin.legalEntityName",
    defaultMessage: "Entity Name",
  },
  tinNumber: {
    id: "Admin.tinNumber",
    defaultMessage: "Tin Number",
  },
  myAccount: {
    id: "Admin.myAccount",
    defaultMessage: "My Account",
  },
  logOut: {
    id: "Auth.logOut",
    defaultMessage: "Logout",
  },
  reSent: {
    id: "Admin.reSent",
    defaultMessage: "RE-SENT",
  },
  reSentSuccessfully: {
    id: "Admin.reSentSuccessfully",
    defaultMessage: "The confirmation email is successfully re-sent.",
  },
  accountsReport: {
    id: "Admin.accountsReport",
    defaultMessage: "Accounts Report",
  },
  accountsSearchPlaceholder: {
    id: "Admin.accountsSearchPlaceholder",
    defaultMessage: "Search by Name, Company, Entity or Email",
  },
  accountsListName: {
    id: "Admin.accountsListName",
    defaultMessage: "Name",
  },
  accountsListCompanyName: {
    id: "Admin.accountsListCompanyName",
    defaultMessage: "Company Name",
  },
  accountsListEntityName: {
    id: "Admin.accountsListEntityName",
    defaultMessage: "Entity Name",
  },
  accountsListRunningCampaigns: {
    id: "Admin.accountsListRunningCampaigns",
    defaultMessage: "Running Campaigns",
  },
  accountsListImpressions: {
    id: "Admin.accountsListImpressions",
    defaultMessage: "Impressions",
  },
  accountsListTotalBalance: {
    id: "Admin.accountsListTotalBalance",
    defaultMessage: "Total Balance",
  },
  accountsTotalsRegisteredAccounts: {
    id: "Admin.accountsTotalsRegisteredAccounts",
    defaultMessage: "Registered Accounts",
  },
  accountsTotalsRegisteredAccountsTooltip: {
    id: "Admin.accountsTotalsRegisteredAccountsTooltip",
    defaultMessage: "All accounts including not moderated ones.",
  },
  accountsTotalsCompanyAccounts: {
    id: "Admin.accountsTotalsCompanyAccounts",
    defaultMessage: "Company Accounts",
  },
  accountsTotalsCompanyAccountsTooltip: {
    id: "Admin.accountsTotalsCompanyAccountsTooltip",
    defaultMessage:
      "Company accounts registered on the platform, including not moderated ones.",
  },
  accountsTotalsRunningCampaigns: {
    id: "Admin.accountsTotalsRunningCampaigns",
    defaultMessage: "Running Campaigns",
  },
  accountsTotalsRunningCampaignsTooltip: {
    id: "Admin.accountsTotalsRunningCampaignsTooltip",
    defaultMessage:
      "Approved and currently active campaigns, including campaigns with daily budget spent.”.",
  },
  accountsTotalsTotalImpressions: {
    id: "Admin.accountsTotalsTotalImpressions",
    defaultMessage: "Total Impressions",
  },
  accountsTotalsTotalImpressionsTooltip: {
    id: "Admin.accountsTotalsTotalImpressionsTooltip",
    defaultMessage:
      "Impressions earned by all campaigns running over the past 30 days.",
  },
  accountsTotalsTotalBalance: {
    id: "Admin.accountsTotalsTotalBalance",
    defaultMessage: "Total Balance",
  },
  accountsTotalsTotalBalanceTooltip: {
    id: "Admin.accountsTotalsTotalBalanceTooltip",
    defaultMessage: "The sum of all account balances.",
  },
  entityTooltip: {
    id: "Payments.entityTooltip",
    defaultMessage: "The legal name of the Company",
  },
  entityDescription: {
    id: "Admin.editEntityDescription",
    defaultMessage:
      "Entity name is the legal registered name of the company. It must be typed in Armenian.",
  },
  failHeader: {
    id: "Admin.editEntityFail",
    defaultMessage: "Unable to save entity name!",
  },
  failDescription: {
    id: "Admin.editEntityFailDescription",
    defaultMessage:
      "An error occured while saving the new entity name. Please, try again.",
  },
  successHeader: {
    id: "Admin.editEntitySuccess",
    defaultMessage: " Entity name is saved!",
  },
  successDescription: {
    id: "Admin.editEntitySuccessDescription",
    defaultMessage: "Entity name of {fullName} account has been changed!",
  },
  publishers: {
    id: "Admin.publishers",
    defaultMessage: "Publishers Linked",
  },
  publishersInventory: {
    id: "Admin.publishersInventory",
    defaultMessage: "Inventory",
  },
  publishersName: {
    id: "Admin.publishersName",
    defaultMessage: "Publisher Name Surname.",
  },
});
