import { ApiService } from "../../global/services";
// import { getPreviousWeek } from "../../global";
// import { IWhitelabel } from "../../../types";
// import { createRequest } from "../../../api/utils";
// import { PUT } from "../../../_constants";


class AdminService {
  static ENDPOINT = `${window.REACT_APP_BASE_URL}/v1/whitelabel/admin`;

  static getPublishers(data: any) {
    return ApiService.post(`${AdminService.ENDPOINT}/publishers/list?`, data);
  }
  static getModerationAccounts(data: any) {
    return ApiService.post(`${AdminService.ENDPOINT}/registrations?`, data);
  }
  static getModerationLegalEntity(data: any) {
    return ApiService.post(`${AdminService.ENDPOINT}/legal/users?`, data);
  }
  static getModerationCampaigns(data: any) {
    return ApiService.post(`${AdminService.ENDPOINT}/campaigns?`, data);
  }

  static setWhitelabelCustomizationDataRequest = (data: any) => {
    return ApiService.put(`${window.REACT_APP_BASE_URL}/v1/whitelabel?`, data);
  }
}

export default AdminService;
