const MONTHS: { [key: number]: string; } = {
  0: 'Jan',
  1: 'Feb',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'Aug',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec',
}

const MILLISECONDS_IN_ONE_DAY = 86400000;

function formatDateMDY(dateString: string | Date | undefined) {
  if (!dateString) {
    return undefined;
  }

  const date = new Date(dateString);

  // @ts-ignore
  return `${MONTHS[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
}

function formatMonth(date: Date, monthFormatter: string) {
  if (monthFormatter === 'MM') {
    return MONTHS[date.getMonth()];
  }
}
// TODO refactor
function formatDate(dateString: string | Date | undefined, format: string = 'dd/MM/yyyy/hh', deliminator = ' ') {
  if (!dateString) {
    return null;
  }
  let finalDateString = '';
  const date = new Date(dateString);

  format.split('/').forEach(function fillFinalDateString(format, index) {
    if (index !== 0) {
      finalDateString += deliminator;
    }
    if (format === 'MM') {
      finalDateString += formatMonth(date, format);
    } else if (format === 'mm') {
      finalDateString += date.getMonth() < 10 ? `0${date.getMonth()}` : date.getMonth();
    } else if (format === 'dd') {
      const days = date.getDate();
      finalDateString += days < 10 ? `0${days}` : days;
    } else if (format === 'yyyy') {
      finalDateString += date.getFullYear();
    } else if (format === 'hh') {
      const hours = date.getHours();
      const minutes = date.getMinutes();
      finalDateString += `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
    }
  });

  return finalDateString;
}

function getPreviousWeek() {
  const date = new Date();
  date.setDate(date.getDate() - 7);
  return date;
}

function getStartOfTheDay (date: string | Date | undefined) {
  if (!date) {
    return new Date();
  }
  const dateObject = new Date(date);
  dateObject.setHours(0, 0, 0, 0);
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0");
  const day = String(dateObject.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}T00:00:00.000Z`;
}

function getEndOfTheDay(date: string | Date | undefined) {
  if (!date) {
    return new Date();
  }
  const dateObject = new Date(date);
  dateObject.setHours(23, 59, 59, 999);
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0");
  const day = String(dateObject.getDate()).padStart(2, "0");
  const hours = String(dateObject.getHours()).padStart(2, "0");
  const minutes = String(dateObject.getMinutes()).padStart(2, "0");
  const seconds = String(dateObject.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
}

function differenceInDays(from: Date, to: Date) {
  from.setHours(0, 0, 0, 0);
  to.setHours(0, 0, 0, 0);
  const diff = to.getTime() - from.getTime();
  return Math.floor(diff / MILLISECONDS_IN_ONE_DAY);
}

function secondsToTimeFormat(seconds: number) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}`;
}

export {
  formatDateMDY,
  getPreviousWeek,
  getStartOfTheDay,
  getEndOfTheDay,
  formatDate,
  secondsToTimeFormat,
  differenceInDays,
}