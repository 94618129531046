import { AsyncState } from "../../global/types";

type PlatformData = {
  desktop: {
    others: number;
    total: number;
    values: {};
  };
  mobile: {
    others: number;
    total: number;
    values: {
      android?: number;
    }
  }
};

type TrafficData = {
  others: number;
  publishers: Array<{
    name: string;
    value: number;
  }>;
}

export type PlatformReportState = AsyncState<{
  clicks: PlatformData;
  impressions: PlatformData;
} | null>;

export type TrafficReportState = AsyncState<{
  clicks: TrafficData;
  impressions: TrafficData;
} | null>;

type BannerData = {
  others: number;
  total: number;
  values: {
    [key: string]: number;
  }
}

export type BannerReportState = AsyncState<{
  clicks: BannerData;
  impressions: BannerData;
} | null>

export type PieLabelRenderProps = {
  cx: number;
  cy: number;
  innerRadius: number;
  outerRadius: number;
  midAngle: number;
  value: string | number;
  name: string;
  percent: number;
}

export enum DeviceType {
  DESKTOP = 2,
  MOBILE = 3,
  ANDROID = 5,
  DESKTOP_OTHERS = 8,
  MOBILE_OTHERS = 9,
}