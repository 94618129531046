export const FAVICON_SMALL_SIZE = "32x32";
export const FAVICON_MEDIUM_SIZE = "64x64";
export const FAVICON_BIG_SIZE = "128x128";
export const LOGO_SMALL_SIZE = "158x32";
export const LOGO_BIG_SIZE = "316x64";
export const AUTH_SMALL_BACKGROUND = "1767x1080";
export const AUTH_BIG_BACKGROUND = "2880x1800";
export const EMAIL_SMALL_BACKGROUND = "616x132";
export const EMAIL_BIG_BACKGROUND = "960x216";
export const AD_LOGO_SIZE = "98x32";

export const imageTypes = {
  png: 1,
  jpg: 2,
  jpeg: 2,
  gif: 3,
  "svg+xml": 4,
  "vnd.microsoft.icon": 5,
  webp: 6,
};
