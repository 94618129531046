import { Theme } from "@mui/material";

export const style = {
  title: (theme: Theme) => ({
    fontSize: 24,
    lineHeight: 1.17,
    letterSpacing: '-0.2px',
    color: '#292f41',
    marginTop: theme.spacing(6),
  }),
  description: (theme: Theme) => ({
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: '0.2px',
    color: 'rgba(41, 47, 65, 0.5)',
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(2),
  }),
  automaticTargeting: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1.4,
    color: '#292f41',
  },
  automaticTargetingDescription: {
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: '0.2px',
    color: '#292f41',
    textAlign: 'center',
  },
  automaticTargetingBox: (isAutomaticTargeting: boolean) => (theme: Theme) => ({
    borderRadius: theme.spacing(4),
    backgroundColor: '#f4f6fa',
    display: 'flex',
    flexDirection: isAutomaticTargeting ? 'column' : 'row',
    alignItems: 'center',
    gap: theme.spacing(4),
    flex: isAutomaticTargeting ? 1 : 'unset',
    justifyContent: 'center',
    padding: `0 ${isAutomaticTargeting ? '0px min(150px, 10vw);' : '2.5vw'}`,
    marginTop: theme.spacing(4),
    height: isAutomaticTargeting ? 'unset' : 124,
    transition: 'all .7s',
    minHeight: isAutomaticTargeting ? '460px' : 'unset',
  }),
  targeting: {
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 680,
  },
  image: {
    transition: 'all .7s',
  },
  manualTargeting: (theme: Theme) => ({
    padding: theme.spacing(6, 11, 6, 8),
  }),
};
