import { Theme } from "@mui/material";

export const style = {
  trafficSource: {
    width: 'calc(100% / 3 - 10.7px)',
    backgroundColor: '#fff',
    height: 600,
    borderRadius: 1,
    boxShadow: '0 12px 8px -10px rgba(0, 0, 0, 0.12)',
    minWidth: 385,
    display: 'flex',
    flexDirection: 'column',
  },
  header: (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(4),
    backgroundColor: '#dfe7f0',
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
  }),
  title: {
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: '0.8px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    color: '#292f41',
  },
  tabs: {
    minHeight: 0,
    border: '1px solid #dfe7f0',
    borderRadius: 1,
  },
  indicator: {
    display: 'none',
  },
  tab: (theme: Theme) => ({
    width: '50%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    minHeight: 0,
    '&.Mui-selected': {
      backgroundColor: 'primary.main',
      color: '#fff',
    },
    textTransform: 'none',
    fontSize: 16,
    borderRadius: 1,
  }),
  content: (theme: Theme) => ({
    padding: theme.spacing(4),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  }),
  noDataBox: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 4,
  },
  resetFilters: {
    borderRadius: 5,
    fontWeight: 700,
  },
  noDataAvailable: {
    color: '#292f41',
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '24px',
    textAlign: 'center',
  },
  sourceName: {
    color: '#292f41',
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '24px',
  },
  sourceBar: (width: number) => ({
    height: 36,
    minWidth: 2,
    background: 'linear-gradient(225deg, #3A77EB, #6CA1F2)',
    width: `${width}%`,
  }),
  sourceMetrics: {
    display: 'flex',
    gap: 2,
    alignItems: 'center',
  },
  sourceValue: {
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: '.8px',
    lineHeight: '16px',
    color: 'primary.main',
  },
  metrics: (theme: Theme) => ({
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  }),
  source: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    cursor: 'pointer',
    '&:last-child': {
      cursor: 'inherit',
    }
  },
  select: (theme: Theme) => ({
    height: 40,
    marginTop: theme.spacing(4),
  }),
};
