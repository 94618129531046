import React, { useEffect, useState } from "react";
import { AdminDrawer,
  // Modal,
  Sidebar } from "../../containers";
import { FormattedMessage, IntlShape } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { ISpace, IUser, IWhitelabelSettings } from "../../types";
import { AUTH_TOKEN, REFRESH_TOKEN, userRoles } from "../../_constants";
import "./style.scss";
import clsx from "clsx";
import { Button } from "@mui/material";
import { getSpaceImage, Hamburger } from "../../modules";
import {
  selectCurrentUser,
  selectCurrentUserRole, selectCurrentUserSpace,
  selectCurrentUserSpaceImage,
  selectCurrentUserSpaceName, setLogout,
} from "../../modules/store/reducers/authentication";
import { whitelabelSettingsSelector } from "../../modules/store/reducers/admin";
import axios from "axios";

const styles = {
  logoutBtn: {
    backgroundColor: '#84EBFD',
    boxShadow: 'none',
    borderRadius: 50,
    fontWeight: 700,
    whiteSpace: 'nowrap',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#84EBFD',
      opacity: 0.7
    }
  }
}

interface IProps {
  title: string | JSX.Element | any;
  iconName: string;
  className?: string;
  children: JSX.Element[] | JSX.Element | any;
  isProfilePage?: boolean;
  logout?: Function;
  currentBalance?: number;
  currentUserRole?: number;
  currentUserSpaceName?: string;
  currentUserSpaceImage?: string;
  header?: JSX.Element[] | JSX.Element;
  currentUser?: IUser;
  APIResponseSuccess?: Function;
  intl?: IntlShape;
  isOwnerAccount?: boolean;
  currentUserSpace?: ISpace;
  settings?: IWhitelabelSettings;
  modalType?: any;
  toggle?: any;
  isOpen?: any;
  closeModal?: any;
  modalTypes?: any;
  modalData?: any;
  openModal?: any;
}

const AuthTitleWrapper = (props:IProps) => {

  const {
    children,
    title,
    isProfilePage,
    className = "",
    // modalType,
    // toggle,
    // isOpen,
    // closeModal,
    // modalTypes,
    // modalData,
    // openModal,
    header,
  } = props;

  const dispatch = useDispatch();

  const settings = useSelector(whitelabelSettingsSelector);
  const currentUser = useSelector(selectCurrentUser);
  const currentUserRole = useSelector(selectCurrentUserRole);
  const currentUserSpace = useSelector(selectCurrentUserSpace);
  const currentUserSpaceName = useSelector(selectCurrentUserSpaceName);
  const currentUserSpaceImage = useSelector(selectCurrentUserSpaceImage);

  const [adminPanelIsOpen, setAdminPanelIsOpen] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [navBarActiveClass, setNavBarActiveClass] = useState<string>("");

  const toggleMenu = () => {
    setActive(!active);
  };

  const openAdminPanel = () => {
    if (navBarActiveClass) {
      toggleMenu();
    }
    setAdminPanelIsOpen(!adminPanelIsOpen)
  };

  const handleKeyUp = (event: any) => {
    if (
      !adminPanelIsOpen &&
      event.keyCode === 191 &&
      event.target.tagName !== "INPUT"
    ) {
      openAdminPanel();
    }
  };

  const logout = () => {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    sessionStorage.removeItem('currentAccountCurrency');
    delete axios.defaults.headers.common["Authorization"];
    dispatch(setLogout());
  }

  useEffect(() => {
    if (currentUserRole === userRoles.WL_OWNER) {
      document.addEventListener("keyup", handleKeyUp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (active) {
      document.body.style.position = "fixed";
      setNavBarActiveClass("is-active")
    } else {
      document.body.style.position = "static";
      setNavBarActiveClass("");
    }
  }, [active]);


  // const openRefillModal = () => {
  //
  //   const { tos_status_type_key } = currentUser;
  //
  //   if (
  //     tos_status_type_key !== null &&
  //     tos_status_type_key === TOS_STATUSES.PENDING
  //   ) {
  //     APIResponseSuccess(
  //       formatMessage(billingMessages.success),
  //       formatMessage(profileMessages.legalEntityInfoPending)
  //     );
  //     return;
  //   }
  //   openModal({
  //     modalType: modalTypes.refillBalanceModal,
  //     modalData: {},
  //   });
  // };



  const isMyAccount = currentUserSpace?.userId === currentUser?.id;

  return (
    <>
      <Sidebar settings={settings} isActive={navBarActiveClass} />
      {adminPanelIsOpen && (
        <AdminDrawer
          open={adminPanelIsOpen}
          closeAdminPanel={() => {
            setAdminPanelIsOpen(false)}}
        />
      )}
      <div className="auth-title-wrapper-container">
        <div
          className={clsx("auth-title", {
            user: currentUserRole === userRoles.USER_OWNER,
          })}
        >
          {[userRoles.WL_OWNER, userRoles.WL_MODERATOR].includes(
              currentUserRole
            ) ? (
              <div
                className={clsx("auth-title-admin", {
                  open: adminPanelIsOpen,
                })}
                onClick={openAdminPanel}
              >
                <div className="auth-title-admin-account-image">
                  <img
                    src={getSpaceImage(currentUserSpaceImage)}
                    alt="space"
                  />
                </div>
                <div className="auth-title-admin-text">
                  <p className="auth-title-admin-account">
                    <FormattedMessage
                      id="Admin.currentAccount"
                      defaultMessage="Current Account"
                    />{" "}
                    {isMyAccount &&
                      `(My Account)`}
                  </p>
                  <p className="auth-title-admin-account-name">
                    {currentUserSpaceName}{" "}
                  </p>
                </div>
                <div className="auth-title-admin-arrow">
                  <div
                    className={clsx("dropdown-arrow", {
                      open: adminPanelIsOpen,
                    })}
                  />
                </div>
              </div>
            ) : null
          }
        <div className="auth-title-meta">
            <span
              className={clsx("auth-title-text", {
                user: currentUserRole === userRoles.USER_OWNER,
              })}
            >
              {title}
            </span>
        </div>
        <div className="auth-title-billing">
          {isProfilePage ? (
            <div className="auth-title-logout">
              <Button
                className={'mai-button'}
                onClick={() => logout()}
                sx={styles.logoutBtn}
                variant={'contained'}
              >{'Logout'}</Button>
            </div>
            ) : null
          }
            <div className="menu-icon">
              <Hamburger
                sidebarToggle={toggleMenu}
                sidebarOpen={!!navBarActiveClass}
                icon="icon1"
              />
            </div>
          </div>
        </div>
      </div>
      {header && <div className="auth-header">{header}</div>}
      <div className={`auth-children ${className}`}>{children}</div>
      {/*{isOpen && (*/}
      {/*  <Modal*/}
      {/*    modalData={modalData}*/}
      {/*    modalType={modalType}*/}
      {/*    toggle={toggle}*/}
      {/*    isOpen={isOpen}*/}
      {/*    openModal={openModal}*/}
      {/*    closeModal={closeModal}*/}
      {/*    modalTypes={modalTypes}*/}
      {/*  />*/}
      {/*)}*/}
    </>
  );
}

// const mapStateToProps = (state: IRootState) => ({
  // currentBalance: select.space.currentBalanceSelector(state),
  // currentUserSpaceName: select.authentication.selectCurrentUserSpaceName(state),
  // currentUserRole: select.authentication.selectCurrentUserRole(state),
  // settings: select.admin.whitelabelSettingsSelector(state),
  // currentUserSpaceImage: select.authentication.selectCurrentUserSpaceImage(state),
  // currentUser: select.authentication.selectCurrentUser(state),
  // isOwnerAccount: select.authentication.isOwnerAccountSelector(state),
  // currentUserSpace: select.authentication.selectCurrentUserSpace(state),
// });

// const mapDispatchToProps = (dispatch: Dispatch) => ({
  // logout: dispatch.authentication.logout,
  // APIResponseSuccess: bindActionCreators(APIResponseSuccess, dispatch),
// });

export default AuthTitleWrapper;
