import { Theme } from "@mui/material";

export const style = {
  view: (theme: Theme) => ({
    background: `linear-gradient(225deg,${theme.palette.secondary.main},${theme.palette.primary.main}) center center/cover`,
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: theme.spacing(6),
    paddingTop: '10vh',
  }),
  companyLogo: {
    maxWidth: 200,
    maxHeight: 100,
  },
  createAccount: (theme: Theme) => ({
    color: 'hsla(0,0%,100%,.5)',
    textTransform: 'uppercase',
    marginTop: 'auto',
    marginBottom: theme.spacing(6),
    cursor: 'pointer',
  }),
}