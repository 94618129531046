import { Theme } from "@mui/material";

export const style = {
  input: (theme: Theme) => ({
    minWidth: 160,
    backgroundColor: '#fff',
    border: '1px solid #dfe7f0',
    borderRadius: 1,
    transition: 'border .3s',
    minHeight: '100%',
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(3.5),
  }),
  dateInput: {
    height: 40,
    cursor: 'pointer',
  },
  popoverContent: (theme: Theme) => ({
    padding: theme.spacing(4),
    display: 'flex',
    gap: theme.spacing(4),
  }),
  customRange: {
    fontSize: 14,
    color: 'rgba(41,47,65,.5)',
    letterSpacing: '.8px',
    lineHeight: '16px',
    textTransform: 'uppercase',
  },
  datePreset: {
    fontSize: 14,
    color: 'rgba(41,47,65,.5)',
    letterSpacing: '.8px',
    lineHeight: '16px',
    textTransform: 'uppercase',
  },
  dateInputField: {
    display: 'flex',
    alignItems: 'center',
  },
  dateLabelField: (theme: Theme) => ({
    width: 85,
    fontSize: 14,
    color: 'rgba(41,47,65,.5)',
    border: '1px solid #dfe7f0',
    borderTopLeftRadius: 1,
    borderBottomLeftRadius: 1,
    textTransform: 'uppercase',
    paddingLeft: theme.spacing(2),
    height: 42,
    display: 'flex',
    alignItems: 'center',
  }),
  dateSelection: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  preset: (theme: Theme) => ({
    borderBottom: '1px solid #f4f6fa',
    cursor: 'pointer',
    transition: '.2s ease',
    fontWeight: 600,
    lineHeight: '24px',
    fontSize: 18,
    padding: theme.spacing(2, 3, 2, 0),
    '&:hover': {
      color: 'primary.main',
    }
  }),
  popover: {
    overflow: 'visible',
  },
  textField: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  selected: {
    color: 'primary.main',
  }
}