import { ApiService } from "../modules/global/services";

export const getDictionaryRequest = (lang: string = "en_US") =>
  ApiService.baseGet(`/v1/dictionary/${lang}`);

export const getDictionaryHashRequest = () =>
  ApiService.baseGet("/v1/dictionary/hash");

export const getDictionaryByIdRequest = (id: string) =>
  ApiService.baseGet(`/v1/dictionary/meta/${id}/en_US`);
