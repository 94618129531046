// Async action postfyxes
export const START_POST = "_START";
export const FAIL_POST = "_FAIL";
export const SUCCESS_POST = "_SUCCESS";

/**
 * NOTE: Actiontype formula: [reducer_name][...nested_data_names][action]
 */

// Dictionary
export const DICTIONARY_GET = "DICTIONARY_GET";
export const DICTIONARY_GET_HASH = "DICTIONARY_GET_HASH";
export const GET_DATA_FROM_DICTIONARY = "GET_DATA_FROM_DICTIONARY";

// Authentication
export const LOGOUT = "LOGOUT";
export const SWITCH_SPACE = "SWITCH_SPACE";
export const SWITCH_SPACE_START = "SWITCH_SPACE_START";
export const SWITCH_SPACE_SUCCESS = "SWITCH_SPACE_SUCCESS";

export const AUTH_REGISTRATION_CONFIRM = "AUTH_REGISTRATION_CONFIRM";
export const AUTH_REGISTRATION_CONFIRM_START =
  "AUTH_REGISTRATION_CONFIRM_START";
export const AUTH_REGISTRATION_CONFIRM_SUCCESS =
  "AUTH_REGISTRATION_CONFIRM_SUCCESS";
export const AUTH_REGISTRATION_CONFIRM_FAIL = "AUTH_REGISTRATION_CONFIRM_FAIL";

export const AUTH_REGISTRATION_VERIFY = "AUTH_REGISTRATION_VERIFY";
export const AUTH_REGISTRATION_VERIFY_START = "AUTH_REGISTRATION_VERIFY_START";
export const AUTH_REGISTRATION_VERIFY_SUCCESS =
  "AUTH_REGISTRATION_VERIFY_SUCCESS";
export const AUTH_REGISTRATION_VERIFY_FAIL = "AUTH_REGISTRATION_VERIFY_FAIL";

export const AUTH_REGISTER = "AUTH_REGISTER";
export const AUTH_REGISTER_SUCCESS = "AUTH_REGISTER_SUCCESS";
export const AUTH_REGISTER_FAIL = "AUTH_REGISTER_FAIL";

export const AUTH_CHECK_REGISTRATION_TOKEN = "AUTH_CHECK_REGISTRATION_TOKEN";
export const AUTH_CHECK_REGISTRATION_TOKEN_START =
  "AUTH_CHECK_REGISTRATION_TOKEN_START";
export const AUTH_CHECK_REGISTRATION_TOKEN_SUCCESS =
  "AUTH_CHECK_REGISTRATION_TOKEN_SUCCESS";
export const AUTH_CHECK_REGISTRATION_TOKEN_FAIL =
  "AUTH_CHECK_REGISTRATION_TOKEN_FAIL";

export const AUTH_CHECK_INVITATION_TOKEN = "AUTH_CHECK_INVITATION_TOKEN";
export const AUTH_CHECK_INVITATION_TOKEN_START =
  "AUTH_CHECK_INVITATION_TOKEN_START";
export const AUTH_CHECK_INVITATION_TOKEN_SUCCESS =
  "AUTH_CHECK_INVITATION_TOKEN_SUCCESS";
export const AUTH_CHECK_INVITATION_TOKEN_FAIL =
  "AUTH_CHECK_INVITATION_TOKEN_FAIL";

export const AUTH_GET_REGISTRATION = "AUTH_GET_REGISTRATION";
export const AUTH_GET_REGISTRATION_START = "AUTH_GET_REGISTRATION_START";
export const AUTH_GET_REGISTRATION_SUCCESS = "AUTH_GET_REGISTRATION_SUCCESS";
export const AUTH_GET_REGISTRATION_FAIL = "AUTH_GET_REGISTRATION_FAIL";

export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_FAIL = "AUTH_LOGIN_FAIL";

export const AUTH_TOKEN_INVALID = "AUTH_TOKEN_INVALID";
export const AUTH_GET_USER = "AUTH_GET_USER";
export const AUTH_GET_USER_SUCCESS = "AUTH_GET_USER_SUCCESS";
export const AUTH_GET_USER_FAIL = "AUTH_GET_USER_FAIL";
export const AUTH_UPDATE_USER = "AUTH_UPDATE_USER";
export const AUTH_UPDATE_USER_START = "AUTH_UPDATE_USER_START";
export const AUTH_UPDATE_USER_SUCCESS = "AUTH_UPDATE_USER_SUCCESS";
export const AUTH_UPDATE_USER_FAIL = "AUTH_UPDATE_USER_FAIL";

export const AUTH_INVITE_USERS = "AUTH_INVITE_USERS";
export const AUTH_INVITE_USERS_SUCCESS = "AUTH_INVITE_USERS_SUCCESS";
export const AUTH_FORGOT_PASSWORD_INIT = "AUTH_FORGOT_PASSWORD_INIT";
export const AUTH_FORGOT_PASSWORD_INIT_SUCCESS =
  "AUTH_FORGOT_PASSWORD_INIT_SUCCESS";
export const AUTH_FORGOT_PASSWORD_INIT_FAIL = "AUTH_FORGOT_PASSWORD_INIT_FAIL";

export const AUTH_CHANGE_PASSWORD = "AUTH_CHANGE_PASSWORD";
export const AUTH_CHANGE_PASSWORD_START = "AUTH_CHANGE_PASSWORD_START";
export const AUTH_CHANGE_PASSWORD_SUCCESS = "AUTH_CHANGE_PASSWORD_SUCCESS";
export const AUTH_CHANGE_PASSWORD_FAIL = "AUTH_CHANGE_PASSWORD_FAIL";

export const AUTH_CHANGE_LANGUAGE = "AUTH_CHANGE_LANGUAGE";
export const AUTH_CHANGE_LANGUAGE_START = "AUTH_CHANGE_LANGUAGE_START";
export const AUTH_CHANGE_LANGUAGE_SUCCESS = "AUTH_CHANGE_LANGUAGE_SUCCESS";
export const AUTH_CHANGE_LANGUAGE_FAIL = "AUTH_CHANGE_LANGUAGE_FAIL";

export const AUTH_FORGOT_PASSWORD_FINISH = "AUTH_FORGOT_PASSWORD_INIT";
export const AUTH_FORGOT_PASSWORD_FINISH_SUCCESS =
  "AUTH_FORGOT_PASSWORD_INIT_SUCCESS";
export const AUTH_FORGOT_PASSWORD_FINISH_FAIL =
  "AUTH_FORGOT_PASSWORD_INIT_FAIL";

export const AUTH_INVITATION_COMPLETE = "AUTH_INVITATION_COMPLETE";
export const AUTH_INVITATION_COMPLETE_SUCCESS =
  "AUTH_INVITATION_COMPLETE_SUCCESS";
export const AUTH_INVITATION_COMPLETE_FAIL = "AUTH_INVITATION_COMPLETE_FAIL";
export const AUTH_SET_JWT_TOKEN = "AUTH_SET_JWT_TOKEN";
export const AUTH_GET_USER_ACCOUNT = "AUTH_GET_USER_ACCOUNT";
export const AUTH_GET_USER_ACCOUNT_SUCCESS = "AUTH_GET_USER_ACCOUNT_SUCCESS";
export const AUTH_SET_USER_ACCOUNT = "AUTH_SET_USER_ACCOUNT";
export const AUTH_SET_USER_ACCOUNT_SUCCESS = "AUTH_SET_USER_ACCOUNT_SUCCESS";

// Preferences
export const PREFERENCE_CHANGE = "PREFERENCE_CHANGE";

// Campaign
export const ADD_BLOCKED_WEBSITES_LIST = "ADD_BLOCKED_WEBSITES_LIST";
export const NULLIFY_CAMPAIGNS = "NULLIFY_CAMPAIGNS";
export const GET_CAMPAIGNS = "GET_CAMPAIGNS";
export const GET_CAMPAIGNS_SUCCESS = "GET_CAMPAIGNS_SUCCESS";
export const GET_CAMPAIGNS_FAIL = "GET_CAMPAIGNS_FAIL";

export const CLEAR_CAMPAIGN = "CLEAR_CAMPAIGN";

export const GET_CAMPAIGN = "GET_CAMPAIGN";
export const GET_CAMPAIGN_SUCCESS = "GET_CAMPAIGN_SUCCESS";
export const GET_CAMPAIGN_FAIL = "GET_CAMPAIGN_FAIL";
export const RUN_CAMPAIGN_SUCCESS = "RUN_CAMPAIGN_SUCCESS";
export const RUN_CAMPAIGN = "RUN_CAMPAIGN";
export const PUBLISH_CAMPAIGN = "PUBLISH_CAMPAIGN";
export const PUBLISH_CAMPAIGN_SUCCESS = "PUBLISH_CAMPAIGN_SUCCESS";
export const PAUSE_CAMPAIGN_SUCCESS = "PAUSE_CAMPAIGN_SUCCESS";
export const PAUSE_CAMPAIGN = "PAUSE_CAMPAIGN";
export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN";

export const GET_CAMPAIGN_CPM_ESTIMATION = "GET_CAMPAIGN_CPM_ESTIMATION";
export const GET_CAMPAIGN_CPM_ESTIMATION_SUCCESS =
  "GET_CAMPAIGN_CPM_ESTIMATION_SUCCESS";

export const GET_STARRED_CAMPAIGNS = "GET_STARRED_CAMPAIGNS";
export const GET_STARRED_CAMPAIGNS_SUCCESS = "GET_STARRED_CAMPAIGNS_SUCCESS";
export const GET_STARRED_CAMPAIGNS_FAIL = "GET_STARRED_CAMPAIGNS_FAIL";

export const GET_DASHBOARD_CAMPAIGNS = "GET_DASHBOARD_CAMPAIGNS";
export const GET_DASHBOARD_CAMPAIGNS_SUCCESS =
  "GET_DASHBOARD_CAMPAIGNS_SUCCESS";
export const GET_DASHBOARD_CAMPAIGNS_FAIL = "GET_DASHBOARD_CAMPAIGNS_FAIL";

export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
export const CREATE_CAMPAIGN_SUCCESS = "CREATE_CAMPAIGN_SUCCESS";
export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN";
export const UPDATE_CAMPAIGN_SUCCESS = "UPDATE_CAMPAIGN_SUCCESS";

export const SAVE_AND_PUBLISH_CAMPAIGN = "SAVE_AND_PUBLISH_CAMPAIGN";
export const SAVE_AND_PUBLISH_CAMPAIGN_SUCCESS =
  "SAVE_AND_PUBLISH_CAMPAIGN_SUCCESS";

export const CREATE_BLOCKLIST = "CREATE_BLOCKLIST";
export const CREATE_PREFERRED_LIST = "CREATE_PREFERRED_LIST";
export const UPDATE_BLOCKLIST = "UPDATE_BLOCKLIST";
export const DELETE_BLOCKLIST = "DELETE_BLOCKLIST";
export const UPDATE_PREFERRED_LIST = "UPDATE_PREFERRED_LIST";
export const DELETE_PREFERRED_LIST = "DELETE_PREFERRED_LIST";
export const UPDATE_CAMPAIGN_FILTERS = "UPDATE_CAMPAIGN_FILTERS";
export const UPDATE_CAMPAIGN_SORT_PARAMS = "UPDATE_CAMPAIGN_SORT_PARAMS";
export const TOGGLE_FAVORITE_CAMPAIGN = "TOGGLE_FAVORITE_CAMPAIGN";
export const TOGGLE_FAVORITE_CAMPAIGN_SUCCESS =
  "TOGGLE_FAVORITE_CAMPAIGN_SUCCESS";
export const NULLIFY_CAMPAIGN = "NULLIFY_CAMPAIGN";
export const NULLIFY_BLOCKLIST_PREFERREDLIST =
  "NULLIFY_BLOCKLIST_PREFERREDLIST";
export const GET_AUDIENCE_SIZE = "GET_AUDIENCE_SIZE";
export const GET_AUDIENCE_SIZE_START = "GET_AUDIENCE_SIZE_START";
export const GET_AUDIENCE_SIZE_SUCCESS = "GET_AUDIENCE_SIZE_SUCCESS";
export const GET_LOCATION_COORDINATES = "GET_LOCATION_COORDINATES";
export const GET_LOCATION_COORDINATES_SUCCESS =
  "GET_LOCATION_COORDINATES_SUCCESS";
export const CHANGE_BANNER_TYPE_KEY = "CHANGE_BANNER_TYPE_KEY";
// Audience

export const GET_AUDIENCE_LIST = "GET_AUDIENCE_LIST";
export const GET_AUDIENCE_LIST_START = "GET_AUDIENCE_LIST_START";
export const GET_AUDIENCE_LIST_SUCCESS = "GET_AUDIENCE_LIST_SUCCESS";
export const GET_AUDIENCE_LIST_FAIL = "GET_AUDIENCE_LIST_FAIL";
export const UPLOAD_AUDIENCE = "UPLOAD_AUDIENCE";
export const UPLOAD_AUDIENCE_START = "UPLOAD_AUDIENCE_START";
export const UPLOAD_AUDIENCE_SUCCESS = "UPLOAD_AUDIENCE_SUCCESS";
export const UPLOAD_AUDIENCE_FAIL = "UPLOAD_AUDIENCE_FAIL";
export const ACCEPT_AUDIENCE_FILE_UPLOADING = "ACCEPT_AUDIENCE_FILE_UPLOADING";
export const ACCEPT_AUDIENCE_FILE_UPLOADING_SUCCESS =
  "ACCEPT_AUDIENCE_FILE_UPLOADING_SUCCESS";
export const ACCEPT_AUDIENCE_FILE_UPLOADING_FAIL =
  "ACCEPT_AUDIENCE_FILE_UPLOADING_FAIL";
export const CANCEL_AUDIENCE_FILE_UPLOADING = "CANCEL_AUDIENCE_FILE_UPLOADING";
export const CANCEL_AUDIENCE_FILE_UPLOADING_SUCCESS =
  "CANCEL_AUDIENCE_FILE_UPLOADING_SUCCESS";
export const CANCEL_AUDIENCE_FILE_UPLOADING_FAIL =
  "CANCEL_AUDIENCE_FILE_UPLOADING_FAIL";
export const GET_CAMPAIGN_AUDIENCE = "GET_CAMPAIGN_AUDIENCE";
export const GET_CAMPAIGN_AUDIENCE_SUCCESS = "GET_CAMPAIGN_AUDIENCE_SUCCESS";
export const GET_AUDIENCE = "GET_AUDIENCE";
export const GET_AUDIENCE_SUCCESS = "GET_AUDIENCE_SUCCESS";
export const GET_AUDIENCE_FAIL = "GET_AUDIENCE_FAIL";
export const NULLIFY_AUDIENCE_DETAILS = "NULLIFY_AUDIENCE_DETAILS";

// Creatives

export const GET_CAMPAIGN_CREATIVE = "GET_CAMPAIGN_CREATIVE";
export const GET_CAMPAIGN_CREATIVE_SUCCESS = "GET_CAMPAIGN_CREATIVE_SUCCESS";

export const UPDATE_CREATIVE = "UPDATE_CREATIVE";
export const UPDATE_CREATIVE_SUCCESS = "UPDATE_CREATIVE_SUCCESS";

// spaces

export const GET_USERS_BY_SPACE_ID = "GET_USERS_BY_SPACE_ID";
export const GET_USERS_BY_SPACE_ID_SUCCESS = "GET_USERS_BY_SPACE_ID_SUCCESS";
export const SPACE_REINVITE_USER = "SPACE_REINVITE_USER";
export const SPACE_REVOKE_INVITATION = "SPACE_REVOKE_INVITATION";
export const SPACE_REVOKE_INVITATION_SUCCESS =
  "SPACE_REVOKE_INVITATION_SUCCESS";
export const CHANGE_USER_ROLE = "CHANGE_USER_ROLE";
export const CHANGE_USER_ROLE_SUCCESS = "CHANGE_USER_ROLE_SUCCESS";
export const CHANGE_USER_ROLE_FAIL = "CHANGE_USER_ROLE_FAIL";
export const CHANGE_USER_PAYMENT_ROLE = "CHANGE_USER_PAYMENT_ROLE";
export const CHANGE_USER_PAYMENT_ROLE_SUCCESS =
  "CHANGE_USER_PAYMENT_ROLE_SUCCESS";
export const CHANGE_USER_PAYMENT_ROLE_FAIL = "CHANGE_USER_PAYMENT_ROLE_FAIL";
export const CREATE_SPACE = "CREATE_SPACE";
export const CREATE_SPACE_SUCCESS = "CREATE_SPACE_SUCCESS";
export const CREATE_SPACE_FAIL = "CREATE_SPACE_FAIL";
export const UPDATE_SPACE = "UPDATE_SPACE";
export const UPDATE_SPACE_SUCCESS = "UPDATE_SPACE_SUCCESS";
export const UPDATE_SPACE_FAIL = "UPDATE_SPACE_FAIL";
export const GET_SPACE = "GET_SPACE";
export const GET_SPACE_SUCCESS = "GET_SPACE_SUCCESS";
export const GET_SPACE_FAIL = "GET_SPACE_FAIL";
export const NULLIFY_SPACE = "NULLIFY_SPACE";

// Reports
export const GET_REPORTS = "GET_REPORTS";
export const GET_REPORTS_START = "GET_REPORTS_START";
export const GET_REPORTS_SUCCESS = "GET_REPORTS_SUCCESS";
export const GET_REPORTS_FAIL = "GET_REPORTS_FAIL";

export const SET_REPORTS_FILTERS = "SET_REPORTS_FILTERS";

export const NULLIFY_REPORTS = "NULLIFY_REPORTS";

// White Label

export const GET_COMPANY_WHITELABEL_CUSTOMIZE =
  "GET_COMPANY_WHITELABEL_CUSTOMIZE";
export const GET_COMPANY_WHITELABEL_CUSTOMIZE_FAIL =
  "GET_COMPANY_WHITELABEL_CUSTOMIZE_FAIL";
export const GET_COMPANY_WHITELABEL_CUSTOMIZE_SUCCESS =
  "GET_COMPANY_WHITELABEL_CUSTOMIZE_SUCCESS";
export const GET_COMPANY_DATA = "GET_COMPANY_DATA";
export const GET_COMPANY_DATA_FAIL = "GET_COMPANY_DATA_FAIL";
export const GET_COMPANY_DATA_SUCCESS = "GET_COMPANY_DATA_SUCCESS";
export const SET_COMPANY_WHITELABEL_CUSTOMIZE =
  "SET_COMPANY_WHITELABEL_CUSTOMIZE";
export const SET_COMPANY_WHITELABEL_CUSTOMIZE_FAIL =
  "SET_COMPANY_WHITELABEL_CUSTOMIZE_FAIL";
export const SET_COMPANY_WHITELABEL_CUSTOMIZE_SUCCESS =
  "SET_COMPANY_WHITELABEL_CUSTOMIZE_SUCCESS";
export const WHITE_LABEL_GET = "WHITE_LABEL_GET";
export const WHITE_LABEL_GET_FAIL = "WHITE_LABEL_GET_FAIL";
export const WHITE_LABEL_GET_SUCCESS = "WHITE_LABEL_GET_SUCCESS";
export const CHAT_INITIALIZED_SUCCESS = "CHAT_INITIALIZED_SUCCESS";

// Payments

export const SPACE_CURRENT_BALANCE_GET = "SPACE_CURRENT_BALANCE_GET";
export const SPACE_CURRENT_BALANCE_GET_FAIL = "SPACE_CURRENT_BALANCE_GET_FAIL";
export const SPACE_CURRENT_BALANCE_GET_SUCCESS =
  "SPACE_CURRENT_BALANCE_GET_SUCCESS";
export const REFILL_BALANCE_HISTORY_GET = "REFILL_BALANCE_HISTORY_GET";
export const REFILL_BALANCE_HISTORY_GET_FAIL =
  "REFILL_BALANCE_HISTORY_GET_FAIL";
export const REFILL_BALANCE_HISTORY_GET_SUCCESS =
  "REFILL_BALANCE_HISTORY_GET_SUCCESS";

export const INCOME_HISTORY_GET = "INCOME_HISTORY_GET";
export const INCOME_HISTORY_GET_FAIL = "INCOME_HISTORY_GET_FAIL";
export const INCOME_HISTORY_GET_SUCCESS = "INCOME_HISTORY_GET_SUCCESS";
export const PAYMENTS_HISTORY_MODAL_GET = "PAYMENTS_HISTORY_MODAL_GET";
export const PAYMENTS_HISTORY_MODAL_GET_FAIL =
  "PAYMENTS_HISTORY_MODAL_GET_FAIL";
export const PAYMENTS_HISTORY_MODAL_GET_SUCCESS =
  "PAYMENTS_HISTORY_MODAL_GET_SUCCESS";
export const SET_SPACE_REFILL_BALANCE = "SET_SPACE_REFILL_BALANCE";
export const GET_SPACE_REFILL_PAYMENT_ID = "GET_SPACE_REFILL_PAYMENT_ID";
export const UPDATE_PAYMENTS_FILTERS = "UPDATE_PAYMENTS_FILTERS";
export const NULLIFY_PAYMENTS_FILTERS = "NULLIFY_PAYMENTS_FILTERS";
export const NULLIFY_PAYMENTS_MODAL_DATA = "NULLIFY_PAYMENTS_MODAL_DATA";
export const WITHDRAW_BALANCE_GET = "WITHDRAW_BALANCE_GET";
export const WITHDRAW_BALANCE_GET_FAIL = "WITHDRAW_BALANCE_GET_FAIL";
export const WITHDRAW_BALANCE_GET_SUCCESS = "WITHDRAW_BALANCE_GET_SUCCESS";
export const SPACE_PAYMENTS_METHODS_GET = "SPACE_PAYMENTS_METHODS_GET";
export const SPACE_PAYMENTS_METHODS_GET_FAIL =
  "SPACE_PAYMENTS_METHODS_GET_FAIL";
export const SPACE_PAYMENTS_METHODS_GET_SUCCESS =
  "SPACE_PAYMENTS_METHODS_GET_SUCCESS";
export const SPACE_PAYMENTS_METHODS_SEND = "SPACE_PAYMENTS_METHODS_SEND";
export const SPACE_PAYMENTS_METHODS_SEND_FAIL =
  "SPACE_PAYMENTS_METHODS_SEND_FAIL";
export const SPACE_PAYMENTS_METHODS_SEND_SUCCESS =
  "SPACE_PAYMENTS_METHODS_SEND_SUCCESS";
export const WITHDRAW_AMOUNT_SEND = "WITHDRAW_AMOUNT_SEND";

export const ROUTES_ADD = "ROUTES_ADD";