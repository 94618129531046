import "./style.scss";
import IntDatePicker from "../IntForm/IntDatePicker";
import React from "react";

interface IProps {
  label?: string;
  type?: string;
  value?: any;
  format?:string;
  variant?: 'outlined' | 'filled' | 'standard';
  disabled?: boolean;
  autoFocus?: boolean;
  currentValue?: any;
  fullWidth?: boolean;
  placeholder?: string;
  input?: any;
  className?: string;
  inputClassName?: string;
}

const DateRow = ({...props}: IProps) => {
  const { className = '', fullWidth = false, input = {}, label, disabled} = props;
  const {name, value, format, onChange} = input;

  return (
    <div className={`input-row ${fullWidth ? 'w-100' : ''} ${className}`}>
      {label ? (<div className={'label-wrapper'}>
        <label htmlFor={`${name}`} className={'label'}>{label}</label>
      </div>) : null}
      <div className={`input-wrapper ${fullWidth ? 'w-100' : ''}`}>
        <IntDatePicker
          className={`input-field ${disabled ? 'disabled' : ''}`}
          format={format}
          disabled={disabled}
          name={name}
          value={new Date(value.toString().split('.')[0])}
          onChange={onChange}
        />
      </div>
    </div>
  )
}

export default DateRow;