/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 9/3/2023
 * All Rights Reserved.
 */
import { WEEKDAYS } from "../../utils";
import { style } from './style';
import { Box } from "@mui/material";
import { useState } from "react";
import { doubleDigits } from "../../../../utils";

const rows = Array(24).fill(0);


type Props = {
  schedule: TSchedule;
  setSchedule: (param: TSchedule) => void;
}

function Scheduling({ schedule, setSchedule }: Props) {
  const [isMouseDown, setIsMouseDown] = useState(false);

  function handleHourSchedule(weekdayIndex: number, index: number) {
    const transformedIndex = doubleDigits(+index);
    const scheduleCopy = {...schedule};
    if (scheduleCopy[weekdayIndex].includes(transformedIndex)) {
      scheduleCopy[weekdayIndex] = scheduleCopy[weekdayIndex].filter(index => index !== transformedIndex);
    } else {
      scheduleCopy[weekdayIndex] = [...scheduleCopy[weekdayIndex], transformedIndex];
    }
    setSchedule(scheduleCopy);
  }

  function handleMouseEnter(weekdayIndex: number, index: number) {
    if (isMouseDown) {
      handleHourSchedule(weekdayIndex, index);
    }
  }

  function handleMouseDown() {
    setIsMouseDown(true);
  }

  function handleMouseUp() {
    setIsMouseDown(false);
  }

  function handleHourScheduleForAllDays(index: number) {
    const transformedIndex = doubleDigits(+index);
    const checked = Object.values(schedule).some(hours => !hours.includes(transformedIndex));
    const result: TSchedule = {};
    for (let key in schedule) {
      const hoursSelected = schedule[key];
      if (hoursSelected.includes(transformedIndex)) {
        if (checked) {
          result[key] = hoursSelected;
        } else {
          result[key] = hoursSelected.filter(hour => hour !== transformedIndex);
        }
      } else  {
        if (checked) {
          result[key] = [...hoursSelected, transformedIndex];
        }
      }
    }
    setSchedule(result)
  }

  function isAllChecked(index: number) {
    const transformedIndex = doubleDigits(+index);
    return Object.values(schedule).every(hours => hours.includes(transformedIndex));
  }

  return (
    <Box sx={style.scheduling} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
      <div>
        <Box sx={[style.row, style.hours]}>
          <Box sx={style.hour}>12 AM</Box>
          <Box sx={style.hour}>3 AM</Box>
          <Box sx={style.hour}>6 AM</Box>
          <Box sx={style.hour}>9 AM</Box>
          <Box sx={style.hour}>12 PM</Box>
          <Box sx={style.hour}>3 PM</Box>
          <Box sx={style.hour}>6 PM</Box>
          <Box sx={style.hour}>9 PM</Box>
        </Box>
        {WEEKDAYS.map((weekday, weekdayIndex) => (
          <Box sx={style.row} key={weekday}>
            <Box sx={style.weekday}>{weekday}</Box>
            {rows.map((row, index) => ( // eslint-disable-line
              <Box
                sx={[
                  style.item,
                  index % 3 === 0 && style.seperatedItem,
                  schedule[weekdayIndex]?.includes(doubleDigits(+index))
                  && style.activeItem,
                ]}
                onClick={() => handleHourSchedule(weekdayIndex , index )}
                key={index}
                onMouseEnter={() => handleMouseEnter(weekdayIndex , index )}
              />
            ))}
          </Box>
        ))}
        <Box sx={style.row}>
          <Box sx={style.allDays}>All days</Box>
          {rows.map((row, index) => ( // eslint-disable-line
            <Box
              sx={[
                style.item,
                index % 3 === 0 && style.seperatedItem,
                isAllChecked(index) && style.activeItem,
              ]}
              onClick={() => handleHourScheduleForAllDays(index)}
              key={index}
            />
          ))}
        </Box>
      </div>
    </Box>
  )
}

Scheduling.displayName = 'Scheduling';

export default Scheduling;