import { Theme } from "@mui/material";

export const style = {
  voiceAds: {
    width: '100%',
    maxWidth: 680,
  },
  title: (theme: Theme) => ({
    fontSize: 24,
    lineHeight: 1.17,
    letterSpacing: '-0.2px',
    color: '#292f41',
    padding: theme.spacing(6, 0),
  }),
  description: (theme: Theme) => ({
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: '0.2px',
    color: '#292f41',
    padding: theme.spacing(8, 0, 4),
  }),
  addVoiceFile: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.33,
    color: '#292f41',
  },
  addVoice: {
    borderRadius: 5,
    width: 170,
  },
  voiceAdsUploadIcon: {
    width: 120,
    height: 120,
  },
  voiceUploader: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    backgroundColor: '#f4f6fa',
    borderRadius: 1.5,
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(8, 0),
  }),
  hiddenInput: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    opacity: 0,
    top: 0,
    left: 0,
    cursor: 'pointer',
  },
  uploader: {
    position: 'relative',
    display: 'flex',
  },
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'background.paper',
    borderRadius: 3,
    boxShadow: '0 12px 8px -10px rgba(0, 0, 0, 0.12)',
    width: 780,
    height: 470,
  },
  uploadVoiceFile: {
    fontSize: 20,
    lineHeight: 1.4,
    color: '#292f41',
  },
  modalHeader: (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(4, 9),
  }),
  crossIcon: {
    cursor: 'pointer',
  },
  content: (theme: Theme) => ({
    margin: theme.spacing(5, 9),
    backgroundColor: '#f4f6fa',
    width: 'calc(100% - 72px)',
    height: 260,
    borderRadius: 1,
    border: '1px solid #dfe7f0',
    padding: theme.spacing(4),
  }),
  divider: (theme: Theme) => ({
    marginTop: theme.spacing(9),
  }),
  footerAction: {
    borderRadius: 5,
    width: 140,
  },
  discard: {
    color: 'rgba(41, 47, 65, 0.5)',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    alignItems: 'flex-end',
  },
  addIcon: {
    width: 48,
    height: 48,
  },
  plusIconButton: {
    backgroundColor: '#3A77EB',
    width: 48,
    height: 48,
  },
};