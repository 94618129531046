import './style.scss';

interface IProps {
  children: any;
  title?: string;
  className?: string;
  footer?: boolean;
  noBorder?: boolean;
  contentRight?: boolean;
}
export const ContentWrapper = (props: IProps) => {
  const {title, noBorder, children, className = ''} = props;

  const Header = ({children, noBorder}: Partial<IProps>) => {
    return (
      <div className={`header ${noBorder ? 'no-border' : ""}`}>
        {children}
      </div>
    )
  }

  return (
    <section className={`mai-content-wrapper ${className}`}>
      {title ? <Header noBorder ={noBorder}>{title}</Header> : null}
      <div className={"mai-content-wrapper-children"}>{children}</div>
    </section>

  )
}

export const ContentWrapperHeader = ({children, noBorder}: Partial<IProps>) => {
  return (
    <div className={`header ${noBorder ? 'no-border' : ""}`}>
      {children}
    </div>
  )
}
export const ContentWrapperFooter = ({children, contentRight}: Partial<IProps>) => {
  return (
    <div className={`footer ${contentRight ? 'content-right' : ""}`}>
      {children}
    </div>
  )
};
