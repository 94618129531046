export const style = {
  headCell: {
    color: 'rgba(41,47,65,.5)',
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  tableContainer: {
    minHeight: 'calc(100vh - 150px)'
  },
  dialog: {
    '& * div': {
      '&.MuiPaper-root.MuiPaper-elevation': {
        maxWidth: '1200px',
        minWidth: '800px',
      }
    }
  }
};