import React from "react";
import classnames from "classnames";
import "./style.scss";

const TextWithTooltip = ({ text, tooltip, className }) => {
  return (
    <p
      className={classnames("text-container", {
        [className]: className,
      })}
    >
      <span className="text-tooltip">{tooltip}</span>
      {text}
    </p>
  );
};

export default TextWithTooltip;
