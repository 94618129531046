import { Box, Button, ButtonProps } from "@mui/material";
import { style  } from './style';

type Props = ButtonProps & {
  loading: boolean;
}

function LoadingButton({ loading, sx, children, ...props }: Props) {
  return (
    <Button sx={Array.isArray(sx) ? [loading && style.loadingButton, style.button, ...sx] : [
      loading && style.loadingButton,
      style.button,
      sx,
    ]} {...props}>
      {loading && <Box sx={style.progressBar} />}
      {!loading && children}
    </Button>
  )
}

LoadingButton.displayName = 'LoadingButton';

export default LoadingButton;