import { Theme } from "@mui/material";

export const style = {

  divider: (theme: Theme) => ({
    marginLeft: theme.spacing(27.5),
  }),
  icon: {
    width: 36,
    height: 36,
    '& > path': {
      fill: '#326AFC',
    }
  },
  iconWrapper: {
    width: 44,
    height: 44,
    padding: 1,
    borderRadius: '50%',
    backgroundColor: '#dfe7f0',
    display: 'flex',
    transition: 'transform .5s',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
  goalTitle: (theme: Theme) => ({
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.33,
    color: '#292f41',
  }),
};
