import { ApiService } from "../../global/services";
import { IReportFilter } from "../../../types";
import { ReportDashboardRequestDTO, ReportFormDTO, ReportLightDTO } from "../types";
import moment from "moment";


export const fromDate = (date: Date | string) => `${moment(date).format('YYYY-MM-DD')}T00:00:00.000Z`;
export const toDate = (date: Date | string) => `${moment(date).add(1, 'seconds').format('YYYY-MM-DD')}T23:59:59.000Z`;

class ReportService {
  static ENDPOINT = `${window.REACT_APP_BASE_URL}/v1/reports`;

  static buildReportLightDTO(filter: ReportFormDTO) {
    return {
      from_date: filter.date.from,
      to_date: filter.date.to,
      id: filter.campaignId,
      device_type_key: filter.deviceTypeKey,
      // visualSizeKey can be 1 and if it is 0 it means we don't need to send a specific filter for visuals
      visual_size_key: filter.visualSizeKey !== 1 ? filter.visualSizeKey : null,
      granularity_type_key: filter.granularityType,
      publisher: filter.publisher,
    };
  }

  static separateReportRequestsWeekly(filter: IReportFilter | ReportDashboardRequestDTO) {
    const MILLISECONDS_IN_DAYS = 86400000;
    const DAYS_IN_WEEK = 7;
    const fromDate = new Date(filter.from_date || '');
    const toDate = new Date(filter.to_date || '');

    const daysBetweenDates = Math.floor((
      toDate.getTime() - fromDate.getTime()
    ) / MILLISECONDS_IN_DAYS);
    const filters = [];

    let currentStartDate = new Date(fromDate);

    if (daysBetweenDates > DAYS_IN_WEEK) {
      const prevWeekBeforeEnd = new Date(toDate);
      prevWeekBeforeEnd.setDate(prevWeekBeforeEnd.getDate() - DAYS_IN_WEEK);

      while (currentStartDate <= prevWeekBeforeEnd) {
        const currentEndDate = new Date(currentStartDate);
        currentEndDate.setDate(currentEndDate.getDate() + DAYS_IN_WEEK - 1);

        filters.push({
          ...filter,
          from_date: new Date(currentStartDate).toISOString(),
          to_date: new Date(+new Date(currentEndDate) - 1000).toISOString(),
        });

        currentStartDate.setDate(currentStartDate.getDate() + DAYS_IN_WEEK);
      }
    }

    filters.push({
      ...filter,
      from_date: new Date(currentStartDate).toISOString(),
      to_date: new Date(+new Date(toDate) - 1000).toISOString(),
    });

    return filters;
  }

  static getReportTotals({ date, ...filter }: any, metrics = []) {
    return ApiService.post(`${ReportService.ENDPOINT}/total`, {
      ...filter,
      from_date: fromDate(filter.from_date),
      to_date: toDate(filter.to_date),
      tz_offset_seconds: 0,
      included_metrics: metrics
    });
  }

  static getReportDashboard(filters: any[]) {

    return filters.map(filter => {
      return ApiService.post(`${ReportService.ENDPOINT}/dashboard`,
      {...filter, from_date: fromDate(filter.from_date),
        to_date: toDate(filter.to_date),
        tz_offset_seconds: 0})});
  }

  static getPlatformReport(filter: ReportLightDTO) {
    return ApiService.post(`${ReportService.ENDPOINT}/platforms`, {
      ...filter,
      from_date: fromDate(filter.from_date),
      to_date: toDate(filter.to_date),
      tz_offset_seconds: 0,
    });
  }

  static getPublishersReport(filter: ReportLightDTO) {
    return ApiService.post(`${ReportService.ENDPOINT}/publishers`, {
      ...filter,
      from_date: fromDate(filter.from_date),
      to_date: toDate(filter.to_date),
      tz_offset_seconds: 0,
    });
  }

  static getPlacementsReport(filter: ReportLightDTO) {
    return ApiService.post(`${ReportService.ENDPOINT}/placement`, filter);
  }

  static getBannersReport(filter: ReportLightDTO) {
    return ApiService.post(`${ReportService.ENDPOINT}/banners`, {
      ...filter,
      granularity_type_key: 2,
      from_date: fromDate(filter.from_date),
      to_date: toDate(filter.to_date),
      tz_offset_seconds: 0,
    });
  }

  static getDimensionReport(filter: any) {
    const _filters = { ...filter,
      from_date: fromDate(filter.from_date),
      to_date: toDate(filter.to_date),
      tz_offset_seconds: 0,
    }
    return ApiService.post(`${ReportService.ENDPOINT}/dimension`, _filters);
  }
}

export default ReportService;
