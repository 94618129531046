/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 12/12/2023
 * All Rights Reserved.
 */
import { Box, Button, Typography } from "@mui/material";
import emptyAudienceImage from '../../../../assets/images/empty-audience.jpg';
import { style } from './style';
import { useTranslation } from "react-i18next";

type Props = {
  onCreate: () => void;
}

function EmptyAudienceList({ onCreate }: Props) {
 const { t } = useTranslation('translation', { keyPrefix: 'audience' });

 return (
   <Box sx={style.container}>
     <Box component='img' src={emptyAudienceImage} width={220} height={160} />
     <Typography sx={style.title}>{t('createAudience')}</Typography>
     <Typography sx={style.description}>{t('description')}</Typography>
     <Button variant='contained' sx={style.create} onClick={onCreate}>{t('create')}</Button>
   </Box>
 ); 
}

EmptyAudienceList.displayName = 'EmptyAudienceList';

export default EmptyAudienceList;