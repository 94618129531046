import { RadioGroup, RadioGroupProps } from "@mui/material";
import React, { PropsWithChildren, Ref } from "react";

type Props = PropsWithChildren<RadioGroupProps> & {
  inputRef?: Ref<HTMLInputElement>;
  errorMessage: string;
};

function IntRadioGroup({ children, inputRef, errorMessage, ...props }: Props) {
  return (
    <RadioGroup ref={inputRef} {...props}>
      {children}
    </RadioGroup>
  )
}

IntRadioGroup.displayName = 'IntRadioGroup';

export default IntRadioGroup;