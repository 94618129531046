import { Theme } from "@mui/material";

export const style = {
  title: {
    fontSize: 24,
    color: '#292f41',
    letterSpacing: '-0.2px',
    lineHeight: 1.17,
  },
  attribution: {
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    width: '100%',
    maxWidth: 680,
  },
  label: {
    fontSize: '.875rem',
    letterSpacing: '.2px',
    lineHeight: '1rem',
    color: 'rgba(41,47,65,.5)',
  },
  params: {
    fontWeight: 600,
  },
  textarea: {
    width: '100%',
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },

  attributionRow: {
    position: 'relative'
  },
  secondaryTitle: {
    textTransform: 'uppercase',
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: '.8px',
    color: '#292f41',
    marginLeft: 2,
    marginBottom: 2,
  },
  secondaryEventName: {
    width: '45%',
    marginBottom:'8px',
    marginRight:'8px',
    backgroundColor: '#fff',
    '& input': {
      padding: '8px 14px 8px 14px'
    },
    '&:hover fieldset': {
      borderColor: '#658ffd !important'
    }
  },
  secondaryEventNameErr: {
    width: '100%',
    marginBottom:'8px',
    backgroundColor: '#fff',
    '& input': {
      padding: '8px 50px 8px 14px'
    },
    '& fieldset': {
      border: '2px solid red',
    },
    '& p': {
      color: 'red',
    },
    '&:hover fieldset': {
      borderColor: '#658ffd !important'
    }
  },
  addSecondary: {
    float: 'right',
    lineHeight: '12px',
    textTransform: 'none',
  },

  deleteIconSecondary: (theme: Theme) => ({
    width: 36,
    height: 36,
    cursor: 'pointer',
    position: 'absolute',
    color: '#658ffd',
    bottom: 5,
    right: 0,
  }),
}
