/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 12/12/2023
 * All Rights Reserved.
 */
import { useTranslation } from "react-i18next";
import { AuthTitleWrapper } from "../../../../components";
import { Paper, TableCell, TableHead, TableRow, TableContainer, Table, TableBody, Modal } from "@mui/material";
import { style } from './style';
import EmptyAudienceList from "../EmptyAudienceList";
import { useState, useEffect, useCallback } from "react";
import SelectAudience from "../SelectAudience";
import { AudienceService } from "../../services";
import TableBodySkeleton from "../../../global/components/TableBodySkeleton";
import { AudienceListDTO, AudienceResponseDTO } from "../../types/audience";
import { formatDate } from "../../../global";
import AudienceListHeader from "../AudienceListHeader";
import { StatusCode } from "../../../global/types";
import { useSelector } from "react-redux";
import { NotificationService } from "../../../global/services";
import AudienceListRow from "../AudienceListRow";
import { selectCurrentUserSpaceId } from "../../../store/reducers/authentication";

function AudienceList() {
  const { t } = useTranslation('translation', { keyPrefix: 'audience' });

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [audienceData, setAudienceData] = useState<AudienceListDTO>({
    isLoading: true,
    data: [],
  });

  const userId = useSelector(selectCurrentUserSpaceId)

  function handleCreateModalOpen() {
    setCreateModalOpen(true);
  }

  function handleCreateModalClose() {
    setCreateModalOpen(false);
  }

  const getAudienceList = useCallback(() => {
    setAudienceData({
      data: [],
      isLoading: true,
    });
    AudienceService.getAudienceList(userId)
      .then(({data: { type, data }}) => {
        // eslint-disable-next-line
        if (type?.code == StatusCode.OK) {
          setAudienceData({
            data: data.data
              .map(({ name, row_count, created_at, type, id }: AudienceResponseDTO) => ({
              name,
              id,
              type,
              rowCount: row_count,
              date: formatDate(created_at),
            })),
            isLoading: false,
          });
        } else {
          NotificationService.handleError(t('somethingWentWrong'))
        }
      });
  }, [userId, t]);

  useEffect(() => {
    getAudienceList();
  }, [getAudienceList]);

  return (
    <AuthTitleWrapper
      title={t('title')}
      iconName="audience"
    >
      <AudienceListHeader onCreate={handleCreateModalOpen} />
      <Paper>
        <TableContainer sx={style.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell size='small' sx={style.headCell}>
                  {t('head.name')}
                </TableCell>
                <TableCell size='small' sx={style.headCell}>
                  {t('head.type')}
                </TableCell>
                <TableCell size='small' sx={style.headCell}>
                  {t('head.rowCount')}
                </TableCell>
                <TableCell size='small' sx={style.headCell}>
                  {t('head.date')}
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody sx={style.tableBody}>
              {audienceData.isLoading ? (
                <TableBodySkeleton cellCount={4} />
              ) : (
                !!audienceData.data.length ?
                  audienceData.data.map(audience => (
                    <AudienceListRow
                      audience={audience}
                      key={audience.id}
                      refreshList={getAudienceList}
                    />
                  )) : (
                    <EmptyAudienceList onCreate={handleCreateModalOpen}/>
                  )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Modal open={createModalOpen} onClose={handleCreateModalClose}>
        <div>
          <SelectAudience onClose={handleCreateModalClose} getAudienceList={getAudienceList} />
        </div>
      </Modal>
    </AuthTitleWrapper>
  );
}

AudienceList.displayName = 'Audience';

export default AudienceList;
