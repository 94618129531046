/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 2/15/2024
 * All Rights Reserved.
 */
import { ChangeEvent, ReactNode } from "react";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import { style } from './style';
import { useTranslation } from "react-i18next";
import { IntCheckbox, IntFieldController, IntSlider } from "../../../global";
import { useFormContext, useWatch } from "react-hook-form";
import { Gender } from "../../types";
type Props = {
  footer: ReactNode;
}

const REGIONS = [
  { id: 0, label: 'Lagos', value: 'lagos', },
  { id: 1, label: 'West', value: 'west' },
  { id: 2, label: 'North West', value: 'north_west' },
  { id: 3, label: 'North East', value: 'north_east' },
  { id: 4, label: 'South - South', value: 'south_south' },
  { id: 5, label: 'South - East', value: 'south_east' },
];

const CONNECTION_TYPES = [
  { id: 0, label: '2G', value: '2g' },
  { id: 1, label: '3G', value: '3g' },
  { id: 2, label: '4G', value: '4g' },
  { id: 3, label: '5G', value: '5g' },
  { id: 4, label: 'Other', value: 'other' },
];

const CUSTOMER_ATTRIBUTES = [
  { id: 0, label: 'Super Gold', value: 'super_gold' },
  { id: 1, label: 'Gold', value: 'gold' },
  { id: 2, label: 'New Ultra', value: 'new_ultra' },
  { id: 3, label: 'New', value: 'new' },
  { id: 4, label: 'Silver', value: 'silver' },
  { id: 5, label: 'Ivory', value: 'ivory' },
];

function VoiceTargeting({ footer } : Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'campaign.targeting' });

  const { control, setValue } = useFormContext();

  const [gender, ageRange, regions, connectionTypes, customerAttributes, languages] = useWatch({
    name: [
      'targeting.gender', 'targeting.ageRange', 'targeting.regions',
      'targeting.connectionTypes', 'targeting.customerAttributes',
      'targeting.targetLanguages'
    ],
    control,
  });

  function handleGenderChange(gender: Gender) {
    setValue('targeting.gender', gender);
  }

  function handleRegionChange(region: string) {
    setValue('targeting.regions',
      regions.includes(region) ? regions.filter((r: string) => r !== region) : [...regions, region]
    );
  }

  function handleConnectionTypeChange(connectionType: string) {
    setValue('targeting.connectionTypes',
      connectionTypes.includes(connectionType) ? connectionTypes.filter((c: string) => c !== connectionType) : [...connectionTypes, connectionType]
    );
  }

  function handleCustomerAttributeChange(attribute: string) {
    setValue('targeting.customerAttributes',
      customerAttributes.includes(attribute) ? customerAttributes.filter((a: string) => a !== attribute) : [...customerAttributes, attribute]
    );
  }

  function handleTargetLanguageChange(event: ChangeEvent<HTMLInputElement>, language: string) {
    setValue('targeting.targetLanguages', [language]);
  }

  return (
    <Box sx={style.targeting}>
      <Typography sx={style.title}>{t('title')}</Typography>
      <Divider />
      <Box sx={style.formGroups}>
        <FormControl sx={style.formGroup} component="fieldset" variant="standard">
          <FormLabel sx={style.label} component="legend">{t('region')}</FormLabel>
          <FormGroup>
            {REGIONS.map(({ id, value, label }) => (
              <FormControlLabel key={id} control={
                <IntCheckbox value={regions.includes(value)} onChange={() => handleRegionChange(value)} />
              } label={label} />
            ))}
          </FormGroup>
        </FormControl>
        <FormControl sx={style.formGroup} component="fieldset" variant="standard">
          <FormLabel sx={style.label} component="legend">{t('connectionType')}</FormLabel>
          <FormGroup>
            {CONNECTION_TYPES.map(({ id, value, label }) => (
              <FormControlLabel key={id} control={
                <IntCheckbox value={connectionTypes.includes(value)} onChange={() => handleConnectionTypeChange(value)} />
              } label={label} />
            ))}
          </FormGroup>
        </FormControl>
      </Box>
      <Box sx={style.formGroups}>
        <FormControl sx={style.formGroup} component="fieldset" variant="standard">
          <FormLabel sx={style.label} component="legend">{t('customerAttributes')}</FormLabel>
          <FormGroup>
            {CUSTOMER_ATTRIBUTES.map(({ id, label, value }) => (
              <FormControlLabel key={id} control={
                <IntCheckbox value={customerAttributes.includes(value)} onChange={() => handleCustomerAttributeChange(value)} />
              } label={label} />
            ))}
          </FormGroup>
        </FormControl>
        <FormControl sx={style.formGroup}>
          <FormLabel sx={style.label}>{t('language')}</FormLabel>
            <RadioGroup name='targetLanguages' value={languages[0] || ''} onChange={handleTargetLanguageChange}>
              <FormControlLabel value="english" control={<Radio />} label={t('english')} />
              <FormControlLabel value="hausa" control={<Radio />} label={t('hausa')} />
            </RadioGroup>
        </FormControl>
      </Box>
      <Box sx={style.demographicFields}>
        <Box>
          <Typography sx={style.label}>{t('gender')}</Typography>
          <Box sx={style.genderSelect}>
            <Box
                // eslint-disable-next-line
              sx={[style.genderField, gender == Gender.ALL && style.selectedGender]}
              onClick={() => handleGenderChange(Gender.ALL)}
            >
              <Typography sx={style.genderType}>{t('all')}</Typography>
            </Box>
            <Box
                // eslint-disable-next-line
              sx={[style.genderField, gender == Gender.MALE && style.selectedGender]}
              onClick={() => handleGenderChange(Gender.MALE)}
            >
              <Typography sx={style.genderType}>{t('men')}</Typography>
            </Box>
            <Box
                // eslint-disable-next-line
              sx={[style.genderField, gender == Gender.FEMALE && style.selectedGender]}
              onClick={() => handleGenderChange(Gender.FEMALE)}
            >
              <Typography sx={style.genderType}>{t('women')}</Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={style.age}>
          <Typography sx={style.label}>{t('age')}</Typography>
          <Box sx={style.ageRangeSlider}>
            <Typography>{ageRange[0]}</Typography>
            <IntFieldController
              control={control}
              name='targeting.ageRange'
              Component={IntSlider}
              valueLabelDisplay="auto"
              min={18}
              max={65}
              sx={style.slider}
            />
            <Typography>{ageRange[1]}</Typography>
          </Box>
        </Box>
      </Box>
      {footer}
    </Box>
  );
}

VoiceTargeting.displayName = 'VoiceTargeting';

export default VoiceTargeting;