import { AudienceForm } from "../types/audience";

const AUDIENCE_FORM_DEFAULT_VALUES: AudienceForm = {
  name: '',
  file: undefined,
}

export {
  AUDIENCE_FORM_DEFAULT_VALUES,
};
