import React from "react";
import {
  Box,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import { style } from "./style";
import { invoicesHeadConfig } from "./config";
import { downLoadInvoice } from "../../../../../../screens/Billing/services/invoiceDownloadService";

interface IProps {
  row: any;
  currency?: string;
  onSendInvoice: () => void;
}

function AdminInvoicesRow(props: IProps) {
  const {row, onSendInvoice} = props;

  return (
    <TableRow
      sx={[style.tableRow]}
    >
      {invoicesHeadConfig.map(cell => {
          return (
            <TableCell key={cell.key}>
              <Box sx={style.rowCollection}>
                <Typography title={cell.parser(row[cell.key])} sx={style.rowText}>
                  {cell.parser(row[cell.key])}
                </Typography>
              </Box>
            </TableCell>
          )
        })
      }
      <TableCell>
        <Box sx={style.rowCollection}>
          <Typography
            onClick={() => row.last_notified_dt === null && onSendInvoice()}
            sx={row.last_notified_dt === null ? style.rowBtn : style.btnDisabled}>
            {'Send Invoice'}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box sx={style.rowCollection}>
            <Typography onClick={() => downLoadInvoice(row.id)} sx={style.rowText}>
              <DownloadIcon />
            </Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
}

export default AdminInvoicesRow;