import { Theme } from "@mui/material";

export const style = {
  fields: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    marginTop: theme.spacing(4),
  }),
  label: {
    textTransform: 'uppercase',
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: '.8px',
    color: '#292f41',
  },
  field: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 2,
    padding: theme.spacing(4, 3, 2),
  }),
  hashtagLike: (theme: Theme) => ({
    padding: theme.spacing(2, 3),
    backgroundColor: '#f4f6fa',
    borderRadius: theme.spacing(5),
    cursor: 'pointer',
  }),
  hashtagText: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.14,
    letterSpacing: '0.8px',
    color: '#292f41',
    textTransform: 'uppercase',
  },
  hashtags: {
    display: 'flex',
    gap: 3,
  },
  divider: (theme: Theme) => ({
    margin: theme.spacing(6, 0),
  }),
  autocomplete: {
    background: '#fff',
  },
  genderType: {
    fontSize: 18,
    lineHeight: 1.33,
    color: '#292f41',
  },
  genderSelect: (theme: Theme) => ({
    borderRadius: 1,
    border: '1px solid #dfe7f0',
    display: 'flex',
    width: 'fit-content',
    marginTop: theme.spacing(2),
  }),
  genderField: {
    width: 106,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  selectedGender: {
    backgroundImage: 'linear-gradient(249deg, #65abf6, #2e6bde)',
    borderRadius: 1,
    boxShadow: '0 12px 8px -10px rgba(58, 119, 235, 0.2)',
    border: '1px solid #2e79f7',
    '& > p': {
      color: '#fff',
    },
  },
  demographicFields: (theme: Theme) => ({
    display: 'flex',
    gap: theme.spacing(8),
    padding: theme.spacing(4, 3, 2),
    flexWrap: 'wrap',
  }),
  slider: {
    marginTop: 2,
    '& > .MuiSlider-thumb': {
      backgroundColor: '#fff',
    },
    '& > .MuiSlider-rail': {
      backgroundColor: '#dfe7f0',
      height: 16,
    },
    '& > .MuiSlider-track': {
      height: 16,
    },
    '& > .MuiSlider-mark': {
      height: '100%',
      width: 4,
      opacity: 1,
      backgroundColor: '#fff',
    }
  },
  age: {
    width: 300,
  },
  ageRangeSlider: {
    display: 'flex',
    gap: 6,
    alignItems: 'center',
  },
  fieldCheckbox: (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
  }),
  lookalikes: (theme: Theme) => ({
    padding: theme.spacing(4, 3, 2),
  }),
  heading: {
    fontSize: 20,
    lineHeight: 1.4,
    color: '#292f41',
  },
  lookalikesIndicator: (theme: Theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(5),
    marginTop: theme.spacing(3),
  }),
  similarityLabel: {
    fontSize: 14,
    color: '#292f41',
    letterSpacing: '0.2px',
    lineHeight: 1.14,
  },
  labelWithTooltip: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
  description: (theme: Theme) => ({
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: '0.2px',
    color: 'rgba(41, 47, 65, 0.5)',
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(2),
  }),
};
