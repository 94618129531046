import React from "react";

type Props = {
  color: string,
  onClick?: () => void,
};

export default function Color({ color, onClick }: Props) {
  return (
    <div
      className="color-circle"
      style={{ backgroundColor: `#${color}` }}
      onClick={onClick}
    />
  );
}
