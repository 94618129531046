/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 12/12/2023
 * All Rights Reserved.
 */
import { Box, Button, IconButton, Paper, Typography } from "@mui/material";
import { style } from './style';
import './style.scss'
import { useTranslation } from "react-i18next";
import { useForm, useWatch } from "react-hook-form";
import { CrossIcon, IntFieldController, IntUploadFile } from "../../../global";
import { ChangeEvent, useState } from "react";
import { AudienceForm } from "../../types/audience";
import { AudienceService } from "../../services";
import { StatusCode } from "../../../global/types";
import { NotificationService } from "../../../global/services";
import { AUDIENCE_FORM_DEFAULT_VALUES, getCreateAudienceValidationSchema } from "../../utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { selectCurrentUserSpaceId } from "../../../store/reducers/authentication";
import dynamicAudienceIcon from '../../../../assets/images/dynamic_audience.png';
import staticAudienceIcon from '../../../../assets/images/static_audince.png';
import dynamicAudienceMein from '../../../../assets/images/dynamic_audience_mein.png';
import { useHistory } from "react-router-dom";

type TAudienceType = 'STATIC' | 'DYNAMIC';

type Props = {
  onClose: () => void;
  getAudienceList: () => void;
}

function SelectAudience({ onClose, getAudienceList }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'audience' });
  const history = useHistory();


  const [audienceType, setAudienceType] = useState<TAudienceType>('STATIC');
  const userId = useSelector(selectCurrentUserSpaceId)

  const {
    control,
    setValue,
    handleSubmit,
    formState: {
      isSubmitting,
      errors,
    },
  } = useForm<AudienceForm>({
    defaultValues: AUDIENCE_FORM_DEFAULT_VALUES,
    resolver: yupResolver(getCreateAudienceValidationSchema()) as any,
  });

  const file = useWatch({
    name: 'file',
    control,
  });


  function handleCSVUpload(e: ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];
    if (file) {
      setValue('file', file);
    }
  }

  async function onSubmit(formValues: AudienceForm) {
    const { data } = await AudienceService.uploadAudience(formValues, userId);
    // eslint-disable-next-line
    if (data.type?.code == StatusCode.OK) {
      NotificationService.handleSuccess(t('uploadSuccess'));
      onClose();
      getAudienceList();
    } else if (data.type?.code === 186) {
      NotificationService.handleError(t('emptyFile'));
    } else {
      NotificationService.handleError(t('somethingWentWrong'));
    }
  }

  return (
    <Paper sx={style.paper}>
      <Box className={'selectAudience'}>
        <Typography className={'title'}>{t('selectAudienceTitle')}</Typography>
        <Box onClick={() => setAudienceType('STATIC')}
             className={audienceType === 'STATIC' ? 'selected item' : 'item'}
        >
          <Box className={'icon'}>
            <img src={staticAudienceIcon as string} alt="staticAudienceIcon" />
          </Box>
          <Box className={'item-text'}>
            <Typography className={'item-title'}>{t('staticAudienceItemTitle')}</Typography>
            <Typography className={'item-description'}>{t('staticAudienceItemDescription')}</Typography>
          </Box>
        </Box>
          <Box onClick={() => setAudienceType('DYNAMIC')}
               className={audienceType === 'DYNAMIC' ? 'selected item' : 'item'}
          >
          <Box className={'icon'}>
            <img src={dynamicAudienceIcon as string} alt="dynamicAudienceIcon" />
          </Box>
          <Box className={'item-text'}>
            <Typography className={'item-title'}>{t('dynamicAudienceItemTitle')}</Typography>
            <Typography className={'item-description'}>{t('dynamicAudienceItemDescription')}</Typography>
          </Box>
        </Box>
      </Box>
      {audienceType === 'STATIC' ? (
        <Box sx={style.staticAudience} component='form' onSubmit={handleSubmit(onSubmit)}>
      <Box sx={style.header}>
        <Typography sx={style.staticAudience.title}>{t('staticAudienceTitle')}</Typography>
        <IconButton size='small' sx={style.iconButton} onClick={onClose}>
          <CrossIcon />
        </IconButton>
      </Box>
      <Box sx={style.form}>
        <Box sx={style.field}>
          <Typography sx={style.label}>{t('name')}</Typography>
          <IntFieldController control={control} name='name' />
        </Box>
        <IntUploadFile
          classes={{ root: style.uploadFile }}
          onFileChange={handleCSVUpload}
          accept='.csv'
          uploaded={!!file}
          hasError={!!errors.file}
        />
      </Box>
      <Box sx={style.footer}>
        {/*<Button variant='text' sx={style.cancel} onClick={onClose}>*/}
        {/*  {t('cancel')}*/}
        {/*</Button>*/}
        <Button
          variant='contained'
          type='submit'
          disabled={isSubmitting}
          sx={style.button}
        >{t('upload')}</Button>
      </Box>
      </Box>
        ) : (
          <Box className={'dynamic-audience'}>
            <IconButton className={'dynamic-audience-close-btn'} size='small' sx={style.iconButton} onClick={onClose}>
              <CrossIcon />
            </IconButton>
            <img src={dynamicAudienceMein as string} alt="dynamicAudienceMein" />
            <Typography className={'dynamic-audience-description'}>{t('dynamicAudienceDescription')}</Typography>
            <Typography className={'dynamic-audience-description-1'}>{t('dynamicAudienceDescription1')}</Typography>
              <Button
                onClick={() => history.push('/audience/create')}
                className={'dynamic-audience-btn'}
                variant='contained'
                disabled={isSubmitting}
                sx={style.button}
              >{t('createDynamicAudience')}</Button>
          </Box>
        )
      }
    </Paper>
  )
}

export default SelectAudience;