// @ts-nocheck
import React from "react";
import { Tooltip, PieChart, Pie, Cell, Legend, Label } from "recharts";
import "./style.scss";
import { ReportDetailNotFound } from "../../../containers";
import { uuidv4 } from "../../../modules";
import { formatDateToDMY } from "../../../modules/global/utils/date-utils";

interface ITooltip {
  active: boolean;
  payload: any[];
  label: string;
  labelName: string;
}


const RADIAN = Math.PI / 180;

const CustomTooltip = ({ active, payload, labelName }: ITooltip) => {
  if (active && payload && payload.length !== 0) {
    return (
      <div className="custom-tooltip pie-graph">
        <p className="custom-tooltip-date"> {payload[0].name}</p>
        <p className="custom-tooltip-content">
          <svg
            width="16"
            height="16"
            version="1.1"
            fill={payload[0].payload.fill}
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="16" height="16" />
          </svg>
          <span className="custom-tooltip-value">
            {labelName && (
              <span className="custom-tooltip-value-label">{labelName}: </span>
            )}
            {numberToStringWithCommas(payload[0].value)}
          </span>
        </p>

        {payload[1] && (
          <p className="" style={{ margin: 0 }}>
            <svg
              width="16"
              height="16"
              version="1.1"
              fill={payload[1].payload.fill}
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="16" height="16" />
            </svg>
            <span className="custom-tooltip-value">
              $ {numberToStringWithCommas(payload[1].value)}
            </span>
          </p>
        )}
      </div>
    );
  }

  return null;
};

const renderCustomizedLegend = (props) => {
  const { payload, onClick } = props;
  payload.sort((a, b) =>
    a.payload.value < b.payload.value
      ? 1
      : b.payload.value < a.payload.value
      ? -1
      : 0
  );
  return (
    <ul className="pie-graph-customized-legend">
      {payload.map(({ payload: { name, fill, value, type } }) => {
        if (value === 0) {
          return null;
        }
        const handelClick = () => {
          onClick({ type, name, fill, value });
        };
        return (
          <li
            className="pie-graph-customized-legend-item"
            onClick={handelClick}
          >
            <svg
              width="16"
              height="16"
              version="1.1"
              fill={fill}
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="16" height="16" />
            </svg>
            <span className="pie-graph-customized-legend-value">{name}</span>
            <span className="pie-graph-customized-legend-count">
              ({numberToStringWithCommas(value)})
            </span>
          </li>
        );
      })}
    </ul>
  );
};

type IProps = {
  dataKey: string;
  versusDataKey?: string;
  withTooltip: boolean;
  data: any;
  showTotalInCenter?: boolean;
  onClick?: any;
  labelName?: string;
};

const pieWidth = 320;
const pieHeight = 340;

export const PieGraph = ({
  data,
  dataKey,
  versusDataKey,
  withTooltip,
  showTotalInCenter,
  onClick,
  labelName,
}: IProps) => {
  if (data.total === 0) {
    return <ReportDetailNotFound />;
  }

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    value,
    name,
    payload,
  }: any) => {
    const handelClick = () => {
      const { type, name, fill, value } = payload;
      onClick({ type, name, fill, value });
    };
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    if (percent < 0.05) {
      return null;
    }

    return (
      <text
        x={x + 5}
        y={y - 20}
        fill="white"
        textAnchor="middle"
        className="pie-graph-label"
        dominantBaseline="central"
        onClick={handelClick}
      >
        <tspan x={x + 5} dy="1.2em">
          {name}
        </tspan>
        <tspan x={x + 5} dy="1.2em" style={{ fontWeight: "bold" }}>
          {numberToStringWithCommas(value)}
        </tspan>
      </text>
    );
  };

  const id = uuidv4();
  const versusId = uuidv4();

  const MOBILE_COLORS = [`${id}-100`, `${id}-80`, `${id}-60`];
  const DESKTOP_COLORS = [
    `${versusId}-100`,
    `${versusId}-80`,
    `${versusId}-60`,
  ];
  const COLORS = [...MOBILE_COLORS, ...DESKTOP_COLORS];

  return (
    <PieChart
      data={data}
      width={pieWidth}
      height={pieHeight}
      margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
    >
      <Legend
        verticalAlign="bottom"
        content={renderCustomizedLegend}
        onClick={onClick}
      />
      <defs>
        <linearGradient id={`${id}-100`} x1="0" y1="0" x2="0" y2="1">
          <stop
            offset="0%"
            stopColor={"var(--secondary_color)"}
            stopOpacity={1}
          />
          <stop
            offset="100%"
            stopColor={"var(--primary_color)"}
            stopOpacity={1}
          />
        </linearGradient>
        <linearGradient id={`${id}-80`} x1="0" y1="0" x2="0" y2="1">
          <stop
            offset="0%"
            stopColor={"var(--secondary_color)"}
            stopOpacity={0.8}
          />
          <stop
            offset="100%"
            stopColor={"var(--primary_color)"}
            stopOpacity={0.8}
          />
        </linearGradient>
        <linearGradient id={`${id}-60`} x1="0" y1="0" x2="0" y2="1">
          <stop
            offset="0%"
            stopColor={"var(--secondary_color)"}
            stopOpacity={0.6}
          />
          <stop
            offset="100%"
            stopColor={"var(--primary_color)"}
            stopOpacity={0.6}
          />
        </linearGradient>
        <linearGradient id={`${versusId}-100`} x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor="var(--gauge_color)" stopOpacity={1} />
          <stop offset="100%" stopColor="var(--gauge_color)" stopOpacity={1} />
        </linearGradient>
        <linearGradient id={`${versusId}-80`} x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor="var(--gauge_color)" stopOpacity={0.8} />
          <stop
            offset="100%"
            stopColor="var(--gauge_color)"
            stopOpacity={0.8}
          />
        </linearGradient>
        <linearGradient id={`${versusId}-60`} x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor="var(--gauge_color)" stopOpacity={0.6} />
          <stop
            offset="100%"
            stopColor="var(--gauge_color)"
            stopOpacity={0.6}
          />
        </linearGradient>
      </defs>
      <Pie
        dataKey="value"
        data={data[dataKey]}
        isAnimationActive={false}
        cx={160}
        cy={180}
        innerRadius={versusDataKey ? 120 : 70}
        outerRadius={160}
        labelLine={false}
        label={!versusDataKey ? renderCustomizedLabel : false}
        startAngle={90}
        endAngle={450}
        onClick={onClick}
        stroke="none"
      >
        {data[dataKey].map((entry: any, index: number) => {
          const color = entry.isMobile
            ? MOBILE_COLORS[index % MOBILE_COLORS.length]
            : entry.isDesktop
            ? DESKTOP_COLORS[index % DESKTOP_COLORS.length]
            : COLORS[index % COLORS.length];

          return <Cell key={`cell-${index}`} fill={`url(#${color})`} />;
        })}

        {showTotalInCenter && (
          <Label
            value="Total"
            position="centerBottom"
            className="pie-graph-center-label-top"
          />
        )}
        {showTotalInCenter && (
          <Label
            value={data.total ? numberToStringWithCommas(data.total) : 0}
            position="centerTop"
            className="pie-graph-center-label-bottom"
          />
        )}
      </Pie>
      {withTooltip && (
        <Tooltip
          label="tooltip-label"
          labelFormatter={formatDateToDMY}
          formatter={numberToStringWithCommas}
          content={CustomTooltip}
          cursor={false}
          labelName={labelName}
          active={true}
        />
      )}
      {!!versusDataKey && (
        <Pie
          data={data[versusDataKey]}
          dataKey="value"
          isAnimationActive={false}
          cx={160}
          cy={180}
          outerRadius={95}
          label={renderCustomizedLabel}
          labelLine={false}
          startAngle={90}
          endAngle={450}
          onClick={onClick}
          stroke="none"
        >
          {data[versusDataKey].map((entry: any, index: number) => {
            const color = entry.isMobile
              ? MOBILE_COLORS[index % MOBILE_COLORS.length]
              : entry.isDesktop
              ? DESKTOP_COLORS[index % DESKTOP_COLORS.length]
              : COLORS[index % COLORS.length];
            return <Cell key={`cell-${index}`} fill={`url(#${color})`} />;
          })}
        </Pie>
      )}
    </PieChart>
  );
};
