import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import {
  // PaymentModal,
  // WithdrawModal,
  // ApproveCampaignModal,
  // PaymentsWithdrawHistoryModal,
  // CommentModal,
  // TransactionModal,
  AdminAccountModal,
  // EntityEditModal,
  // ArchiveWarningModal,
} from "../../containers";
import { IModalProps } from "../../types";

import { modalTypes } from "../../providers";
import "./styles.scss";
// import { AdaptiveModal } from "../../containers/ModalComponents/AdaptiveModal";

const modalRoot = document.getElementById("modal-root");

interface IState {}

const Header = ({ children, styles }: any) => (
  <div className="modal-header-container" style={styles}>
    <span className="modal-title">{children}</span>
  </div>
);

const Footer = ({ children, styles }: any) => (
  <div className="modal-footer-container" style={styles}>
    {children}
  </div>
);

export class Modal extends Component<IModalProps, IState> {
  el: any;
  static Header = Header;

  static Footer = Footer;

  constructor(props: IModalProps) {
    super(props);
    // Create a div that we'll render the modal into. Because each
    // Modal component has its own element, we can render multiple
    // modal components into the modal container.
    this.el = document.createElement("div");
    this.el.className = "overlay";
  }

  componentDidMount() {
    // Append the element into the DOM on mount.
    if (modalRoot) {
      modalRoot.appendChild(this.el);
    }
  }

  componentWillUnmount() {
    // Remove the element from the DOM when we unmount
    if (modalRoot) {
      modalRoot.removeChild(this.el);
    }
  }

  renderContent = () => {
    const { modalType, modalData, closeModal } = this.props;
    switch (modalType) {
      case modalTypes.refillBalanceModal:
        break;
        // return <PaymentModal closeModal={closeModal} modalData={modalData} />;
      // case modalTypes.archiveWarningModal:
      //   return (
      //     <ArchiveWarningModal closeModal={closeModal} modalData={modalData} />
      //   );
      // case modalTypes.withdrawModal:
      //   return <WithdrawModal closeModal={closeModal} modalData={modalData} />;
      // case modalTypes.approveCampaignModal:
      //   return (
      //     <ApproveCampaignModal
      //       {...this.props}
      //       closeModal={closeModal}
      //       modalData={modalData}
      //     />
      //   );
      // case modalTypes.entityEditModal:
      //   return (
      //     <EntityEditModal
      //       {...this.props}
      //       closeModal={closeModal}
      //       modalData={modalData}
      //     />
      //   );
      // case modalTypes.paymentsWithdrawHistoryModal:
      //   return (
      //     <PaymentsWithdrawHistoryModal
      //       {...this.props}
      //       closeModal={closeModal}
      //       modalData={modalData}
      //     />
      //   );
      // case modalTypes.commentModal:
      //   return (
      //     <CommentModal
      //       {...this.props}
      //       closeModal={closeModal}
      //       modalData={modalData}
      //     />
      //   );
      // case modalTypes.transactionModal:
      //   return (
      //     <TransactionModal
      //       {...this.props}
      //       closeModal={closeModal}
      //       modalData={modalData}
      //     />
      //   );
      case modalTypes.adminAccountsModal:
        return (
          <AdminAccountModal
            {...this.props}
            closeModal={closeModal}
            modalData={modalData}
          />
        );
      // case modalTypes.adaptiveModal:
      //   return (
      //     <AdaptiveModal
      //       {...this.props}
      //       closeModal={closeModal}
      //       modalData={modalData}
      //     />
      //   );

      default:
        return null;
    }
  };

  render() {
    const {
      props: { styles, contentStyles },
    } = this;
    const modal = (
      <div className="app-modal" style={styles}>
        <div className="app-modal-content" style={contentStyles}>
          {this.renderContent()}
        </div>
      </div>
    );
    // Use a portal to render the children into the element
    return ReactDOM.createPortal(modal, this.el);
  }
}

Header.propTypes = {
  children: PropTypes.any,
  styles: PropTypes.object,
};
