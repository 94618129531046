// import { Theme } from "@mui/material";

export const style = {
  title: {
    fontSize: 24,
    lineHeight: 1.17,
    color: '#292f41',
  },
  frequency: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    width: '100%',
    maxWidth: 680,
  },
  label: {
    color: 'rgba(41, 47, 65, 0.5)',
    fontSize: 12,
  },
  input: {
    '& input': {
      borderLeft: '1px solid #dfe7f0',
      borderRadius: 'unset !important',
      paddingLeft: 1,
    },
    height: 'auto',
    width: 'calc(100% / 3 - 6px)',
    minWidth: 140,
  },
  fields: {
    display: 'flex',
    gap: 2,
    width: '100%',
    flexWrap: 'wrap',
  },
  frequencyFields: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  square: {
    width: 20,
    height: 20,
    borderRadius: 0.5,
  },
  selectedSquare: {
    backgroundColor: 'primary.main'
  },
  unselectSquare: {
    backgroundColor: '#fff',
    border: '1px solid #dfe7f0',
  },
  schedulingUsage: {
    display: 'flex',
    gap: 4,
    justifyContent: 'flex-end',
  },
  squareWrapper: {
    display: 'flex',
    gap: 2,
    alignItems: 'center',
  },
  hours: {
    color: '#292f41',
    fontSize: 14,
    letterSpacing: '.2px',
    lineHeight: '16px',
  },
};