import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import QueryBuilder from "../QueryBuilder";
import { useTranslation } from "react-i18next";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useEffect, useState } from "react";

interface IProps {
  data: IBidding;
  position: number;
  totalCount: number;
  onMove(position: number, movement: TMovement): void;
  onRemove(id: number): void;
  saveRules(result: IBidding): void;
}

function BiddingItem(props: IProps) {
  const {onMove, onRemove, saveRules, data, position, totalCount} = props;

  const { t } = useTranslation('translation', { keyPrefix: 'campaign.owner' });

  const [name, setName] = useState<string>("");
  const [multiplier, setMultiplier] = useState<number>();
  const [expanded, setExpanded] = useState<boolean>(true);
  const [editMode, setEditMode] = useState<boolean>(false);

  const handleNameSave = () => {
    if(!!name) {
      data.name = name;
      saveRules(data);
    }
    setEditMode(false);
  }

  const handleMultiplierSave = (value: number) => {
    setMultiplier(value);
    data.multiplier = value;
    saveRules(data);
  }

  useEffect(() => {
    if(data) {
      setName(data.name);
      setMultiplier(data.multiplier);
    }
    // eslint-disable-next-line
  }, []);


  return (
    <Box className={'bidding-item-wrap'}>
      <Box className={'bidding-item-header'}>
        <Box sx={{display: 'flex'}}>
          {expanded ? <KeyboardArrowDownIcon sx={{marginTop: '6px', cursor: 'pointer'}} onClick={() => setExpanded(!expanded)} /> :
            <KeyboardArrowRightIcon sx={{marginTop: '6px', cursor: 'pointer'}} onClick={() => setExpanded(!expanded)} />}
          {!editMode ? (
            <Box sx={{display: 'flex'}} className={'name'}>
              <Typography>{data.name}</Typography>
              <BorderColorIcon className={'edit-icon'} onClick={ () => setEditMode(true)} />
            </Box>) : (
            <TextField
              value={name}
              className={'name-input'}
              onChange={(e) => setName(e.target.value)}
              InputProps={{
              endAdornment: <Typography className={'endAdornment'} onClick={() => handleNameSave()} >{t('save')}</Typography>,
            }} />
            )}
        </Box>

        <Box className={'add-bidding'}>
          <TextField
            className={'multiplayer-input'}
            placeholder={t<string>('multiplierPlaceholder')}
            type="number"
            value={multiplier}
            onChange={(e) => handleMultiplierSave(+e.target.value)}
          />
          {position !== 0 ? (<Button
            className={'remove'}
            variant={'text'}
            onClick={() => onMove(position, 'UP')}
          >{t('moveUp')}
          </Button>) : null}
          {position !== totalCount ? (<Button
            className={'remove'}
            variant={'text'}
            onClick={() => onMove(position, 'DOWN')}
          >{t('moveDown')}
          </Button>) : null}
          <Button
            className={'remove'}
            variant={'text'}
            onClick={() => onRemove(data.id)}
          >{t('remove')}
          </Button>
        </Box>
      </Box>

      {expanded ? (<Box className={'bidding-item-body'}>
        <QueryBuilder data={{...data}} saveRules={(rules) => saveRules(rules)} />
      </Box>) : <Divider />}
    </Box>
  )
}

BiddingItem.displayName = 'BiddingItem';

export default BiddingItem;
