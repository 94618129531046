import React, { Component } from "react";
import { injectIntl, IntlShape } from "react-intl";
import { PaymentCard } from "../../components/PaymentCard";
import { IPaymentMethods } from "../../types";
import "./PaymentsCardsList.scss";
interface IProps {
  intl: IntlShape;
  spaceId: string;
  payWithBankGateway: Function;
  paymentMethods: Array<IPaymentMethods>;
  removeSpacePaymentMethod: Function;
  setSpaceDefaultPayment: Function;
}

class PaymentsCardsList extends Component<IProps> {
  render() {
    const {
      props: {
        paymentMethods,
        removeSpacePaymentMethod,
        spaceId,
        setSpaceDefaultPayment,
      },
    } = this;
    return (
      <div className="card-list-container">
        {paymentMethods && paymentMethods.length
          ? paymentMethods.map(
              ({
                card_number,
                credit_card_type_key,
                exp_month,
                exp_year,
                id,
                is_default,
                client_name,
              }) => (
                <PaymentCard
                  clientName={client_name}
                  key={id}
                  cardNumber={card_number}
                  typeKey={credit_card_type_key}
                  expMonth={exp_month}
                  expYear={exp_year}
                  paymentId={id}
                  spaceId={spaceId}
                  removeSpacePaymentMethod={removeSpacePaymentMethod}
                  isDefault={is_default}
                  setSpaceDefaultPayment={setSpaceDefaultPayment}
                />
              )
            )
          : ""}
      </div>
    );
  }
}

export default injectIntl(PaymentsCardsList);
