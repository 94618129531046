import { Theme } from "@mui/material";

export const style = {
  sizes: {
    fontWeight: 600,
    fontSize: '1.5rem',
    lineHeight: '1rem',
  },
  disapproved: {
    marginLeft: '8px',
    color: 'rgb(211, 47, 47)'
  },
  approved: {
    marginLeft: '8px',
    color: 'rgb(46, 125, 50)'
  },
  namesLabel: {
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1rem',
  },
  errorText: {
    color: 'red',
    fontSize: '14px',
    marginTop: '-14px'
  },
  names: (nameError: string) => ({
    '.css-15akli9-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: 0,
    },
    '.Mui-focused fieldset' : {
      border: `${!!nameError && '0 !important'}`
    },
    border: `1px solid ${!!nameError ? 'red' : 'transparent'}`,
    input: {
      padding: '8.5px 14px',
    },
    '&:hover fieldset': {
      borderColor: '#2e79f7 !important',
    },
    width: 750,
    borderRadius: 0,
  }),
  addBanner: {
    fontWeight: 'bold',
    fontSize: 12,
    lineHeight: 1,
    letterSpacing: '0.8px',
    color: '#292f41',
  },
  banner: (width: number, height: number) => (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4, 0),
    backgroundColor: 'rgba(242, 248, 252, 0.5)',
    width: width,
    height,
    maxWidth: '100%',
    position: 'relative',
    minHeight: height,
  }),
  addIcon: {
    stroke: '#2E6BDE',
  },
  bannerPreview: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    marginBottom: '50px'
  },
  hiddenInput: {
    position: 'absolute',
    opacity: 0,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
  progress: {
    '&&': {
      width: '24px !important',
      height: '24px !important',
    }
  },
  bannerImage: (width: number) => ({
    height: '100%',
    minWidth: width,
  }),
  imageWrapper: {
    padding: 0,
    alignItems: 'unset',
    flexBasis: 'min-content'
  },
  trashIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: 5,
    bottom: 5,
    background: 'rgba(0,0,0,.5)',
    padding: 1,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  border: (hasError: boolean) => ({
    border: `1px solid ${hasError ? 'red' : '#2e79f7'}`,
  }),
}