export const style = {
  headCell: {
    color: 'rgba(41,47,65,.5)',
    textTransform: 'uppercase',
    fontWeight: 600,
    padding: '8px 0',
    // textAlign: 'center !important',
    // '& span': {paddingLeft: '8px'}
  },
  tableContainer: {
    minHeight: 'calc(100vh - 150px)'
  },
};