import { Theme } from "@mui/material";

export const style = {
  tab: (theme: Theme) => ({
    boxShadow: '0 12px 8px -10px rgba(0, 0, 0, 0.12)',
    borderRadius: theme.spacing(1),
    border: '1px solid #dfe7f0',
    padding: theme.spacing(4),
    cursor: 'pointer',
    transition: 'all .3s',
  }),
  selectedTab: (theme: Theme) => ({
    borderBottomWidth: 10,
    borderBottomLeftRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    borderBottomColor: 'primary.main',
    backgroundColor: 'rgba(205, 213, 222, 0.2)',
  }),
  metricBox: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 4,
    justifyContent: 'space-between',
  },
  labelBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '3px',
  },
  label: {
    fontSize: 12,
    color: 'rgba(41, 47, 65, 0.5)',
    letterSpacing: '0.8px',
    lineHeight: '14px',
    // textTransform: 'uppercase',
    fontWeight: 600,
  },
  value: (theme: Theme) => ({
    fontWeight: 600,
    fontSize: 20,
    color: '#292f41',
    lineHeight: '18px',
    textAlign: 'left',
    marginTop: theme.spacing(1),
  })
};