
export const style = {

  disapproved: {
    fontSize: '16px',
    marginLeft: '4px',
    color: '#ef5350'
  },
  pending: {
    fontSize: '16px',
    marginLeft: '4px',
  },
  approved: {},
}