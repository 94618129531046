import { Box, CircularProgress, SxProps, Theme, Tooltip, Typography } from "@mui/material";
import { style } from './style';
import { InfoIcon } from "../../../global";

type Props = {
  infoItems: Array<{
    label: string;
    tooltipText: string;
    value: string | number | undefined;
    id: number;
  }>;
  selected: boolean;
  sx: SxProps<Theme>;
  onChange: () => void;
  isLoading: boolean;
};

function MetricSummaryCard({
 infoItems,
 selected,
 sx,
 onChange ,
 isLoading,
}: Props) {
  return (
    <Box
      sx={
        [
          style.tab,
          selected && style.selectedTab,
          ...(Array.isArray(sx) ? sx : [sx]),
        ]
      }
      onClick={onChange}
    >
      <Box sx={style.metricBox}>
        {infoItems.map(infoItem => (
          <Box key={infoItem.id}>
            <Box sx={style.labelBox}>
              <Typography sx={style.label}>{infoItem.label}</Typography>
                {infoItem.tooltipText ? (
                    <Tooltip title={infoItem.tooltipText}>
                        <InfoIcon />
                    </Tooltip>
                ) : null}
            </Box>
            {isLoading ? (<CircularProgress size={20} />) : (
              <Typography sx={style.value}>{infoItem.value}</Typography>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

MetricSummaryCard.displayName = 'MetricSummaryCard';

export default MetricSummaryCard;

