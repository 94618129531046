import moment from "moment";
import { numberToStringWithCommas } from "../../../../../global";

export const invoicesHeadConfig: IReportHeadCell[] = [
  {
    key: 'user_name',
    parser: (field) => field || 'n/a',
  },
  {
    key: 'id',
    parser: (field) => field || 'n/a',
  },
  {
    key: 'status',
    parser: (field) => field || 'n/a',
  },
  {
    key: 'to_be_paid',
    parser: (field) => numberToStringWithCommas(field || 0, 2),
  },
  {
    key: 'from_date',
    parser: (field) => moment(field).format('DD/MM/yyyy'),
  },
  {
    key: 'to_date',
    parser: (field) => moment(field).format('DD/MM/yyyy'),
  },
];
