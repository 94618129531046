import {
  Box,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { style } from "./style";
import { reportListHeadConfig } from "../config";

interface IProps {
  row: any;
  currency: string;
}

function ReportListRow(props: IProps) {
  const {row, currency} = props;

  return (
    <TableRow
      sx={[style.tableRow]}
    >
      {reportListHeadConfig.map(cell => {
        switch(cell.key) {
          case 'engagementsRate' :
            return (
              <TableCell key={cell.key}>
                <Box sx={style.rowCollection}>
                  <Typography sx={style.rowText}>
                    {cell.parser(row.session_count !== 0 ? row.engaged_session_count / row.session_count : 0)}
                  </Typography>
                </Box>
              </TableCell>
            )

          case 'bounces' :
            return (
              <TableCell key={cell.key}>
                <Box sx={style.rowCollection}>
                  <Typography sx={style.rowText}>
                    {cell.parser(row.session_count - row.engaged_session_count)}
                  </Typography>
                </Box>
              </TableCell>
            )

          default :
            return (
              <TableCell key={cell.key}>
                <Box sx={style.rowCollection}>
                  <Typography title={cell.parser(row[cell.key], currency)} sx={style.rowText}>
                    {cell.parser(row[cell.key], currency)}
                  </Typography>
                </Box>
              </TableCell>
            )
          }
        })
      }
    </TableRow>
  );
}

ReportListRow.displayName = 'ReportListRow';

export default ReportListRow;