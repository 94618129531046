import { Theme } from "@mui/material";

export const style = {
  targeting: {
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 680,
  },
  title: (theme: Theme) => ({
    fontSize: 24,
    lineHeight: 1.17,
    letterSpacing: '-0.2px',
    color: '#292f41',
    margin: theme.spacing(6, 0),
  }),
  formGroups: {
    display: 'flex',
    marginTop: 6,
  },
  formGroup: {
    flex: 1,
  },
  demographicFields: (theme: Theme) => ({
    display: 'flex',
    gap: theme.spacing(8),
    padding: theme.spacing(6, 3, 2),
    flexWrap: 'wrap',
  }),
  label: {
    textTransform: 'uppercase',
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: '.8px',
    color: '#292f41',
  },
  genderSelect: (theme: Theme) => ({
    borderRadius: 1,
    border: '1px solid #dfe7f0',
    display: 'flex',
    width: 'fit-content',
    marginTop: theme.spacing(2),
  }),
  genderField: {
    width: 106,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  selectedGender: {
    backgroundImage: 'linear-gradient(249deg, #65abf6, #2e6bde)',
    borderRadius: 1,
    boxShadow: '0 12px 8px -10px rgba(58, 119, 235, 0.2)',
    border: '1px solid #2e79f7',
    '& > p': {
      color: '#fff',
    },
  },
  genderType: {
    fontSize: 18,
    lineHeight: 1.33,
    color: '#292f41',
  },
  age: {
    width: 300,
  },
  ageRangeSlider: {
    display: 'flex',
    gap: 6,
    alignItems: 'center',
  },
  slider: {
    marginTop: 2,
    '& > .MuiSlider-thumb': {
      backgroundColor: '#fff',
    },
    '& > .MuiSlider-rail': {
      backgroundColor: '#dfe7f0',
      height: 16,
    },
    '& > .MuiSlider-track': {
      height: 16,
    },
    '& > .MuiSlider-mark': {
      height: '100%',
      width: 4,
      opacity: 1,
      backgroundColor: '#fff',
    }
  },
}