import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { DashboardData } from "../../types";
import { formatDate } from "../../../global";

type Props = {
  data: Array<DashboardData>;
  dateFormat: string;
  propertyName: string;
}

function ReportingAreaChart({ data, dateFormat, propertyName }: Props) {
  return (
    <ResponsiveContainer width='100%' height={400}>
      <AreaChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tickFormatter={date => formatDate(date, dateFormat) as string} fontSize={14}/>
        <YAxis />
        <Tooltip
          cursor={false}
          itemStyle={{ textTransform: 'capitalize' }}
          labelFormatter={label => formatDate(label, dateFormat)}
        />
        <Area type="linear" dataKey={propertyName} stroke="#3a77eb" fill="#3A77EB" />
      </AreaChart>
    </ResponsiveContainer>
  )
}

ReportingAreaChart.displayName = 'ImpressionsChart';

export default ReportingAreaChart;