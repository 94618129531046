import { Granularity } from "../types";

const MILLISECONDS_IN_DAYS = 86400000;
const MILLISECONDS_IN_HOURS = 3600000;

function fillDashboardInitialData(fromDate: Date, toDate: Date, granularityType: number) {
  let arrayLength;

  if (granularityType == Granularity.PER_DAYS) { // eslint-disable-line
    arrayLength = Math.floor((
      toDate.getTime() - fromDate.getTime()
    ) / MILLISECONDS_IN_DAYS);
  } else {
    arrayLength = Math.floor((toDate.getTime() - fromDate.getTime()) / MILLISECONDS_IN_HOURS);
  }

  let date = new Date(fromDate);
  return Array(arrayLength).fill({
    cpm: 0,
    reach: 0,
    spent: 0,
    bids: 0,
    clicks: 0,
    impressions: 0,
    cpc: 0,
    ctr: 0,
  }).map(item => {
    if (granularityType == Granularity.PER_DAYS) { // eslint-disable-line
      date.setDate(date.getDate() + 1);
    } else {
      date.setHours(date.getHours() + 1);
    }
    return ({
      ...item,
      name: date.toISOString(),
    });
  });
}


export {
  fillDashboardInitialData,
}