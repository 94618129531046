import { defineMessages } from "react-intl";

export default defineMessages({
  audienceName: {
    id: "Audience.audienceName",
    defaultMessage: "Audience Name",
  },
  estimatedSize: {
    id: "Audience.estimatedSizeSize",
    defaultMessage: "Estimated Size"
  },
  uploadedInfo: {
    id: "Audience.uploadedInfo",
    defaultMessage: "Uploaded Info"
  },
  dateCreated: {
    id: "Audience.dateCreated",
    defaultMessage: "Date Created"
  },
  audienceNameExistTitle: {
    id: "Audience.audienceNameExistTitle",
    defaultMessage: "AUDIENCE NAME ALREADY EXIST"
  },
  audienceNameExistDescription: {
    id: "Audience.audienceNameExistDescription",
    defaultMessage: "To publish the audience, change name."
  }
});
