import { ApiService } from "../../global/services";
import { ForgotPasswordDTO } from "../../auth/types";
import axios from "axios";

class UserService {
  static ENDPOINT = `${window.REACT_APP_BASE_URL}/v1/user`;

  static getUserById(id: string) {
    return ApiService.get(`${UserService.ENDPOINT}/${id}`);
  }

  static resetPassword(data: ForgotPasswordDTO) {
    return axios.post(`${UserService.ENDPOINT}/reset-password/init`, data.email, {headers: {
        'Content-Type': 'text/plain',
        authorization: ""
      }});
  }

  static finishForgotPasswordRequest = (data: any) =>
    axios.post(`${UserService.ENDPOINT}/reset-password/finish`, data, {headers: {
        // 'Content-Type': 'text/plain',
        authorization: ""
      }});
}

export default UserService;
