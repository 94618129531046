import React from "react";
import { FormattedMessage } from "react-intl";
import { Sidebar } from "../../containers";
import "./styles.css";

const Result = () => (
    <div className="not-found-wrapper">
        <Sidebar />
        <h1 style={{ margin: "auto" }}>
            <FormattedMessage id="404.notFound" defaultMessage="Page not found" />
        </h1>
    </div>
);
export default Result;
