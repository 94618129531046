import { defineMessages } from "react-intl";

export default defineMessages({
  passwordNoMatch: {
    id: "Validation.passwordNomatch",
    defaultMessage: "Passwords do not match",
  },
  passwordInsecure: {
    id: "Validation.passwordInsecure",
    defaultMessage:
      "Password should be at least 8 characters and contain 1 each of the following characters types:  number, uppercase, and special symbols.",
  },
  passwordPersonal: {
    id: "Validation.passwordPersonal",
    defaultMessage:
      "Password should not contain any of your personal information-specifically real name, company name.",
  },
  password: { id: "Auth.password", defaultMessage: "Password" },
  repeatPassword: {
    id: "Auth.confirmPassword",
    defaultMessage: "Confirm Password",
  },
  enterPassword: {
    id: "Auth.enterPassword",
    defaultMessage: "Enter Password",
  },
});
