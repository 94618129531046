import { Box } from "@mui/material";
import { style } from './style';
import { CrossIcon } from "../../../global";
import { MouseEvent } from "react";

type Props = {
  images: Array<{ url?: string; id?: string; file_id?: string; }>;
  onRemove: (event: MouseEvent) => void;
}

function ImageList({ images, onRemove }: Props) {
  return (
    <Box sx={style.images}>
      {images.map(image => (
          <Box key={image.id || image.file_id} sx={style.imageBox}>
            <Box src={image.url} component='img' sx={style.image} />
            <CrossIcon style={style.crossIcon} onClick={onRemove}/>
          </Box>
      ))}
    </Box>
  )
}

ImageList.displayName = 'DynamicAdImageList';

export default ImageList;