import { Theme } from "@mui/material";

export const style = {
  campaignNameCell: {
    display: 'flex',
    gap: 4,
    alignItems: 'center',
    maxWidth: 500,
    minHeight: '40px',
    position: 'relative'
  },
  campaignStatus: (color: string) => ({
    color,
    textTransform: 'uppercase',
    fontSize: '.875rem',
    lineHeight: '1rem',
    fontWeight: 400,
    marginTop: 1,
  }),
  tableRow: (theme: Theme) => ({
    cursor: 'pointer',
    '&:hover': {
      background: '#f2f8fc',
      transition: '.3s',
    },
    '& > td': {
      padding: theme.spacing(4, 0),
      verticalAlign: 'text-top',
    },
    '& > td:first-of-type': {
      padding: theme.spacing(2, 0, 2, 6),
      verticalAlign: 'inherit',
    },
    '& > td:last-of-type': {
      verticalAlign: 'inherit',
    },
    // '& > td:nth-last-child(2)': {
    //   verticalAlign: 'inherit',
    // },
  }),
  campaignType: {
    fontSize: 16,
    fontWeight: 'bold',
    padding: '0 16px',
  },
  round: (color: string) => ({
    height: 16,
    width: 16,
    border: '2px solid #dfe7f0',
    borderRadius: '100%',
    backgroundColor: color,
  }),
  campaignName: (theme: Theme) => ({
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: '#292f41',
    fontWeight: 600,
    maxWidth: 380,
    minWidth: 380,
    marginRight: theme.spacing(3),
  }),
  unlimitedBudget: {
    fontWeight: 700,
    fontSize: 16,
    padding: '0 16px',
  },
  budget: {
    padding: '0 16px',
  },
  budgetInCash: {
    fontWeight: 700,
    fontSize: 20,
  },
  budgetType: {
    color: 'rgba(41,47,65,.5)',
    fontSize: '.75rem',
    letterSpacing: '.8px',
    lineHeight: '.75rem',
    textTransform: 'uppercase',
  },
  simpleText: {
    fontSize: 20,
    color: '#292f41',
    lineHeight: '28px',
    fontWeight: 600,
    padding: '0 16px',
    width: 100,
  },
  rowMenu: {
    position: 'relative',
  },
  rowCollection: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  multiRowText: {
    fontSize: '22px',
    color: '#292f41',
    lineHeight: '27px',
    fontWeight: 600,
    width: '100%',
  },
  skeletonWrap: {
    position: 'absolute',
    zIndex: 3,
    top: 0,
    bottom: 0,
    left: 0,
    right: '16px',
    backgroundColor: '#FFFFFF',
  },
  multiRowSkeleton: {
    height: '100%',
    position: 'absolute',
    backgroundColor: 'rgb(244, 246, 250, .8)',
    zIndex: 1,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  metricsBlock: {
    display: 'flex',
    flexDirection: 'row',
  },
  singleMetric: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: '24px'
  },
  metricLabel: {
    fontSize: 13,
    color: '#292f41',
    lineHeight: '15px',
    fontWeight: 300,
  },
  metricText: {
    fontSize: 15,
    color: '#292f41',
    lineHeight: '15px',
    fontWeight: 600,
    minWidth: 50,
  },
  multiRowLabel: {
    fontSize: 12,
    color: '#292f41',
    lineHeight: '18px',
    fontWeight: 300,
    width: "100%",
  },

  switchBox: {
    padding: '0 16px',
    '& span': {
      margin: 'auto',
    }
  },
  progressWrapper: {
    position: 'relative',
    width: 100,
  },
  progress: {
    position: 'absolute',
    width: 400,
  },
  moreVertIcon: {
    color: '#6CA1F2'
  }
};
