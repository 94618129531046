import React, { useEffect, useState } from "react";
import { IUser, IDictionaryField } from "../../../../../../types";
import { profileMessages } from "../../../../../../messages";
import { IntlShape, injectIntl } from "react-intl";
import "./style.scss";
import { USER_STATUSES, USER_STATUSES_VIEW } from "../../../../../../_constants";
import { routerPaths } from "../../../../../../config/routes/paths";
import { formatDateToDMY } from "../../../../../global/utils/date-utils";
import AdminService from "../../../../services/admin.service";
import { CoreTable } from "../../../../../global/components/core-table";
import { TableNotFound } from "../../../../../../components";
import { useHistory } from "react-router-dom";
import { goToRouteLinkWithId, InfoIcon } from "../../../../../global";
import { Tooltip } from "@mui/material";


interface IProps {
  moderationUsers?: IUser[];
  intl: IntlShape;
  dictUserStatus?: IDictionaryField[];
}

const reqData: any = {
  date:{
    from_date: "2024-09-30T15:43:13.885Z",
    to_date: "2024-10-07T15:43:13.885Z",
    datePresetValue: {
      from_date: "2024-09-30T15:43:13.885Z",
      to_date: "2024-10-07T15:43:13.885Z"
    },
    key: "7_DAYS"
  },
  search: "",
  status: [],
  page: 1,
  dateCustomRange: false,
  dateLabel: "",
  from_date: "2024-09-30",
  to_date: "2024-10-07",
  status_filter: [1,2]
}

const headCells = [
  {key: 'name', parser: (data: string) => data || "" },
  {key: 'company_name', parser: (data: string) => data || "" },
  {key: 'email', parser: (data: string) => data || "" },
  {key: 'phone', parser: (data: string) => data || "" },
  {key: 'registration_date', parser: (data: string) => data || "" },
  {key: 'status_key', parser: (data: any) => data || ""}
];

const AdminModerationAccounts = (props: IProps) => {

  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [moderationUsers, setModerationUsers] = useState<any>()

  const { intl: { formatMessage }} = props;

  const renderNotfound = () => {
    return (
      <TableNotFound
        titleId="Admin.accountsNotFoundTitle"
        titleDefaultMessage="No Accounts found"
        descriptionId="Admin.accountsNotFoundDescription"
        descriptionDefaultMessage="Try adjusting filters or choosing different date range."
      />
    );
  };

  const getData = (reqData) => {
    setIsLoading(true);

    AdminService.getModerationAccounts(reqData).then(({data}) => {
      const _moderationUsers = data.data.data.map(
        ({
           first_name,
           id,
           last_name,
           email,
           phone,
           created_at,
           company_name,
           status_key,
         }) => {
          return {
            name: `${first_name} ${last_name}`,
            company_name: company_name ? company_name : "-",
            email,
            phone,
            id,
            registration_date: formatDateToDMY(created_at),
            status_key: (
              <span>
              {USER_STATUSES_VIEW[status_key]}{" "}
                {USER_STATUSES.APPROVED === status_key && (
                  <span className={'tooltip-container'}>
                    <Tooltip  title={formatMessage(profileMessages.waitingUserConfirm)}>
                      <InfoIcon />
                    </Tooltip>
                  </span>
                )}
            </span>
            ),
            link: goToRouteLinkWithId(routerPaths.ModerationUser, id),
          };
        }
      );
      setModerationUsers({
        data: _moderationUsers,
          total_size: data.data.total_size,
          page_size: data.data.page_size,
          current_page: data.data.current_page,
          isLoading: false,
      });
      setIsLoading(false);
    })
  };

  const onPageChange = (newPage: number) => {
    getData({...reqData, page: newPage });
  }

  useEffect(() => {
    getData(reqData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="accounts-moderation-table-wrapper">
      <CoreTable
        data={moderationUsers}
        translationPrefix={'admin.moderation.accountList'}
        loading={isLoading}
        headCells={headCells}
        hasPagination={true}
        noData={renderNotfound()}
        onPageChange={(page) => onPageChange(page)}
        onRowClick={(row) => history.push('/admin/moderation/user/' + row.id)}
      />
    </div>
  );

}

export default injectIntl(AdminModerationAccounts);
