import React from "react";
import iconPlaceholder from '../../assets/images/ic_placeholder.png';

import "./style.scss";
import { CrossIcon, ProfileIcon } from "../../modules";

function nFormatter(num: any) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "B";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
}

interface IProps {
  withCloseIcon?: boolean;
  items?: any;
  deleteItem: () => void;
}
class AppsAndWebsitesPreviewList extends React.Component<IProps> {
  render() {
    const {
      props: { items, withCloseIcon, deleteItem },
    } = this;
    return (
      <div className="apps-and-websites-preview-list-item">

        <div className="apps-and-websites-preview-list-item-content">
          <div className="apps-and-websites-preview-list-item-content-left-block">
            {/* {withCloseIcon && ( */}
            <div
              style={{
                minHeight: "36px",
                minWidth: "36px",
                height: "36px",
                width: "36px",
                borderRadius: "4px",
              }}
            >
              <img
                src={items?.icon || iconPlaceholder}
                alt="logo"
                style={{
                  borderRadius: "4px",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
            {/* )} */}
            <div style={{ width: "100%" }}>
              <p className="apps-and-websites-preview-list-item-content-left-block-name">
                {items ? items?.name : ""}
              </p>
              <p className="apps-and-websites-preview-list-item-content-left-block-type">
                {items?.description || ""}
              </p>
            </div>
          </div>
          {/*+++++++++++++++++++++++++++++++++*/}
          <div className="apps-and-websites-preview-list-item-content-right-block">
            <div className="apps-and-websites-preview-profile-icon">
              <div>
                <ProfileIcon />
              </div>
              <p>
                {items
                  ? `${nFormatter(items?.users_min)} - ${nFormatter(
                      items?.users_max
                    )}`
                  : "0"}
              </p>
            </div>
            {withCloseIcon && (
              <div
                className="apps-and-websites-preview-close-icon"
                onClick={() => {
                  deleteItem();
                }}
              >
                <CrossIcon />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default AppsAndWebsitesPreviewList;
