import { Theme } from "@mui/material";

export const style = {
  circle: {
    position: 'absolute',
    right: '10px',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    cursor: 'pointer'
  },
  tabs: {
    border: '1px solid #dfe7f0',
    borderRadius: 1,
    width: 'fit-content',
    minHeight: "10px"
  },
  tab: (theme: Theme) => ({
    fontSize: 18,
    minHeight: "10px",
    padding: theme.spacing(1, 6),
    textTransform: 'none',
    '&.Mui-selected': {
      backgroundColor: 'primary.main',
      color: '#fff',
    }
  }),

}