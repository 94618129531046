import { configureStore } from '@reduxjs/toolkit';
import axios from 'axios';
import { rootReducer } from '../modules/store/reducers';
// import logger from 'redux-logger';

// import { useLocalStorage } from 'hooks/use-local-storage';

import { loadAuthState, saveAuthState } from './auth-storage';
// import { getLocalStorage } from 'configs/local-storage';

// import { IMenuState } from 'reducers/menu-state';

// const menuStateStore = getLocalStorage<IMenuState>('menuState', '{"opened":true}');


export const store1 = configureStore({
  reducer: rootReducer,
  preloadedState: { ...{ authenticationStore: loadAuthState() } },
  // middleware: getDefaultMiddleware => getDefaultMiddleware().concat(logger),
  devTools: process.env.NODE_ENV !== 'production',
});

store1.subscribe(() => {

  const data = store1.getState();

  if (data.authenticationStore) {

    saveAuthState(data.authenticationStore);

    axios.defaults.headers.common.Authorization = `Bearer ${data.authenticationStore.authToken}`;
  }
});
