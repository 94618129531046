import React, { useState } from "react";
import { injectIntl, IntlShape, defineMessages } from "react-intl";
import "./style.scss";
import { adminMessages } from "../../../../messages";
import { CoreTabs, TabBody } from "../../../global/components/CoreTabs";
import AdminHeader from "../../components/AdminHeader";
import { AdminModerationCampaigns } from "./components/AdminModerationCampaigns";
import { AdminModerationAccounts } from "./components/AdminModerationAccounts";
import { AdminLegalModerationAccounts } from "./components/AdminLegalModerationAccounts";


const messages = defineMessages({
  title: {
    id: "Moderation.title",
    defaultMessage: "Moderation",
  },
});

interface IProps {
  intl: IntlShape;
}

const Moderation = (props: IProps) =>  {

  const {
    intl: { formatMessage },
  } = props;

  const [selected, setSelected] = useState(1);


  return (
    <AdminHeader title={formatMessage(messages.title)}>
      <div>
        <CoreTabs
          className={'admin-moderation-tabs-header'}
          config={[
            {label: `${formatMessage(adminMessages.adminSearchCampaigns)}`},
            {label: `${formatMessage(adminMessages.adminSearchAccounts)}`},
            {label: `${formatMessage(adminMessages.adminLinksLegalEntity)}`},
          ]}
          selectedTab={selected}
          onTabSelect={ setSelected }
        />

        <TabBody selectedTab={selected} tabKey={0}>
          <div className="admin-moderation-tab">
            <AdminModerationCampaigns />
          </div>
        </TabBody>
        <TabBody selectedTab={selected} tabKey={1}>
          <div className="admin-moderation-tab">
            <AdminModerationAccounts />
          </div>
        </TabBody>
        <TabBody selectedTab={selected} tabKey={2}>
          <div className="admin-moderation-tab">
            <AdminLegalModerationAccounts />
          </div>
        </TabBody>
      </div>
    </AdminHeader>
);
}

// const mapStateToProps = (state: IRootState) => ({
//   moderationUsers: select.admin.moderationUsersSelector(state),
//   moderationCampaign: select.admin.moderationCampaignSelector(state),
//   dictUserStatus: select.dictionary.selectDictionaryField(state, "user_status"),
// });
//
// const mapDispatchToProps = (dispatch: Dispatch) => ({
//   getModerationRegistrations: dispatch.admin.getModerationRegistrations,
//   getModerationCampaign: dispatch.admin.getModerationCampaign,
//   setAdminNewSpace: dispatch.admin.setAdminNewSpace,
//   getDictionaryValues: dispatch.dictionary.getDictionaryValues,
// });

// Moderation.displayName = "Moderation";

export default injectIntl(Moderation);



;
