import { Theme } from "@mui/material";

export const style = {
  image: {
    width: 36,
    height: 36,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    borderRight: '1px solid rgba(41,47,65,.2)',
    flex: '60% 1',
  },
  optionName: {
    fontSize: 16,
    marginLeft: 4,
  },
  numbers: {
    flex: '40% 1',
    paddingLeft: 4,
    display: 'flex',
    gap: 2,
    alignItems: 'center',
  },
  appList: (theme: Theme) => ({
    padding: theme.spacing(3, 4),
    backgroundColor: '#f4f6fa',
    border: '1px solid #dfe7f0',
    borderRadius: 1,
    overflow: 'auto',
    maxHeight: 200,
    height: 'auto',
    gap: theme.spacing(3),
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
  }),
  app: {
    display: 'flex',
  },
  appInList: (theme: Theme) => ({
    backgroundColor: '#fff',
    paddingLeft: theme.spacing(3),
    padding: theme.spacing(3, 2, 3, 3),
    borderRadius: 2,
    width: 210,
    height: 50,
    alignItems: 'center',
  }),
  appTitle: {
    fontSize: 12,
    letterSpacing: '0.8px',
    color: '#292f41',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100px',
  },
  autocomplete: {
    backgroundColor: '#fff',
  },
  crossIcon: {
    marginLeft: 'auto',
    cursor: 'pointer',
  },
  appInfo: (theme: Theme) => ({
    marginLeft: theme.spacing(2),
  }),
  noItemFound: {
    fontSize: 18,
    lineHeight: 1.33,
    color: 'rgba(41, 47, 65, 0.5)',
    display: 'flex',
    gap: 1,
    flexWrap: 'wrap',
  },
  itemText: {
    color: '#292f41',
  },
  noOptionBox: (theme: Theme) => ({
    paddingBottom: theme.spacing(24),
  }),
  pendingIcon: {
    backgroundColor: '#DFE7F0',
    display: 'flex',
    borderRadius: 1,
  }
}