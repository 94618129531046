export const RT_OK = 0;
export const RT_BACKEND_EXCEPTION = 1;
export const RT_INVALID_ARGUMENT = 2;
export const RT_ILLEGAL_ACTION = 3;
export const RT_TOKEN_EXPIRED = 4;
export const RT_TOKEN_INVALID = 5;
export const RT_USER_NOT_FOUND = 6;
export const RT_USER_ACCOUNT_NOT_FOUND = 7;
export const RT_SIZE_INVALID = 8;
export const RT_FILE_NOT_CONTAIN_INDEX_HTML = 10;
export const RT_USER_PASSWORD_NOT_MATCH = 12;
export const RT_DATE_FORMAT_INVALID = 11;
export const RT_JWT_SPACE_ID_NOT_FOUND = 20;

// Space related
export const RT_SPACE_NOT_FOUND = 110;
export const RT_SPACE_HAS_NO_CAMPAIGN = 111;

// Campaign related
export const RT_CAMPAIGN_NOT_FOUND = 130;
export const RT_CAMPAIGN_AUDIENCE_NOT_FOUND = 131;
export const RT_CAMPAIGN_CREATIVES_NOT_FOUND = 132;
export const RT_CAMPAIGN_NOT_SUPPORTED_STATUS = 133;
export const RT_CAMPAIGN_ILLEGAL_STATUS_FOR_ACTION = 134;
export const RT_CAMPAIGN_NOT_READY_FOR_MODERATION = 135;
export const RT_CAMPAIGN_NOT_NEED_MODERATION = 136;
export const RT_CAMPAIGN_NEED_MODERATION = 137;
export const RT_CAMPAIGN_IS_OBSOLETE_FOR_MODERATION = 138;
export const RT_CAMPAIGN_IS_NOT_CHANGED_AFTER_REJECTION = 139;
export const RT_CAMPAIGN_MANDATORY_FIELDS_MISSING = 1301;
export const RT_CAMPAIGN_BUDGET_AND_BID_MISMATCH = 1302;
export const RT_CAMPAIGN_START_DATE_END_DATE_MISMATCH = 1303;
export const RT_CAMPAIGN_START_DATE_SHOULD_BE_IN_FUTURE = 1304;
export const RT_CAMPAIGN_END_DATE_SHOULD_BE_IN_FUTURE = 1305;
export const RT_CAMPAIGN_START_DATE_IN_NOT_MODIFIABLE = 1306;
export const RT_CAMPAIGN_WRONG_BUDGET_TYPE_SPECIFIED = 1307;
export const RT_CAMPAIGN_WRONG_BID_SPECIFIED = 1308;
export const RT_CAMPAIGN_WRONG_BUDGET_SPECIFIED = 1309;
export const RT_CAMPAIGN_APP_URL_IS_NOT_MODIFIABLE = 1310;
export const RT_CAMPAIGN_APP_DOES_NOT_EXIST_IN_THE_APP_STORE = 1311;
export const RT_CAMPAIGN_WRONG_PIXEL_URL_SPECIFIED = 1313;
export const RT_CAMPAIGN_NAME_EXIST = 1312;
export const RT_CAMPAIGN_WRONG_URL = 1314;
export const RT_SPACE_INVITATION_VALIDATION_ISSUES = 1400;
export const RT_SPACE_SPACE_BALANCE_SPENT = 1440;
export const RT_SPACE_INVITATION_USER_VERIFIED = 1401;
export const RT_SPACE_INVITATION_USER_NOT_VERIFIED = 1402;
export const RT_SPACE_MISMATCH = 1420;

// Report related
export const RT_REPORT_MODE_IS_NOT_SUPPORTED = 150;

export const RT_REGISTRATION_MAIL_EXIST = 160;
export const RT_REGISTRATION_NOT_FOUND = 161;
export const RT_REGISTERED_USER_VERIFIED = 162;
export const RT_REGISTERED_USER_NOT_VERIFIED = 163;

export const RT_COMPANY_NOT_FOUND = 170;

export const RT_AUDIENCE_NOT_FOUND = 180;
export const RT_FILE_SIZE_INVALID = 181;
export const RT_AUDIENCE_SIZE_INVALID = 182;
export const RT_AUDIENCE_NAME_EXISTS = 183;
export const RT_LOCATION_NOT_FOUND = 190;
