import { Box, Typography } from "@mui/material";
import noAudience from '../../../../assets/images/no_audience.png';
import { useTranslation } from "react-i18next";
import { style } from './style';


function NoCampaignLog() {
  const { t } = useTranslation('translation', { keyPrefix: 'campaign.log' });
  return (
    <Box sx={style.emptyTable}>
      <Box
        component='img'
        src={noAudience}
        width={220}
        height={160}
        alt='No Data To Display image'
        sx={style.image}
      />
      <Typography sx={style.noCampaigns}>{t('noLog')}</Typography>
      {/*<Typography sx={style.description}>{t('noResult')}</Typography>*/}
      {/*<Typography sx={style.description}>{t('checkFilter')}</Typography>*/}
    </Box>
  );
}

NoCampaignLog.displayName = 'NoCampaignLog';

export default NoCampaignLog;