/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 8/15/2023
 * All Rights Reserved.
 */
import { Box, Divider, InputAdornment, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { style } from "./style";
import { InfoIcon, IntCheckbox, IntFieldController } from "../../../global";
import { useFormContext, useWatch } from "react-hook-form";
import IntDatePicker from "../../../global/components/IntForm/IntDatePicker";
import { useSelector } from "react-redux";
import { CampaignFormDTO, CampaignObjective, CampaignObjectiveType } from "../../types";
import { ReactNode, useMemo } from "react";
import campaignGoalImage from "../../../../assets/images/choose-goal.svg";
import AwarenessForm from "./AwarenessForm";
import WebsitesForm from "./WebsitesForm";
import AppsForm from "./AppsForm";
import AttributionForm from "./AttributionForm";
import { getCurrentAccountCurrency } from "../../../store/reducers/authentication";

type Props = {
  footer: ReactNode;
}

function CampaignBasicInfo({ footer }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'campaign.basicInfo' });

  const {
    setValue,
    control,
    formState
  } = useFormContext<CampaignFormDTO>();

  const [userCPX, cpxManual, campaignType, goal] = useWatch({
    control,
    name: ['userCPX', 'cpxManual', 'bidding_strategy.type', 'bidding_strategy.goal'],
  });

  const isAudioAd = goal === CampaignObjective.AUDIO_ADVERTISING;

  const currency = useSelector(getCurrentAccountCurrency);

  const perThousandLabel = useMemo(() => {
    /* eslint-disable */
    if (campaignType == CampaignObjectiveType.CONVERSION) {
      return 'conversion';
    }
    if (campaignType == CampaignObjectiveType.CONSIDERATION && goal === CampaignObjective.LANDING_PAGE_TRAFFIC) {
      return 'click';
    }
    if (goal == CampaignObjective.APP_INSTALLS) {
      return 'install'
    }

    return 'impression';
  }, [campaignType, goal]);

  const isAppCampaign = campaignType === CampaignObjectiveType.CONSIDERATION
    && goal === CampaignObjective.APP_INSTALLS;
  /* eslint-enable */
  return (
    <Box sx={style.basicInfo}>
      <Typography sx={style.title}>{t('title')}</Typography>
      {isAudioAd && (
        <Typography sx={style.description}>{t('description')}</Typography>
      )}
      <Divider />
      <Box sx={style.content}>
        <Box sx={style.field}>
          <Typography sx={style.label}>{t('campaignName')}</Typography>
          <IntFieldController control={control} name='name' sx={style.input} />
        </Box>
        {/*{!isAudioAd && (*/}
        {/*  <Box sx={style.field}>*/}
        {/*    <Box sx={style.labelWithTooltip}>*/}
        {/*      <Typography sx={style.label}>{t('clickLink')}</Typography>*/}
        {/*      <Tooltip title={t('clickLinkTooltip')}>*/}
        {/*        <InfoIcon />*/}
        {/*      </Tooltip>*/}
        {/*    </Box>*/}
        {/*    <IntFieldController*/}
        {/*      control={control}*/}
        {/*      name='productURL'*/}
        {/*      sx={style.input}*/}
        {/*      disabled*/}
        {/*      placeholder='https://'*/}
        {/*    />*/}
        {/*  </Box>*/}
        {/*)}*/}
        {/*{*/}
        {/*isAppCampaign && <Box sx={style.field}>*/}
        {/*  <Typography sx={style.label}>{t('impressionTrackingURL')}</Typography>*/}
        {/*  <IntFieldController*/}
        {/*    control={control}*/}
        {/*    name='bidding_strategy.app_promotion.tracking_url'*/}
        {/*    sx={style.input}*/}
        {/*    disabled*/}
        {/*  />*/}
        {/*</Box>*/}
        {/*}*/}
        <Box sx={style.fields}>
          <Box sx={[style.field, style.halfRow]}>
            <Typography sx={style.label}>{t('startDate')}</Typography>
            <IntFieldController
              control={control}
              name='campaignStartAt'
              Component={IntDatePicker}
              placeholder={t<string>('immediately')}
              minDate={new Date()}
            />
          </Box>
          <Box sx={[style.field, style.halfRow]}>
            <Typography sx={style.label}>{t('endDate')}</Typography>
            <IntFieldController
              control={control}
              name='campaignEndAt'
              Component={IntDatePicker}
              placeholder={t<string>('selectDate')}
              minDate={new Date()}
            />
          </Box>
        </Box>
        <Box sx={style.fields}>
          <Box sx={[style.field, style.halfRow]}>
            <Box sx={style.labelWithTooltip}>
              <Typography sx={style.label}>{t('dailyBudget')}</Typography>
              <Tooltip title={t('dailyAmountTooltip')}>
                <InfoIcon />
              </Tooltip>
            </Box>
            <IntFieldController
              control={control}
              name='budget'
              sx={style.dailyAmount}
              type='number'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    {currency || '$'}
                  </InputAdornment>
                )
              }}
            />
          </Box>
        </Box>
        {!isAudioAd && (
          <>
            <Divider />
            <Box>
              <Box sx={style.checkboxField}>
                <IntFieldController control={control} name='cpxManual' Component={IntCheckbox} />
                <Typography sx={style.label}>{t('manualOptimization')}</Typography>
                {cpxManual && (
                  <IntFieldController
                    control={control}
                    name='userCPX'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          {currency || '$'}
                        </InputAdornment>
                      )
                    }}
                    sx={style.cpm}
                  />
                )}
              </Box>

              {cpxManual && (
                <Box sx={style.optimizer}>
                  <Typography sx={style.optimizerLabel}>
                    {t('optimizeFor')}
                  </Typography>
                  <Box sx={style.tooltipWithLabel}>
                    <Typography>
                      <b>{currency}{userCPX}</b>
                      {' '}
                      {t(`perThousand.${perThousandLabel}`)}
                    </Typography>
                    <Tooltip title={t(`perThousandTooltip.${perThousandLabel}`)}>
                      <InfoIcon />
                    </Tooltip>
                  </Box>
                </Box>
              )}
            </Box>
          </>
        )}

        {campaignType !== CampaignObjectiveType.OTHER ? (<Box sx={style.formContent}>
          {!campaignType && (
            <Box sx={style.chooseGoalFirst}>
              <Box component='img' src={campaignGoalImage} width={160} height={130} />
              <Typography sx={style.chooseGoal}>{t('chooseGoal')}</Typography>
            </Box>
          )}
          {campaignType === CampaignObjectiveType.AWARENESS && (
            <AwarenessForm control={control} setValue={setValue} />
          )}
          {campaignType === CampaignObjectiveType.CONVERSION && (
            <WebsitesForm control={control} setValue={setValue} errors={formState.errors}/>
          )}
          {campaignType === CampaignObjectiveType.CONSIDERATION && (
            <AppsForm control={control} setValue={setValue} />
          )}

        </Box>) : null}

        { goal !== CampaignObjective.AUDIO_ADVERTISING &&
        goal !== CampaignObjective.APP_INSTALLS &&
        goal !== CampaignObjective.APP_CUSTOM_EVENT ? <AttributionForm /> : null }

      </Box>
      {footer}
    </Box>
  );
}

CampaignBasicInfo.displayName = 'CampaignBasicInfo';

export default CampaignBasicInfo;