/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 9/10/2023
 * All Rights Reserved.
 */
import { ReactElement, useEffect, useState } from "react";
import { Modal } from "@mui/material";
import { Prompt, useHistory } from "react-router-dom";

type Props = {
  when: boolean;
  shouldBlockNavigation?: Function;
  content: (props: { onCancel: (cb?: Function) => void; onConfirm: () => void; }) => ReactElement;
}

function BlockRoute({
  when,
  shouldBlockNavigation = () => false,
  content,
}: Props) {
  const [modalVisible, updateModalVisible] = useState(false);
  const [lastLocation, updateLastLocation] = useState<null | { pathname: string; }>(null);
  const [confirmedNavigation, updateConfirmedNavigation] = useState(false);

  const history = useHistory();

  const showModal = (location: { pathname: string; }) => {
    updateModalVisible(true);
    updateLastLocation(location);
  };

  const closeModal = (cb?: Function) => {
    updateModalVisible(false);
    if (typeof cb == 'function') {
      cb();
    }
  };

  const handleBlockedNavigation = (nextLocation: { pathname: string; }, message: string) => {
    if (history.location.pathname === nextLocation.pathname) {
      return true;
    }
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      showModal(nextLocation);
      return false;
    }
    return true;
  };
  const handleConfirmNavigationClick = () => {
    closeModal(() => {
      if (lastLocation) {
        updateConfirmedNavigation(true);
      }
    });
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      const referrer = sessionStorage.getItem('referrer');
      history.push(referrer || lastLocation.pathname);
      updateConfirmedNavigation(false);
    }
  }, [confirmedNavigation]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Modal open={modalVisible} onClose={() => closeModal()}>
        {content({ onConfirm: handleConfirmNavigationClick, onCancel: closeModal })}
      </Modal>
    </>
  );
}

export default BlockRoute;
