import React from "react";
import { useIntl } from "react-intl";
import { IAdminAccountsTotals } from "../../../../../../types";
import { adminMessages } from "../../../../../../messages";
import "./style.scss";
import DashboardCard from "../../../../../../components/DashboardCard";

interface IProps {
  totals: IAdminAccountsTotals;
  loading: boolean;
}

export const AdminAccountsTotals = ({ totals, loading }: IProps) => {
  const { formatMessage } = useIntl();

  return (
    <div className="admin-accounts-totals">
      <DashboardCard
        data={[
          {
            label: formatMessage(
              adminMessages.accountsTotalsRegisteredAccounts
            ),
            value: totals?.users,
            tooltip: formatMessage(
              adminMessages.accountsTotalsRegisteredAccountsTooltip
            ),
          },
        ]}
        loading={loading}
        disabled
      />
      <DashboardCard
        data={[
          {
            label: formatMessage(adminMessages.accountsTotalsCompanyAccounts),
            value: totals?.companies,
            tooltip: formatMessage(
              adminMessages.accountsTotalsCompanyAccountsTooltip
            ),
          },
        ]}
        loading={loading}
        disabled
      />
      <DashboardCard
        data={[
          {
            label: formatMessage(adminMessages.accountsTotalsRunningCampaigns),
            value: totals?.running_campaigns,
            tooltip: formatMessage(
              adminMessages.accountsTotalsRunningCampaignsTooltip
            ),
          },
        ]}
        loading={loading}
        disabled
      />
      <DashboardCard
        data={[
          {
            label: formatMessage(adminMessages.accountsTotalsTotalImpressions),
            value: totals?.impressions,
            tooltip: formatMessage(
              adminMessages.accountsTotalsTotalImpressionsTooltip
            ),
          },
        ]}
        loading={loading}
        disabled
      />
      <DashboardCard
        data={[
          {
            label: formatMessage(adminMessages.accountsTotalsTotalBalance),
            value: totals?.balance,
            tooltip: formatMessage(
              adminMessages.accountsTotalsTotalBalanceTooltip
            ),
            showMoneySymbol: true,
          },
        ]}
        loading={loading}
        disabled
      />
    </div>
  );
};

export default AdminAccountsTotals;
