// @ts-nocheck
import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { InfoDialogContainer } from "../../containers";
import { tawkInit } from "../../utils";
import { ISpace, IUser } from "../../types";
import { TAWK_API, userRoles } from "../../_constants";
import Loading from "../../modules/global/components/Loading";
import {
  isUserLoaded,
  setSpace,
  setUser,
} from "../../modules/store/reducers/authentication";
import { setHasCampaign } from "../../modules/store/reducers/campaign";
import { setWhitelabelData } from "../../modules/store/reducers/space";
import { setWhitelabel, whitelabelDataSelector } from "../../modules/store/reducers/admin";
import { hasCampaignSelector } from "../../modules/store/reducers/campaign";
import {
  getCompanyWhitelabelDataRequest,
  getCompanyWhitelabelRequest,
  getUserRequest,
} from "../../api";
import { i18n } from "../../modules";
import CampaignService from "../../modules/campaign/services/campaign.service";
// TODO: Children type

const MainContainer = (props: any) => {
  const fbContainer = useRef<HTMLDivElement>();
  const dispatch = useDispatch();
  const userLoaded = useSelector(isUserLoaded)
  const whitelabelData = useSelector(whitelabelDataSelector)
  const hasCampaign = useSelector(hasCampaignSelector)

  const {children} = props;


  const getUserSuccessCallback = (user: IUser) => {
    if (user.role_key === userRoles.WL_OWNER) {
      return null;
    }

    getCompanyWhitelabelRequest(user.whitelabel_id).then(({data:{ data }}) => {
      dispatch(setWhitelabelData(data));
      if (data.whitelabel_settings.fb_page_id) {
        const { fb_page_id, primary_color } = data.whitelabel_settings;
        const fbElement = ReactDOM.findDOMNode(fbContainer.current);
        fbElement.setAttribute("page_id", fb_page_id);
        fbElement.setAttribute("theme_color", `#${primary_color}`);
        FB.init({
          xfbml: true,
          version: "v6.0",
        });
      } else {
        tawkInit(TAWK_API, () => {});
      }
    })
  };

 const getUserData = () => {
     getUserRequest().then(async (res) => {
       const user: IUser = res.data.data;
       if (!user) {
         return;
       }
       if (user.locale === 'ru_RU') {
         i18n.changeLanguage('ru');
       } else if (user.locale === 'en_US') {
         i18n.changeLanguage('en');
       }
       const {data} = await CampaignService.getSpaceCampaignCountRequest(user.current_space_id)
         dispatch(setHasCampaign(data.type.code === 0 && data.data));
       const currentSpace: ISpace =
         Object.values(user.spaces)
           .flat()
           .find(({ id }) => id === user.current_space_id) || {};
       sessionStorage.setItem('spaceID', currentSpace.id);
       // TODO: remove this code and make normal code
       getUserSuccessCallback(user)

       dispatch(setSpace(currentSpace));
       dispatch(setUser(user));
     })
  }

  // const getDictionary = () => {
  //   const currentHash = Number(localStorage.getItem(DICT_HASH_KEY));
  //   if (!currentHash) {
  //     getDictionaryRequest().then(({ data }: IDictionaryResponse) => {
  //       localStorage.setItem(DICT_HASH_KEY, data.hash);
  //       // saveDictionary(data.dictionary);
  //       dispatch(dictLoaded(true));
  //     })
  //   } else {
  //     getDictionaryHashRequest().then((hash: number) => {
  //       if (currentHash !== hash) {
  //         getDictionaryRequest().then(({ data }: IDictionaryResponse) => {
  //           localStorage.setItem(DICT_HASH_KEY, data.hash);
  //           // saveDictionary(data.dictionary);
  //           dispatch(dictLoaded(true));
  //         })
  //       }
  //     })
  //   }
  // }
  const getWhitelabel = async () => {
    const {data} = await getCompanyWhitelabelDataRequest();
    dispatch(setWhitelabel(data.data));
  }

  useEffect(() => {
    getWhitelabel();
    getUserData();
    // getDictionary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    return !userLoaded || (hasCampaign === null && userLoaded !== true) || !whitelabelData ? <Loading /> : (
      <div className="dsp">
        <div
          ref={fbContainer}
          className="fb-customerchat"
          attribution="install_email"
        />
        <Helmet>
          <title>{whitelabelData?.name}</title>
        </Helmet>
        <InfoDialogContainer>{children}</InfoDialogContainer>
        {/*{children}*/}
      </div>
    );

}

// const mapDispatchToProps = (dispatch: Dispatch) => ({
  // getDictionary: dispatch.dictionary.getDictionary,
  // getUser: dispatch.authentication.getUser,
  // getCompanyWhitelabel: dispatch.space.getCompanyWhitelabel,
  // getWhitelabel: dispatch.admin.getWhitelabel,
// });

export default MainContainer;
