import { mixed, object, string } from "yup";
import { t } from "i18next";
import _ from "lodash";

function getCreateAudienceValidationSchema() {
  return object().shape({
    name: string().required(t<string>('errorMessages.required')),
    file: mixed<File>()
      .test(
        "required",
        t<string>('errorMessages.required'),
        (file?: File) => !!file,
      ),
  });
}

const validateAudience = (list: IActionItem[]) => {
  const result = {isValid: true, data: []}
  if (!list.length) return result;

  const _list = _.cloneDeep(list);

  _list.forEach(item => {
    if(!item.period) {
      item.periodError = 'required field';
      result.isValid = false;
    }
    if(!item.action) {
      item.actionError = 'required field';
      result.isValid = false;
    }
    if(!!item.parameters.length){
      const params = _.cloneDeep(item.parameters);
      params.forEach(p => {
        if(!p.value) {
          p.valueError = 'required field';
          result.isValid = false;
        }
      })
      item.parameters = params;
    }
  })

  return {...result, data: _list}
}

export {
  validateAudience,
  getCreateAudienceValidationSchema,
}