import React from "react";
import { FormattedMessage } from "react-intl";
import "./style.scss";

interface IProps {
  id: string;
  defaultMessage: string;
  staticText?: string;
  data?: any;
}

export function InputErrorMessage(props: IProps) {
  const {id, defaultMessage, staticText, data } = props;
  return (
    <div className="input-error-message">
      {staticText ? (
        staticText
      ) : (
        <FormattedMessage
          id={id}
          defaultMessage={defaultMessage}
          values={data}
        />
      )}
    </div>
  );
}

