import { Theme } from "@mui/material";

export const style = {
  tableRow: (theme: Theme) => ({
    background: '#FFFFFF',
    cursor: 'pointer',
    '&:hover': {
      background: '#f2f8fc',
      transition: '.3s',
    },
    '& > td': {
      padding: theme.spacing(2, 0),
      verticalAlign: 'text-top',
    },
    '& > td:first-of-type': {
      'div': {
        width: '150px',

        '& > p' : {
          fontWeight: 600
        }
      },

      padding: theme.spacing(2, 0, 2, 2),
      verticalAlign: 'inherit',
    },
    '& > td:last-of-type': {
      verticalAlign: 'inherit',
    },
    '& > td:nth-last-child(2)': {
      verticalAlign: 'inherit',
    },
  }),
  rowCollection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '80px',
    padding: '0 8px',
    // margin: 'auto',
  },
  rowText: {
    fontSize: 15,
    color: '#292F41',
    lineHeight: '16px',
    fontWeight: 500,
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },

};
