import { Theme } from "@mui/material";

export const style = {
  geographyInputs: (theme: Theme) => ({
    display: 'flex',
    marginTop: theme.spacing(3),
    gap: '5%',
    flexWrap: 'wrap',
    padding: theme.spacing(3, 0, 4),
  }),
  appCategories: {
    flexBasis: '100%',
  },
  fieldLabel: (theme: Theme) => ({
    fontSize: 14,
    letterSpacing: '.8px',
    lineHeight: '16px',
    color: 'rgba(41,47,65,.5)',
    textTransform: 'uppercase',
    marginBottom: theme.spacing(2),
  }),
  excludeAllGames: (theme: Theme) => ({
    flexBasis: '100%',
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(3, 0),
  }),
  excludeAllGamesLabel: {
    fontSize: 14,
    lineHeight: '1.71',
    color: '#505d68',
  },
  textArea: {
    height: 'fit-content',
  },
  gameCategories: (theme: Theme) => ({
    flexBasis: '100%',
    marginBottom: theme.spacing(6),
  }),
  placementSelectFields: {
    flexBasis: '47.5%',
  },
  field: {
    width: '100%',
  },
  devicesToDisplay: (theme: Theme) => ({
    marginTop: theme.spacing(4),
  }),
  selectField: {
    height: 40,
  },
  title: {
    fontSize: 24,
    lineHeight: 1.17,
    letterSpacing: '-0.2px',
    color: '#292f41',
    marginBottom: 5,
  },
  languageField: (theme: Theme) => ({
    marginTop: theme.spacing(4),
  }),
  geography: {
    width: '100%',
    maxWidth: 680,
    minWidth: 680,
  },
}