import "./IntDrawer.scss";


const AdminSearch = (props) => {
  const {children, onClose} = props

  const closeHandler = (e) => (e?.target?.tagName.toUpperCase() !== 'INPUT' && e?.target?.tagName.toUpperCase() !== 'BUTTON' && onClose());

  return (
    <div onClick={closeHandler} className={`int-drawer int-drawer-top int-drawer-open`} style={{transition: "none 0s ease 0s"}}>
      <div className={'int-drawer-mask'}></div>
      <div className={'int-drawer-content-wrapper'}>
        <div className={'int-drawer-content'}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default AdminSearch;