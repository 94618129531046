import {
  Box,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  Link,
  Typography,
} from "@mui/material";
import { style } from "./style";
import { useTranslation } from "react-i18next";
import { AllTimePerformanceDTO, CampaignStatus } from "../../types";
import { ArrowIcon, formatDateMDY, numberToStringWithCommas } from "../../../global";
import { useEffect, useMemo, useState } from "react";
import { CampaignService } from "../../services";
import { useSelector } from "react-redux";
import { Link as RouterLink } from 'react-router-dom';
import { routerPaths } from "../../../../config/routes/paths";
import { getCurrentAccountCurrency } from "../../../store/reducers/authentication";

type Props = {
  statusKey: number;
  campaignStartAt: Date | undefined;
  id: string;
}

export type AllTimePerformanceProps = Props;

function AllTimePerformance({ statusKey, campaignStartAt, id }: Props) {
  const { t } = useTranslation();

  const [allTimePerformance, setAllTimePerformance] = useState<AllTimePerformanceDTO>({
    isLoading: true,
    data: null,
  });

  const currency = useSelector(getCurrentAccountCurrency)

  function getAllTimePerformance() {
    setAllTimePerformance({
      data: null,
      isLoading: true,
    })
    CampaignService.getAllTimePerformance(id)
    .then(({ data }) => {
      setAllTimePerformance({
        isLoading: false,
        data: data.data,
      });
    });
  }

  useEffect(() => {
    getAllTimePerformance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);


  const ctr = useMemo(() => {
    if (!allTimePerformance.data) {
      return 0;
    }

    const ctrVal = (allTimePerformance.data.current_period_clicks / allTimePerformance.data.current_period_impressions)
      * 100;

    if (Number.isNaN(ctrVal) || ctrVal === Infinity) {
      return 0;
    }

    return ctrVal;
  }, [allTimePerformance]);

  // eslint-disable-next-line
  const showDate = statusKey == CampaignStatus.RUNNING || statusKey == CampaignStatus.WAITING_TO_START ;
// eslint-disable-next-line
  const moderating = statusKey == CampaignStatus.IN_MODERATION;

  return (
    <Card sx={style.allTimePerformance}>
      <CardContent>
        <Typography
          sx={style.headerTitle}
          variant='h6'
        >{t('campaign.performance.title')}</Typography>
        <Divider />
        <Box sx={style.status}>
          <Box sx={style.circle(statusKey)}/>
          <Typography sx={style.statusText}>
            {t(`campaign.performance.status.${statusKey}`)}
            {' '}
            {showDate && (
              formatDateMDY(campaignStartAt)
            )}
          </Typography>
        </Box>
        <Divider />
        {!moderating && (
          <Box sx={style.total}>
            <Box sx={style.totalInfo}>
              <Typography>{t('campaign.performance.spent')}</Typography>
              {allTimePerformance.isLoading ? (
                <CircularProgress size={24} />
              ) : (
                <Typography sx={style.totalValue}>
                  {
                    `${currency || '$'} ${
                      numberToStringWithCommas(allTimePerformance.data?.current_period_spent || 0)
                    }`
                  }
                </Typography>
              )}
            </Box>
            <Box sx={style.totalInfo}>
              <Typography>{t('campaign.performance.impressions')}</Typography>
              {allTimePerformance.isLoading ? (
                <CircularProgress size={24} />
              ) : (
                <Typography sx={style.totalValue}>
                  {numberToStringWithCommas(allTimePerformance.data?.current_period_impressions || 0)}
                </Typography>
              )}
            </Box>
              <Box sx={style.totalInfo}>
                <Typography>{t('campaign.performance.ctr')}</Typography>
                {allTimePerformance.isLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  <Typography sx={style.totalValue}>
                    {numberToStringWithCommas(ctr,2)}
                    %
                  </Typography>
                )}
              </Box>
          </Box>
        )}
        <CardActions>
          <Link component={RouterLink} sx={style.link} to={`${routerPaths.Reports}?campaignId=${id}`}>
            {t('campaign.performance.report')}
            <ArrowIcon style={style.arrow}/>
          </Link>
        </CardActions>
      </CardContent>
    </Card>
  )
}

AllTimePerformance.displayName = 'AllTimePerformance';

export default AllTimePerformance;