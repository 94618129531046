import { Box, Typography } from "@mui/material";
import { style } from './style';
import { useTranslation } from "react-i18next";
import ErrorIcon from '@mui/icons-material/Error';

interface IProps {
  decision: Nullable<IDecision>
}

function ApprobationStatus(props: IProps) {
  const { decision } = props;
  const { t } = useTranslation('translation', { keyPrefix: 'campaign.ApprobationStatus' });

  const statusChecker = () => {
    if(decision) {
      const { dealsPolicyCompliance, networkPolicyCompliance, platformPolicyCompliance } = decision;

      switch (true) {
        case dealsPolicyCompliance.status === 'APPROVED' &&
        networkPolicyCompliance.status === 'APPROVED' &&
        platformPolicyCompliance.status === 'APPROVED' :
          return {
            icon: null,
            message: '',
            status: "approved"
          };

        case dealsPolicyCompliance.status === 'DISAPPROVED' ||
        networkPolicyCompliance.status === 'DISAPPROVED' ||
        platformPolicyCompliance.status === 'DISAPPROVED' : {
          let codes = "";
          const topics = [...dealsPolicyCompliance.topics, ...networkPolicyCompliance.topics, ...platformPolicyCompliance.topics];
          topics.forEach(({ policyTopic }, index) => {
            codes += `${policyTopic} ${index < topics.length - 1 ? ', ' : ''}`
          })

          return {
            icon: <ErrorIcon sx={{ color: '#e84b39' }} />,
            message: `${t('errMessage')}`.replace('$$REJECTIONCODE$$', codes),
            status: "disapproved"
          };
        }

        case dealsPolicyCompliance.status === 'PENDING_REVIEW' ||
        networkPolicyCompliance.status === 'PENDING_REVIEW' ||
        platformPolicyCompliance.status === 'PENDING_REVIEW' : {
          return {
            icon: <ErrorIcon sx={{ color: '#ffbb00'}} />,
            message: `${t('pendMessage')}`,
            status: "pending"
          };
        }

        default:
          return {
            icon: null,
            message: '',
            status: ""
          };
      }
    }
    return {
      icon: null,
      message: '',
      status: ""
    };
  }

  return props.decision ? (
        <Box sx={{display: 'flex', paddingLeft: '4px', alignItems: 'center'}}>
          {statusChecker().icon}
          <Typography sx={style[statusChecker().status as keyof  typeof style]}>
            {statusChecker().message}
          </Typography>
        </Box>) : null

}

export default ApprobationStatus;