import { Theme } from "@mui/material";

export const style = {
  adPreview: (theme: Theme) => ({
    position: 'relative',
    display: 'flex',
    width: 332,
    height: 622,
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: 1.5,
    backgroundColor: '#f4f6fa',
  }),
  header: (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: 'inset 0 -1px 0 0 #f4f6fa',
    padding: theme.spacing(0, 5),
  }),
  adPreviewType: {
    fontSize: 18,
    lineHeight: 1.33,
    color: '#292f41',
    width: 170,
    marginTop: 4,
  },
  centerIframe: {
    flexDirection: 'column',
    display: 'flex',
    margin: 'auto',
  },
  previewIframe: {
    width: '100%',
    flexGrow: 2,
    overflowX: 'auto',
    display: 'flex',
    padding: 4,
  },
  trashIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: 5,
    bottom: 5,
    background: 'rgba(0,0,0,.5)',
    padding: 1,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageWrapper: {
    padding: 0,
    alignItems: 'unset',
  },
  banner: (width: number, height: number) => (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4, 0),
    backgroundColor: 'rgba(242, 248, 252, 0.5)',
    width: width,
    height,
    maxWidth: '100%',
    position: 'relative',
    minHeight: height,
  }),
}