import "./loading.css";

interface IProps {
  text?: string;
  style?: any;
}
const Loading = (props: IProps) => {
  const {text, style} = props;
  return (
    <div className={"text-center loader-wrapper"} style={style}>
      <div className={"loader-container"}>
        <div className={"loader-icon"}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          {text ? <span>{text}</span> : null}
        </div>
      </div>
    </div>
  )
};

export default Loading;