import { Theme } from "@mui/material";

export const style = {
  form: (running: boolean) => ({
    minHeight: 'calc(100vh - 248px)',
    display: 'flex',
    flexDirection: running ? 'row' : 'column',
    paddingBottom: 25,
  }),
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'background.paper',
    borderRadius: 3,
    boxShadow: '0 12px 8px -10px rgba(0, 0, 0, 0.12)',
    width: 480,
  },
  modalTitle: {
    fontSize: 20,
    padding: 5,
    color: '#292f41',
    fontWeight: 200,
    letterSpacing: '.5px',
    textAlign: 'center',
  },
  modalContent: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(8, 5),
  }),
  modalFooter: {
    padding: 5,
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 2,
  },
  button: {
    borderRadius: 5,
    minWidth: 100,
  },
  warningIcon: {
    width: 60,
    height: 60,
    '& path': {
      fill: '#FECC58',
    }
  },
  warningMessage: {
    textAlign: 'center',
  },
  breadcrumbArrow: {
    margin: '0 1rem',
  },
  campaignName: {
    fontSize: '1.25rem',
    color: 'rgba(41, 47, 65, 0.5)',
    letterSpacing: '0.5px',
    lineHeight: '1.25rem',
    fontWeight: 'bold',
  },
};