import { Theme } from "@mui/material";

export const style = {
  responsiveAds: (theme: Theme) => ({
    padding: theme.spacing(0, 2.5),
    backgroundColor: '#fff',
    boxShadow: '0 12px 8px -10px rgba(0, 0, 0, 0.12)',
    marginBottom: theme.spacing(5),
    borderRadius: 1.25,
    border: '1px solid #dfe7f2',
  }),
  header: (theme: Theme) => ({
    padding: theme.spacing(3, 4),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  sectionTitle: {
    fontSize: 24,
    lineHeight: '28px',
    fontWeight: 200,
  },
  responsiveAdsType: (theme: Theme) => ({
    fontSize: 12,
    color: 'rgba(41,47,65,.5)',
    letterSpacing: '.8px',
    lineHeight: '12px',
    textTransform: 'uppercase',
    fontWeight: 200,
    marginRight: theme.spacing(2),
  }),
  responsiveAdsTypeBox: {
    display: 'flex',
    alignItems: 'center',
  },
}