import { Theme } from "@mui/material";

export const style = {
  platformOS: {
    width: 'calc(100% / 3 - 10.7px)',
    backgroundColor: '#fff',
    height: 600,
    borderRadius: 1,
    boxShadow: '0 12px 8px -10px rgba(0, 0, 0, 0.12)',
    minWidth: 385,
    display: 'flex',
    flexDirection: 'column',
  },
  header: (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(4),
    backgroundColor: '#dfe7f0',
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
  }),
  title: {
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: '0.8px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    color: '#292f41',
  },
  tab: (theme: Theme) => ({
    width: '50%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    minHeight: 0,
    '&.Mui-selected': {
      backgroundColor: 'primary.main',
      color: '#fff',
    },
    textTransform: 'none',
    fontSize: 16,
    borderRadius: 1,
  }),
  tabs: {
    minHeight: 0,
    border: '1px solid #dfe7f0',
    borderRadius: 1,
  },
  indicator: {
    display: 'none',
  },
  content: (theme: Theme) => ({
    padding: theme.spacing(4),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  }),
  tooltipContent: (theme: Theme) => ({
    backgroundColor: '#fff',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
  }),
  tooltipTitle: {
    fontWeight: 700,
    fontSize: 16,
    color: 'rgba(41, 47, 65, 0.5)',
    letterSpacing: '0.8px',
    lineHeight: '12px',
    textTransform: 'capitalize',
  },
  tooltipLabel: {
    color: 'rgba(41, 47, 65, 0.5)',
    fontSize: '14px',
    letterSpacing: '0.8px',
    lineHeight: '16px',
    textTransform: 'capitalize',
    fontWeight: 700,
  },
  tooltipValue: {
    color: '#292f41',
    fontSize: 16,
  },
  tooltipInfo: (theme: Theme) => ({
    display: 'flex',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  }),
  chart: (theme: Theme) => ({
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
  }),
  platformList: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 2,
  },
  listItem: {
    width: 'fit-content',
    padding: 0,
    display: 'flex',
    gap: 1,
    cursor: 'pointer',
  },
  noDataAvailable: {
    color: '#292f41',
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '24px',
    textAlign: 'center',
  },
  noDataBox: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 4,
  },
  resetFilters: {
    borderRadius: 5,
    fontWeight: 700,
  }
}