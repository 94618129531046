import { number, object, ref, string } from "yup";
import { t } from "i18next";

function getProfilePersonalFormSchema() {
  return object().shape({
    firstName: string().required(t<string>('errorMessages.required')),
    lastName: string().required(t<string>('errorMessages.required')),
    phone: string().required(t<string>('errorMessages.required')),
  });
}

function getProfileSecurityFormSchema() {
  return object().shape({
    currentPassword: string().required(t<string>('errorMessages.required')),
    newPassword: string()
      .required(t<string>('errorMessages.required'))
      .min(8, t<string>('errorMessages.passwordLength'))
      .matches(/[A-Z]/, t<string>('errorMessages.upperCase'))
      .matches(/[0-9]/, t<string>('errorMessages.number'))
      .matches(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/, t<string>('errorMessages.symbol')), // eslint-disable-line
    repeatNewPassword: string()
      .oneOf([ref('newPassword')], t<string>('errorMessages.matchPassword'))
      .required(t<string>('errorMessages.required')),
  });
}

function getProfileLegalFormSchema() {
  return object().shape({
    companyName: string().required(t<string>('errorMessages.required')),
    tinNumber: string(),
  });
}
function getProfileRewardedAdsFormSchema() {
  return object().shape({
    rewarded_ads_cpm_rate: number().positive().required(t<string>('errorMessages.required')),
  });
}


export {
  getProfilePersonalFormSchema,
  getProfileSecurityFormSchema,
  getProfileLegalFormSchema,
  getProfileRewardedAdsFormSchema,
}