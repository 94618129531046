export const style = {
  resizer: (width: number, height: number) => ({
    position: 'relative',
    width,
    height,
  }),
  resizeIcon: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: 24,
    height: 24,
    zIndex: 9,
  },
  wrapperForWindow: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    bottom: 0,
    display: 'none',
  }
};