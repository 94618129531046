import { defineMessages } from "react-intl";

export default defineMessages({
  tokenInvalid: {
    id: "Auth.tokenInvalid",
    defaultMessage: "Token invalid",
  },
  tokenExpired: {
    id: "Auth.tokenExpired",
    defaultMessage: "Token expired",
  },
  completeRegistration: {
    id: "Auth.completeRegistration",
    defaultMessage: "Complete Registration",
  },
  confirmEmailSuccess: {
    id: "Auth.confirmEmailSuccess",
    defaultMessage:
      "Your account is successfully verified, you can now log in.",
  },
});
