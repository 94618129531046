import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table, TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import StraightIcon from "@mui/icons-material/Straight";

import { invoicesHeadConfig } from "./config";
import { useTranslation } from "react-i18next";
import { getRefillPaymentHistoryRequest, invoicePayRequest, invoiceSendRequest } from "../../../../../../api";
import { getPreviousWeek, IntDateRangePicker } from "../../../../../global";
import { fromDate, toDate } from "../../../../../report/services/report.service";
import TableBodySkeleton from "../../../../../global/components/TableBodySkeleton";
import NoReports from "../../../../../report/components/NoReports";
import ManualPayModal from "../ManualPayModal";
import { NotificationService } from "../../../../../global/services";
import AdminUserSearch from "./AdminUserSearch";
import AdminInvoicesRow from "./AdminInvoicesRow";
import { useHistory, useParams } from "react-router-dom";
import { CURRENCYICONS } from "../../../../../store";
import SendInvoiceModal, { ISendData } from "../SendInvoiceModal";

interface IPayData {
  amount: string | number;
  referer: string;
  transaction_id: string;
  comment: string;
}

let initState = true;

const AdminInvoicesList = ({className = ''}: {className? : string}) => {

  const history = useHistory();
  const { id } = useParams<{ id?: string; }>();
  const { t } = useTranslation('translation', { keyPrefix: 'admin.invoice.billing' })

  const [sorting, setSorting] = useState(null);
  const [user, setUser] = useState<Nullable<any>>(null);
  const [invoice, setInvoice] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentHistoryData, setPaymentHistoryData] = useState(null);
  const [date, setDate] = useState({from: getPreviousWeek(), to: new Date()});
  const [rowsPerPage] = useState<number>(10)
  const [page] = useState(0);
  const [, setTotalCount] = useState<number>(0);
  const [manualPayOpen, setManualPayOpen] = useState<boolean>(false);
  const [sendInvoiceOpen, setSendInvoiceOpen] = useState<boolean>(false);


  const [isLoading, setIsLoading] = useState<boolean>(false)

  const noDataToDisplay = paymentHistoryData?.length === 0 && !isLoading;

  // function handleChangePage(event: unknown, newPage: number) {
  //   setPage(newPage);
  // }
  //
  // function handleChangeRowsPerPage(event: ChangeEvent<HTMLInputElement>) {
  //   setRowsPerPage(Number(event.target.value));
  // }

  const getPaymentHistory = (id: string) => {
    setIsLoading(true);
    const data = {
      user_id: id,
      status: null,
      from_date: fromDate(date.from),
      to_date: toDate(date.to),
      page: page + 1,
      size: rowsPerPage,
    }
    getRefillPaymentHistoryRequest(data).then(({data}) => {
      if(data.type.code === 0){
        setPaymentHistoryData(data?.data?.data);
        setTotalCount(data?.data?.total_size);
      }
      setIsLoading(false);
      initState = false;
    })
  }

 const onSendInvoice = (row) => {
   setInvoice(row);
   setSendInvoiceOpen(true)
 }

 const onManualPay = () => {
   // setInvoice(row);
   if(!id) return;
   setManualPayOpen(true);
 }

 const onPay = (data: IPayData ) => {
    if(!id) return;
     setLoading(true);
     invoicePayRequest(data, id).then(({ data }) => {
       if(data?.type?.code === 0) {
         NotificationService.handleSuccess('Payment done successfully');
         setLoading(false);
         setManualPayOpen(false);
         getPaymentHistory(id ? id : "");
       } else {
         NotificationService.handleError(data?.type?.message);
       }
     })
 }

 const onSend = (data: ISendData ) => {
   if(!invoice.id) return;
   setLoading(true);
   invoiceSendRequest(data, invoice.id).then(({ data }) => {
     if(data?.type?.code === 0) {
       NotificationService.handleSuccess('Invoice send successfully');
       setLoading(false);
       setSendInvoiceOpen(false);
       // getPaymentHistory(id ? id : "");
     } else {
       NotificationService.handleError(data?.type?.message);
     }
   })
 }

 const onAccountSelect = (account: Nullable<any>) => {
   const dynamicURL = !!account ? `/admin/invoice/${account.space_id}` : '/admin/invoice';
   history.replace(dynamicURL)
   getPaymentHistory(!!account ? account.space_id : "");
   setUser(account);
 }

  useEffect(() => {
    if(!initState){
      getPaymentHistory(id ? id : "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, page, rowsPerPage]);

  useEffect(() => {
    getPaymentHistory(id ? id : "");

    return () => {
      initState = true;
      setPaymentHistoryData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return  <Paper className={className}>
    <TableContainer className={'report-list'}>
      <Box className={'invoice-list-header top-radius'}>
        <Box sx={{width: '326px'}}>
          <AdminUserSearch onUserSelect={(account) => onAccountSelect(account)}/>
        </Box>
        {!!user ? (<Box className={'currency-wrap'}>
          <Typography className={'currency-label'}>{`Currency: `}</Typography>
          <Typography className={'currency-icon'}>{` ${ CURRENCYICONS[user?.currency]}`}</Typography>
        </Box>) : null}

        <Typography
          title={!!id ? '' : 'Select Account to Activate Manual Pay'}
          className={!!id ? 'btn active' : 'btn inactive' }
          onClick={() => onManualPay()}>
          {'Manual Pay'}
        </Typography>
        <Box>
          <Typography className={'invoice-list-filter-date-label'}>{'show Payment History for'}</Typography>

          <Box sx={{
            // width: 250,
            float: 'right',
            marginRight: '30px !important',
            '& > div': {
              height: '36px',
              '& > div': {
                border: 'none !important',
                minWidth: '130px',
                paddingLeft: 0,
              }
            }
          }}>

            <IntDateRangePicker
              onChange={(date) => setDate(date)}
              value={date}
              name={'filterDate'}
              includeDefaultPresets={false}
              presets={[
                // { id: 7, label: t('allTime'), value: diffInDays, selected: false },
                { id: 1, label: 'Today', value: 0, selected: false },
                // { id: 5, label: t('thisMonth'), value: +moment().date() - 1, selected: false },
                // { id: 6, label: t('pastMonth'), value: getCustomPresetRange(), selected: false },
                { id: 2, label: 'Last 7 days', value: 7, selected: true },
                { id: 3, label: 'Last 30 days', value: 30, selected: false },
                { id: 4, label: 'Last 365 days', value: 365, selected: false },
              ]}
            />
          </Box>

        </Box>


      </Box>
      <Table>
        <TableHead className={'report-list-head'}>
          <TableRow>
            {
              invoicesHeadConfig.map(({key, orderField}:IReportHeadCell) => orderField ? (
                  <TableCell key={key} size='small' className={'report-list-head-cell'}>
                    <TableSortLabel
                      IconComponent={sorting.field !== orderField ? SwapVertIcon : StraightIcon}
                      active={sorting.field === orderField}
                      direction={sorting.field === orderField && sorting.asc ? 'asc' : 'desc'}
                      onClick={() => setSorting({field: orderField, asc: !sorting.asc})}
                    >
                      {t(`head.${key}`)}
                    </TableSortLabel>

                  </TableCell>) : (
                  <TableCell key={key} size='small' className={'report-list-head-cell'}>
                    {t(`head.${key}`)}
                  </TableCell>
                )
              )
            }
            <TableCell size='small' className={'report-list-head-cell'}></TableCell>
            <TableCell size='small' className={'report-list-head-cell'}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {noDataToDisplay ? null : (
            isLoading ? (
              <TableBodySkeleton rowCount={rowsPerPage} cellCount={8} />
            ) : (
              <>
                {paymentHistoryData && paymentHistoryData.map((row: any, i) => (
                  <AdminInvoicesRow row={row} key={i} onSendInvoice={() => onSendInvoice(row)}/>
                ))}
              </>
            )
          )}
        </TableBody>
      </Table>
      {noDataToDisplay && (
        <NoReports />
      )}
    </TableContainer>
      {/*{!noDataToDisplay && (<TablePagination*/}
      {/*  rowsPerPageOptions={[10, 15, 25]}*/}
      {/*  count={totalCount}*/}
      {/*  rowsPerPage={rowsPerPage}*/}
      {/*  page={page}*/}
      {/*  onPageChange={handleChangePage}*/}
      {/*  onRowsPerPageChange={handleChangeRowsPerPage}*/}
      {/*  nextIconButtonProps={{*/}
      {/*    disabled: isLoading || page === Math.floor(totalCount / rowsPerPage),*/}
      {/*  }}*/}
      {/*  backIconButtonProps={{*/}
      {/*    disabled: isLoading || page === 0,*/}
      {/*  }}*/}
      {/*  component='div'*/}
      {/*/>)}*/}
    {manualPayOpen ? (
      <ManualPayModal
        t={t}
        userId={id}
        loading={loading}
        title={t("manualPay")}
        leftBtnText={t("cancel")}
        rightBtnText={t("pay")}
        onCancel={() => setManualPayOpen(false)}
        onConfirm={(payData) => onPay(payData) }/>
    ) : null}
    {sendInvoiceOpen ? (
      <SendInvoiceModal
        t={t}
        userId={id}
        loading={loading}
        invoice={invoice}
        title={t("sendInvoice")}
        leftBtnText={t("cancel")}
        rightBtnText={t("pay")}
        onCancel={() => setSendInvoiceOpen(false)}
        onConfirm={(sendData) => onSend(sendData) }/>
    ) : null}
  </Paper>

}

export default AdminInvoicesList;