/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 9/1/2023
 * All Rights Reserved.
 */
import { Typography, Box, Radio, Tooltip, InputAdornment, Divider, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { style } from './style';
import { CopyIcon, InfoIcon, IntFieldController, IntRadioGroup } from "../../../../global";
import { Control, SetFieldValue, useWatch } from "react-hook-form";
import { NotificationService } from "../../../../global/services";
import { CampaignObjective } from "../../../types";
import { useEffect } from "react";

type Props = {
  control: Control<any>;
  setValue: SetFieldValue<any>;
}

function AwarenessForm({ control, setValue }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'campaign.goal' });

  const landingURL = useWatch({
    control,
    name: 'bidding_strategy.click_url',
  });

  function copyToClipboard() {
    navigator.clipboard.writeText(`<script src="https://cdn1.intent.ai/pixel/${sessionStorage.getItem("spaceID")}/intent.js"></script>`);
    NotificationService.handleSuccess(t("copied"));
  }

  useEffect(() => {
    setValue('productURL', landingURL);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landingURL]);

  return (
    <Box sx={style.awarenessForm}>
      <Typography sx={style.selectType}>{t('selectType')}</Typography>
      <IntFieldController
        control={control}
        name='bidding_strategy.goal'
        Component={IntRadioGroup}
        sx={style.radioGroup}
      >
        <Box sx={style.radio}>
          <Radio value={CampaignObjective.ENHANCED_EXPOSURE} />
          <Typography sx={style.radioLabel}>
            {t('enhancedExposure')}
            <Tooltip title={t('enhancedExposureTooltip')}>
              <InfoIcon />
            </Tooltip>
          </Typography>
        </Box>
        <Box sx={style.radio}>
          <Radio value={CampaignObjective.MAXIMIZED_REACH} />
          <Typography sx={style.radioLabel}>
            {t('maximizedReach')}
            <Tooltip title={t('maximizedReachTooltip')}>
              <InfoIcon />
            </Tooltip>
          </Typography>
        </Box>
      </IntFieldController>
      <Divider sx={style.divider}/>
      <Box sx={style.content}>
        <Box sx={[style.field, style.multipleLabelsField]}>
          <Typography sx={style.pasteGTM}>{t('pasteGTM')}</Typography>
          <TextField
            disabled
            multiline
            rows={4}
            sx={style.customHTML}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end' sx={style.adornment}>
                  <CopyIcon
                    viewBox='0 0 36 36'
                    sx={style.copyIcon}
                    onClick={copyToClipboard}
                  />
                </InputAdornment>
              )
            }}
            defaultValue={`<script src="https://cdn1.intent.ai/pixel/${sessionStorage.getItem('spaceID')}/intent.js"></script>`}
          />
          <Typography sx={style.optimizeCampaign}>{t('optimizeCampaign')}</Typography>
        </Box>
        <Box sx={style.field}>
          <Box sx={style.withTooltip}>
            <Typography sx={style.label}>{t('landingURL')}</Typography>
            <Tooltip title={t('landingURLTooltip')}>
              <InfoIcon />
            </Tooltip>
          </Box>
          <IntFieldController
            control={control}
            name='bidding_strategy.click_url'
            placeholder={t<string>('https')}
          />
        </Box>
      </Box>
    </Box>
  );
}

AwarenessForm.displayName = 'AwarenessForm';

export default AwarenessForm;