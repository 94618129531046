import React from "react";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import { MainContainer } from "./containers";
import { Routes } from "./Routes";
import packageJson from "../package.json";
import localeData from "./i18n/data.json";
import "rc-dropdown/assets/index.css";
import "./reset.scss";
import "./App.css";
import { TopProgressBarProvider } from "./providers/TopProgressBarProvider";
import { ClientInfoContext } from "./providers/ClientInfoProvider";
import { getClient } from "./utils";
import { createTheme, ThemeProvider } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { IWhitelabelSettings } from "./types";
import { currentUserLocaleSelector } from "./modules/store/reducers/authentication";
import { whitelabelSettingsSelector } from "./modules/store/reducers/admin";


console.log("Current version: " + packageJson.version);
console.log("intent.ai");


const defaultTheme = {
  typography: {
    fontFamily: [
      "Source Sans Pro",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "sans-serif"
    ].join(','),
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          textAlign: 'center' as 'center',
        },
      }
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          disableScrollLock: true,
          sx: {
            maxHeight: 350,
          },
        },
      },
      styleOverrides: {
        select: {
          '& ~ fieldset': {
            borderColor: '#dfe7f0',
          },
        },
        root: {
          height: 40,
          backgroundColor: '#fff',
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        size: 'small' as const,
      }
    },
    MuiPopover: {
      defaultProps: {
        disableScrollLock: true,
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#dfe7f1',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#ccc',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          background: '#ccc',
          '& > span': {
            background: '#8e8e8e',
          },
        },
      },
    },
  },
  spacing: 4,
};

const App = () => {

  // const history = useHistory();
  // const dispatch = useDispatch();
  const currentUserLocale = useSelector(currentUserLocaleSelector)
  const whiteLabelSettings = useSelector(whitelabelSettingsSelector)

  const getLocaleMessages = (language: string) => {
    if (!language) {
      return localeData.en;
    }
    const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
    const tempData: any = localeData;
    return (
      tempData[languageWithoutRegionCode] || tempData[language] || localeData.en
    );
  }

  const getWhiteLabelTheme = (whiteLabelSettings?: IWhitelabelSettings) => {
    if (whiteLabelSettings) {
      return {
        palette: {
          primary: {
            main: `#${whiteLabelSettings.primary_color}`,
          },
          secondary: {
            main: `#${whiteLabelSettings.secondary_color}`,
          },
          warning: {
            main: `#${whiteLabelSettings.warning_color}`,
          },
          error: {
            main: `#${whiteLabelSettings.gauge_color}`,
          }
        }
      }
    }
  }

  // componentDidMount() {
  //   const newDate = new Date();
  //   const date = new Date(localStorage.getItem(AUTH_TOKEN_EXPIRY_DATE) || '');
  //   // date.setHours(date.getHours() - 1);
  //   if (newDate > date) {
      // this.props.logout();
    // }
  // }



  const messages = getLocaleMessages(currentUserLocale);

  const clientInfo = getClient();

  const whiteLabelTheme = getWhiteLabelTheme(whiteLabelSettings);

  const theme = createTheme(deepmerge(defaultTheme, whiteLabelTheme || {}));

  return (
    <ThemeProvider theme={theme}>
      <IntlProvider locale={"en"} messages={messages} textComponent="span">
        <TopProgressBarProvider />
        <div className="resize-warning">
          <p className="resize-warning-message">
            For having best user experience, please use desktop.
          </p>
        </div>
          <MainContainer>
            <ClientInfoContext.Provider value={{
              client: clientInfo,
            }}>
              <Routes />
            </ClientInfoContext.Provider>
          </MainContainer>
      </IntlProvider>
    </ThemeProvider>
  );
}

// const mapStateToProps = (state: IRootState) => {
//   return ({
//     currentUserLocale: select.authentication.currentUserLocaleSelector(state),
//     whiteLabelSettings: state.admin?.whitelabel?.whitelabel_settings,
//   });
// };

// const mapDispatchToProps = (dispatch: Dispatch) => ({
//   logout: dispatch.authentication.logout,
// });

export default App;
