import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { TopProgressBar } from "../../utils";
import './index.scss';

const TopProgressBarProvider = () => {
  const [className, setClassName] = useState("");
  const subscriber = useCallback((started) => {
    if (started) {
      setClassName("top-progress-bar-start");
    } else {
      setClassName("top-progress-bar-invisible");
    }
  }, []);

  useEffect(() => {
    TopProgressBar.subscribe(subscriber);
  }, [subscriber]);

  return <div className={`top-progress-bar ${className}`} />;
};

export default TopProgressBarProvider;
