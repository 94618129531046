import { Theme } from "@mui/material";

export const style = {
  form: (theme: Theme) => ({
    background: '#fff',
    boxShadow: '0 12px 8px -10px rgba(0,0,0,.12)',
    width: '100%',
    borderRadius: 1,
    border: '1px solid #dfe7f2',
    padding: theme.spacing(4, 0),
  }),
  formTitle: (theme: Theme) => ({
    fontSize: 24,
    lineHeight: '28px',
    fontWeight: 200,
    textTransform: 'uppercase',
    padding: theme.spacing(3, 4),
  }),
  field: (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(4, 6),
    '&:hover': {
      background: '#f2f8fc',
      transition: '0.3s',
    }
  }),
  fieldLabel: (theme: Theme) => ({
    textTransform: 'uppercase',
    paddingLeft: theme.spacing(4),
    fontWeight: 600,
    color: '#000',
    fontSize: 14,
  }),
  submitForm: (theme: Theme) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(3),
  }),
  submitButton: (theme: Theme) => ({
    border: '1px solid #3A77EB',
    backgroundColor: '#fff',
    boxShadow: '0 12px 8px -10px rgba(58,119,235,.2)',
    minHeight: 32,
    fontSize: 14,
    borderRadius: 5,
    padding: theme.spacing(3, 5),
    letterSpacing: '.8px',
    lineHeight: '16px',
    color: '#3A77EB',
    textTransform: 'uppercase',
    '&:disabled': {
      opacity: 0.5,
      color: '#3A77EB',
    }
  }),
  headerField: {
    color: 'rgba(41,47,65,.5)',
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '0.8rem',
  },
  header: (theme: Theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#dfe7f1',
    height: 32,
    padding: theme.spacing(0, 6),
    alignItems: 'center',
  }),
};