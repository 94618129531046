import { Theme } from "@mui/material";

export const style = {
  audienceList: (theme: Theme) => ({
    padding: theme.spacing(3, 4),
    backgroundColor: '#f4f6fa',
    border: '1px solid #dfe7f0',
    borderRadius: 1,
    overflow: 'auto',
    maxHeight: 200,
    height: 'auto',
    gap: theme.spacing(3),
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
  }),
  autocomplete: {
    backgroundColor: '#fff',
  },
  audience: (theme: Theme) => ({
    backgroundColor: '#fff',
    padding: theme.spacing(0, 3),
    borderRadius: 1,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    height: 60,
  }),
  audienceName: {
    width: '50%',
    borderRight: '1px solid #dfe7f0',
    fontSize: 18,
    lineHeight: 1.33,
    marginLeft: 4,
    textTransform: 'capitalize',
  },
  profileIcon: {
    marginLeft: 12,
  },
  estimatedSize: {
    fontSize: 14,
    marginLeft: 3,
  },
  crossIcon: {
    marginLeft: 'auto',
    cursor: 'pointer',
  }
}
