import React, { ReactNode } from 'react';

import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { CURRENCIES } from "../../../store";


interface IBodyProps {
  sortedData?: Nullable<any[]>;
  hasAction?: boolean;
  withActionDropDown?: boolean;
  colspan?: number;
  headCells: any[];
  onRowClick?(row: any): void;
  actions?(rowData: any, handleClose: () => void): ReactNode;
}

const CoreTableBody = (props: IBodyProps) => {
  const { sortedData, headCells, onRowClick, hasAction, withActionDropDown, actions } = props;

  const [, setAnchorEl] = React.useState<null | HTMLElement>(null);


  const handleCloseActionMenu = () => {
    setAnchorEl(null);
  };


  return (
    <TableBody>
      {sortedData && sortedData.length ? (
        sortedData.map((tableRowItem, index) => {

          return (
            <TableRow sx={{cursor: 'pointer'}} key={index} onClick={() => onRowClick && onRowClick(tableRowItem)}>
              {headCells.map(({key, parser}) => {

                return (
                  <TableCell style={{ minWidth: '12.5%' }} key={key} align="left">
                    <Typography color="text.primary" fontSize="14px" fontWeight="400" lineHeight="20px">
                      {key === 'balance' ? (<strong>{`${CURRENCIES[tableRowItem.currency] || '$'} ${parser(tableRowItem[key as keyof typeof tableRowItem])}`}</strong>) : parser(tableRowItem[key as keyof typeof tableRowItem])}
                    </Typography>
                  </TableCell>
                );
              })}

              {hasAction ? (
                <TableCell
                  align="right"
                  className="sticky-table-column position-right"
                >
                  {withActionDropDown ? (
                    <>

                    </>
                  ) : (
                    actions && actions(tableRowItem, handleCloseActionMenu)
                  )}
                </TableCell>
              ) : null}
            </TableRow>
          );
        })
      ) : null }
    </TableBody>
  );
};

export default CoreTableBody;
