import { PieLabelRenderProps } from "../../types";
import { numberToStringWithCommas } from "../../../global";
import React from "react";
import { Box, Typography } from "@mui/material";
import { style } from './style';

const RADIAN = Math.PI / 180;
function renderCustomizedLabel(handleClick: Function) {
  return ({ cx, cy, innerRadius, outerRadius, midAngle, name, value, percent }: PieLabelRenderProps) => {
    if (percent < 0.05) {
      return null;
    }

    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x + 5}
        y={y - 20}
        fill='white'
        textAnchor="middle"
        dominantBaseline="central"
        onClick={() => handleClick({ name })}
      >
        <tspan x={x + 5} dy="1.2em">
          {name.replace('_', 'x')}
        </tspan>
        <tspan x={x + 5} dy="1.2em" style={{ fontWeight: "bold", textAlign: 'center', }}>
          {numberToStringWithCommas(+value)}
        </tspan>
      </text>
    );
  }
}

function customTooltip(tabName: string) {
  return ({  active, payload }: any) => {
    if (!active) {
      return null;
    }

    return (
      <Box sx={style.tooltipContent}>
        <Typography sx={style.tooltipTitle}>{payload[0].name?.replace('_', 'x')}</Typography>
        <Box sx={style.tooltipInfo}>
          <svg
            width="16"
            height="16"
            version="1.1"
            fill={payload[0].payload.fill}
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="16" height="16" />
          </svg>
          <Typography sx={style.tooltipLabel}>
            {tabName}:{' '}
            <strong style={style.tooltipValue}>{numberToStringWithCommas(payload[0].value)}</strong>
          </Typography>
        </Box>
      </Box>
    );
  };
}

export { renderCustomizedLabel, customTooltip };