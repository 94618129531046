const {
  REACT_APP_BASE_URL,
  BASE_BID,
  REACT_APP_WEB_APP_NAME,
  REACT_APP_WEB_APP_SECRET,
  REACT_APP_GAUGE_URL,
} = window;
export const POST = "POST";
export const GET = "GET";
export const PUT = "PUT";
export const DELETE = "DELETE";
export {
  REACT_APP_BASE_URL,
  BASE_BID,
  REACT_APP_WEB_APP_NAME,
  REACT_APP_WEB_APP_SECRET,
};

export const BASE_IMAGE_PATH = "/user/profile/image/";
export const AUTH_KEY = "X-Auth-Token";
export const GAUGE_URL = REACT_APP_GAUGE_URL;

export const AUTH_TOKEN = window.REACT_APP_AUTH_KEY || "DSP_AUTH_TOKEN";
export const DICT_HASH_KEY = window.REACT_APP_DICT_HASH_KEY || "Dict-Hash";
export const AUTH_TOKEN_EXPIRY_DATE = window.REACT_APP_EXPIRY_DATE || "DSP_AUTH_TOKEN_EXPIRY_DATE";

export const REFRESH_TOKEN = `REFRESH_${AUTH_TOKEN}`;
export const AMERIA_URL =
  window.REACT_APP_AMERIA_URL || "https://servicestest.ameriabank.am";

export const BANNER_SERVER_URL = window.REACT_APP_BANNER_SERVER_URL;

export const TAWK_API = "5de66a5dd96992700fca8502";

export const TERMS_AND_SERVICE_URL = "https://intent.ai/terms.html";
export const DSP_TYPE_KEY = 1;
