import { TrafficSourceSortingOption } from "../types/traffic";
import { t } from 'i18next';
import { getPreviousWeek } from "../../global";

const REPORT_FILTERS_DEFAULT_VALUES = {
  campaignId: '',
  publisher: '',
  visualSizeKey: 1,
  deviceTypeKey: 1,
  date: {
    from: getPreviousWeek(),
    to: new Date(),
  },
  filtersIsLoading: true,
}

const TRAFFIC_SOURCE_SORTING_OPTIONS = [
  { value: TrafficSourceSortingOption.HIGH_TO_LOW, label: t('reporting.traffic.highToLow') },
  { value: TrafficSourceSortingOption.LOW_TO_HIGH, label: t('reporting.traffic.lowToHigh') },
];

const BANNER_SIZES_COLORS = [
  '#F5BC82',
  '#4A84ED',
  '#98BAF5',
  '#6198F1',
  '#EF902E',
  '#76A4F2',
  '#72A0F1',
  '#94B7F5',
  '#F3A758',
];

const BANNER_SIZE_DICT: { [key: string]: number } = {
  '970_90': 101,
  '320_50': 102,
  '728_90': 103,
  '160_600': 104,
  '250_250': 105,
  '300_250': 106,
  '300_100': 107,
  '300_600': 108,
  '240_400': 109,
}

const BANNER_SIZE_DICT_LIST: {label: string, value: number}[] = [
  {label: t('reporting.filter.allSizes'),value: 1},
  {label: '970 x 90', value: 101},
  {label: '728 x 90', value: 103},
  {label: '320 x 50', value: 102},
  {label: '300 x 250', value: 106},
  {label: '300 x 100', value: 107},
  {label: '250 x 250', value: 105},
  {label: '300 x 600', value: 108},
  {label: '160 x 600', value: 104},
  {label: '240 x 400', value: 109},
]

const DIMENSIONS_LIST = [
{ label: "Device OS", value: "DEVICE_TYPE"},
{ label: "Apps/Sites", value: "PUBLISHER"},
{ label: "Banner Dimension", value: "BANNER_DIMENSION"},
{ label: "Creatives", value: "CREATIVE_TYPE"},
{ label: "Ad Exchange", value: "EXCHANGE"},
{ label: "Advertiser", value: "ADVERTISER"},
{ label: "Country", value: "GEO_COUNTRY"},
{ label: "Region", value: "GEO_REGION"},
{ label: "City", value: "GEO_CITY"},
{ label: "Year", value: "TIME_YEAR"},
{ label: "Quarter", value: "TIME_QUARTER"},
{ label: "Month", value: "TIME_MONTH"},
{ label: "Week", value: "TIME_WEEK"},
{ label: "Day of week", value: "TIME_DAY"},
{ label: "Hour of day", value: "TIME_HOUR"},
]


export {
  REPORT_FILTERS_DEFAULT_VALUES,
  TRAFFIC_SOURCE_SORTING_OPTIONS,
  BANNER_SIZE_DICT_LIST,
  BANNER_SIZES_COLORS,
  BANNER_SIZE_DICT,
  DIMENSIONS_LIST,
}
