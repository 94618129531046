import React, { FC } from 'react';

import App from './App';
import "./reset.scss";
import "./App.css";
import { SignInRoute } from "./config/routes/components/signInRoute";
import { getIsAuth } from "./modules/store/reducers/authentication";
import { useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material";
import { IWhitelabelSettings } from "./types";
import { deepmerge } from "@mui/utils";
import { whitelabelSettingsSelector } from "./modules/store/reducers/admin";
import { IntlProvider } from "react-intl";
import messages from "./messages/profile.messages";
import NotificationProvider from "./modules/global/components/NotificationProvider";

const Auth: FC = () => {

  const whiteLabelSettings = useSelector(whitelabelSettingsSelector)

  const defaultTheme = {
    typography: {
      fontFamily: [
        "Source Sans Pro",
        "-apple-system",
        "BlinkMacSystemFont",
        "Segoe UI",
        "Roboto",
        "Oxygen",
        "Ubuntu",
        "Cantarell",
        "Fira Sans",
        "Droid Sans",
        "Helvetica Neue",
        "sans-serif"
      ].join(','),
    },
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            textAlign: 'center' as 'center',
          },
        }
      },
      MuiSelect: {
        defaultProps: {
          MenuProps: {
            disableScrollLock: true,
            sx: {
              maxHeight: 350,
            },
          },
        },
        styleOverrides: {
          select: {
            '& ~ fieldset': {
              borderColor: '#dfe7f0',
            },
          },
          root: {
            height: 40,
            backgroundColor: '#fff',
          },
        },
      },
      MuiAutocomplete: {
        defaultProps: {
          size: 'small' as const,
        }
      },
      MuiPopover: {
        defaultProps: {
          disableScrollLock: true,
        }
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: '#dfe7f1',
          },
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            color: '#ccc',
          },
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            background: '#ccc',
            '& > span': {
              background: '#8e8e8e',
            },
          },
        },
      },
    },
    spacing: 4,
  };

  const getWhiteLabelTheme = (whiteLabelSettings?: IWhitelabelSettings) => {
    if (whiteLabelSettings) {
      return {
        palette: {
          primary: {
            main: `#${whiteLabelSettings.primary_color}`,
          },
          secondary: {
            main: `#${whiteLabelSettings.secondary_color}`,
          },
          warning: {
            main: `#${whiteLabelSettings.warning_color}`,
          },
          error: {
            main: `#${whiteLabelSettings.gauge_color}`,
          }
        }
      }
    }
  }
  const whiteLabelTheme = getWhiteLabelTheme(whiteLabelSettings);
  const theme = createTheme(deepmerge(defaultTheme, whiteLabelTheme || {}));

  const isAuth = useSelector(getIsAuth);
  return (
    <ThemeProvider theme={theme}>
      <IntlProvider locale={"en"} messages={messages} textComponent="span">
        {localStorage.getItem('DSP_AUTH_TOKEN') && isAuth ? <App /> :  <SignInRoute />}
      </IntlProvider>
      <NotificationProvider />
    </ThemeProvider>
  );
};

export default Auth;
