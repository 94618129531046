import { Box, IconButton, Modal, Paper, Typography } from "@mui/material";
import { style } from './style';
import { useTranslation } from "react-i18next";
import { Control, useWatch } from "react-hook-form";
import React, { useEffect, useState } from "react";
import {
  ArrowLeftRoundedIcon,
  ArrowRightRoundedIcon,
  CrossIcon,
  Resizeable,
  ResizeIcon,
} from "../../../global";
import {
  BANNER_HTML,
  DYNAMIC_AD_SIZES,
  MAX_DYNAMIC_AD_PICTURES_ALLOWED,
  RESPONSIVE_AD_BOUNDS,
  setIframeContent,
} from "../../utils";
import { DynamicAdSize } from "../../types";

type Props = {
  control: Control<any>;
}

let bannerHTML = BANNER_HTML.replace('$$TITLE$$', '');
bannerHTML = bannerHTML.replace('$$DESCRIPTION$$', '');
bannerHTML = bannerHTML.replace('$$COMPANY_NAME$$', '');
bannerHTML = bannerHTML.replace('$$CALL_TO_ACTION$$', '');

function AdPreview({ control }: Props) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'campaign.dynamicAd',
  });

  const fields = useWatch({
    name: 'dynamicAd',
    control,
  });

  const [previewAdId, setPreviewAdId] = useState(DYNAMIC_AD_SIZES[0].id);
  const [modalOpen, setModalOpen] = useState(false);
  const showPreview = fields.images.length === MAX_DYNAMIC_AD_PICTURES_ALLOWED
    || !!fields.logo.length
    || fields.companyName
    || fields.callToAction
    || fields.titles[0]?.title
    || fields.descriptions[0]?.description;
  const selectedAdPreview = DYNAMIC_AD_SIZES.find(({ id }) => id === previewAdId) as DynamicAdSize;

  function loadIframeContent() {
    const iframe = document.querySelector<HTMLIFrameElement>('.iframe');
    const iframeInModal = document.querySelector<HTMLIFrameElement>('.iframe-modal');
    if (modalOpen && iframeInModal) {
      setIframeContent(iframeInModal, fields);
    } else if (iframe) {
      setIframeContent(iframe, fields);
    }
  }

  useEffect(() => {
    loadIframeContent();
    /* eslint-disable */
  }, [
    fields.images.length,
    fields.logo.length,
    fields.companyName,
    fields.callToAction,
    fields.titles[0]?.title,
    fields.descriptions[0]?.description,
    showPreview,
    modalOpen,
    fields.callToActionColor,
  ]);
  /* eslint-enable */

  const [iframeSize, setIframeSize] = useState({
    width: selectedAdPreview.width,
    height: selectedAdPreview.height,
  });

  function onResize({ width, height }: { width: number; height: number; }) {
    setIframeSize(iframeSize => ({
      width: width || iframeSize.width,
      height: height || iframeSize.height,
    }));
  }

  const showingFirstPreview = previewAdId === DYNAMIC_AD_SIZES[0].id;
  const showingLastPreview = previewAdId === DYNAMIC_AD_SIZES[DYNAMIC_AD_SIZES.length - 1].id;

  function nextAdPreview() {
    setPreviewAdId(id => id + 1);
  }

  function previousAdPreview() {
    setPreviewAdId(id => id - 1);
  }

  function handleModalOpen() {
    setModalOpen(true);
  }

  function handleModalClose() {
    setModalOpen(false);
  }

  useEffect(() => {
    setIframeSize({
      width: selectedAdPreview.width,
      height: selectedAdPreview.height,
    });
  }, [selectedAdPreview.width, selectedAdPreview.height]);

  function onIframeSizeChange(width: number, height: number) {
    setIframeSize({ width, height });
    const adPreview = DYNAMIC_AD_SIZES.find(dynamicAd => dynamicAd.width === width && dynamicAd.height === height);
    if (adPreview) {
      setPreviewAdId(adPreview.id);
    }
  }

  return (
    <Box sx={style.adPreview}>
      {
        showPreview
          ? (
            <>
              <Box sx={style.header}>
                <Typography sx={style.adPreviewType}>{t('adPreviewTitle')}</Typography>
                <Box sx={style.headerActions}>
                  <IconButton size='small' sx={style.zoomButton} onClick={handleModalOpen}>
                    <ResizeIcon sx={style.resizeIcon}/>
                  </IconButton>
                  <IconButton disabled={showingFirstPreview} onClick={previousAdPreview} size='small'>
                    <ArrowLeftRoundedIcon style={style.icon(showingFirstPreview)} />
                  </IconButton>
                  <IconButton disabled={showingLastPreview} onClick={nextAdPreview} size='small'>
                    <ArrowRightRoundedIcon style={style.icon(showingLastPreview)} />
                  </IconButton>
                </Box>
              </Box>
              <Box sx={style.previewIframe}>
                <Box sx={style.centerIframe}>
                  <iframe
                    id="inlineFrameExample8"
                    title="Inline Frame Example"
                    width={selectedAdPreview.width}
                    height={selectedAdPreview.height}
                    srcDoc={bannerHTML}
                    style={{ alignSelf: 'center', }}
                    className='iframe'
                    onLoad={loadIframeContent}
                  />
                  <Typography sx={style.iframeSize}>
                    {t('exampleOfAd', { width: selectedAdPreview.width, height: selectedAdPreview.height })}
                  </Typography>
                </Box>
              </Box>
            </>
          )
          : (
            <Typography sx={style.adPreviewText}>{t('adPreview')}</Typography>
          )
      }
      <Modal open={modalOpen} onClose={handleModalClose} >
        <Paper elevation={3} sx={style.paper}>
          <Box sx={style.header}>
            <Typography variant='h5'>
              {t('adPreviewSize', iframeSize)}
            </Typography>
            <Box sx={style.headerActions}>
              <IconButton onClick={handleModalClose} size='small' sx={style.closeButton}>
                <CrossIcon />
              </IconButton>
            </Box>
          </Box>
          <Box sx={style.content}>
            <Box sx={style.sizes}>
              <Typography
                sx={style.size('300x600' === `${iframeSize.width}x${iframeSize.height}`)}
                onClick={() => onIframeSizeChange(300, 600)}
              >
                {t('size', { size: '300x600' })}
              </Typography>
              <Typography
                sx={style.size('160x600' === `${iframeSize.width}x${iframeSize.height}`)}
                onClick={() => onIframeSizeChange(160, 600)}
              >
                {t('size', { size: '160x600' })}
              </Typography>
              <Typography
                sx={style.size('240x400' === `${iframeSize.width}x${iframeSize.height}`)}
                onClick={() => onIframeSizeChange(240, 400)}
              >
                {t('size', { size: '240x400' })}
              </Typography>
              <Typography
                sx={style.size('300x250' === `${iframeSize.width}x${iframeSize.height}`)}
                onClick={() => onIframeSizeChange(300, 250)}
              >
                {t('size', { size: '300x250' })}
              </Typography>
              <Typography
                sx={style.size('250x250' === `${iframeSize.width}x${iframeSize.height}`)}
                onClick={() => onIframeSizeChange(250, 250)}
              >
                {t('size', { size: '250x250' })}
              </Typography>
              <Typography
                sx={style.size('970x90' === `${iframeSize.width}x${iframeSize.height}`)}
                onClick={() => onIframeSizeChange(300, 100)}
              >
                {t('size', { size: '970x90' })}
              </Typography>
              <Typography
                sx={style.size('728x90' === `${iframeSize.width}x${iframeSize.height}`)}
                onClick={() => onIframeSizeChange(728, 90)}>

                {t('size', { size: '728x90' })}
              </Typography>
              <Typography
                sx={style.size('320x50' === `${iframeSize.width}x${iframeSize.height}`)}
                onClick={() => onIframeSizeChange(320, 50)}>

                {t('size', { size: '320x50' })}
              </Typography>
              <Typography
                sx={style.size('300x100' === `${iframeSize.width}x${iframeSize.height}`)}
                onClick={() => onIframeSizeChange(300, 100)}
              >
                {t('size', { size: '300x100' })}
              </Typography>
            </Box>
            <Box sx={style.resizable}>
              <Box sx={style.resizableHeader}>
                <Typography sx={style.adPreviewType}>{t('adPreviewTitle')}</Typography>
                <Box sx={style.actions}>
                  <IconButton disabled={showingFirstPreview} onClick={previousAdPreview} size='small'>
                    <ArrowLeftRoundedIcon style={style.icon(showingFirstPreview)} />
                  </IconButton>
                  <IconButton disabled={showingLastPreview} onClick={nextAdPreview} size='small'>
                    <ArrowRightRoundedIcon style={style.icon(showingLastPreview)} />
                  </IconButton>
                </Box>
              </Box>
              <Box sx={style.resizer}>
                <Box sx={style.resizerWrapper}>
                  <Resizeable
                    width={iframeSize.width}
                    height={iframeSize.height}
                    onResize={onResize}
                    bounds={RESPONSIVE_AD_BOUNDS}
                  >
                    <iframe
                      id="inlineFrameExample8"
                      title="Inline Frame Example"
                      width='100%'
                      height='100%'
                      srcDoc={bannerHTML}
                      style={{ alignSelf: 'center', margin: 'auto', }}
                      className='iframe-modal'
                      onLoad={loadIframeContent}
                    />
                  </Resizeable>
                </Box>
                <Typography sx={style.iframeSize}>
                  {t('exampleOfAd', { width: selectedAdPreview.width, height: selectedAdPreview.height })}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Modal>
    </Box>
  );
}

AdPreview.displayName = 'AdPreview';

export default AdPreview;
