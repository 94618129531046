import { AUDIENCE_STATUS_COLORS, CAMPAIGN_STATUS_COLORS } from "../../../../_constants";

// const INVENTORY_STATUS_COLORS = {
//   ACTIVE: "#6CD387",
//   MISSING_CODE: "#E14B4B",
// };

let backgroundColor: string;
interface IProps {
  status: number;
  styles?: object;
  isCampaign?: boolean;
  isAudience?: boolean;
}
const StatusIndicator = (props: IProps) => {
  const {status,
    styles = {},
    isCampaign = false,
    isAudience = false
   } = props;


  if (isCampaign) {
    backgroundColor = CAMPAIGN_STATUS_COLORS[status];
  }
  if (isAudience) {
    backgroundColor = AUDIENCE_STATUS_COLORS[status];
  }

  return <div className={"campaign-list-item-status"} style={{backgroundColor: backgroundColor, ...{styles}}}></div>
};

export default StatusIndicator;
