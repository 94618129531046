/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 9/2/2023
 * All Rights Reserved.
 */
import { Box, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { style } from './style';
import Banner from "../Banner";
import { WarningIcon } from "../../../global";
import { useFormContext } from "react-hook-form";
import { ReactNode, useState } from "react";

const VISUALS_SIZES = [
  { id: 0, width: 970, height: 90, },
  { id: 1, width: 728, height: 90, },
  { id: 2, width: 320, height: 50, },
  { id: 3, width: 300, height: 250, },
  { id: 4, width: 300, height: 100, },
  { id: 5, width: 250, height: 250, },
  { id: 6, width: 300, height: 600, },
  { id: 7, width: 160, height: 600, },
  { id: 8, width: 240, height: 400, },
  { id: 9, width: 300, height: 480, },
];

type Props = {
  footer: ReactNode;
}

function VisualsForm({ footer }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'campaign.visuals' });

  const [uploadHTMLOpen, setUploadHTMLOpen] = useState(false);

  const { formState: { errors } } = useFormContext();

  const hasError = !!errors?.visuals;

  function toggleUploadHTML() {
    setUploadHTMLOpen(open => !open);
  }

  return (
    <Box sx={style.visuals}>
      <Box sx={style.banners}>
        <Typography sx={style.title}>
          {t('title')}
        </Typography>
        <Divider sx={style.divider} />
        <Box sx={style.visualBanners}>
          {VISUALS_SIZES.slice(0, 6).map(visual => (
            <Box sx={style.bannerPreview} key={visual.id}>
              <Banner width={visual.width} height={visual.height} hasError={hasError} />
            </Box>
          ))}
          <Box sx={style.rowBanners}>
            <Box sx={style.bannerPreview}>
              <Banner width={300} height={600} hasError={hasError} />
            </Box>
            <Box sx={style.bannerPreview}>
              <Banner width={160} height={600} hasError={hasError} />
            </Box>
            <Box sx={style.bannerPreview}>
              <Banner width={240} height={400} hasError={hasError} />
            </Box>
            <Box sx={style.bannerPreview}>
              <Banner width={300} height={480} hasError={hasError} />
            </Box>
          </Box>
          <Box>
            <Box sx={style.uploadHTML} onClick={toggleUploadHTML}>
              <WarningIcon />
              <Typography sx={style.uploadHTMLText}>{t('uploadingHTML')}</Typography>
            </Box>
            {uploadHTMLOpen && (
              <Typography sx={style.uploadInfo}>{t('uploadInfo')}</Typography>
            )}
          </Box>
        </Box>
      </Box>
      {footer}
    </Box>
  );
}

VisualsForm.displayName = 'VisualsForm';

export default VisualsForm;