import "./style.scss"
import { useTranslation } from "react-i18next";
import {
  Box,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import './style.scss';
import { reportListHeadConfig } from "./config";
import { style } from "../Report/style";
import { ChangeEvent, useEffect, useState } from "react";
import { Control, useWatch } from "react-hook-form";
import ReportService from "../../services/report.service";
import ReportListRow from "./ReportListRow";
import { useSelector } from "react-redux";
import TableBodySkeleton from "../../../global/components/TableBodySkeleton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DIMENSIONS_LIST } from "../../utils";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import NoReports from "../NoReports";
import StraightIcon from "@mui/icons-material/Straight";
import { getCurrentAccountCurrency } from "../../../store/reducers/authentication";

interface IProps {
  control: Control<any>;
  exportCSV: (dimension: string) => void;
}

let initialState = true;

function ReportList(props: IProps) {

  const {control, exportCSV} = props;
  const formValues = useWatch({ control, });
  const currency = useSelector(getCurrentAccountCurrency);
  const { t } = useTranslation('translation', { keyPrefix: 'reporting.list' });

  const [dimensions, setDimensions] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDimension, setSelectedDimension] = useState<string>('DEVICE_TYPE');
  const [sorting, setSorting] = useState(
    {
      field: '',
      asc: false,
    });

  const noDataToDisplay = dimensions.length === 0 && !isLoading;

  const getReportsDimension = (campaignId: string, fromDate: Date, toDate: Date) => {
    setIsLoading(true);
    ReportService.getDimensionReport(
      {
        id: campaignId,
        // id: "666c1cf2444c5310531fff75",
        page: page + 1,
        page_size: rowsPerPage,
        dimension: selectedDimension,
        order_field: sorting.field,
        order_asc: sorting.asc,
        from_date: fromDate,
        to_date: toDate,
      }).then(res => {
        if(res.data.type.code === 0){
          setTotalCount(res.data.data.total_size || 0);
          setDimensions(res.data.data.data || []);
          setIsLoading(false);
          initialState = false;
        }
      }
    )
  }

  function handleChangePage(event: unknown, newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(Number(event.target.value));
  }

  useEffect(() => {
    if (!initialState && formValues.campaignId) {
      getReportsDimension(formValues.campaignId, formValues.date.from, formValues.date.to)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.campaignId, page, rowsPerPage, selectedDimension, sorting.field, sorting.asc, formValues.date]);

  useEffect(() => {
    if (initialState && formValues.campaignId) {
      getReportsDimension(formValues.campaignId, formValues.date.from, formValues.date.to)
    }
    return () => {
      initialState = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.campaignId]);


  return (
    <Paper className={'top-radius'}>
        <TableContainer className={'report-list'}>
          <Box className={'report-list-filter top-radius'}>

            <Typography  className={'report-list-filter-csv'} sx={{...style.exportCSV, marginBottom: '12px',
              textAlign: 'end'}} onClick={() => exportCSV(selectedDimension)}>
              {t('filters.exportCSV')}
            </Typography>
            <Select
              sx={{ minWidth: 80, backgroundColor: 'transparent', marginRight: '30px',  float: 'right'}}
              variant="standard"
              displayEmpty
              IconComponent={ExpandMoreIcon}
              disableUnderline
              value={selectedDimension}
              onChange={(e) => setSelectedDimension(e.target.value)}
            >
              {
                DIMENSIONS_LIST.map(({label, value}: any) => (
                  <MenuItem value={value} key={value}>{label}</MenuItem>
                ))
              }
            </Select>
            <Typography  className={'report-list-filter-dimensions-label'}>
              {t('filters.dimensionLabel')}
            </Typography>

          </Box>
          <Table>
            <TableHead className={'report-list-head'}>
              <TableRow>
                {
                  reportListHeadConfig.map(({key, orderField}:IReportHeadCell) => orderField ? (
                    <TableCell key={key} size='small' className={'report-list-head-cell'}>
                      <TableSortLabel
                        IconComponent={sorting.field !== orderField ? SwapVertIcon : StraightIcon}
                        active={sorting.field === orderField}
                        direction={sorting.field === orderField && sorting.asc ? 'asc' : 'desc'}
                        onClick={() => setSorting({field: orderField, asc: !sorting.asc})}
                      >
                        {t(`head.${key}`)}
                      </TableSortLabel>

                    </TableCell>) : (
                    <TableCell key={key} size='small' className={'report-list-head-cell'}>
                      {t(`head.${key}`)}
                    </TableCell>
                    )
                  )
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {noDataToDisplay ? null : (
                isLoading ? (
                  <TableBodySkeleton rowCount={rowsPerPage} cellCount={14} />
                ) : (
                  <>
                    {dimensions.map((row: any, i) => (
                      <ReportListRow
                        row={row}
                        key={i}
                        currency={currency || '$'}
                      />
                    ))}
                  </>
                )
              )}
            </TableBody>
          </Table>
          {noDataToDisplay && (
            <NoReports />
          )}
        </TableContainer>
      {!noDataToDisplay && (<TablePagination
        rowsPerPageOptions={[10, 15, 25]}
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{
          disabled: isLoading || page === Math.floor(totalCount / rowsPerPage),
        }}
        backIconButtonProps={{
          disabled: isLoading || page === 0,
        }}
        component='div'
      />)}
    </Paper>
  );
}

ReportList.displayName = 'ReportList';

export default ReportList;