const NEW_ROUTES = {
  auth: {
    register: {
      fullPath: '/auth/signup',
    },
    forgotPassword: {
      fullPath: '/auth/forgot-password',
    },
    login: {
      fullPath: '/auth/login',
    }
  },
  campaign: {
    list: {
      fullPath: '/campaigns',
      onPageChange: (newPage: number) => `/campaigns?page=${newPage}`,
    },
    new: {
      fullPath: '/campaigns/create',
      params: {
        replicatedId: 'replicatedId',
        tab: 'tab',
      },
      onReplicate(id: string) {
        return `${this.fullPath}?${this.params.replicatedId}=${id}`
      },
      onCreate(tab: string) {
        return `${this.fullPath}?${this.params.tab}=${tab}`;
      }
    },
    edit: {
      fullPath: '/campaigns/edit',
      onEdit(id: string, query: string = '') {
        return `${this.fullPath}/${id}${query}`;
      }
    }
  },
  report: {
    getReportByCampaignID: (campaignId: string) => `/reports?campaignId=${campaignId}`,
  }
}

const EMAIL_REGEX = /^[A-Z0-9._%+\-']+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i;

const WEEKDAYS = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
];

export {
  NEW_ROUTES,
  EMAIL_REGEX,
  WEEKDAYS,
}