import { Theme } from "@mui/material";

export const style = {
  view: (theme: Theme) => ({
    width: '100%',
    minHeight: '100vh',
    background: `linear-gradient(225deg,${theme.palette.secondary.main},${theme.palette.primary.main}) center center/cover`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  }),
  form: (theme: Theme) => ({
    borderRadius: 3,
    boxShadow: '0 4px 8px 0 rgba(0,0,0,.08)',
    padding: theme.spacing(8),
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
    marginTop: 'auto',
  }),
  forgotPassword: {
    fontSize: 24,
    color: '#292f41',
    letterSpacing: '-.2px',
    textAlign: 'center',
    lineHeight: '28px',
    fontWeight: 600,
  },
  fieldInput: {
    width: 250,
  },
  fieldLabel: {
    color: 'rgba(41,47,65,.5)',
    textTransform: 'uppercase',
    fontSize: 14,
    letterSpacing: '.8px',
    lineHeight: '16px',
  },
  field: (theme: Theme) => ({
    gap: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  }),
  submitButton: (theme: Theme) => ({
    background: `linear-gradient(225deg,${theme.palette.secondary.main},${theme.palette.primary.main}) center center/cover`,
    borderRadius: theme.spacing(5),
    fontWeight: 700,
    color: '#fff',
    '&:disabled': {
      opacity: 0.5,
    }
  }),
  backToLogin: {
    fontWeight: 600,
  },
  createAccount: (theme: Theme) => ({
    color: 'hsla(0,0%,100%,.5)',
    textTransform: 'uppercase',
    marginTop: 'auto',
    marginBottom: theme.spacing(6),
    cursor: 'pointer',
  }),
  emailSent: {
    textAlign: 'center',
  }
}