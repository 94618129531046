import { Autocomplete, AutocompleteProps, TextField } from "@mui/material";
import { Ref, useMemo } from "react";
import { SelectOption } from "../IntSelect";

type Props = Omit<AutocompleteProps<any, any, any, any>, 'renderInput'> & {
  placeholder: string;
  inputRef?: Ref<HTMLInputElement>;
  errorMessage?: string;
  onChange: (...event: any[]) => void;
  disableClearable?: boolean;
  extractValue?: boolean;
}

function IntAutocomplete({
  placeholder,
  inputRef,
  errorMessage,
  onChange,
  value,
  disableClearable = false,
  // TODO delete as soon as all the components are corrected
  extractValue = false,
  ...props
}: Props) {
  const inputProps: { placeholder?: string } = {};
  if (!value?.length) {
    inputProps.placeholder = placeholder;
  }

  const autocompleteValue = useMemo(() => {
    if (!props.options.length) {
      return value;
    }
    return value.map((option: SelectOption | string | number) => {
      if (typeof option === 'string' || typeof option === 'number') {
        return props.options.find(({ value }) => value == option); // eslint-disable-line
      }
      return option;
    })
  }, [value, props.options]);

  return (
    <Autocomplete
      disablePortal
      onChange={(e, newValue: SelectOption[]) => {
        if (disableClearable) {
          if (newValue?.length > value?.length) {
            if (extractValue) {
              onChange(newValue.map(({ value }) => value as string));
            } else {
              onChange(newValue);
            }
          }
        } else {
          if (extractValue) {
            onChange(newValue.map(({ value }) => value));
          } else {
            onChange(newValue);
          }
        }
      }}
      renderInput={(params) =>
        <TextField
          {...params}
          inputRef={inputRef}
          {...inputProps}
        />
    }
      isOptionEqualToValue={(option, value) => option.value === value.value}
      filterSelectedOptions
      value={autocompleteValue}
      {...props}
    />
  )
}

IntAutocomplete.displayName = 'IntAutocomplete';

export default IntAutocomplete;