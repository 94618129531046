import { Box, MenuItem, Select, TextField, Typography } from "@mui/material";
import './style.scss';
import { useTranslation } from "react-i18next";
import { CrossIcon } from "../../../global";
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import _ from "lodash";

const initialParameter: IParameter = {metric: "page_url", condition: "contains", value: '' };

const ParametersItem = (props: any) => {
  const { setParameter, parameter, addParameter, removeParameter } = props;
  return (
    <Box className={'parameters-item'}>
      <Select
        className={'parameters-select'}
        variant="standard"
        displayEmpty
        IconComponent={ExpandMoreIcon}
        disableUnderline
        value={'page_url'}
        // onChange={(e) => setName( e.target.value)}
      >
        <MenuItem value={"page_url"}>Page URL</MenuItem>

      </Select>
      <Select
        className={'parameters-select'}
        variant="standard"
        displayEmpty
        IconComponent={ExpandMoreIcon}
        disableUnderline
        value={'contains'}
        // onChange={(e) => setName( e.target.value)}
      >
        <MenuItem value={"contains"}>Contains</MenuItem>
      </Select>

      <TextField
        className={!!parameter?.valueError ? 'action-input error' : 'action-input'}
        value={parameter?.value}
        onChange={(e) => setParameter({...parameter, value: e.target.value, valueError: ''})}
      />
      <AddIcon fontSize={'small'} onClick={() => addParameter()} className={'add-parameter'} />
      <CrossIcon onClick={() => removeParameter()} className={'remove-parameter'} />
    </Box>
  )
}


const DynamicAudienceItem = (props: any) => {
  const {actionItem, onRemove, setData } = props;
  const { t } = useTranslation('translation', { keyPrefix: 'audience.createPage' });

  const setParameter = (param: IParameter , index: number) => {
    const params = _.cloneDeep(actionItem.parameters);
    params[index] = {...params[index],...param};
    actionItem.parameters = _.cloneDeep(params);
    setData({...actionItem});
  }

  const addParameter = () => {
    const params = _.cloneDeep(actionItem.parameters);
    params.push(initialParameter);
    actionItem.parameters = _.cloneDeep(params);
    setData({...actionItem});
  }

  const removeParameter = (index: number) => {
    const params = _.cloneDeep(actionItem.parameters);
    params.splice(index, 1);
    actionItem.parameters = _.cloneDeep(params);
    setData({...actionItem});
  }

  return (
    <Box className={'action-item-wrap'}>
      <Box className={'action-item'}>
        <Box className={!!actionItem.parameters.length ? 'action-item-header border-bottom' : 'action-item-header'}>

          <Typography className={'action-item-header-text'}>{t('action')}</Typography>
          <TextField
            className={!!actionItem?.actionError ? 'action-input error' : 'action-input'}
            value={actionItem?.action}
            onChange={(e) => setData({...actionItem, action: e.target.value, actionError: ''})}
          />
          <Typography className={'action-item-header-text'}>{t('inThePast')}</Typography>
          <TextField
            className={!!actionItem?.periodError ? 'action-input small error' : 'action-input small'}
            value={actionItem?.period}
            type={'number'}
            inputProps={{ maxLength: 2 }}
            onChange={(e) => setData({...actionItem, period: e.target.value, periodError: ''})}
          />
          <Typography className={'action-item-header-text'}>{t('days')}</Typography>

          <Typography onClick={() => !actionItem.parameters.length && addParameter()} className={'expand'}>{t('refineAction')}</Typography>
        </Box>
        {!!actionItem.parameters.length ? (
          <Box className={'action-item-body'}>
            <Typography className={'action-item-body-title'}>{t('itemBodyTitleStart')}</Typography>
            <Typography sx={{margin: '0 3px'}} className={'action-item-body-title'}><b>{t('every')}</b></Typography>
            <Typography className={'action-item-body-title'}>{t('itemBodyTitleEnd')}</Typography>
            {actionItem.parameters.map((param: IParameter, index) => (
              <ParametersItem
                key={index}
                parameter={param}
                setParameter={(param: IParameter) => setParameter(param, index)}
                addParameter={() => addParameter()}
                removeParameter={() => removeParameter(index)}/>
            ))}
          </Box>
        ) : null}
      </Box>
      <CrossIcon onClick={() => onRemove()} className={'remove-item'} />
    </Box>
  )
}

export default DynamicAudienceItem;