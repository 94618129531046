import React from "react";
import AdminHeader from "../../components/AdminHeader";
import { useTranslation } from "react-i18next";
import AdminInvoicesList from "./components/AdminInvoiceList";


const Invoice = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.invoice' });

  return (
    <div>
      <AdminHeader title={t("title")} >
        <div style={{minHeight: '600px'}}>
          <AdminInvoicesList />
        </div>
      </AdminHeader>
    </div>
  );
}

export default Invoice;
