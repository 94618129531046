export const style = {
  emptyValue: {
    color: '#bfbfbf',
    lineHeight: '20px',
  },
  multiple: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 0.5,
  },
  errorState: {
    '& > fieldset': {
      borderColor: 'red !important',
    }
  }
};