import { Theme } from "@mui/material";

export const style = {
  errorText: {
    color: 'red',
    fontSize: '14px',
    marginTop: '-8px'
  },
  names: (nameError: string) => ({
    '.Mui-focused fieldset' : {
      border: `${!!nameError && '0 !important'}`
    },
    '.MuiOutlinedInput-notchedOutline' : {
      borderColor: '#dfe7f0',
    },
    border: `1px solid ${!!nameError ? 'red' : 'transparent'}`,
    borderRadius: '4px',
    input: {
      padding: '8.5px 14px',
    },
    '&:hover fieldset': {
      borderColor: '#2e79f7 !important',
    },
  }),

  title: {
    fontSize: 24,
    lineHeight: 1.17,
    letterSpacing: '-0.2px',
    color: '#292f41',
  },
  nativeAds: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
  }),
  field: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 2,
  },
  loadingIcon: {
    width: 25,
    height: 25,
  },
  label: {
    textTransform: 'uppercase',
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: '.8px',
    color: '#292f41',
  },
  addIconWrapper: {
    backgroundColor: '#F4F6FA',
    width: 48,
    height: 48,
    borderRadius: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    '&:hover > svg': {
      display: 'unset !important',
    },
  },
  addIcon: {
    width: 48,
    height: 48,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    flexGrow: 2,
  },
  helper: (theme: Theme) => ({
    fontSize: 14,
    lineHeight: '1.14',
    letterSpacing: '0.2px',
    color: 'rgba(41, 47, 65, 0.5)',
    marginTop: theme.spacing(1),
  }),
  crossIcon: {
    position: 'absolute' as const,
    left: 0,
    cursor: 'pointer',
    display: 'none',
    backgroundColor: '#F4F6FA',
  },
  button: {
    '&:hover':{
      backgroundColor: 'transparent',
    },
    alignSelf: 'flex-start',
    position: 'relative',
    cursor: 'pointer',
  },
  hiddenInput: {
    position: 'absolute',
    opacity: 0,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
  native: {
    display: 'flex',
    gap: 5,
    width: 680,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems :'flex-start',
    gap: 2,
  },
  videoField: {
    display: 'flex',
    alignItems: 'center',
  },
  tickIcon: {
    width: 28,
    height: 28,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
  },
  disapproved: {
    marginLeft: '8px',
    color: 'rgb(211, 47, 47)'
  },
  approved: {
    marginLeft: '8px',
    color: 'rgb(46, 125, 50)'
  },
}