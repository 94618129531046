import React, { useEffect, useState } from "react";

import { Table, TableContainer, TablePagination } from "@mui/material";
import CoreTableBody from './core-table-body';
import { CoreTableHead } from './core-table-head';
import './style.scss';


interface ITableProps {
  data?: any;
  headCells: ITableHeadItem[];
  rowsPerPage?: number;
  hasPagination?: boolean;
  hasAction?: boolean;
  hasSearch?: boolean;
  loading?: boolean;
  actions?: any;
  noData: any; // todo change to the react node
  translationPrefix: string;
  onPageChange?(page: number): void;
  onRowClick?(row: any) : void;
}

const CoreTable = (props: ITableProps) => {

  const {
    translationPrefix,
    hasPagination,
    hasAction,
    headCells,
    loading,
    onPageChange,
    onRowClick,
    actions,
    noData,
    data,
  } = props;

  const [rowsPerPage, setRowsPerPage] = useState(0);



  function handleChangePage(event: unknown, newPage: number) {
    onPageChange && onPageChange(newPage +1);
  }

  useEffect(() => {
    if(data?.page_size){
      setRowsPerPage(data.page_size)

    }
  }, [data?.page_size]);

  return (
    <div className="core-table-wrap">

      <div className="table-container" id="core-table-container">
        <>
          <TableContainer id="core-table">
            <Table sx={{minHeight: !data?.data.length ? '500px' : '20px'}} aria-label="custom pagination table">
              <CoreTableHead
                translationPrefix={translationPrefix}
                headCells={headCells}
              />

              {!!data?.data.length ? (
                <CoreTableBody
                  onRowClick={(row) => onRowClick && onRowClick(row)}
                  headCells={headCells}
                  sortedData={data?.data}
                  hasAction={hasAction}
                  actions={actions}
                />) : noData
              }
            </Table>
          </TableContainer>

          {hasPagination && data?.data.length && data?.total_size > rowsPerPage ? (
            <TablePagination
              onRowsPerPageChange={(e) => {
                setRowsPerPage(+e.target.value);
              }}
              rowsPerPageOptions={[rowsPerPage]}
              count={data.total_size}
              rowsPerPage={rowsPerPage}
              page={data.current_page -1}
              onPageChange={handleChangePage}
              nextIconButtonProps={{
                disabled: loading || data.current_page === Math.ceil(data.total_size / rowsPerPage),
              }}
              backIconButtonProps={{
                disabled: loading || data.current_page === 1,
              }}
              component='div'
            />
          ) : null}
        </>
      </div>
    </div>
  );
};

export default CoreTable;
