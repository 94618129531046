import { Theme } from "@mui/material";

export const style = {
  chart: (theme: Theme) => ({
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
  }),
  sizeList: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 2,
  },
  listItem: {
    width: 'fit-content',
    padding: 0,
    display: 'flex',
    gap: 1,
    cursor: 'pointer',
  },
  noDataAvailable: {
    color: '#292f41',
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '24px',
    textAlign: 'center',
  },
  noDataBox: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 4,
  },
  resetFilters: {
    borderRadius: 5,
    fontWeight: 700,
  }

}