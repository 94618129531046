enum ProgressBarStatus {
  UNSET = "UNSET",
  IN_PROGRESS = "IN_PROGRESS"
}

type Subscriber = (started: boolean) => void;

export default class TopProgressBar {
  static status = ProgressBarStatus.UNSET;
  static subscribers: Array<Subscriber> = [];

  static start() {
    if (TopProgressBar.status === ProgressBarStatus.UNSET) {
      TopProgressBar.status = ProgressBarStatus.IN_PROGRESS;
      TopProgressBar.subscribers.forEach((subscriber) => subscriber(true));
    }
  }

  static done() {
    TopProgressBar.status = ProgressBarStatus.UNSET;
    TopProgressBar.subscribers.forEach((subscriber) => subscriber(false));
  }

  static subscribe(subscriber: Subscriber) {
    TopProgressBar.subscribers.push(subscriber);
  }
}
