import React from "react";
import { Redirect, Switch, BrowserRouter, Route } from "react-router-dom";



import {
  signInRoutesConfig,
} from "../route-config";

export const SignInRoute = () => {
  const redirectToNotfound = () => <Redirect to={'/auth/login'} />;
  return (
    <BrowserRouter>
      <Switch>
        {signInRoutesConfig.map(item => <Route key={item.path} path={item.path} component={item.component} />)}
        <Route render={redirectToNotfound} />
      </Switch>
    </BrowserRouter>
  );
};
