import React, { Component } from "react";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import { Modal } from "../../../containers";
import { IAdminAccountsUser } from "../../../types";
import { transactionsMessages, adminMessages } from "../../../messages";
import "./style.scss";
import { Flex } from "../../../modules/global/components/Flex";
import InputRow from "../../../modules/global/components/InputRow";
import { Button } from "@mui/material";

interface IProps {
  modalData: { account: IAdminAccountsUser };
  intl: IntlShape;
  closeModal: Function;
}

export class AdminAccountModal extends Component<IProps> {
  render() {
    const {
      props: {
        intl: { formatMessage },
        closeModal,
        modalData: { account },
      },
    } = this;

    return (
      <div>
        <div className="transaction-modal">
          <Modal.Header>
            <FormattedMessage
              id="Admin.contactInformation"
              defaultMessage="Contact Information"
            />
          </Modal.Header>
          <div>
            <InputRow
              currentValue={account.full_name}
              label={formatMessage(adminMessages.accountsListName) + 'ggg'}
              // withLabel
              disabled
              // baseline
            />
            <InputRow
              currentValue={
                account.company_name
                  ? account.company_name
                  : formatMessage(transactionsMessages.individual)
              }
              label={formatMessage(transactionsMessages.companyName)}
              disabled
            />
            <InputRow
              currentValue={account.entity_name}
              label={formatMessage(transactionsMessages.entity)}
              disabled
            />
            <InputRow
              currentValue={account.email}
              label={formatMessage(transactionsMessages.email)}
              disabled
            />
            <InputRow
              currentValue={account.phone}
              label={formatMessage(transactionsMessages.phone)}
              disabled
            />
          </div>
          <Modal.Footer>
            <Flex style={{ width: "100%" }} justifyContent="center">
              <Button
                sx={{borderRadius: '20px', margin: '16px 0'}}
                variant={'outlined'}
                onClick={() => closeModal()}
              >{'Close'}</Button>
            </Flex>
          </Modal.Footer>
        </div>
      </div>
    );
  }
}

export default injectIntl(AdminAccountModal);
