import { Box, Chip, MenuItem, MenuItemProps, Select, SelectProps, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { style } from './style';

export type SelectOption = {
  label: string;
  value: string | number | Array<string>;
  disabled?: boolean;
}

type Props = SelectProps & {
  errorMessage?: string;
  options: Array<SelectOption>;
  menuItemProps?: MenuItemProps;
  dirtyText?: string | null;
};

function IntSelect({
  errorMessage,
  options = [],
  menuItemProps = {},
  dirtyText = null,
  ...props
}: Props) {
  const { t } = useTranslation();

  function renderValue(value: unknown) {
    if (value == undefined || value == '' || (Array.isArray(value) && value.length == 0)) { // eslint-disable-line
      return (
        <Typography sx={style.emptyValue}>{dirtyText || t('actions.select')}</Typography>
      );
    }
    if (Array.isArray(value)) {
      const values = options.filter(option => value.includes(option.value as string));
      return (
        <Box sx={style.multiple}>
          {values.map((item) => (
            <Chip key={item.value as string} label={item.label} />
          ))}
        </Box>
      )
    }
    const option = options.find((item) => item.value == value) as SelectOption; // eslint-disable-line
    return option?.label || '';
  }

  return (
    <Select
      displayEmpty
      renderValue={renderValue}
      sx={errorMessage ? style.errorState : null}
      {...props}
    >
      {options.map(option => (
        <MenuItem
          value={option.value} disabled={option.disabled || false}
          key={option.value as string}
          {...menuItemProps}
        >{option.label}</MenuItem>
      ))}
    </Select>
  )
}

IntSelect.displayName = 'IntSelect';

export default IntSelect;