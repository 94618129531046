import React from "react";
import "./styles.scss";

const NumberAnimatedLoading = () => {
  return (
    <div className="dots-loading">
      <div className="col-3">
        <div className="snippet">
          <div className="stage">
            <div className="dot-flashing"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NumberAnimatedLoading;
