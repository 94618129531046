import { useFieldArray, useFormContext, useFormState, useWatch } from "react-hook-form";
import { Box, Button, Divider, InputAdornment, TextField, Tooltip, Typography } from "@mui/material";
import { AddOutlinedIcon, getImageDetails, IntFieldController, IntSwitch } from "../../../global";
import { style } from './style';
import { useTranslation } from "react-i18next";
import { NotificationService } from "../../../global/services";
import { UploadService } from "../../../upload/services";
import { AdPreview, ImageList } from "../index";
import {
  creativeNameRunTimeValidator,
  DYNAMIC_AD_ACCEPTABLE_SIZE,
  MAX_DYNAMIC_AD_PICTURES_ALLOWED,
} from "../../utils";
import { StatusCode } from "../../../global/types";
import { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { CampaignFormDTO } from "../../types";
import ApprobationStatus from "../ApprobationStatus";

type Props = {
  footer: ReactNode;
}

function DynamicAdsForm({ footer }: Props) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'campaign.dynamicAd',
  });

  const { control, setValue } = useFormContext<CampaignFormDTO>();

  const formState = useFormState<CampaignFormDTO>({
    control,
  });

  const { fields } = useFieldArray({
    control,
    name: 'dynamicAd.titles',
  });

  const { fields: descriptionFields } = useFieldArray({
    control,
    name: 'dynamicAd.descriptions',
  });

  const picturesFieldArray = useFieldArray({
    control,
    name: 'dynamicAd.images',
  });

  const logoFieldArray = useFieldArray({
    control,
    name: 'dynamicAd.logo',
  });

  const [dynamicAdEnabled, creative_name] = useWatch({
    name: ['dynamicAdEnabled', 'dynamicAd.creative_name'],
    control,
  })

  const [nameError, setNameError] = useState('');

  const hasImageError = !!formState.errors?.dynamicAd?.images;
  const hasLogoError = !!formState.errors?.dynamicAd?.logo;
  const hasError = !!formState.errors?.dynamicAd?.creative_name;
  console.log('dynamic add error', formState.errors, formState.errors?.dynamicAd?.creative_name)

  async function handleFileUpload(event: any) {
    const file = event.target.files[0];
    const fileSize = await getImageDetails(file);
    // if (fileSize.width && fileSize.height && fileSize.width >= DYNAMIC_AD_ACCEPTABLE_SIZE && fileSize.height >= DYNAMIC_AD_ACCEPTABLE_SIZE && fileSize.width / fileSize.height === 1) {

      if (+fileSize.width === DYNAMIC_AD_ACCEPTABLE_SIZE && +fileSize.height === DYNAMIC_AD_ACCEPTABLE_SIZE) {
      const response = await UploadService.uploadFile3(file);
      if (response.data.type.code === StatusCode.OK) {
        picturesFieldArray.append( { url: response.data.data.file_url, id: response.data.data.file_id });
      }
    } else {
      NotificationService.handleError(t('imageSizeError'));
    }
  }

  async function handleLogoUpload(event: any) {
    const file = event.target.files[0];
    const fileSize = await getImageDetails(file);
    if (fileSize.width !== fileSize.height) {
      NotificationService.handleError(t('imageSquareError'));
    } else {
      const response = await UploadService.uploadFile3(file);
      if (+response.data.type.code === StatusCode.OK) {
        logoFieldArray.append( { url: response.data.data.file_url, id: response.data.data.file_id });
      }
    }
  }

  const pictureAdditionDisabled = picturesFieldArray.fields.length === MAX_DYNAMIC_AD_PICTURES_ALLOWED;
  const logoAdditionDisabled = logoFieldArray.fields.length === MAX_DYNAMIC_AD_PICTURES_ALLOWED;

  const [
    descriptionFieldArraySubscriber,
    titleFieldArraySubscriber,
    companyName,
    callToAction,
    creativeServingDecision
  ] = useWatch<CampaignFormDTO>({
    name: ['dynamicAd.descriptions', 'dynamicAd.titles', 'dynamicAd.companyName', 'dynamicAd.callToAction', 'dynamicAd.creativeServingDecision'],
    control,
  }) as [CampaignFormDTO['dynamicAd']['descriptions'], CampaignFormDTO['dynamicAd']['titles'], string, string, Nullable<IDecision>];

  const controlledDescriptionFields = descriptionFieldArraySubscriber ? descriptionFields.map((field, index) => {
    return {
      ...field,
      ...descriptionFieldArraySubscriber[index]
    };
  }) : [];

  const controlledTitleFields = titleFieldArraySubscriber ? fields.map((field, index) => {
    return {
      ...field,
      ...titleFieldArraySubscriber[index]
    };
  }) : [];

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(
      'dynamicAd.creative_name',
      creativeNameRunTimeValidator(e.target.value, setNameError)
    );
  }

  useEffect(() => {
    if(hasError)
      setNameError('Creative Name is a required field');
  }, [hasError]);

  return (
    <Box sx={style.dynamicAds}>
      <Box>
        <Box sx={style.header}>
          <Typography sx={style.title}>
            {t('title')}
          </Typography>
          <Tooltip title={dynamicAdEnabled ? t('disable') : t('enable') }>
            <Box>
              <IntFieldController
                control={control}
                name='dynamicAdEnabled'
                Component={IntSwitch}
              />
            </Box>
          </Tooltip>
        </Box>
        <Typography sx={style.description}>{t('sectionDescription')}</Typography>
      </Box>
      <Divider />
      <ApprobationStatus decision={creativeServingDecision} />
      <Box sx={style.form}>
        <Box sx={style.fields}>
          <Box sx={style.field}>
            <Typography sx={style.fieldLabel}>CREATIVE NAME</Typography>
            <TextField
              value={creative_name || ""}
              id="outlined-basic"
              variant="outlined"
              onChange={(e) => handleNameChange(e as ChangeEvent<HTMLInputElement>)}
              placeholder={'Enter creative name'}
              sx={{...style.names(nameError), }}
            />
            {!!nameError ? <Typography sx={style.errorText}>{nameError}</Typography> : (
              <Typography sx={style.fieldRequired}>
                {t('required', { start: creative_name?.length || 0, finish: 75 })}
              </Typography>
            )}
          </Box>

          {controlledTitleFields.map((field, index) => (
            <Box sx={style.field} key={field.id}>
              <Typography sx={style.fieldLabel}>{t('adTitle')}</Typography>
              <IntFieldController
                control={control}
                name={`dynamicAd.titles.${index}.title`}
                sx={style.fieldInput}
                placeholder={t<string>('titlePlaceholder')}
              />
              <Typography sx={style.fieldRequired}>
                {t('required', { start: field.title.length, finish: 30 })}
              </Typography>
            </Box>
          ))}

          <Divider />
          {controlledDescriptionFields.map((field, index) => (
            <Box sx={style.field} key={field.id}>
              <Typography sx={style.fieldLabel}>{t('adDescription')}</Typography>
              <IntFieldController
                control={control}
                name={`dynamicAd.descriptions.${index}.description`}
                sx={style.fieldInput}
                placeholder={t<string>('description')}
              />
              <Typography sx={style.fieldRequired}>{
                t('required', { start: field.description.length, finish: 50 })}
              </Typography>
            </Box>
          ))}
          <Divider />
          <Box sx={style.field}>
            <Typography sx={style.fieldLabel}>{t('businessName')}</Typography>
            <IntFieldController
              control={control}
              name='dynamicAd.companyName'
              sx={style.fieldInput}
              placeholder={t<string>('name')}
            />
            <Typography sx={style.fieldRequired}>{
              t('required', { start: companyName.length || 0, finish: 10 })}
            </Typography>
          </Box>
          <Box sx={style.ctaFields}>
            <Box sx={style.field}>
              <Typography sx={style.fieldLabel}>{t('cta')}</Typography>
              <IntFieldController
                control={control}
                name='dynamicAd.callToAction'
                sx={style.fieldInput}
                hideErrorMessage
              />
              <Typography sx={style.fieldRequired}>{
                t('required', { start: callToAction.length, finish: 12 })}
              </Typography>
            </Box>
            <Box sx={style.field}>
              <Typography sx={style.fieldLabel}>{t('ctaColor')}</Typography>
              <IntFieldController
                control={control}
                name='dynamicAd.callToActionColor'
                sx={style.fieldInput}
                hideErrorMessage
                InputProps={{
                  startAdornment: <InputAdornment position="start">#</InputAdornment>,
                }}
              />
              <Typography sx={style.fieldRequired}>{t('requiredField')}</Typography>
            </Box>
          </Box>
          <Divider />
          <Box sx={style.pictures}>
            <Typography sx={style.fieldLabel}>{t('image')}</Typography>
            <Typography sx={[style.fieldHelperText, style.imageHelperText]}>{t('fileRequirements')}</Typography>
            <ImageList images={picturesFieldArray.fields} onRemove={() => picturesFieldArray.remove(0)} />
            <Button
              startIcon={<AddOutlinedIcon color={pictureAdditionDisabled ? 'disabled' : 'primary'} />}
              sx={[style.fileUpload, hasImageError && style.fileUploadError]}
              component="label"
              disabled={pictureAdditionDisabled}
            >
              {t('addPhoto')}
              <input
                type='file'
                hidden
                style={style.uploadInput}
                onChange={handleFileUpload}
                value={picturesFieldArray.fields[0] ? '' : undefined}
              />
            </Button>
          </Box>
          <Box sx={style.pictures}>
            <Typography sx={style.fieldLabel}>{t('logo')}</Typography>
            <Typography sx={[style.fieldHelperText, style.imageHelperText]}>{t('upToOneLogo')}</Typography>
            <ImageList images={logoFieldArray.fields} onRemove={() => logoFieldArray.remove(0)} />
            <Button
              startIcon={<AddOutlinedIcon color={logoAdditionDisabled ? 'disabled' : 'primary'} />}
              sx={[style.fileUpload, hasLogoError && style.fileUploadError]}
              component="label"
              disabled={logoAdditionDisabled}
            >
              {t('addLogo')}
              <input
                type='file'
                hidden
                style={style.uploadInput}
                onChange={handleLogoUpload}
              />
            </Button>
          </Box>
        </Box>
        <Box>
          <Box sx={style.adPreview}>
            <AdPreview control={control} />
          </Box>
        </Box>
      </Box>
      {footer}
    </Box>
  );
}

DynamicAdsForm.displayName = 'DynamicAdsForm';

export default DynamicAdsForm;
