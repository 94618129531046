import { FieldValues } from "react-hook-form/dist/types/fields";

export type ProfileUploadedImage = {
  image: string | null;
  type: number;
}

export interface PasswordChangeFormDTO extends FieldValues {
  currentPassword: string;
  newPassword: string;
  repeatNewPassword: string;
}

export enum UserRole {
  WHITELABEL_ACCOUNTANT = 11,
  WL_OWNER = 10,
}

export enum UserType {
  LEGAL = 1,
  PHYSICAL = 2,
}

export type ProfileLegalFormFields = {
  tinNumber: string | undefined;
  companyName: string;
}
export interface RewardedAdFormFields {
  rewarded_ads_cpm_rate: number;
}
export interface RewardedAdFormDTO extends RewardedAdFormFields {
  userId: string;
}

export type ProfileNotificationForm = {
  campaignRejected: boolean;
  outOfBudget: boolean;
  closeToThreshold: boolean;
  spaceOutOfBudget: boolean;
  campaignApproved: boolean;
  endDateReached: boolean;
}

export type ProfileLegalDTO = {
  userId: string;
  tinNumber: string | undefined;
  companyName: string;
}