import { Slider, SliderProps } from "@mui/material";

type Props = SliderProps & {
  inputRef?: any;
  errorMessage?: string;
}

function IntSlider({
  inputRef,
  errorMessage,
  ...props
}: Props) {
  return (
    <Slider {...props}/>
  );
}

IntSlider.displayName = 'IntSlider';

export default IntSlider;