import React, { useEffect } from "react";
import ReactPortal from "./ReactPortal";
import "./styles.scss";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
};

export const ModalBeta = ({
  children,
  isOpen,
  onClose: handleClose,
}: Props) => {
  useEffect(() => {
    const closeOnEscapeKey = (e: KeyboardEvent) =>
      e.key === "Escape" ? handleClose() : null;
    document.body.addEventListener("keydown", closeOnEscapeKey);
    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKey);
    };
  }, [handleClose]);

  if (!isOpen) return null;

  return (
    <ReactPortal wrapperId="modal-root">
      <div
        className="overlay"
        onClick={e => {
          if (e.target !== e.currentTarget) return;
          handleClose();
        }}
      >
        <div className="app-modal">
          {isOpen && <div className="app-modal-content">{children}</div>}
        </div>
      </div>
    </ReactPortal>
  );
};
