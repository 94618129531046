import { Theme } from "@mui/material";

export const style = {
  card: (theme: Theme) => ({
    backgroundColor: '#fff',
    padding: theme.spacing(4, 6),
    borderRadius: theme.spacing(4),
    border: '1px solid #bfcfe3',
    width: 240, height: 160,
    cursor: 'pointer',
  }),
  title: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '1.33',
    color: '#292f41',
  },
  description: {
    fontSize: 12,
    lineHeight: '1.33',
    color: 'rgba(41, 47, 65, 0.6)',
  },
  circle: (theme: Theme) => ({
    backgroundColor: '#dfe7f0',
    width: 44,
    height: 44,
    borderRadius: '50%',
    marginTop: theme.spacing(3),
    transition: 'transform .4s ease-in-out',
  }),
  selectedCard: {
    border: '2px solid #3a77eb',
    backgroundColor: '#F4F6FA',
  },
  selectedCircle: {
    transform: 'scale(1.5)',
  }
};
