import { AuthState } from "../modules/store/reducers/authentication";

export const loadAuthState = () => {
  try {
    const serializedState = localStorage.getItem('authState');

    if (serializedState === null) {
      return null;
    }

    return JSON.parse(serializedState) as AuthState;
  } catch (e) {
    return null;
  }
};

export const saveAuthState = (authenticationStore: AuthState) => {
  try {
    if (authenticationStore) {
      const serializedState = JSON.stringify(authenticationStore);

      localStorage.setItem('authState', serializedState);
    }
  } catch (e) {
    // ignore errors
  }
};
