import {
  TextField,
  TextFieldProps,
} from "@mui/material";
import { styles } from './styles';
import { FormEventHandler } from "react";

type Props = TextFieldProps & {
  input?: {
    value: string;
    name: string;
    onChange: FormEventHandler<HTMLInputElement>;
  };
  meta?: {
    error?: {
      defaultMessage: string;
    };
    touched: boolean;
  };
  errorMessage?: string;
  hideErrorMessage?: boolean;
};

function IntTextField({
  input,
  sx = null,
  meta,
  FormHelperTextProps = {},
  errorMessage,
  hideErrorMessage,
  ...props
}: Props) {
  const error = errorMessage || (meta && meta.touched && meta.error && meta.error.defaultMessage) || null;
  return (
    <TextField
      size='small'
      helperText={hideErrorMessage ? null : error}
      {...props}
      inputProps={input}
      sx={Array.isArray(sx) ? [styles.input, ...sx] : [
        styles.input,
        error ? styles.inputError : null,
        sx,
      ]}
      FormHelperTextProps={{
        sx: styles.helperText,
        ...FormHelperTextProps
      }}
    />
  );
}

IntTextField.displayName = 'IntTextField';

export default IntTextField;
