import { Theme } from "@mui/material";

export const style = {
  label: {
    textTransform: 'uppercase',
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: '.8px',
    color: '#292f41',
    marginLeft: 2,
  },
  radioGroupLabel: {
    marginBottom: 2,
  },
  comingSoon: {
    color: 'rgba(41, 47, 65, 0.5)',
    fontSize: 12,
    lineHeight: 1.33,
    letterSpacing: '0.17px',
    fontStyle: 'italic',
  },
  radioLabel: {
    fontSize: 14,
    color: '#292f41',
    letterSpacing: '0.2px',
    lineHeight: 1.14,
    display: 'flex',
    gap: 1,
    alignItems: 'center',
  },
  radio: {
    display: 'flex',
    alignItems: 'center',
  },
  radioGroup: {
    flexDirection: 'row',
    gap: 11,
    rowGap: 0,
  },
  websitesForm: (theme: Theme) => ({
    padding: theme.spacing(0, 12),
    width: '100%',
  }),
  divider: (theme: Theme) => ({
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
  }),
  field: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1.5,
  },
  helperText: {
    fontSize: 11,
    lineHeight: 1.09,
    color: 'rgba(41, 47, 65, 0.5)',
    marginLeft: 2,
  },
  fields: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  switchField: {
    display: 'flex',
    gap: 3,
  },
  additionalInfo: (theme: Theme) => ({
    fontSize: 14,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
  }),
  customActionDivider: {
    marginBottom: 1,
  },
  fieldWithAdornment: {
    '& input': {
      borderLeft: '1px solid #dfe7f0',
      borderRadius: 'unset !important',
      paddingLeft: 2,
    },
  },
  pasteGTM: (theme: Theme) => ({
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: '0.2px',
    color: '#292f41',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(3),
  }),
  optimizeCampaign: {
    fontSize: 11,
    lineHeight: 1.18,
    color: 'rgba(41, 47, 65, 0.5)',
  },
  copyIcon: (theme: Theme) => ({
    width: 36,
    height: 36,
    cursor: 'pointer',
    position: 'absolute',
    bottom: 0,
    right: 0,
  }),
  copyIconSecondary: (theme: Theme) => ({
    width: 36,
    height: 36,
    cursor: 'pointer',
    position: 'absolute',
    bottom: 0,
    right: 36,
  }),
  deleteIconSecondary: (theme: Theme) => ({
    width: 36,
    height: 36,
    cursor: 'pointer',
    position: 'absolute',
    color: '#658ffd',
    bottom: -5,
    right: 0,
  }),
  multipleLabelsField: {
    gap: 2,
  },
  customHTML: {
    '& textarea': {
      fontSize: 14,
      position: 'relative',
    },
  },
  withTooltip: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
  },
  urlTypes: {
    display: 'flex',
    background: 'white',
    height: 32,
    alignItems: 'center',
    border: 'solid 1px #dfe7f0',
    borderRadius: 1,
  },
  urlType: {
    width: '50%',
    textAlign: 'center',
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: '0.2px',
    color: '#292f41',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  activeURLType: {
    backgroundImage: 'linear-gradient(261deg, #65abf6, #2e6bde)',
    height: '100%',
    borderRadius: 1,
    boxShadow: '0 12px 8px -10px rgba(58, 119, 235, 0.2)',
    border: 'solid 1px #2e79f7',
    color: 'rgba(255, 255, 255, 0.9)',
  },
  customRule: {
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: '0.2px',
    color: '#292f41',
  },
  rule: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  condition: {
    width: 165,
    flex: 1,
  },
  conversionURLCondition: {
    display: 'flex',
    gap: 2,
  },
  conditionValueInput: {
    flex: 2,
  },
  secondaryTitle: {
    textTransform: 'uppercase',
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: '.8px',
    color: '#292f41',
    marginLeft: 2,
    marginBottom: 2,
  },
  secondaryEventName: {
    width: '100%',
    marginBottom:'8px',
    backgroundColor: '#fff',
    '& input': {
      padding: '8px 50px 8px 14px'
    },
    '&:hover fieldset': {
      borderColor: '#658ffd !important'
    }
  },
  secondaryEventNameErr: {
    width: '100%',
    marginBottom:'8px',
    backgroundColor: '#fff',
    '& input': {
      padding: '8px 50px 8px 14px'
    },
    '& fieldset': {
      border: '2px solid red',
    },
    '& p': {
      color: 'red',
    },
    '&:hover fieldset': {
      borderColor: '#658ffd !important'
    }
  },
  addSecondary: {
    float: 'right',
    lineHeight: '12px',
    textTransform: 'none',
  },
  rowFields: {
    display: 'flex',
    gap: 3,
  },
  autocompleteField: {
    flexBasis: '50%',
  },
  bundleID: {
    width: 'calc(50% - 4px)',
  },
  appName: {
    fontSize: 12,
    color: '#292f41',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  crossIcon: {
    width: 30,
    height: 30,
    cursor: 'pointer',
  },
  app: (theme: Theme) => ({
    backgroundColor: '#E7E7EC',
    borderRadius: 1,
    flexGrow: 2,
    height: 40,
    alignSelf: 'flex-end',
    padding: theme.spacing(2, 2, 2, 3),
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    width: 'calc(50% - 8px)',
  }),
}