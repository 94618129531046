import React from "react";
import { FormattedMessage } from "react-intl";
import "./style.scss";
import { useSelector } from "react-redux";
import { numberToStringWithCommas } from "../../../modules";

interface IProps {
  budget_type_key?: number;
  budget?: number;
}

export const CampaignListBudget = ({ budget, budget_type_key }: IProps) => {
  // @ts-ignore we must change to useAppSelector
  const whitelabel = useSelector(store => store.admin.whitelabel);
  const settings = whitelabel?.whitelabel_settings || {};
  if (!budget_type_key) {
    return null;
  }

  if (budget_type_key === 3) {
    return (
      <p style={{ fontWeight: 600 }}>
        <FormattedMessage
          id="BudgetType.unlimited"
          defaultMessage="Unlimited"
        />
      </p>
    );
  }

  return (
    <div className="campaing-list-budget">
      <span className="campaing-list-budget-balance">
        {`${settings.currency || '$'} ${numberToStringWithCommas(budget ? budget : 0)}`}
      </span>
      <p className="campaing-list-budget-status">
        { budget_type_key === 2 ? (
            <FormattedMessage
            id="CampaignList.perDay"
            defaultMessage="PER DAY"
            />
          ) : (
            <FormattedMessage
            id="CampaignList.inTotal"
            defaultMessage="IN TOTAL"
            />
          )
        }
      </p>
    </div>
  );
};

export default CampaignListBudget;
