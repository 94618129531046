import React, { useState } from "react";
import Color from "./Color";
import "./style.scss";
import ColorPickerChooser from "./colorPickerChooser";

type TProps = {
  input: any,
  label: string,
  right: boolean,
};

const ColorPickerField  = (props: TProps) => {

  const {input, label, right} = props;

  const [open, setOpen] = useState<boolean>(false);

  const onClick = () => setOpen(true);

  const closeModal = () => setOpen( false );

  return (
    <div className="color-picker-field-container">
      <span className="color-picker-field-label">{label}</span>
      <Color key={input.value} color={input.value} onClick={onClick} />
      {open && (
        <ColorPickerChooser
          open={open}
          closeModal={closeModal}
          onChange={input.onChange}
          color={input.value}
          right={right}
        />
      )}
    </div>
  );
}

export default ColorPickerField;
