import { Theme } from "@mui/material";

export const style = {
  form: {
    width: 500,
  },
  header: (theme: Theme) => ({
    marginBottom: theme.spacing(4),
  }),
  content: (theme: Theme) => ({
    margin: theme.spacing(4, 0, 4, 0),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 12,
  }),
  textField: {
    width: '100%',
  },
  field: (theme: Theme) => ({
    marginBottom: theme.spacing(3),
  }),
  accountTypeField: {
    height: 40,
    width: '100%',
  },
  footer: (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),
  }),
  cancel: (theme: Theme) => ({
    marginRight: theme.spacing(3),
  }),
  menuItem: {
    textTransform: 'capitalize',
  }
}