import {
  QueryClient,
  QueryClientProvider, useMutation, useQuery, useQueryClient
} from "@tanstack/react-query";
import { getPaymentMethodsRequest, removePaymentMethodRequest, makeDefaultPaymentMethodRequest } from "../../../api";
import { AddCreditCard } from "../../../components";
import { PaymentCardsList } from "../../../components/PaymentsCardsList";
import { RemovePayload } from "../../../components/PaymentsCardsList/PaymentCardsListBeta";
import { ModalBeta } from "../../../containers/Modal/ModalBeta";
import React, { useState } from "react";
import "./AddPaymentMethods.scss";

import StripeElements from "./StripeElements";
import { NotificationService } from "../../../modules/global/services";

const queryClient = new QueryClient();

export const AddPaymentMethods = () => {
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const paymentMethodQuery = useQuery(["paymentMethods"], getPaymentMethodsRequest);

  const makeDefaultMutation = useMutation(makeDefaultPaymentMethodRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(['paymentMethods'])
      setIsOpen(false);
    },
    onError: (err: any) => {
      NotificationService.handleError(err?.response?.data?.type?.message);
    }
  });

  const removePaymentMutation = useMutation(removePaymentMethodRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(['paymentMethods']);
    },
    onError: (err: any) => {
      NotificationService.handleError(err?.response?.data?.type?.message);
    }
  });

  const handleCardRemove = (card: RemovePayload) => {
    removePaymentMutation.mutate(card.id);
  }

  const handleAddPaymentMethodSuccess = async () => {
    await queryClient.invalidateQueries({ queryKey: ['paymentMethods'] });
    setIsOpen(false)
  }

  return (
    <div className="card-list-wrapper">
      <PaymentCardsList
        onDefaultChange={(value => makeDefaultMutation.mutate(value.method_id))}
        onCardRemove={handleCardRemove}
        state={removePaymentMutation.isLoading ? "deleting" : makeDefaultMutation.isLoading ? "submitting" : "idle"}
        paymentMethods={paymentMethodQuery?.data || []}
      />
      <AddCreditCard payWithBankGateway={() => setIsOpen(true)} />

      <ModalBeta isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <StripeElements onSuccess={handleAddPaymentMethodSuccess} />
      </ModalBeta>
    </div>
  );
};

const Result = () => (
  <QueryClientProvider client={queryClient}>
    <AddPaymentMethods />
  </QueryClientProvider>
);
export default Result;
