import { createSlice } from '@reduxjs/toolkit';
import { IGenericList, ITransaction } from "../../../types";

export interface ITransactionsState {
  transactionsHistoryIsLoading: boolean;
  transactionsListData: {} | IGenericList<ITransaction>;
  transaction: any;
}

export const transactionsSlice = createSlice({
  name: 'transactionsStore',
  initialState: {
    transactionsListData: {},
    transactionsHistoryIsLoading: true,
    transaction: {},
  } as ITransactionsState,
  reducers: {
    setTransactionsHistoryIsLoading: (state, action) => ({
      ...state,
      transactionsHistoryIsLoading: action.payload,
    }),
    setTransactionsListData: (state, action) => ({
      ...state,
      transactionsListData: action.payload,
    }),
    setTransaction: (state, action) => ({
      ...state,
      transaction: action.payload,
    }),
  },
});

export const {
  setTransactionsHistoryIsLoading,
  setTransactionsListData,
  setTransaction
} = transactionsSlice.actions;

export const transactionsHistoryIsLoadingSelector = (state: any) => state.transactionsStore.transactionsHistoryIsLoading;
export const transactionsListDataSelector = (state: any) => state.transactionsStore.transactionsListData;
export const transactionSelector = (state: any) => state.transactionsStore.tranascation;

export default transactionsSlice.reducer;
