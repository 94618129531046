/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 8/10/2023
 * All Rights Reserved.
 */
import { useState, MouseEvent, useRef, useEffect } from "react";
import { style } from './style';
import { Box, Popover, TextField, Typography } from "@mui/material";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { differenceInDays, formatDate } from "../../../utils";
import { useHistory, useLocation } from "react-router-dom";
import { fromDate, toDate } from "../../../../report/services/report.service";
import moment from "moment";


export type IntDateRangePickerProps = {
  name: string;
  fromDatePickerProps?: ReactDatePickerProps;
  toDatePickerProps?: ReactDatePickerProps;
  value: { from: Date; to: Date; };
  onChange: Function;
  includeDefaultPresets?: boolean;
  presets?: Array<{ id: number, label: string; value: number | number[]; selected: boolean }>;
}

const DEFAULT_PRESETS = [
  { id: 1, label: 'Today', value: 1, selected: false },
  { id: 2, label: 'Last 7 days', value: 7, selected: true },
  { id: 3, label: 'Last 30 days', value: 30, selected: false },
  { id: 4, label: 'Last 365 days', value: 365, selected: false },
];

let delayId: any, delay: number = 1300;

let finalPresets: { id: number, label: string; value: number | number[]; selected: boolean }[] = [];

const setDateToSearchParams = (history: any, search: string, start: Date, end: Date) => {
  const searchParams = new URLSearchParams(search);
  searchParams.set("start",fromDate(start));
  searchParams.set("end", toDate(end));
    history.push({
      search: searchParams.toString()
    })
}

function IntDateRangePicker({
  name,
  value,
  fromDatePickerProps,
  toDatePickerProps,
  onChange,
  includeDefaultPresets = true,
  presets = [],
}: IntDateRangePickerProps) {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const fromDate = useRef(null);
  const toDate = useRef(null);

  const from = searchParams.get("start") ? moment(searchParams.get("start") as string).toDate() : value.from;
  const to = searchParams.get("end") ? moment(searchParams.get("end") as string).subtract(1, 'days').toDate() : value.to;

  function handleClick(event: MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const openFromDatePicker = () => {
    // @ts-ignore
    if (fromDate.current && typeof fromDate.current.setOpen === 'function') {
      // @ts-ignore
      fromDate.current.setOpen(true);
    }
  };

  const openToDatePicker = () => {
    // @ts-ignore
    if (toDate.current && typeof toDate.current.setOpen === 'function') {
      // @ts-ignore
      toDate.current.setOpen(true);
    }
  };

  function handleFromChange(date: Date | null) {
    if (date instanceof Date) {
      setDateToSearchParams(history, search, date, to);
      onChange({
        to,
        from: date,
      });
      clearTimeout(delayId);
      delayId = setTimeout(() => {handleClose()} ,delay)

    }
  }

  function handleToChange(date: Date | null) {
    if (date instanceof Date) {
      setDateToSearchParams(history, search, from, date);
      onChange({
        from,
        to: date,
      });
      clearTimeout(delayId);
      delayId = setTimeout(() => {handleClose()} ,delay)
    }
  }


  const diff = differenceInDays(from, to)

  const isDiffMoreThanAYear = diff >= 365;

  function presetChangeBuilder(days: number | number[], id: number) {
    const _day = typeof days === 'number' ? days : days[0];
    const _endDay = typeof days === 'number' ? undefined : days[1];
    return function changePreset() {
      finalPresets.forEach(item => {
        item.selected = item.id === id;
      })
      const toDate = new Date();
      const fromDate = new Date();
      if(_endDay){
        toDate.setDate(toDate.getDate() - _endDay);
      }
      fromDate.setDate(fromDate.getDate() - _day);

      fromDate.setHours(0, 0, 0, 0);
      toDate.setHours(0, 0, 0, 0);

      setDateToSearchParams(history, search, fromDate, toDate);
      onChange({ from: fromDate, to: toDate });
      handleClose();
    }
  }

  useEffect(() => {
    finalPresets = includeDefaultPresets ? [...presets, ...DEFAULT_PRESETS] : presets;

    onChange({ from: from, to: to });
    // setDateToSearchParams(history, search, from, to);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Box sx={style.dateInput}>
      <Box onClick={handleClick} sx={style.input}>
        {
          formatDate(from, 'MM/dd')
        }{isDiffMoreThanAYear ? `, ${from.getFullYear()}` : ' '}- {
        formatDate(to, 'MM/dd')
      }, {to.getFullYear()}
      </Box>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: style.popover,
        }}
      >
        <Box sx={style.popoverContent}>
          <Box sx={style.dateSelection}>
            <Typography sx={style.customRange}>Custom Range</Typography>
            <Box sx={style.dateInputField}>
              <Box sx={style.dateLabelField}>
                <Typography>From</Typography>
              </Box>
              <DatePicker
                onChange={handleFromChange}
                selected={from}
                dateFormat='dd/MM/yyyy'
                ref={fromDate}
                customInput={(
                  <TextField
                    value={formatDate(from)}
                    onFocus={openFromDatePicker}
                    InputProps={{ sx: style.textField }}
                    name={`${name}.from`}
                  />
                )}
                popperProps={{
                  positionFixed: true
                }}
                maxDate={to}
                popperPlacement="bottom-end"
                {...fromDatePickerProps}
              />
            </Box>
            <Box sx={style.dateInputField}>
              <Box sx={style.dateLabelField}>
                <Typography>To</Typography>
              </Box>
              <DatePicker
                onChange={handleToChange}
                selected={to}
                ref={toDate}
                dateFormat='dd/MM/yyyy'
                customInput={(
                  <TextField
                    value={formatDate(to)}
                    onFocus={openToDatePicker}
                    InputProps={{ sx: style.textField }}
                    name={`${name}.to`}
                  />
                )}
                minDate={from}
                popperProps={{
                  positionFixed: true
                }}
                popperPlacement="bottom-end"
                {...toDatePickerProps}
              />
            </Box>
          </Box>
          <Box>
            <Typography sx={style.datePreset}>Preset Ranges</Typography>
            <Box>
              {finalPresets.map(preset => {
              return (
                <Typography
                  sx={[style.preset, preset.selected && style.selected]}
                  onClick={presetChangeBuilder(preset.value, preset.id)}
                  key={preset.id.toString()}
                >{preset.label}</Typography>
              )})
              }
            </Box>
          </Box>
        </Box>
      </Popover>
    </Box>
  )
}

IntDateRangePicker.displayName = 'IntDateRangePicker';

export default IntDateRangePicker;