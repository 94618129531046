import React from "react";
import AdminAccountsContainer from "./AdminAccountsContainer";
import { useTranslation } from "react-i18next";
import AdminHeader from "../../components/AdminHeader";

const AdminAccounts = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.account' });
  return (
    <div>
      <AdminHeader title={t("AccountsReport")}>
        <AdminAccountsContainer />
      </AdminHeader>
    </div>
  );
}

AdminAccounts.displayName = "AdminAccounts";

export default AdminAccounts;
