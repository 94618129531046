import { Theme } from "@mui/material";

export const style = {
  title: {
    fontSize: 24,
    lineHeight: 1.17,
    letterSpacing: '-0.2px',
    color: '#292f41',
  },
  divider: (theme: Theme) => ({
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(9),
  }),
  visuals: {
    width: '100%',
  },
  banners: {
    width: '100%',
  },
  bannerPreview: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  visualBanners: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  bannerDivider: {
    marginTop: 'auto',
  },
  rowBanners: {
    display: 'flex',
    gap: 4,
    flexWrap: 'wrap',
  },
  uploadHTML: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
    cursor: 'pointer',
  },
  uploadHTMLText: {
    color: 'primary.main',
    textDecoration: 'underline',
  },
  uploadInfo: {
    color: 'rgba(41,47,65,.5)',
    fontSize: 14,
    letterSpacing: '.2px',
    lineHeight: '1rem',
  }
}