import React, { useEffect, useState } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { AuthTitleWrapper } from "../../components";
import { useSelector } from "react-redux";
// import {
//   MaiRow,
//   MaiFilters,
//   ContentWrapper,
//   MaiFilterField,
//   MaiFiltersDate,
// } from "@intent-ai/mandal-npm-lib-v2";

import {
  Modal } from "../../containers";
import { billingMessages } from "../../messages";
import "./style.scss";
import { USER_TYPES } from "../../_constants";
import clsx from "clsx";
import { CURRENCIES } from "../../modules/store";
import { numberToStringWithCommas } from "../../modules";
import { selectCurrentUser } from "../../modules/store/reducers/authentication";
import { termsOfServiceLinkSelector } from "../../modules/store/reducers/admin";
import { getCurrentSpaceBalanceRequest } from "../../api";
import Loading from "../../modules/global/components/Loading";
import { AddPaymentMethods } from "../../containers/Billings/AddPaymentMethods";
import { Flex } from "../../modules/global/components/Flex";
import { ContentWrapper } from "../../modules/global/components/ContentWrapper";
import InvoicesList from "./components/invoicesList";



const Billing = (props: any) => {
  const {
    intl: { formatMessage },
    modalType,
    toggle,
    isOpen,
    closeModal,
    modalTypes,
    modalData,
    openModal,
  } = props;

  const currentUser = useSelector(selectCurrentUser);
  const termsOfServiceLink = useSelector( termsOfServiceLinkSelector);

  const [currentBalance, setCurrentBalance] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)


  const getBalance = (id: string) => {
    setIsLoading(true);
    getCurrentSpaceBalanceRequest(id).then(({data}) => {
      if(data.type.code === 0){
        setCurrentBalance(data.data);
      }
      setIsLoading(false);
    })
  }

  useEffect(() => {
    getBalance(sessionStorage.getItem('spaceID'));
    return () => {
      setCurrentBalance(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const noFunds = currentBalance < 1000;
  const lowBalance = currentBalance < 500;
  const getCurrentUserCurrency = () => {
    const storedCurrency: string | null = sessionStorage.getItem('currentAccountCurrency');
    const currency: string = !storedCurrency ? currentUser.currency : storedCurrency;
    return CURRENCIES[currency];
  };

  return (
    <AuthTitleWrapper
      iconName="billing"
      title={formatMessage(billingMessages.title)}
    >
      {isLoading ? <Loading /> : <>
        <div className="billing-screen ">
          <div className="payment-methods-and-funds top-radius">
            <ContentWrapper className={'top-radius'}>
              <Flex
                justifyContent="space-between"
                customClassName="aviable-funds-info"
                alignItems="center"
              >
                <div>
                  <p className="available-funds">
                    <FormattedMessage
                      id="Payments.availableFunds"
                      defaultMessage="Available funds"
                    />
                  </p>
                  <p
                    className={clsx("funds", {
                      "no-funds": noFunds,
                      "low-balance": lowBalance,
                    })}
                  >
                    {getCurrentUserCurrency()} {numberToStringWithCommas(currentBalance) || 0}
                  </p>
                </div>
              </Flex>
              <div className="payment-methods">
                <AddPaymentMethods />
              </div>
            {currentUser.user_type_key === USER_TYPES.company ? (
              <p className="int-info-text">
                <FormattedMessage
                  id="Payments.corporateCard"
                  defaultMessage="We kindly ask you to attach a corporate card in order to receive official transaction statements or proceed with other actions in compliance with our"
                />{" "}
                <a
                  href={termsOfServiceLink}
                  target="_blank"
                  className="link-hover"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage
                    id="Auth.termOfService"
                    defaultMessage="Terms of Service"
                  />
                  {"."}
                </a>
              </p>
              ): null
            }
          </ContentWrapper>
        </div>
          {!isLoading ? <InvoicesList className={'top-radius'} /> : null }
        </div>
        {isOpen && (
          <Modal
            modalData={modalData}
            modalType={modalType}
            toggle={toggle}
            isOpen={isOpen}
            openModal={openModal}
            closeModal={closeModal}
            modalTypes={modalTypes}
          />
        )}
      </>}
    </AuthTitleWrapper>
  );
}

export default injectIntl(Billing)
