import React from "react";
import { useTranslation } from "react-i18next";
import AdminHeader from "../../components/AdminHeader";
import ProfileCustomization from "./components/ProfileCustomization";

const Customization = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.customization' });

  return (
    <div>
      <AdminHeader title={t("title")}>
        <ProfileCustomization />
      </AdminHeader>
    </div>
  );
}

Customization.displayName = "Customization";

export default Customization;
