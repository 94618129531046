import { keyframes } from "@emotion/react";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const style = {
  progressBar: {
    borderRadius: '50%',
    border: '3px solid transparent',
    borderTopColor: '#EBEBEB',
    animation: `${spin} 1s linear infinite`,
    width: 20,
    height: 20,
  },
  button: {
    transition: 'all 150ms',
  },
  loadingButton: {
    maxHeight: 32,
    maxWidth: 32,
    borderRadius: '50%',
    padding: 0,
    minWidth: 0,
    backgroundColor: '#3957EA',
    '&:hover': {
      backgroundColor: '#3957EA',
    }
  },
}