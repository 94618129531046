import { style } from "./style";
import { Box, Divider, Typography } from "@mui/material";
import { IntFieldController, IntSwitch } from "../../../global";
import { useTranslation } from "react-i18next";
import { useWatch, Control } from "react-hook-form";
// import { lazy } from "react";

// const DynamicAdsForm = lazy(() => import('../DynamicAdsForm'))

type Props = {
  control: Control<any>;
}

function ResponsiveAds({ control }: Props) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'campaign.dynamicAd'
  });

  const isManual = useWatch({
    control,
    name: 'dynamicAd.isManual',
  });

  return (
    <Box sx={style.responsiveAds}>
      <Box sx={style.header}>
        <Box sx={style.headerTitle}>
          <Typography sx={style.sectionTitle}>{t('title')}</Typography>
        </Box>
        <Box sx={style.responsiveAdsTypeBox}>
          <Typography sx={style.responsiveAdsType}>{t(isManual ? 'on' : 'off')}</Typography>
          <IntFieldController
            name='dynamicAd.isManual'
            Component={IntSwitch}
            control={control}
          />
        </Box>
      </Box>
      {isManual && (
        <>
          <Divider />
          {/*<Suspense fallback={null}>*/}
          {/*  <DynamicAdsForm control={control} />*/}
          {/*</Suspense>*/}
        </>
      )}
    </Box>
  );
}

ResponsiveAds.displayName = 'ResponsiveAds';

export default ResponsiveAds;