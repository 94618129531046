import "./style.scss";
import { MenuItem, Select} from "@mui/material";

interface IProps {
  label?: string;
  value?: any;
  variant?: 'outlined' | 'filled' | 'standard';
  disabled?: boolean;
  autoFocus?: boolean;
  selectData?: any;
  options?: any;
  currentValue?: any;
  fullWidth?: boolean;
  placeholder?: string;
  input?: any;
  className?: string;
  inputClassName?: string;
}

const SelectRow = ({...props}: IProps) => {
  const { className = '', options, currentValue, fullWidth = false, placeholder, autoFocus = false, input = {}, label, disabled, variant = 'outlined'} = props;
  const {name, value, onChange} = input;

  return (
    <div className={`input-row ${fullWidth ? 'w-100' : ''} ${className}`}>
      {label ? (<div className={'label-wrapper'}>
        <label htmlFor={`${name}`} className={'label'}>{label}</label>
      </div>) : null}
      <div className={`input-wrapper ${fullWidth ? 'w-100' : ''}`}>
        <Select
          name={name ? name : ''}
          value={currentValue ? currentValue : value}
          className={`input-field ${disabled ? 'disabled' : ''}`}
          id={`${name}`}
          variant={variant}
          autoFocus={autoFocus}
          placeholder={placeholder}
          disabled={disabled}
          // type={`${type}`}
          onChange={onChange}
          children={options.map((item: any, i) => ( <MenuItem key={i} value={item.value}>{item.title}</MenuItem>))}
        />
      </div>

    </div>
  )
}

export default SelectRow;