import { Box, Button, Divider, Tooltip, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { style } from './style';
import React from "react";
import { useTranslation } from "react-i18next";
import { InfoIcon, IntFieldController } from "../../../global";
import { yupResolver } from "@hookform/resolvers/yup";
import { getProfileLegalFormSchema } from "../../utils";
import { IUser } from "../../../../types";
import { ProfileService } from "../../services";
import { ProfileLegalFormFields } from "../../types";
import { NotificationService } from "../../../global/services";

type Props = {
  user: IUser;
}

function ProfileLegalForm({ user }: Props) {

  const defaultValues = {
    tinNumber: user.tin_number || '',
    companyName: user.company_name || '',
  }

  const {
    handleSubmit,
    control,
    formState,
    reset,
  } = useForm<ProfileLegalFormFields>({
    resolver: yupResolver(getProfileLegalFormSchema()) as any,
    defaultValues,
    mode: 'onChange',
  });

  const { t } = useTranslation();

  async function onSubmit(values: ProfileLegalFormFields) {
    const response = await ProfileService.updateUserLegalInfo({
      ...values,
      userId: user.id
    });

    if (response.data.type?.code == 0) { // eslint-disable-line
      NotificationService.handleSuccess(t('profile.legalForm.savedSuccessfully'));
      reset({
        tinNumber: response.data.data.tin_number || '',
        companyName: response.data.data.company_name || '', //TODO bug, backend sends null instead of actual entity name
      });
    }
  }


  return (
    <Box component='form' sx={style.form} onSubmit={handleSubmit(onSubmit)}>
      <Typography sx={style.formTitle}>{t('profile.legalForm.title')}</Typography>
      <Divider />
      <Box>
        <Box sx={style.field}>
          <Box sx={style.fieldLabelWrapper}>
            <Typography sx={style.fieldLabel}>{t('profile.legalForm.entityName')}</Typography>
            <Tooltip title={t('profile.legalForm.entityNameDescription')}>
              <InfoIcon />
            </Tooltip>
          </Box>
          <IntFieldController
            name='companyName'
            control={control}
            sx={style.fieldInput}
          />
        </Box>
        <Divider />
        <Box sx={style.field}>
          <Typography sx={[style.fieldLabel, style.tinNumber]}>{t('profile.legalForm.tinNumber')}</Typography>
          <IntFieldController
            name='tinNumber'
            control={control}
            sx={style.fieldInput}
            placeholder={t<string>('profile.legalForm.tinNumber')}
          />
        </Box>
        <Divider />
        <Box sx={style.submitForm}>
          <Button
            disabled={!formState.isDirty || formState.isSubmitting || !formState.isValid}
            sx={style.submitButton}
            type='submit'
          >{t('profile.legalForm.submitTitle')}</Button>
        </Box>
      </Box>
    </Box>
  );
}

ProfileLegalForm.displayName = 'ProfileLegalForm';

export default ProfileLegalForm;
