import { Theme } from "@mui/material";

export const style = {
  namesLabel: {
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1rem',
  },
  errorText: {
    color: 'red',
    fontSize: '14px',
    marginTop: '-8px'
  },
  names: (nameError: string) => ({
    '.Mui-focused fieldset' : {
      border: `${!!nameError && '0 !important'}`
    },
    '.MuiOutlinedInput-notchedOutline' : {
      borderColor: '#dfe7f0',
    },
    border: `1px solid ${!!nameError ? 'red' : 'transparent'}`,
    borderRadius: '4px',
    input: {
      padding: '8.5px 14px',
    },
    '&:hover fieldset': {
      borderColor: '#2e79f7 !important',
    },
    width: '100%'
  }),
  field: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 2,
  },
  fieldLabel: {
    flexBasis: 'calc(50% - 8px)',
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: '0.8px',
    color: '#292f41',
    textTransform: 'uppercase',
  },
  fieldHelperText: {
    flexBasis: 'calc(50% - 8px)',
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: '0.8px',
    color: 'rgba(41, 47, 65, 0.5)',
    textAlign: 'end',
  },
  description: (theme: Theme) => ({
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: '0.2px',
    color: 'rgba(41, 47, 65, 0.5)',
    marginTop: theme.spacing(2),
  }),
  imageHelperText: (theme: Theme) => ({
    textAlign: 'start',
  }),
  fieldInput: {
    flexBasis: '100%',
    height: 'auto',
  },
  fieldRequired: {
    flexBasis: '100%',
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: '0.8px',
    color: 'rgba(41, 47, 65, 0.5)',
    textAlign: 'end',
  },
  form: (theme: Theme) => ({
    padding: theme.spacing(5, 4),
    display: 'flex',
    flexWrap: 'wrap',
    gap: 4,
  }),
  fields: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    maxWidth: 400,
  },
  addTitle: {
    alignSelf: 'flex-start',
  },
  addDescription: {
    alignSelf: 'flex-start',
  },
  adPreview: ((theme: Theme) => ({
    height: 800,
    borderRadius: 1.5,
    backgroundColor: '#f4f6fa',
    padding: theme.spacing(4, 3),
    width: 390,
    minWidth: 390,
  })),
  fileUpload: {
    position: 'relative',
    alignSelf: 'flex-start',
  },
  uploadInput: {
    position: 'absolute' as const,
    top: 0,
    left: 0,
    opacity: 0,
  },
  pictures: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  fileUploadError: {
    color: 'red',
    border: '1px solid red',
  },
  ctaFields: {
    display: 'flex',
    gap: 3,
  },
  title: {
    fontSize: 24,
    lineHeight: 1.17,
    letterSpacing: '-0.2px',
    color: '#292f41',
  },
  dynamicAds: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
  }),
  checkboxField: {
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
  },
  disapproved: {
    marginLeft: '8px',
    color: 'rgb(211, 47, 47)'
  },
  approved: {
    marginLeft: '8px',
    color: 'rgb(46, 125, 50)'
  },
}