import { createSlice } from '@reduxjs/toolkit';
import { IPaymentMethods, PaymentHistory } from "../../../types";

export interface PaymentState {
  isPaymentHistoryLoaded: boolean;
  refillBalanceHistoryData: PaymentHistory;
  paymentMethods: Array<IPaymentMethods>;
  isAllowMakePayment: boolean;
}

export const paymentSlice = createSlice({
  name: 'paymentStore',
  initialState: {
    isSpacePaymentsBindingLoaded: false,
    isPaymentHistoryLoaded: false,
    refillBalanceHistoryData: {
      current_page: 0,
      page_size: 10,
      total_size: 10,
      data: [],
    },
    paymentMethods: [],
    isAllowMakePayment: true,
  } as PaymentState,
  reducers: {
    setPaymentHistoryLoaded: (state, action) => ({
      ...state,
      isPaymentHistoryLoaded: action.payload,
    }),
    setSpacePaymentsBindingLoaded: (state, action) => ({
      ...state,
      isSpacePaymentsBindingLoaded: action.payload,
    }),
    setIsAllowMakePayment: (state, action) => ({
      ...state,
        isAllowMakePayment: action.payload,
    }),
    nullifySpacePayments: (state: any) => ({ ...state, refillBalanceHistoryData: {} }),
  },
});

export const { setSpacePaymentsBindingLoaded,nullifySpacePayments, setIsAllowMakePayment } = paymentSlice.actions;

export const selectPaymentMethods = (state: any) => state.paymentStore.paymentMethods;
export const selectIsAllowMakePayment = (state: any) => state.paymentStore.isAllowMakePayment;
export const selectIsSpacePaymentsBindingLoaded = (state: any) => state.paymentStore.isSpacePaymentsBindingLoaded;
export const selectPaymentHistoryLoaded = (state: any) => state.paymentStore.isPaymentHistoryLoaded;
export const selectDefaultPaymentId = (state: any) => {
  const defaultPaymentMethod = state.paymentStore.paymentMethods?.find(
    (method: any) => method.is_default === true
  );
  return defaultPaymentMethod?.id;
};
export const selectPaymentHistoryData = (state: any) => state.paymentStore.refillBalanceHistoryData;


export default paymentSlice.reducer;
