import { CampaignStatus } from "../../types";
import { Theme } from "@mui/material";

export const style = {
  circle: (statusKey: number) => ({
    width: '1rem',
    height: '1rem',
    minWidth: '1rem',
    borderRadius: '50%',
    border: '2px solid #fdfe7f0',
    /* eslint-disable */
    backgroundColor: statusKey == CampaignStatus.REJECTED ? '#e14b4b'
      : statusKey == CampaignStatus.RUNNING ? '#6cd387'
      : statusKey == CampaignStatus.IN_MODERATION ? '#ef902e'
      : 'gray',
    /* eslint-enable */
    boxShadow: 3,
  }),
  status: (theme: Theme) => ({
    padding: theme.spacing(5, 0),
    display: 'flex',
    alignItems: 'center',
  }),
  link: (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    textDecoration: 'none',
    marginTop: theme.spacing(4),
    textTransform: 'uppercase',
  }),
  arrow: {
    transform: 'rotate(270deg)',
  },
  statusText: (theme: Theme) => ({
    marginLeft: theme.spacing(2),
    color: '#292f41',
    fontWeight: 600,
    fontSize: 18,
  }),
  total: (theme: Theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
  }),
  totalInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
  },
  totalValue: {
    color: '#292f41',
    fontSize: '1.25rem',
    fontWeight: 600,
    lineHeight: '1.25rem',
  },
  headerTitle: (theme: Theme) => ({
    marginBottom: theme.spacing(2),
  }),
  allTimePerformance: {
    width: 286,
    maxHeight: 274,
    height: 'fit-content',
    marginLeft: 10,
    backgroundColor: '#f4f6fa',
  }
}