import React from 'react';

import { TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";

interface ICustomTableProps {
  translationPrefix: string;
  headCells: any[];
}

export const CoreTableHead = (props: ICustomTableProps) => {
  const { headCells, translationPrefix} = props;

  const { t } = useTranslation('translation', { keyPrefix: translationPrefix })

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => {
          return headCell.key === 'action' ? (
            <TableCell id={headCell.key} key={headCell.key} className="sticky-table-column position-right">
              <Typography color="text.primary" fontSize="14px" fontWeight="500" lineHeight="24px">
                {t(headCell.key)}
              </Typography>
            </TableCell>
          ) : (
            <TableCell
              key={headCell.key}
            >
              {t(headCell.key)}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
