import { createSlice } from '@reduxjs/toolkit';

const audienceInitialValues = {
  interactive: false,
  budget_type_key: 2,
  frequencyCap: false,
  setScheduling: false,
  device_type_key: 1,
  scheduling: {},
  targeting: {
    gender: 1,
    apps: {
      include: [],
      exclude: [],
      includeAny: [],
    },
    websites: {
      include: [],
      exclude: [],
      includeAny: [],
    },
    interests: {
      include: [],
      exclude: [],
      includeAny: [],
    },
    languages: {
      include: [],
      exclude: [],
    },
    fromAge: 18,
    toAge: 65,
    location_option_key: "1",
  },
  excluded_categories: [],
  custom_audience: [],
  placement: {
    device_type_key: 1,
    min_os_version: 1,
    max_os_version: 5,
  },
  frequency_cap: {
    1: "",
    2: "",
    3: "",
  },
  visuals: {
    adaptive: {},
  },
  allow_adaptive: true,
  optimization_type_key: 1,
};
export interface IAudienceState {
  audienceList: any;
  audienceListIsLoading: boolean;
  audienceResource: any;
  audienceResourceLoading: boolean;
}


export const audienceSlice = createSlice({

  name: "audienceStore",
  initialState: {
    audienceList: [],
    audienceListIsLoading: false,
    audienceResource: [],
    audienceResourceLoading: false,
  } as IAudienceState,
  reducers: {
    setAudienceResource: (state, action) => ({
      ...state,
      audienceResource: action.payload,
    }),
    setAudienceListIsLoading: (state, action) => ({
      ...state,
      audienceListIsLoading: action.payload,
    }),
    setAudienceResourceLoading: (state, action) => ({
      ...state,
      audienceResourceLoading: action.payload,
    }),
    setAudienceListData: (state, action) => ({
      ...state,
      audienceList: action.payload,
    }),
    nullifyAudienceResource: (state, action) => ({
      ...state,
      audienceResource: [],
    }),

  },
});

export const {
  setAudienceResource,
  setAudienceListIsLoading,
  setAudienceResourceLoading,
  setAudienceListData,
  nullifyAudienceResource,
} = audienceSlice.actions;

export const audienceListIsLoadingSelector = (state: any) => state.audienceStore.audienceListIsLoading;
export const audienceResourceSelector = (state: any) => state.audienceStore.audienceResource;
export const audienceResourceLoadingSelector = (state: any) => state.audienceStore.audienceResourceLoading;
export const audienceListSelector = (state: any) => state.audienceStore.audienceList;
export const initialValuesSelector = (state: any) => audienceInitialValues;


export default audienceSlice.reducer;
