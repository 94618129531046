const appObjectStore: { [key: string]: any } = {
  addObject(objectName: string, object: any) {
    this[objectName] = object;
  },

  removeObject(objectName: string) {
    this[objectName] = null;
  },

  setObject(objectName: string, object: any) {
    this[objectName] = object;
  },

  getObject(objectName: string) {
    return this[objectName];
  }
};

export {
  appObjectStore,
}