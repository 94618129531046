import { IAppsSitesSelectBoxFilterConfig } from "../types";
import { AD_SIZES, AD_TYPES, ENVIRONMENT, POSITION } from "./constants";

export const filtersConfig: IAppsSitesSelectBoxFilterConfig[] = [
  {
    name: 'category',
    labelKey: 'category',
    dataList: [],
  },
  {
    name: 'environment',
    labelKey: 'environment',
    dataList: ENVIRONMENT,
  },
  {
    name: 'creative_position',
    labelKey: 'position',
    dataList: POSITION,
  },
  {
    name: 'creative_type',
    labelKey: 'type',
    dataList: AD_TYPES,
  },
  {
    name: 'creative_size',
    labelKey: 'size',
    dataList: AD_SIZES,
  },
]