import { createSlice } from '@reduxjs/toolkit';
import { IUser, IUserAccount, IWhitelabel } from "../../../types";

export interface ISpaceState {
  currentBalance: number;
  user: null | IUser;
  account: IUserAccount;
  whitelabelData: null | IWhitelabel;
}

export const spaceSlice = createSlice({
  name: 'spaceStore',
  initialState: {
    currentBalance: 0,
    user: null,
    account: {},
    whitelabelData: null,
} as ISpaceState,
  reducers: {
    // setMainLoaderAction: (state, action) => {
    //   state.loading = action.payload;
    // },
    setCurrentBalance: (state, action) => ({
      ...state,
      currentBalance: action.payload
    }),
    setWhitelabelData: (state, action) => ({
      ...state,
      whitelabelData: action.payload,
    }),
  },
});

export const { setCurrentBalance, setWhitelabelData } = spaceSlice.actions;

export const currentBalanceSelector = (state: any) => state.spaceStore.currentBalance;

export default spaceSlice.reducer;
