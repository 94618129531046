import { DashboardData } from "../../types";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { formatDate } from "../../../global";

type Props = {
  data: Array<DashboardData>;
  currency: string | undefined;
  dateFormat: string;
}

function SpendingChart({ data, currency = '$', dateFormat }: Props) {
  return (
    <ResponsiveContainer width='100%' height={400}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tickFormatter={date => formatDate(date, dateFormat) as string} fontSize={14} />
        <YAxis tickFormatter={data => `${currency || '$'} ${data}`}/>
        <Bar dataKey="spent" fill="#3A77EB" />
        <Tooltip
          cursor={false}
          formatter={(value, name) =>  [`${currency} ${value}`, name]}
          itemStyle={{ textTransform: 'capitalize' }}
          labelFormatter={label => formatDate(label, dateFormat)}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

SpendingChart.displayName = 'SpendingChart';

export default SpendingChart;