export const style = {
  label: {
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 1,
    letterSpacing: 0.8,
    color: '#292f41',
    textTransform: 'uppercase',
  },
  uploadFile: (hasError: boolean) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#f4f6fa',
    borderRadius: 1,
    border: `solid 1px ${hasError ? 'red' : '#dfe7f0'}`,
    justifyContent: 'center',
    position: 'relative',
  }),
  uploadInput: {
    position: 'absolute' as const,
    top: 0,
    left: 0,
    opacity: 0,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
  icon: {
    stroke: '#2E6BDE',
    width: 32,
    height: 32,
  },
}