import { Theme } from "@mui/material";

export const style = {
  adPreview: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  adPreviewText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    textAlign: 'center',
  },
  header: (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: 'inset 0 -1px 0 0 #f4f6fa',
    padding: theme.spacing(0, 5),
  }),
  adPreviewType: {
    fontSize: 18,
    lineHeight: 1.33,
    color: '#292f41',
    width: 170,
  },
  headerActions: {
    display: 'flex',
  },
  previewIframe: {
    width: '100%',
    flexGrow: 2,
    overflowX: 'auto',
    display: 'flex',
    padding: 6,
  },
  iframeSize: (theme: Theme) => ({
    fontSize: 12,
    lineHeight: 1.33,
    letterSpacing: '0.17px',
    color: 'rgba(41, 47, 65, 0.5)',
    textAlign: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  }),
  zoomButton: {
    width: 40,
    height: 40,
  },
  paper: (theme: Theme) => ({
    width: '90%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    height: '90%',
    padding: theme.spacing(5, 0),
    display: 'flex',
    flexDirection: 'column',
  }),
  resizable: {
    overflow: 'auto',
    width: '100%',
    height: '100%',
    backgroundColor: '#f4f6fa',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginRight: 5,
  },
  resizeIcon: {
    width: 30,
    height: 30,
  },
  closeButton: {
    padding: 0,
  },
  size: (selected: boolean) => (theme: Theme) => ({
    fontSize: 14,
    color: '#292f41',
    lineHeight: 1.14,
    letterSpacing: '0.2px',
    padding: theme.spacing(4, 0, 4, 5),
    cursor: 'pointer',
    boxShadow: 'inset 0 -1px 0 0 #f4f6fa',
    backgroundColor: selected ? '#f4f6fa' : 'unset',
    borderRight: selected ? '8px solid #3a77eb' : 'unset',
  }),
  content: {
    display: 'flex',
    maxHeight: '90%',
    flex: 2,
  },
  sizes: {
    width: 250,
  },
  resizableHeader: (theme: Theme) => ({
    display: 'flex',
    alignSelf: 'flex-start',
    width: '100%',
    alignItems: 'center',
    padding: theme.spacing(4, 5),
    position: 'relative',
  }),
  resizer: {
    margin: 'auto',
  },
  resizerWrapper: {
    boxShadow: '0 6px 24px 0 rgba(0, 0, 0, 0.15)',
  },
  icon: (disabled: boolean) => ({
    opacity: disabled ? 0.1 : 1,
  }),
  actions: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  overflow: (theme: Theme) => ({
    overflow: 'auto',
    height: '100%',
    width: '100%',
    marginTop: theme.spacing(7),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  centerIframe: {
    flexDirection: 'column',
    display: 'flex',
    margin: 'auto',
  }
}