export type SubscriberFnParam = {
  message: string;
  severity: 'error' | 'success';
  autoHideDuration: number;
}

type SubscriberFn = (param: SubscriberFnParam) => void;

class NotificationService {
  static subscribers: SubscriberFn[] = [];

  static subscribe(subscriber: SubscriberFn) {
    NotificationService.subscribers.push(subscriber);
  }

  static unSubscribe(subscriber: SubscriberFn) {
    NotificationService.subscribers = NotificationService.subscribers.filter(sub => sub == subscriber); // eslint-disable-line
  }

  static handleError(message: string, autoHideDuration: number = 3000) {
    NotificationService.subscribers.forEach(fn => fn({
      message,
      severity: 'error',
      autoHideDuration,
    }));
  }

  static handleSuccess(message: string, autoHideDuration: number = 3000) {
    NotificationService.subscribers.forEach(fn => fn({
      message,
      severity: 'success',
      autoHideDuration,
    }));
  }
}

export {
  NotificationService,
}