import React, { Component } from "react";

export const modalTypes = {
  refillBalanceModal: "refillBalanceModal",
  withdrawModal: "withdrawModal",
  approveCampaignModal: "approveCampaignModal",
  rejectCampaignModal: "rejectCampaignModal",
  paymentsWithdrawHistoryModal: "paymentsWithdrawHistoryModal",
  dropInModal: "dropInModal",
  commentModal: "commentModal",
  transactionModal: "transactionModal",
  adminAccountsModal: "adminAccountsModal",
  adaptiveModal: "adaptiveModal",
  entityEditModal: "entityEditModal",
  archiveWarningModal: "archiveWarningModal",
};

interface IState {
  isOpen: boolean;
  modalType?: string;
  modalData?: any;
}

export const WithModal = (WrappedComponent: React.ComponentType<any>) => {
  return class EnhancedComponent extends Component<any, IState> {
    state = {
      isOpen: false,
      modalType: "",
      modalData: null,
    };

    componentDidMount() {
      document.addEventListener("click", this.handleClickOutSide);
      document.addEventListener("keyup", this.handleKeyUp);
    }

    componentWillUnmount() {
      document.removeEventListener("click", this.handleClickOutSide);
      document.removeEventListener("keyup", this.handleKeyUp);
    }

    handleKeyUp = (event: any) => {
      const { isOpen } = this.state;
      if (isOpen && event.keyCode === 27) {
        this.closeModal();
      }
    };

    openModal = ({ modalType = "", modalData = null }) => {
      this.setState({ modalType, modalData, isOpen: true });
    };

    closeModal = () => {
      this.setState({ isOpen: false, modalType: "", modalData: null });
    };

    handleClickOutSide = (event: any) => {
      const { isOpen } = this.state;
      if (isOpen && event.target.className === "overlay") {
        this.closeModal();
      }
    };

    toggle = () => {
      this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
    };

    render() {
      const { isOpen, modalType, modalData } = this.state;
      const { openModal, closeModal, toggle } = this;
      return (
        <WrappedComponent
          {...this.props}
          isOpen={isOpen}
          toggle={toggle}
          modalType={modalType}
          modalTypes={modalTypes}
          openModal={openModal}
          closeModal={closeModal}
          modalData={modalData}
        />
      );
    }
  };
};
