import { defineMessages } from "react-intl";

export default defineMessages({
  name: {
    id: "CampaignForm.name",
    defaultMessage: "name",
  },
  campaignName: {
    id: "CampaignForm.campaignName",
    defaultMessage: "campaign name",
  },
  type: {
    id: "CampaignList.type",
    defaultMessage: "type",
  },
  startDate: {
    id: "CampaignForm.startDate",
    defaultMessage: "start date",
  },
  startDatePlaceholder: {
    id: "CampaignForm.startDatePlaceholder",
    defaultMessage: "Immediately",
  },
  endDate: {
    id: "CampaignForm.endDate",
    defaultMessage: "end date",
  },
  budgetType: {
    id: "CampaignForm.budgetType",
    defaultMessage: "budget type",
  },
  bidAmount: {
    id: "CampaignForm.bidAmount",
    defaultMessage: "Bid Amount",
  },
  selectRange: {
    id: "CampaignForm.selectRange",
    defaultMessage: "Select a range",
  },
  age: {
    id: "CampaignForm.ageGroups",
    defaultMessage: "Age",
  },
  gender: {
    id: "CampaignForm.gender",
    defaultMessage: "gender",
  },
  target: {
    id: "CampaignForm.target",
    defaultMessage: "Targeting",
  },
  targetTooltip: {
    id: "CampaignForm.targetingTooltip",
    defaultMessage:
      "Targeting allows to direct your ad campaign towards the audiences most relevant for your product or service.",
  },
  frequencyAndScheduling: {
    id: "CampaignForm.frequencyAndScheduling",
    defaultMessage: "Frequency and Scheduling",
  },
  frequencyAndSchedulingTooltip: {
    id: "CampaignForm.frequencyAndSchedulingTooltip",
    defaultMessage:
      "Configure when and how many times you want your campaign to be displayed to the same visitor.",
  },
  interests: {
    id: "CampaignForm.interests",
    defaultMessage: "interests",
  },
  behaviors: {
    id: "CampaignForm.behaviors",
    defaultMessage: "behaviors",
  },
  languages: {
    id: "CampaignForm.languages",
    defaultMessage: "languages",
  },
  selectLanguages: {
    id: "CampaignForm.selectLanguages",
    defaultMessage: "Select Languages",
  },
  selectInterestsOrBrowse: {
    id: "CampaignForm.selectInterestsOrBrowse",
    defaultMessage: "Select Interests or Browse Manually",
  },
  selectBehaviorsOrBrowse: {
    id: "CampaignForm.selectBehaviorsOrBrowse",
    defaultMessage: "Select Behaviors",
  },
  selectLocationType: {
    id: "CampaignForm.selectLocationType",
    defaultMessage: "Select Location Type",
  },
  excludeInterests: {
    id: "CampaignForm.excludeInterests",
    defaultMessage: "Exclude Interests",
  },
  excludeBehaviors: {
    id: "CampaignForm.excludeBehaviors",
    defaultMessage: "Exclude Behaviors",
  },
  excludeLanguages: {
    id: "CampaignForm.excludeLanguages",
    defaultMessage: "exclude languages",
  },
  placaments: {
    id: "CampaignForm.placaments",
    defaultMessage: "Placements",
  },
  selectCategoriesToExclude: {
    id: "CampaignForm.selectCategoriesToExclude",
    defaultMessage: "SELECT WEBSITE CATEGORIES WHICH YOU WANT TO EXCLUDE",
  },
  blockWebsite: {
    id: "CampaignForm.blockWebsite",
    defaultMessage: "Block these websites to display on",
  },
  preferWebsite: {
    id: "CampaignForm.preferWebsite",
    defaultMessage: "Prefer these websites to display on",
  },
  deviceType: {
    id: "CampaignForm.deviceType",
    defaultMessage: "Devices and OPERATING systems to display on",
  },
  cpm: {
    id: "CampaignForm.cpm",
    defaultMessage: "CPM",
  },
  ctr: {
    id: "CampaignList.ctr",
    defaultMessage: "ctr",
  },
  clickLink: {
    id: "CampaignForm.clickLink",
    defaultMessage: "Click Link",
  },
  clickLinkTooltip: {
    id: "CampaignForm.clickLinkTooltip",
    defaultMessage:
      "The destination URL where the visitor will be redirected after clicking the Ad.",
  },
  adsTagging: {
    id: "CampaignForm.adsTagging",
    defaultMessage: "Attribution",
  },
  bidding: {
    id: "CampaignForm.bidding",
    defaultMessage: "Bidding",
  },
  clickLinkPlaceholder: {
    id: "CampaignForm.clickLinkPlaceholder",
    defaultMessage: "https://",
  },
  visuals: {
    id: "CampaignForm.visuals",
    defaultMessage: "VISUALS",
  },
  estimatedReach: {
    id: "CampaignForm.estimatedReach",
    defaultMessage: "ESTIMATED REACH",
  },
  people: {
    id: "CampaignForm.people",
    defaultMessage: "PEOPLE",
  },
  basicInfo: {
    id: "CampaignForm.basicInfo",
    defaultMessage: "Basic info",
  },
  noCampaignToShow: {
    id: "CampaignList.noCampaignToShow",
    defaultMessage: "There are no campaigns to show.",
  },
  noFilteredCampaignToShow: {
    id: "CampaignList.noFilteredCampaignToShow",
    defaultMessage: "There are no campaign matching your filter criteria.",
  },
  budget: {
    id: "CampaignList.budget",
    defaultMessage: "budget",
  },
  impression: {
    id: "CampaignList.impression",
    defaultMessage: "impressions",
  },
  clicks: {
    id: "CampaignList.clicks",
    defaultMessage: "clicks",
  },
  spend: {
    id: "CampaignList.spend",
    defaultMessage: "spend",
  },
  everyone: {
    id: "CampaignForm.everyone",
    defaultMessage: "Everyone",
  },
  automatic: {
    id: "CampaignForm.automatic",
    defaultMessage: "Automatic",
  },
  manual: {
    id: "CampaignForm.manual",
    defaultMessage: "Manual",
  },
  enterHeadline: {
    id: "CampaignForm.headline",
    defaultMessage: "HEADLINE",
  },
  enterDescription: {
    id: "CampaignForm.description",
    defaultMessage: "DESCRIPTION",
  },
  enterDescription2: {
    id: "CampaignForm.description2",
    defaultMessage: "DESCRIPTION",
  },
  enterCallToAction: {
    id: "CampaignForm.callToAction",
    defaultMessage: "CALL TO ACTION",
  },
  adExchanges: {
    id: "CampaignForm.adExchanges",
    defaultMessage: "Ad Exchanges",
  },
  companyName: {
    id: "CampaignForm.companyName",
    defaultMessage: "COMPANY NAME",
  },
  enterHeadlinePlaceholder: {
    id: "CampaignForm.headlinePlaceholder",
    defaultMessage: "Example: McEnroe swears by them © Nike",
  },
  enterDescriptionPlaceholder: {
    id: "CampaignForm.descriptionPlaceholder",
    defaultMessage:
      "Example: The best of Nike will now be delivered to your doorstep. © Nike",
  },
  enterDescription2Placeholder: {
    id: "CampaignForm.description2Placeholder",
    defaultMessage: "Customize it your way! © Nike",
  },
  enterCallToActionPlaceholder: {
    id: "CampaignForm.callToActionPlaceholder",
    defaultMessage: "Example: “Customize now”, “Learn More”, “Buy Now” etc.",
  },
  companyNamePlaceholder: {
    id: "CampaignForm.companyNamePlaceholder",
    defaultMessage: "Nike, Inc.",
  },
  enterHeadlineTooltip: {
    id: "CampaignForm.headlineTooltip",
    defaultMessage:
      "Write a headline to grab attention and create interest among potential customers.",
  },
  enterDescriptionTooltip: {
    id: "CampaignForm.descriptionTooltip",
    defaultMessage:
      "A compelling message describing the offering can be a good fit here.",
  },
  enterDescription2Tooltip: {
    id: "CampaignForm.description2Tooltip",
    defaultMessage:
      "A compelling offer or an evidence of benefits can be written here.",
  },
  enterCallToActionTooltip: {
    id: "CampaignForm.callToActionTooltip",
    defaultMessage:
      "Move the customer from the compelling offer to a direct action.",
  },
  maxCharPlaceHolder: {
    id: "CampaignForm.maxCharPlaceholder",
    defaultMessage: "{value} Characters maximum",
  },
  companyLogo: {
    id: "CampaignForm.companyLogo",
    defaultMessage: "Company Logo",
  },
  uploadFile: {
    id: "CampaignForm.uploadFile",
    defaultMessage: "Upload File",
  },
  saved: {
    id: "Notification.savedDescription",
    defaultMessage: "Your changes are successfully saved.",
  },
  createBlocklistPlaceholder: {
    id: "CampaignForm.createBlocklistPlaceholder",
    defaultMessage: "Input website links separated by comma",
  },
  uploadImage: {
    id: "CampaignForm.uploadImage",
    defaultMessage: "Upload Image",
  },
  adaptiveImage: {
    id: "CampaignForm.adaptiveImage",
    defaultMessage: "Adaptive Image",
  },
  previewTooltip: {
    id: "CampaignForm.previewTooltip",
    defaultMessage:
      "Preview ads or upload files manually for each size. We support jpg, png or a zip file, which includes index.html.",
  },
  adsTaggingPublisherUrlTooltip: {
    id: "CampaignForm.adsTaggingPublisherUrlTooltip",
    defaultMessage:
      "URL of the publisher website, where the ad was clicked on.",
  },
  adsTaggingBannerTypeTooltip: {
    id: "CampaignForm.adsTaggingBannerTypeTooltip",
    defaultMessage:
      "The type of banner, which was clicked on: Adaptive, Rich Media(HTML), Image.",
  },
  adsTaggingCampaignTypeTooltip: {
    id: "CampaignForm.adsTaggingCampaignTypeTooltip",
    defaultMessage: "The name of the campaign the clicked ad belongs to.",
  },
  adsTaggingBannerSizeTooltip: {
    id: "CampaignForm.adsTaggingBannerSizeTooltip",
    defaultMessage: "The size of the banner/placement, for example: 160x600.",
  },
  adsTaggingAnonymousIdTooltip: {
    id: "CampaignForm.adsTaggingAnonymousIdTooltip",
    defaultMessage:
      "Consistent and unique ID assigned to each visitor of the webpage.",
  },
  sentModeration: {
    id: "CampaignForm.sentModeration",
    defaultMessage: "Your campaign has been sent for moderation!",
  },
  campaignUpdated: {
    id: "CampaignForm.campaignUpdated",
    defaultMessage: "Your campaign has been successfully updated!",
  },
  status: {
    id: "CampaignStatus.status",
    defaultMessage: "Status",
  },
  adaptiveContentTooltip: {
    id: "CampaignForm.adaptiveContentTooltip",
    defaultMessage:
      "Adaptive ads allow to support all ad sizes in a fast and easy way as the content adjusts to any placement size. If you turn off adaptive ads and leave some sizes without visual, you will have less bids available for your campaign.",
  },
  campaigns: {
    id: "Sidebar.campaigns",
    defaultMessage: "campaigns",
  },
  globalError: {
    id: "APIResponseFailGlobal",
    defaultMessage: "Sorry, Something went wrong, please try again later.",
  },
  campaignApproved: {
    id: "CampaignForm.campaignApproved",
    defaultMessage: "Campaign has been approved!",
  },
  campaignRejected: {
    id: "CampaignForm.campaignRejected",
    defaultMessage: "Campaign has been rejected!",
  },
  estimatedDailyResultsTooltip: {
    id: "CampaignForm.estimatedDailyResultsTooltip",
    defaultMessage:
      "All numbers are only estimates. Numbers shown are based on the target segment, sizes covered, CPM set.",
  },
  estimatedImpressionsTooltip: {
    id: "CampaignForm.estimatedImpressionsTooltip",
    defaultMessage:
      "By covering all the visual sizes and setting the CPM to its maximum, this number will project the possible portion of impressions for your campaign. This is only an estimate.",
  },
  audienceSizeTooltip: {
    id: "Audience.audienceSizeTooltip",
    defaultMessage:
      "To estimate the audience size we use Age, Gender and Interests chosen in the Targeting settings.",
  },
  campaingVisualsMissing: {
    id: "CampaignForm.campaingVisualsMissing",
    defaultMessage: "Upload visuals before publishing your campaign.",
  },
  uploadAnImage: {
    id: "CampaignForm.uploadAnImage",
    defaultMessage:
      "Please, upload an image (450x450 px) in the Adaptive Content section",
  },
  endDateErrorMessage: {
    id: "CampaignForm.endDateErrorMessage",
    defaultMessage:
      "You cannot publish a campaign with a start date or end date in the past. Edit the dates and Publish your campaign again.",
  },
  notificationWarning: {
    id: "Notification.warning",
    defaultMessage: "Warning",
  },
  notificationInsufficientBalance: {
    id: "Notification.insufficientBalance",
    defaultMessage: "Insufficient Balance",
  },
  accountBudgetIsBelow: {
    id: "CampaignForm.insufficientBalance",
    defaultMessage:
      "Your account balance is insufficient. Please, refill your balance to publish this campaign",
  },
  actionModerationRun: {
    id: "CampaignStatus.actionModerationRun",
    defaultMessage:
      "This campaign is in moderation. You will be able to start or pause it after moderators approve it.",
  },
  actionWaitingToStart: {
    id: "CampaignStatus.actionWaitingToStart",
    defaultMessage:
      "{campaignName} is set to start on {startDate}. Do you want to start it now?",
  },
  actionRejectedTitle: {
    id: "CampaignStatus.actionRejectedTitle",
    defaultMessage: "This campaign is rejected",
  },
  actionRejected: {
    id: "CampaignStatus.actionRejected",
    defaultMessage:
      "{campaignName} is rejected due to {reason}. Edit the campaign details or contact support.",
  },
  actionRunning: {
    id: "CampaignStatus.actionRunning",
    defaultMessage: "{campaignName} is paused.",
  },
  actionPaused: {
    id: "CampaignStatus.actionPaused",
    defaultMessage: "{campaignName} is running.",
  },
  notice: {
    id: "Notification.notice",
    defaultMessage: "Notice",
  },
  actionFinishedBudgetSpent: {
    id: "CampaignStatus.actionFinishedBudgetSpent",
    defaultMessage:
      "This campaign has reached its budget limit. Add budget to start it again.",
  },
  actionAccountBalanceSpent: {
    id: "CampaignStatus.actionAccountBalanceSpent",
    defaultMessage:
      "Your account budget is insufficient to start this campaign. Please, refill your balance.",
  },
  actionFinishedOverdue: {
    id: "CampaignStatus.actionFinishedOverdue",
    defaultMessage:
      "This campaign has reached its end date and has been finished.",
  },
  actionModerationRunTitle: {
    id: "CampaignStatus.actionModerationRunTitle",
    defaultMessage: "WAIT UNTIL CAMPAIGN IS APPROVED",
  },
  somethingWentWrong: {
    id: "Exception.somethingWentWrong",
    defaultMessage:
      "Something went wrong. Try reloading the page or contact support",
  },
  sorry: {
    id: "Exception.sorry",
    defaultMessage: "We Are Sorry",
  },
  bidAmountTooltip: {
    id: "CampaignForm.bidAmountTooltip",
    defaultMessage:
      "Actual spent may vary based on the traffic fluctuations, however you will not be charged more than the daily amount inputted.",
  },
  targetOn: {
    id: "CampaignForm.targetOn",
    defaultMessage: "Target Segment",
  },
  targetOff: {
    id: "CampaignForm.targetOff",
    defaultMessage: "All People",
  },
  optimizeFor: {
    id: "CampaignForm.optimizeFor",
    defaultMessage: "Optimize For:",
  },
  optimizeForTooltip: {
    id: "CampaignForm.optimizeForTooltip",
    defaultMessage:
      "Optimize performance based on your campaign objectives by choosing impressions, clicks or conversions as your primary goal.",
  },
  optimizeConversionTooltip: {
    id: "CampaignForm.optimizeConversionTooltip",
    defaultMessage:
      "Conversion optimization requires additional parameters. Contact support to get more info.",
  },
  optimizeBiddingTooltip: {
    id: "CampaignForm.optimizeBiddingTooltip",
    defaultMessage:
      "The bidding mechanism allows you to bid the price you prefer. Average CPM is set automatically based on the current active ads and placements available. You can set it manually.",
  },
  optimizeImpressionTooltip: {
    id: "CampaignForm.optimizeImpressionTooltip",
    defaultMessage:
      "Impressions estimation is not available for Optimized campaigns yet. You can track your results when the campaign is running.",
  },
  wrongURLHeader: {
    id: "CampaignForm.wrongURLHeader",
    defaultMessage: "MISSING CAMPAIGN CLICK LINK",
  },
  createNewCampaign: {
    id: "Campaign.createNewCampaign",
    defaultMessage: "Create New Campaign",
  },
  wrongURLDescription: {
    id: "CampaignForm.wrongURLDescription",
    defaultMessage:
      "To publish the campaign, input the Click Link in Basic Info.",
  },
  banner: {
    id: "Campaign.banner",
    defaultMessage: "Banner",
  },
  bannerDescription: {
    id: "Campaign.bannerDescription",
    defaultMessage:
      "Banner ad is embedded onto a webpage and can be served in various sizes and formats.",
  },
  catfish: {
    id: "Campaign.catfish",
    defaultMessage: "Catfish",
  },
  catfishDescription: {
    id: "Campaign.catfishDescription",
    defaultMessage:
      "Catfish ad appears on top or bottom of a webpage as a full-width ads and remains on the screen until the visitor closes it.",
  },
  adaptive: {
    id: "CampaignForm.adaptive",
    defaultMessage: "Adaptive",
  },
  html: {
    id: "CampaignForm.html",
    defaultMessage: "HTML",
  },
  interactive: {
    id: "Campaign.interactive",
    defaultMessage: "Interactive",
  },
  interactiveDescription: {
    id: "Campaign.interactiveDescription",
    defaultMessage:
      "Interactive banner ad is a rich media ad format which delivers highly interactive creatives across the platforms.",
  },
  fullScreen: {
    id: "Campaign.Fullscreen",
    defaultMessage: "Fullscreen",
  },
  fullScreenDescription: {
    id: "Campaign.FullScreenDescription",
    defaultMessage:
      "Full Screen Ad is a banner which appears on the website screen and remains until the visitor closes it.",
  },
  inPageVideo: {
    id: "Campaign.inPageVideo",
    defaultMessage: "In-page Video",
  },
  inPageVideoDescription: {
    id: "Campaign.inPageVideoDescription",
    defaultMessage:
      "In-page video ads appear between the text paragraphs. As the visitor scrolls the page, the video ad sticks to the bottom of the page.",
  },
  archiveCampaignTitle: {
    id: "CampaignArchive.Title",
    defaultMessage: "Archive Campaign",
  },
  archiveCampaignDescription: {
    id: "CampaignArchive.Description",
    defaultMessage:
      "This campaign is {status}. Archiving will change its status to 'Archived'. You will be able to view this campaign by filtering Archived campaigns from the Status selector on the Campaigns page.",
  },
  archiveButtonLabel: {
    id: "CampaignArchive.Archive",
    defaultMessage: "Archive",
  },
  recoverCampaignTitle: {
    id: "CampaignArchive.recoverTitle",
    defaultMessage: "Unarchiving Campaign?",
  },
  recoverCampaignDescription: {
    id: "CampaignArchive.recoverDescription",
    defaultMessage: "This campaign will appear on the Campaigns page.",
  },
  archiveNotificationSuccess: {
    id: "Notification.archiveSuccess",
    defaultMessage: "The campaign is archived!",
  },
  recoverNotificationSuccess: {
    id: "Notification.recoverSuccess",
    defaultMessage: "The campaign is unarchived!",
  },  
  audienceName: {
    id: "CampaignList.audienceName",
    defaultMessage: "Audience Name",
  },
  adTitle: {
    id: "CampaignForm.adTitle",
    defaultMessage: "Enter ad title"
  },
  adDescription: {
    id: "CampaignForm.adTitle",
    defaultMessage: "Enter ad description",
  },
  adBusinessName: {
    id: "CampaignForm.adBusinessName",
    defaultMessage: "Business name",
  },
  adCTAText: {
    id: "CampaignForm.adCTAText",
    defaultMessage: "CTA text",
  },
  responsiveAds: {
    id: "CampaignForm.responsiveAds",
    defaultMessage: "Responsive Ads",
  },
});


