import client from './api';
import axios, { RawAxiosRequestHeaders } from "axios";

type AppendedHeaders = {
  Authorization?: string;
  'Content-Type'?: string;
}

const newAxiosInstance = axios.create();
delete newAxiosInstance.defaults.headers.common['Authorization'];

class ApiService {
  static cache: Record<string, any> = {};

  static get(url: string, head: any = {}) {
    if (ApiService.cache[url]) {
      return Promise.resolve(ApiService.cache[url]);
    }
    const headers: AppendedHeaders = {};
    try {
      return client({...headers, ...head}).get(url);
    } catch (err) {
      throw err;
    }
  }

  static put<T>(url: string, body: T | null = null, headers: HeadersInit = {}, authorized = true) {
    const transformedHeaders = {
      'Content-Type': 'application/json',
      ...headers,
    };
    try {
      return client(transformedHeaders as RawAxiosRequestHeaders).put(url, body);
    } catch (err) {
      throw err;
    }
  }

  static post(url: string, body: any | null = null, headers: HeadersInit = {}, authorized = true, stringify = true) {
    let appended: AppendedHeaders = {};
    let requestBody: string | FormData = (body as FormData);
     // eslint-disable-next-line
    const fileUpload = headers['Content-Type'] === 'text/csv';
    if (!(body instanceof FormData) && stringify) {
      appended['Content-Type'] = 'application/json';
      requestBody = JSON.stringify(body);
    }
    const transformedHeaders = {
      ...headers,
      ...appended,
    };
    try {
      return client(transformedHeaders as RawAxiosRequestHeaders).post(url, requestBody);
    } catch (err) {
      throw err;
    }
  }


  static baseGet(url: string, authorized = true, cache = false) {
    if (ApiService.cache[url]) {
      return Promise.resolve(ApiService.cache[url]);
    }
    try {
      return newAxiosInstance.get(window.REACT_APP_BASE_URL + url);
    } catch (err) {
      throw err;
    }
  }

  static basePost(url: string, body: any | null = null, headers: any = {}, authorized = true, stringify = true) {
    try {
      return newAxiosInstance.post(window.REACT_APP_BASE_URL + url, body, { headers: {...headers} });
    } catch (err) {
      throw err;
    }
  }

  static basePut<T>(url: string, body: T | null = null, headers: HeadersInit = {}, authorized = true) {
    const config: any = {
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      }
    };
    try {
      return newAxiosInstance.put(url, body, config);
    } catch (err) {
      throw err;
    }
  }

  static delete(url: string, headers: HeadersInit = {}, authorized = true) {
    const transformedHeaders = {
      'Content-Type': 'application/json',
      ...headers,
    };

    try {
      return client(transformedHeaders as RawAxiosRequestHeaders).delete(url);
    } catch (err) {
      throw err;
    }
  }

  static transformDate(date: Date | undefined) {
    if (date) {
      let months: string | number = date.getMonth() + 1;
      months = months < 10 ? `0${months}` : months;
      let day: string | number = date.getDate();
      day = day < 10 ? `0${day}` : day;
      return `${date.getFullYear()}-${months}-${day}`;
    }
    return null;
  }
}

export {
  ApiService,
}