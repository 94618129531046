import { AnyAction, combineReducers, Reducer } from 'redux';

import authenticationStore from './authentication';
import adminStore from './admin';
import audienceStore from './audience';
import campaignStore from './campaign';
import dialogStore from './dialog';
import dictionaryStore from './dictionary';
import paymentStore from './payment';
import spaceStore from './space';
import transactionsStore from './transactions';
import { reducer as form } from "redux-form";


export const appReducer = combineReducers({
  authenticationStore,
  adminStore,
  audienceStore,
  campaignStore,
  dialogStore,
  dictionaryStore,
  paymentStore,
  spaceStore,
  transactionsStore,
  form,
});

export type RootState = ReturnType<typeof appReducer>;

export const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  let newState: RootState = state;

  if (action.type === 'LOGOUT') {
    newState = {} as RootState;
  }

  return appReducer(newState, action);
};
