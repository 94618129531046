import { Theme } from "@mui/material";

export const style = {
  label: {
    textTransform: 'uppercase',
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: '.8px',
    color: '#292f41',
    marginLeft: 2,
  },
  pasteGTM: (theme: Theme) => ({
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: '0.2px',
    color: '#292f41',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(3),
  }),
  customHTML: {
    '&&': {
      background: '#fff',
      '& textarea.Mui-disabled:disabled': {
        fontSize: 14,
        position: 'relative',
        webkitTextFillColor: '#292f41 !important',
      },
      '& > div': {
        padding: 3,
      }
    }
  },
  copyIcon: {
    width: 36,
    height: 36,
    cursor: 'pointer',
  },
  multipleLabelsField: {
    gap: 2,
  },
  adornment: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: 36,
    height: 36,
  },
  radioGroupLabel: {
    marginBottom: 2,
  },
  divider: (theme: Theme) => ({
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
  }),
  radioLabel: {
    fontSize: 14,
    color: '#292f41',
    letterSpacing: '0.2px',
    lineHeight: 1.14,
  },
  radio: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
  radioGroup: {
    flexDirection: 'row',
    gap: 11,
    rowGap: 0,
  },
  websitesForm: (theme: Theme) => ({
    padding: theme.spacing(0, 12),
    width: '100%',
  }),
  field: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1.5,
  },
  helperText: {
    fontSize: 11,
    lineHeight: 1.09,
    color: 'rgba(41, 47, 65, 0.5)',
    marginLeft: 2,
  },
  withTooltip: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
  },
  fields: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  switchField: {
    display: 'flex',
    gap: 3,
  },
  comingSoon: {
    color: 'rgba(41, 47, 65, 0.5)',
    fontSize: 12,
    lineHeight: 1.33,
    letterSpacing: '0.17px',
    fontStyle: 'italic',
  },
  rowFields: {
    display: 'flex',
    gap: 3,
  },
  autocompleteField: {
    flexBasis: '50%',
  },
  app: (theme: Theme) => ({
    backgroundColor: '#E7E7EC',
    borderRadius: 1,
    flexGrow: 2,
    height: 40,
    alignSelf: 'flex-end',
    padding: theme.spacing(2, 2, 2, 3),
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    width: 'calc(50% - 8px)',
  }),
  appName: {
    fontSize: 12,
    color: '#292f41',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  crossIcon: {
    width: 30,
    height: 30,
    cursor: 'pointer',
  },
  bundleID: {
    width: 'calc(50% - 4px)',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  }
}