import moment from "moment";

export const stringToDate = (str: string | Date) => {
  return moment(str).isValid() ? new Date(str) : new Date();
}

export const formatDate = (date: Date | string, format: string) => {
  return moment(date).format(format)
}
export const formatDateToDMY = (date: Date | string) => {
  const format: string = 'MMM DD, YYYY';
  return moment(date).format(format)
}

export const weekAgo = () => {
  const date = new Date();
  return new Date(date.getTime() - 60 * 60 * 24 * 7 * 1000);
}