import { Box, Button, Divider, Tooltip, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { style } from './style';
import React from "react";
import { useTranslation } from "react-i18next";
import { InfoIcon, IntFieldController } from "../../../global";
import { yupResolver } from "@hookform/resolvers/yup";
import { getProfileRewardedAdsFormSchema } from "../../utils";
import { IUser } from "../../../../types";
import { ProfileService } from "../../services";
import { RewardedAdFormFields } from "../../types";
import { NotificationService } from "../../../global/services";

type Props = {
  user: IUser;
}

function VoiceCPMMultiplierForm({ user }: Props) {

  const defaultValues = {
    currency: user.currency,
    rewarded_ads_cpm_rate: user.rewarded_ads_cpm_rate || 1,
  }

  const {
    handleSubmit,
    control,
    formState,
    reset,
  } = useForm<RewardedAdFormFields>({
    resolver: yupResolver(getProfileRewardedAdsFormSchema()) as any,
    defaultValues,
    mode: 'onChange',
  });

  const { t } = useTranslation();

  async function onSubmit(values: RewardedAdFormFields) {
    const response = await ProfileService.updateUserRewardedAdsInfo({
      ...values,
      userId: user.id
    });

    if (response.data.type?.code === 0) {
      NotificationService.handleSuccess(t('profile.legalForm.savedSuccessfully'));
      reset({
        rewarded_ads_cpm_rate: response.data.data.rewarded_ads_cpm_rate || 1,
      });
    }
  }


  return (
    <Box component='form' sx={style.form} onSubmit={handleSubmit(onSubmit)}>
      <Typography sx={style.formTitle}>{t('profile.voiceAdForm.title')}</Typography>
      <Divider />
      <Box>
        <Box sx={style.field}>
          <Typography sx={[style.fieldLabel, style.tinNumber]}>{t('profile.voiceAdForm.currency')}</Typography>
          <IntFieldController
            name='currency'
            control={control}
            sx={style.fieldInput}
            placeholder={t<string>('profile.voiceAdForm.tinNumber')}
            disabled
          />
        </Box>
        <Divider />
        <Box sx={style.field}>
          <Box sx={style.fieldLabelWrapper}>
            <Typography sx={style.fieldLabel}>{t('profile.voiceAdForm.voiceAdCPMMultiplier')}</Typography>
            <Tooltip title={t('profile.voiceAdForm.voiceAdCPMMultiplierTooltip')}>
              <InfoIcon />
            </Tooltip>
          </Box>
          <IntFieldController
            name='rewarded_ads_cpm_rate'
            control={control}
            sx={style.fieldInput}
          />
        </Box>
        <Divider />
        <Box sx={style.submitForm}>
          <Button
            disabled={!formState.isDirty || formState.isSubmitting || !formState.isValid}
            sx={style.submitButton}
            type='submit'
          >{t('profile.legalForm.submitTitle')}</Button>
        </Box>
      </Box>
    </Box>
  );
}

VoiceCPMMultiplierForm.displayName = 'ProfileLegalForm';

export default VoiceCPMMultiplierForm;
