import { REFRESH_TOKEN } from "../_constants";
import {
  ISignUpUser,
  IUser,
  IUserPasswordChange,
  IUserLegalEntity,
} from "../types";
import { ApiService } from "../modules/global/services";

export const getUserRequest = (spaceID?:string) => {
  if (!spaceID) {
    spaceID = sessionStorage.getItem('spaceID') || '';
  }
  if (spaceID) {
    return ApiService.get(`/v1/user/current?space_id=${spaceID}`);
  }
  return ApiService.get(`/v1/user/current`);
};

export const getUserByIdRequest = (id:string) => ApiService.get(`/v1/user/${id}`);


export const registerRequest = (data: ISignUpUser) =>
  ApiService.basePost("/v1/registration", data);

export const completeInvitationRequest = (data: IUser) =>
  ApiService.basePut("/v1/user/invite-completion", data);

export const confirmRegistrationRequest = (data: IUser) =>
  ApiService.basePost("/v1/registration/complete", data);

export const getRegistrationRequest = (id: string) =>
  ApiService.basePost(`/v1/registration/${id}`);

export const initForgotPasswordRequest = (email: string) =>
  ApiService.basePost("/v1/user/reset-password/init", email, {
    "Content-Type": "text/plain",
  });

export const finishForgotPasswordRequest = (data: any) =>
  ApiService.basePost("/v1/user/reset-password/finish", data);

export const checkRegistrationTokenRequest = (token: string) =>
  ApiService.basePost(`/v1/registration/verify-token/${token}`);

export const checkInvitationTokenRequest = (token: string) =>
  ApiService.basePost(`/v1/space/invitation-verification/${token}`);

export const updateUserRequest = (data: IUser) =>
  ApiService.put("/v1/user", data);

export const changePasswordRequest = (data: IUserPasswordChange) =>
  ApiService.post("/v1/user/change-password", data);

export const getCurrentUserAccountRequest = () =>
  ApiService.get("/v1/user/current/account");

export const setCurrentUserAccountRequest = (data: IUser) =>
  ApiService.put("/v1/user/account/settings", data);

export const changeLanguageRequest = (data: IUser) =>
  ApiService.put("/v1/user/change-language", data);

export const updateUserLegalEntityRequest = (data: IUserLegalEntity) =>
  ApiService.put("/v1/user/legal", data);

export const changeSpaceRequest = (spaceId: string) => {
  const formData = [];
  formData.push(
    `${encodeURIComponent("grant_type")}=${encodeURIComponent("refresh_token")}`
  );
  formData.push(
    `${encodeURIComponent("refresh_token")}=${encodeURIComponent(
      localStorage.getItem(REFRESH_TOKEN)
    )}`
  );

  return ApiService.basePost(`/v1/oauth/switch-space/${spaceId}`,
    formData.join("&"),
    {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  );
};
