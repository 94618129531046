import { useEffect, useState } from "react";
import { Box, InputAdornment, TextField, Tooltip, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { abbreviateNumber, InfoIcon } from "../../../global";
import CheckIcon from "@mui/icons-material/Check";
import BlockRoundedIcon from "@mui/icons-material/BlockRounded";
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";
import { Control, SetFieldValue, useWatch } from "react-hook-form";
import "./style.scss";

interface IProps {
  data: any[];
  control: Control<any>;
  setValue: SetFieldValue<any>;
}

type TSelectState = 'UNSELECTED' | 'WHITELIST' | 'BLACKLIST';

const resultViewer = (result: any[]) => {
  return result.length > 4 ? result.slice(0, 3) : result;
}

const AppsSitesCategoriesSelectBox = (props: IProps) => {
 const {
   data,control, setValue} = props;

  const { t } = useTranslation('translation', {
    keyPrefix: 'campaign.placement',
  });

  const [includedAppCategories, excludedAppCategories] = useWatch({
    control,
    name: ['placement.included_app_categories', 'placement.excluded_app_categories'],
  });

  const [selectState, setSelectState] = useState<TSelectState>('UNSELECTED');
  const [openAppBox, setOpenAppBox] = useState<boolean>(false);
  const [searchedData, setSearchedData] = useState<any[]>([]);
  const [result, setResult] = useState<any[]>([]);

  const handleSearch = (value: any) => {
    !openAppBox && setOpenAppBox(true);
    setSearchedData(data.filter(item => item.label.toLowerCase().includes(value.toLowerCase())));
  };

  const handleSelect = (selected: any, type: TSelectState) => {
    const _searchedDat = searchedData.filter(item => item.value !== selected.value)
    const _result = [...result];
    _result.push(selected);
    setSearchedData(_searchedDat);
    setResult(_result);
    setSelectState(type)
  };

  const handleDelete = (selected: any) => {
    const _result = result.filter(item => item.value !== selected.value)
    const _searchedData = [...searchedData];
    _searchedData.push(selected);
    setSearchedData(_searchedData);
    setResult(_result);
    !_result.length && setSelectState('UNSELECTED');
  };

  useEffect(() => {
    if(data?.length){
      const _data = [...data];
      const selectedList = [...excludedAppCategories, ...includedAppCategories];
      setSearchedData(_data.filter(item => selectedList.every(item2 => item2.value !== item.value)))
    }
    // eslint-disable-next-line
  }, [data])

  useEffect(() => {
    if(includedAppCategories?.length){
      setSelectState('WHITELIST')
    } else if (excludedAppCategories?.length) {
      setSelectState('BLACKLIST')
    }
    setResult([ ...excludedAppCategories, ...includedAppCategories])
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
   switch(selectState) {
     case 'WHITELIST' : {
       setValue('placement.included_app_categories', result);
       break;
     }
     case 'BLACKLIST' : {
       setValue('placement.excluded_app_categories', result);
       break;
     }
     default : {
       setValue('placement.excluded_app_categories', []);
       setValue('placement.included_app_categories', []);
     }
    }
    // eslint-disable-next-line
  }, [result])

  return (
    <Box className={'apps-sites-categories-select-box'}>
      <TextField
        onClick={() => setOpenAppBox(!openAppBox)}
        className={'apps-search-input border-color-E6E9ED'}
        onChange={(event) => handleSearch(event.target.value)}
        InputProps={{
          startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
        }}
      />
      {openAppBox ? (
        <Box className={'app-select-wrap'}>
          <Box className={'app-select-header'}>
            <Box
              className={'app-select-header-icons-delete'}
              onClick={() => setOpenAppBox(false)}
            >
              <CloseIcon />
            </Box>
          </Box>

          <Box className={'app-select-body'}>
            <Box className={'app-select-body-left'}>
              {searchedData.map((item) =>  (
                  <Box key={item.value} className={'app-select-body-left-item'}>
                    <Typography title={item.label} className={'app-select-body-left-item-label'}>
                    {item.label}
                    </Typography>
                    {item.impression_count ? (
                      <>
                        <Typography className={'app-select-body-left-item-text'}>
                          {abbreviateNumber(item.impression_count)}
                        </Typography>
                        <Tooltip title={'dailyAmountTooltip'}>
                          <InfoIcon />
                        </Tooltip>
                      </>) : null
                    }
                    <Box className={'app-select-body-left-item-select-icons'}>
                      {selectState === 'UNSELECTED' || selectState === 'BLACKLIST' ? (
                      <Box
                      className={'app-select-body-left-item-select-icons-block'}
                      onClick={() => handleSelect(item, 'BLACKLIST')}
                      >
                        <BlockRoundedIcon />
                      </Box>) : null}
                    {selectState === 'UNSELECTED' || selectState === 'WHITELIST' ? (
                      <Box
                        className={'app-select-body-left-item-select-icons-check'}
                        onClick={() => handleSelect(item, 'WHITELIST')}
                      >
                        <CheckIcon color={"success"} />
                      </Box>) : null}
                    </Box>

                  </Box>
                ))}
            </Box>
            <Box className={'app-select-body-right'}>
              {result.length ? (
                <Box className={'app-select-body-right-header'}>
                  <Typography>
                    {t(selectState)}
                  </Typography>
                </Box>) : null
              }
              <Box className={'app-select-body-right-body'}>
                {
                  result.map((item) => (
                    <Box key={item.value} className={'app-select-body-right-item'}>
                      <Typography title={item.label} className={'app-select-body-left-item-label'}>
                        {item.label}
                      </Typography>
                      {item.impression_count ? (
                        <>
                          <Typography className={'app-select-body-left-item-text'}>
                            {abbreviateNumber(item.impression_count)}
                          </Typography>
                          <Tooltip title={'dailyAmountTooltip'}>
                            <InfoIcon />
                          </Tooltip>
                        </>) : null
                      }
                      <Box className={'app-select-body-right-item-select-icons'}>
                        <Box
                          className={'app-select-body-right-item-select-icons-delete'}
                          onClick={() => handleDelete(item)}
                        >
                          <CloseIcon />
                        </Box>
                      </Box>
                    </Box>
                  ))
                }
              </Box>
            </Box>
          </Box>
        </Box>
      ) : result.length ? (
        <Box className={'chips-view-box'}>
          <Box className={'chips-view-box-label'}>
            {`${t(selectState)}:`}
          </Box>
          <Box className={'chip-container'}>
            { resultViewer(result).map(item => (
              <Typography key={item.value} title={item.label} className={`chip ${result.length > 4 ? 'moreChips' : ''}`}>
                {item.label}
              </Typography>
            ))
            }
            {result.length > 4 ? (
              <Typography onClick={() => setOpenAppBox(true)} className={'view-more'}>
              {`+${ result.length - 3 }`}
              </Typography>) : null}
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}

export default AppsSitesCategoriesSelectBox;

