import React, { useEffect, useState } from "react";
import { CampaignListName, TableNotFound } from "../../../../../../components";
import { ISpace } from "../../../../../../types";
import { injectIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { NEW_ROUTES } from "../../../../../index";
import AdminService from "../../../../services/admin.service";
import {
  setAdminNewSpaceRequest,
} from "../../../../../../api";
import {
  selectCurrentUserSpaceId,
  selectCurrentUserSpaces,
  setSpace,
} from "../../../../../store/reducers/authentication";
import { setHasCampaign } from "../../../../../store/reducers/campaign";
import { useDispatch, useSelector } from "react-redux";
import { CoreTable } from "../../../../../global/components/core-table";
import CampaignService from "../../../../../campaign/services/campaign.service";


const headCells = [
  {key: 'name', parser: (data: any) => data || "" }
];

const reqData: any = {
  date:{
    from_date: "2024-09-30T15:43:13.885Z",
    to_date: "2024-10-07T15:43:13.885Z",
    datePresetValue: {
      from_date: "2024-09-30T15:43:13.885Z",
      to_date: "2024-10-07T15:43:13.885Z"
    },
    key: "7_DAYS"
  },
  search: "",
  status: [],
  page: 1,
  dateCustomRange: false,
  dateLabel: "",
  from_date: "2024-09-30",
  to_date: "2024-10-07",
  status_filter: [1]
}


const AdminModerationCampaigns = () => {

  const [moderationCampaigns, setModerationCampaigns] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const spaces = useSelector(selectCurrentUserSpaces);
  const currentSpaceId = useSelector(selectCurrentUserSpaceId);


  const renderNotfound = () => {
    return (
      <TableNotFound
        titleId="Admin.accountsNotFoundTitle"
        titleDefaultMessage="No Accounts found"
        descriptionId="Admin.accountsNotFoundDescription"
        descriptionDefaultMessage="Try adjusting filters or choosing different date range."
      />
    );
  };


  const setAdminNewSpace = (id: string, callBack ) => {
    setAdminNewSpaceRequest(id).then( async ({data}) => {
      if(data?.type?.code === 0) {
        const {currency, id} = data.data;
        const space: ISpace =
          Object.values(spaces)
            .flat()
            .find((s: any) => s.id === id) || {};

        dispatch(setSpace({...space, ...data.data}));

        if (!currentSpaceId) {
          dispatch(setHasCampaign(false));
        } else {
          const countRes = await CampaignService.getSpaceCampaignCountRequest(currentSpaceId)
          dispatch(setHasCampaign(!!countRes.data.data));
        }
        sessionStorage.setItem('spaceID', id);
        sessionStorage.setItem('currentAccountCurrency', currency);
      }
      setTimeout( () => {
        callBack && callBack()
      }, )
    })
  }

  const getData = (reqData) => {
    setIsLoading(true);

    AdminService.getModerationCampaigns(reqData).then(({data}) => {

      const _moderationCampaigns = data.data.data.map(
        ({ name, id, favorite, status_key, campaign_start_at, space_id }) => {
          return {
            name: (
              <div
                onClick={() => {
                  setTimeout(() => {
                    setAdminNewSpace(space_id, () => {
                      history.replace({
                        pathname: NEW_ROUTES.campaign.edit.onEdit(id as string),
                      });
                    });
                  });
                }}
              >
                <CampaignListName
                  name={name}
                  favorite={favorite}
                  status_key={status_key}
                  campaign_start_at={campaign_start_at}
                />
              </div>
            ),
          };
        }
      );

      setModerationCampaigns({
        data: _moderationCampaigns,
        total_size: data.data.total_size,
        page_size: data.data.page_size,
        current_page: data.data.current_page,
        isLoading: false,
      });
      setIsLoading(false);
    })
  };

  const onPageChange = (newPage: number) => {
    getData({...reqData, page: newPage });
  }

  useEffect(() => {
    getData(reqData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <CoreTable
        data={moderationCampaigns}
        translationPrefix={'admin.moderation.campaignList'}
        loading={isLoading}
        headCells={headCells}
        hasPagination={true}
        noData={renderNotfound()}
        onPageChange={(page) => onPageChange(page)}
        onRowClick={(row) => undefined}
      />
    </div>
  );

}

export default injectIntl(AdminModerationCampaigns);
