import { Switch, SwitchProps } from "@mui/material";
import { style } from './style';
import { Ref } from "react";

type Props = Omit<Omit<SwitchProps, 'value'>, 'onChange'> & {
  value?: boolean;
  inputRef?: Ref<HTMLInputElement>;
  errorMessage?: string;
  onChange: (val: boolean) => void;
};

function IntSwitch({
  sx,
  value,
 errorMessage,
 inputRef,
  onChange,
  ...props
}: Props) {
  return (
    <Switch
      sx={Array.isArray(sx) ? [style.switch, ...sx] : [style.switch, sx]}
      ref={inputRef}
      checked={value}
      onClick={e => e.stopPropagation()}
      onChange={(e, val) => onChange(val)}
      {...props}
    />
  )
}

IntSwitch.displayName = 'IntSwitch';

export default IntSwitch;