import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reset } from "redux-form";
import { FormattedMessage } from "react-intl";

import "./style.scss";
import { REPORTS_FORM } from "../../../_constants";
import { Button } from "@mui/material";

interface IProps {
  reset: Function;
}

class ReportDetailNotFound extends Component<IProps> {
  handleResetFilters = () => {
    const {
      props: { reset },
    } = this;
    reset(REPORTS_FORM);
  };

  render() {
    const { handleResetFilters } = this;
    return (
      <div className="report-details-not-found">
        <div className="report-details-not-found-logo" />
        <div className="report-details-not-found-title">
          <FormattedMessage
            id="DashboardChartSection.reportDetailNotFound"
            defaultMessage="No data available for your selected filter parameters. Please try different settings."
          />
        </div>
        <div className="report-details-not-found-description">
          <Button
            variant={'outlined'}
            onClick={handleResetFilters}
          >{'Reset Filters'}</Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (dispatch: any) => ({
  reset: bindActionCreators(reset, dispatch),
});

export default connect(null, mapStateToProps)(ReportDetailNotFound);
