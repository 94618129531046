import { ApiService } from "../modules/global/services";

export const getCurrentSpaceBalanceRequest = (id: string) =>
  ApiService.get(`/v1/space/balance/${id}`);

export const getCurrentSpaceRequest = (id: string) =>
  ApiService.get(`/v1/space/${id}`);

export const getCurrentSpaceUserDataRequest = (id: string) =>
  ApiService.get(`/v1/user/${id}`);

export const getCurrentSpaceUserAccountDataRequest = (id: string) =>
  ApiService.get(`/v1/user/account/${id}`);
