import { Theme } from "@mui/material";

export const style = {
  statusFilter: {
    width: 250,
    background: '#fff',
  },
  dateRange: {
    // width: 250,
    float: 'right',
    marginRight: '30px !important',
    '& > div': {
      height: '36px',
      '& > div': {
        border: 'none !important',
        minWidth: '130px',
        paddingLeft: 0,
      }
    }
  },
  inputField: {
    width: 250,
  },
  filter: (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4),
    marginBottom: theme.spacing(8),
    flexWrap: 'wrap',
  }),
  add: {
    minWidth: '55px',
    height: '36px',
    float: 'right',
    borderRadius: 5,
    alignSelf: 'center',
    boxShadow: 'none',
    backgroundColor: '#326AFC',

    '& span': {
      margin: 0,
    }
  },
  addIcon: {
    stroke: '#fff',
    fontSize: '24px',
  },
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'background.paper',
    borderRadius: 3,
    boxShadow: '0 12px 8px -10px rgba(0, 0, 0, 0.12)',
    width: 886,
    height: 528,
  },
  exportCSV: {
    marginLeft: 'auto',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.14,
    letterSpacing: '0.2px',
    color: '#3a77eb',
    cursor: 'pointer',
  },
}