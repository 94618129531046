import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import {no_audience} from '../../assets'
import "./style.scss";
import { goToRouteLinkWithId } from "../../modules";
import { Button } from "@mui/material";

interface IProps {
  titleId: string;
  titleDefaultMessage: string;
  descriptionId: string;
  descriptionDefaultMessage: string;
  buttonLabelId?: string;
  link?: string;
  id?: string;
}

const NotFoundData = ({
  titleId,
  titleDefaultMessage,
  descriptionId,
  descriptionDefaultMessage,
  buttonLabelId,
  link,
  id,
}: IProps) => {
  return (
    <div className="table-not-found">
      <div className="table-not-found-logo" >
        <img src={no_audience} alt="no_audience" />
      </div>
      <div className="table-not-found-title">
        <FormattedMessage id={titleId} defaultMessage={titleDefaultMessage} />
      </div>
      <div className="table-not-found-description">
        <FormattedMessage
          id={descriptionId}
          defaultMessage={descriptionDefaultMessage}
        />
      </div>
      { !!link ?
        <Link to={id ? goToRouteLinkWithId(link, id) : link}>
          <Button>
            <FormattedMessage
              id={buttonLabelId}
              defaultMessage={'Go To'}
            />
          </Button>
        </Link> : null }
    </div>
  );
};

export default NotFoundData;
