import { numberToStringWithCommas } from "../../../../modules";
import moment from "moment";

export const invoicesHeadConfig: IReportHeadCell[] = [

  {
    key: 'status',
    parser: (field) => field || 'n/a',
  },
  {
    key: 'total',
    parser: (field) => numberToStringWithCommas(field || 0),
  },
  {
    key: 'charge_from_date',
    parser: (field) => moment(field).format('DD MMMM yyyy HH:mm'),
  },
  {
    key: 'charge_to_date',
    parser: (field) => moment(field).format('DD MMMM yyyy HH:mm'),
  },
];
