import { createSlice } from '@reduxjs/toolkit';

export interface IDialogState {
  open: boolean;
  title: any;
  description: any;
  onConfirm?: Function;
  data: any;
  closeModlaText: string;
  confirmText: string;
  closeDialogCallback: Function | null;
}

const initialState :IDialogState = {
  open: false,
  title: null,
  description: null,
  // onConfirm: () => undefined,
  data: {},
  closeModlaText: "ButtonLabel.cancel",
  confirmText: "ButtonLabel.ok",
  closeDialogCallback: null,
};

export const dialogSlice = createSlice({
  name: 'dialogStore',
  initialState,
  reducers: {
    closeDialog: () => initialState,
    setDialogData: (state, action) => ({
      ...state,
      ...action.payload,
     }),
  },
});

export const { closeDialog, setDialogData } = dialogSlice.actions;

export const dialogStateSelector = (state: any) => state.dialogStore;

export default dialogSlice.reducer;
