import { Box, Button, Typography } from "@mui/material";
import { style } from './style';
import { useTranslation } from "react-i18next";
import { IntCheckbox, IntFieldController, IntSelect } from "../../../global";
import { Control } from "react-hook-form";
import { useSelector } from "react-redux";
import { FormEvent } from "react";
import { termsOfServiceLinkSelector } from "../../../store/reducers/admin";

type Props = {
  control: Control<any>;
  disabled: boolean;
  onSubmit: (event: FormEvent<HTMLDivElement>) => void;
}

function SignupForm({ control, disabled, onSubmit }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'auth.signup' });

  const termsOfServiceURL = useSelector(termsOfServiceLinkSelector);

  const CURRENCY_LIST = [
    { value: 'USD', label: t('currency.usd') },
    { value: 'NGN', label: t('currency.ngn') },
  ];

  //TODO remove
  const isNG = window.location.host.includes('ng-app');

  return (
    <Box component='form' sx={style.form} onSubmit={onSubmit}>
      <Box sx={style.field}>
        <Typography sx={style.fieldLabel}>{t('companyName')}</Typography>
        <IntFieldController
          control={control}
          name='companyName'
          sx={style.fieldInput}
          placeholder={t<string>('companyName')}
        />
      </Box>
      <Box sx={style.field}>
        <Typography sx={style.fieldLabel}>{t('firstName')}</Typography>
        <IntFieldController
          control={control}
          name='firstName'
          sx={style.fieldInput}
          placeholder={t<string>('firstName')}
        />
      </Box>
      <Box sx={style.field}>
        <Typography sx={style.fieldLabel}>{t('lastName')}</Typography>
        <IntFieldController
          control={control}
          name='lastName'
          sx={style.fieldInput}
          placeholder={t<string>('lastName')}
        />
      </Box>
      <Box sx={style.field}>
        <Typography sx={style.fieldLabel}>{t('phoneNumber')}</Typography>
        <IntFieldController
          control={control}
          name='phoneNumber'
          sx={style.fieldInput}
          placeholder={t<string>('phoneNumber')}
        />
      </Box>
      <Box sx={style.field}>
        <Typography sx={style.fieldLabel}>{t('email')}</Typography>
        <IntFieldController
          control={control}
          name='email'
          sx={style.fieldInput}
          placeholder={t<string>('email')}
        />
      </Box>
      <Box sx={style.field}>
        <Typography sx={style.fieldLabel}>{t('preferredCurrency')}</Typography>
        <IntFieldController
          control={control}
          name='currency'
          Component={IntSelect}
          options={CURRENCY_LIST}
          disabled={!isNG}
        />
      </Box>
      <Box sx={style.tos}>
        <IntFieldController
          control={control}
          name='agreeWithTos'
          Component={IntCheckbox}
        />
        <Typography sx={style.tosLabel}>
          {t('acceptTerms')}
          <Box component='a' href={termsOfServiceURL} target='_blank' sx={style.termsOfServiceLink}>
            {t('termsOfService')}
          </Box>
          *
        </Typography>
      </Box>
      <Button
        variant='contained'
        sx={style.createAccount}
        type='submit'
        disabled={disabled}
      >{t('createAccount')}</Button>
    </Box>
  );
}

SignupForm.displayName = 'SignupForm';

export default SignupForm;