import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import "./style.scss";

import { getAdminAccountSearchResultRequest, getUserByIdRequest } from "../../../../../../api";
import { selectCurrentUser } from "../../../../../../modules/store/reducers/authentication";
import { TextField } from "@mui/material";
import { IAdminSearchResultAccount, IUser } from "../../../../../../types";
import { AdminAccount } from "../../../../../../components";
import { FormattedMessage } from "react-intl";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import { NotificationService } from "../../../../../global/services";

let timeoutId;

interface IProps {
  onUserSelect: (account: any) => void;
}

const AdminUserSearch = (props: IProps) => {

  const {onUserSelect} = props;
  const ref = useRef(null);
  const { id } = useParams<{ id?: string; }>();

  const currentUser: IAdminSearchResultAccount & IUser = useSelector(selectCurrentUser);
  const [query, setQuery] = useState<string>("");
  const [, setCurrency] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<any[]>([]);

  const myAccount = !!accounts.length && accounts.find(({ user_id }) => user_id === currentUser.id);

  const getAdminSearchResult = (value: string, state?: string) => {
    getAdminAccountSearchResultRequest({ name: value }).then(({data}) => {
      if(data?.type?.code === 0) {
        if(state === 'INIT' && id) {
          const selected = data.data.filter(item => item.space_id === id);
          if(selected.length){
            setQuery(selected[0].space_name);
            setAccounts(selected)
          }
        } else {
          setAccounts(data.data)
        }
      }
    })
  }

  const onQueryChange = (e: any) => {
    setQuery(e.target.value);
    if(!e.target.value){
      setOpen(false);
      onUserSelect(null);
      setCurrency("")
    } else {
      setOpen(true);
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        getAdminSearchResult(e.target.value);
      }, 300);
    }
  };

  const onAdminChange = (account: any ) => () => {
    onUserSelect(account);
    setOpen(false);
    setQuery(account.space_name);
    setCurrency(account.currency);
    setAccounts([]);
  };

  useEffect(() => {
    if(id) {
      getAdminSearchResult("", "INIT");
      getUserByIdRequest(id).then(({data}) => {
       if(data.type.code === 0 && !!data.data) {
         const name = `${data.data.first_name} ${data.data.last_name}`
         setQuery(name)
         onUserSelect({space_name: name, currency: data.data.currency, space_id: data.data.id});
       }else{
         NotificationService.handleError(data?.type?.message);
       }
      });
    }
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="admin-user-content" ref={ref}>
      <div className={'admin-user-search-input-wrap'}>
        <TextField
          onClick={() => {getAdminSearchResult(query ? query : ""); setOpen(true)}}
          className={'admin-user-search-input'}
          value={query}
          autoComplete='off'
          onChange={onQueryChange}
          placeholder={`Find Account...`}
        />
        {!!query && <div onClick={(e) => {
          e.stopPropagation();
          setQuery("");
          setOpen(false);
          onUserSelect(null);
        }} className={'close'}> <CloseIcon/></div>}
      </div>
      {open ? (
        <div>
          <div className="admin-user-search-container">
            <ul className="admin-search-list">
              {myAccount && (
                <AdminAccount
                  account={myAccount}
                  key={myAccount?.space_id}
                  onAdminChange={() => onAdminChange(myAccount)}
                  isAdmin
                />
              )}

              {!!accounts.length ? (
                accounts.map(account => (
                  <AdminAccount
                    account={account}
                    key={account?.space_id}
                    onAdminChange={() => onAdminChange(account)}
                  />
                ))
              ) : (
                <div className="admin-user-not-found">
                  <FormattedMessage
                    id="Admin.adminAccountsNotFound1"
                    defaultMessage="User not found"
                  />
                </div>
              )}
            </ul>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default AdminUserSearch;
