/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 8/14/2023
 * All Rights Reserved.
 */

import { Theme } from "@mui/material";

export const style = {
  navigation: (theme: Theme) => ({
    position: 'fixed',
    left: 89,
    top: 89,
    overflowY: 'auto',
    height: '100%',
    width: 260,
    backgroundColor: 'rgba(2, 18, 48, 0.72)',
    padding: theme.spacing(12, 0),
  }),
  titleBox: (theme: Theme) => ({
    marginLeft: theme.spacing(8),
  }),
  title: {
    fontSize: 20,
    lineHeight: '1.4',
    color: 'rgba(255, 255, 255, 0.9)',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '90%',
  },
  tab: (theme: Theme) => ({
    fontSize: 14,
    lineHeight: '1.14',
    letterSpacing: '.8px',
    color: 'rgba(255, 255, 255, 0.9)',
    textTransform: 'uppercase',
    padding: theme.spacing(4, 9, 4, 7),
    cursor: 'pointer',
  }),
  tabWrapper: {
    borderLeft: '9px solid transparent',
  },
  advanced: (theme: Theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    alignItems: 'center',
    borderLeft: '9px solid transparent'
  }),
  arrowIcon: (expanded: boolean) => ({
    '& path': {
      stroke: '#E1E7ED',
    },
    transform: `rotate(${expanded ? '180deg' : '0deg'})`,
    transition: 'transform .3s',
    width: 48,
    height: 48,
  }),
  collapsed: {
    backgroundColor: '#303a4f',
  },
  tabs: (theme: Theme) => ({
    minHeight: '500px',
    // marginTop: theme.spacing(12),
  }),
  selectedTab: {
    borderColor: '#3a77eb',
    transition: 'background-color .3s',
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
  },
  audience: {
    position: 'fixed',
    bottom: 0,
    width: '260px',
  },
  content: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 35px 30px 35px',
    height: '165px',
    backgroundColor: '#48546a'
  }),
  label: {
    padding: '4px 0',
    fontSize: 11,
    color: '#FFFFFF',
    // letterSpacing: '0.8px',
    lineHeight: 1.3,
    textTransform: 'uppercase',
    // opacity: 0.75,
    // marginTop: 3,
  },
  audienceSize: {
    fontSize: 32,
    fontWeight: 400,
    lineHeight: '40px',
    // letterSpacing: '1.2px',
    color: '#FFFFFF',
    height: '40px'
    // textAlign: 'center'
    // marginTop: 2,
    // marginBottom: 5,
  },
  impressionsSize: {
    fontSize: 32,
    fontWeight: 400,
    lineHeight: '40px',
    // letterSpacing: '1.2px',
    color: '#FFFFFF',
    height: '40px'
    // textAlign: 'center'
  },
  divider: {
    borderColor: '#d8d8d8',
    opacity: 0.2,
  },
  line: {
    marginTop: '16px',
    height: '2px',
    display: 'flex',
    width: '100%',
  },
  sizeInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
  audienceType: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 2,
  },
  audienceTypeText: {
    fontSize: 12,
    color: 'rgba(255, 255, 255, 0.9)',
    letterSpacing: '0.8px',
    lineHeight: 1,
  },
  part: {
    width: '20%',
    backgroundColor: '#dfe7f0',
  },
  red: {
    backgroundColor: 'red',
  },
  green: {
    backgroundColor: '#87e09e',
  },
  yellow: {
    backgroundColor: '#fac76d',
  },
  underReview: {
    fontSize: 11,
    fontWeight: 'bold',
    lineHeight: 1.09,
    color: 'rgba(255, 255, 255, 0.9)',
    letterSpacing: '0.73px',
  },
  underReviewBox: {
    width: 98,
    height: 18,
    borderRadius: 1.5,
    backgroundImage: 'linear-gradient(260deg, #fac76d 99%, #ef902e 2%)',
    display: 'flex',
    alignItems: 'center',
    marginTop: 1,
    justifyContent: 'center',
  }
}