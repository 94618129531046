import { defineMessages } from "react-intl";

const messages: any = defineMessages({
  sectionPersonalTitle: {
    id: "Profile.sectionPersonalTitle",
    defaultMessage: "Personal",
  },
  sectionSecrurityTitle: {
    id: "Profile.sectionSecrurityTitle",
    defaultMessage: "Secrurity",
  },
  firstName: {
    id: "Auth.firstname",
    defaultMessage: "First Name",
  },
  lastName: {
    id: "Auth.lastName",
    defaultMessage: "Last Name",
  },
  phone: {
    id: "Auth.phone",
    defaultMessage: "phone",
  },
  email: {
    id: "Auth.email",
    defaultMessage: "email",
  },
  fieldRequired: {
    id: "Validation.fieldRequired",
    defaultMessage: "Required field",
  },
  invalidEmail: {
    id: "Validation.invalidEmail",
    defaultMessage: "Please provide valid email address",
  },
  isNumbers: {
    id: "Validation.isNumbers",
    defaultMessage: "Phone is not valid",
  },
  personal: {
    id: "Settings.personal",
    defaultMessage: "PERSONAL",
  },
  currentPassword: {
    id: "Profile.currentPassword",
    defaultMessage: "Current Password",
  },
  newPassword: {
    id: "Profile.newPassword",
    defaultMessage: "New Password",
  },
  repeatNewPassword: {
    id: "Profile.repeatNewPassword",
    defaultMessage: "Repeat New Password",
  },
  profileSettings: {
    id: "Profile.changeSuccessMessage",
    defaultMessage: "Profile Settings",
  },
  changeSuccessDescription: {
    id: "Profile.changeSuccessDescription",
    defaultMessage: "All changes have been saved successfully",
  },
  changeFailMessage: {
    id: "Profile.changeFailMessage",
    defaultMessage: "Failed to save changes",
  },
  changeFailDescription: {
    id: "MyAccount.changeFailDescription",
    defaultMessage: "Sorry, something went wrong",
  },
  passwordInsecure: {
    id: "Validation.passwordInsecure",
    defaultMessage:
      "Password should be at least 8 characters and contain 1 each of the following characters types:  number, uppercase, and special symbols.",
  },
  passwordNoMatch: {
    id: "Validation.passwordNomatch",
    defaultMessage: "Passwords do not match",
  },
  profileTitle: {
    id: "Profile.title",
    defaultMessage: "Profile",
  },
  eventType: {
    id: "MyAccount.notificationEventType",
    defaultMessage: "Event Type",
  },
  notificationEmail: {
    id: "MyAccount.notificationEmail",
    defaultMessage: "Email Notifications",
  },
  notificationType1: {
    id: "MyAccount.notificationType1",
    defaultMessage: "When campaign is rejected",
  },
  notificationType2: {
    id: "MyAccount.notificationType2",
    defaultMessage: "When campaign runs out of budget",
  },
  notificationType3: {
    id: "MyAccount.notificationType3",
    defaultMessage: "When campaign end date is reached",
  },
  notificationType4: {
    id: "MyAccount.notificationType4",
    defaultMessage: "When account runs out of budget",
  },
  notificationType5: {
    id: "MyAccount.notificationType5",
    defaultMessage: "When campaign is approved",
  },
  notificationType6: {
    id: "MyAccount.notificationType6",
    defaultMessage: "When account budget reaches $ 1000 threshold",
  },
  notificationType7: {
    id: "MyAccount.notificationType7",
    defaultMessage: "When a new account is registered",
  },
  notificationType8: {
    id: "MyAccount.notificationType8",
    defaultMessage: "When a new campaign is published",
  },
  responseTitle: {
    id: "MyAccount.notificationResponseTitle",
    defaultMessage: "Notification settings",
  },
  responseSuccess: {
    id: "MyAccount.notificationResponseSuccess",
    defaultMessage: "Notification settings have been saved",
  },
  responseFail: {
    id: "MyAccount.notificationResponseFail",
    defaultMessage: "Failed to save notification settings",
  },
  logoLabel: {
    id: "MyAccount.customizeLogoLabel",
    defaultMessage: "Company logo",
  },
  logoInfo: {
    id: "MyAccount.customizeLogoInfo",
    defaultMessage:
      "Supported formats: PNG, SVG. Minimum size: 158px x 32 px, Maximum size: 316px x 64px, 158px x 32px",
  },
  sidebarBackgroundLabel: {
    id: "MyAccount.customizeSidebarBackgroundLabel",
    defaultMessage: "Sidebar Bakcground",
  },
  sidebarBackgroundInfo: {
    id: "MyAccount.customizeLogoSidebarInfo",
    defaultMessage:
      "Supported formats: JPEG. Minimum size: 256px x 800px, Maximum size: 512px x 1600px",
  },
  authBackgroundLabel: {
    id: "MyAccount.customizeAuthBackgroundLabel",
    defaultMessage: "Authentication background",
  },
  authBackgroundInfo: {
    id: "MyAccount.customizeAuthBackgroundInfo",
    defaultMessage:
      "Supported formats: JPEG. Minimum size: 1440 x 800px, Maximum size: 2880px x 1800px",
  },
  popupBackgroundLabel: {
    id: "MyAccount.customizePopupBackgroundLabel",
    defaultMessage: "Popup Header",
  },
  popupBackgroundInfo: {
    id: "MyAccount.customizePopupBackgroundInfo",
    defaultMessage:
      "Supported formats: JPEG. Minimum size: 480 x 108px, Maximum size: 960px x 216px",
  },
  emailHeaderLabel: {
    id: "MyAccount.customizeEmailHeaderBackgroundLabel",
    defaultMessage: "Email header",
  },
  emailHeaderInfo: {
    id: "MyAccount.customizeEmailHeaderBackgroundInfo",
    defaultMessage:
      "Supported formats: JPEG. Minimum size: 480 x 108px, Maximum size: 960px x 216px",
  },
  emailFooterLabel: {
    id: "MyAccount.customizeEmailHeaderBackgroundLabel",
    defaultMessage: "Email footer",
  },
  emailFooterInfo: {
    id: "MyAccount.customizeEmailHeaderBackgroundInfo",
    defaultMessage:
      "Supported formats: JPEG. Minimum size: 480 x 108px, Maximum size: 960px x 216px",
  },
  faviconLabel: {
    id: "MyAccount.customizeFaviconLabel",
    defaultMessage: "Favicon",
  },
  faviconInfo: {
    id: "MyAccount.customizeFaviconInfo",
    defaultMessage:
      "Supported formats: PNG. Minimum size: 158px x 32 px, Maximum size: 316px x 64px",
  },
  facebookPageIdLabel: {
    id: "MyAccount.customizeFacebookPageIdLabel",
    defaultMessage: "Facebook Page ID",
  },
  facebookLoggedInGreetingLabel: {
    id: "MyAccount.customizefacebookLoggedInGreetingLabel",
    defaultMessage: "Facebook logged in greeting",
  },
  facebookLoggedOutGreetingLabel: {
    id: "MyAccount.customizeFacebookLoggedOutGreetingLabel",
    defaultMessage: "Facebook logged out greeting",
  },
  color1: {
    id: "MyAccount.customizeColor1",
    defaultMessage: "Color 1",
  },
  color2: {
    id: "MyAccount.customizeColor2",
    defaultMessage: "Color 2",
  },
  color3: {
    id: "MyAccount.customizeColor3",
    defaultMessage: "Color 3",
  },
  color4: {
    id: "MyAccount.customizeColor4",
    defaultMessage: "Color 4",
  },
  registrationDate: {
    id: "Auth.registrationDate",
    defaultMessage: "Registration date",
  },
  contactPerson: {
    id: "Auth.contactPerson",
    defaultMessage: "Contact Person",
  },
  name: {
    id: "Auth.name",
    defaultMessage: "Name",
  },
  companyName: {
    id: "Auth.companyName",
    defaultMessage: "Company Name",
  },
  roleModerator: {
    id: "Auth.roleModerator",
    defaultMessage: "Moderator",
  },
  role: {
    id: "Auth.role",
    defaultMessage: "Role",
  },
  legalEntityName: {
    id: "Auth.legalEntityName",
    defaultMessage: "Legal Entity Name (in Armenian)",
  },
  legalEntityNameTooltip: {
    id: "Auth.legalEntityNameTooltip",
    defaultMessage: "The legal, registered name of the Company.",
  },
  legalEntityNamePlaceholder: {
    id: "Auth.legalEntityNamePlaceholder",
    defaultMessage: "«Իմ Կազմակերպությունը» ՍՊԸ",
  },
  tinNumber: {
    id: "Auth.tinNumber",
    defaultMessage: "Tin Number (ՀՎՀՀ) ",
  },
  legalEntityInfoPending: {
    id: "Auth.legalEntityInfoPending",
    defaultMessage:
      "Your contract information is processing. It may take a while. Try to come back in a couple of minutes to refill your balance or contact support.",
  },
  termOfServiceRequired: {
    id: "Auth.termOfServiceRequired",
    defaultMessage: "Please, read and accept the Terms of Service.",
  },
  legalEntityNameNotArmenianValidation: {
    id: "Auth.legalEntityNameNotArmenianValidation",
    defaultMessage: "Please, input the legal name of your Company in Armenian",
  },
  submitApplicationDescription: {
    id: "Auth.submitApplicationDescription",
    defaultMessage: "Get the most of your ad campaigns!",
  },
  status: {
    id: "Auth.status",
    defaultMessage: "Status",
  },
  waitingUserConfirm: {
    id: "Auth.waitingUserConfirm",
    defaultMessage: "Waiting until user confirms e-mail",
  },
  customizeTermsOfService: {
    id: "MyAccount.customizeTermsOfService",
    defaultMessage: "Terms of Service",
  },
  customizePrivacyPolicy: {
    id: "MyAccount.customizePrivacyPolicy",
    defaultMessage: "Privacy Policy",
  },
  customizeAdLogo: {
    id: "MyAccount.customizeAdLogo",
    defaultMessage: "Ad Logo",
  },
  customizeAdLogoInfo: {
    id: "MyAccount.customizeAdLogoInfo",
    defaultMessage:
      "Supported formats: PNG, SVG. Size: 98px x 32px. The logo must NOT be transparent and must have grey background with white text/symbols.",
  },
  customizeInvalidValidUrl: {
    id: "MyAccount.customizeInvalidValidUrl",
    defaultMessage: "Please, enter a valid URL.",
  },
});

export default messages;
