import { createContext, useContext } from "react";

const ClientInfoContext = createContext({
  client: 'dsp',
});

const useClientInfoProvider = () => {
  return useContext(ClientInfoContext);
}

export { ClientInfoContext, useClientInfoProvider };