/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 8/14/2023
 * All Rights Reserved.
 */
import { Box, Typography } from "@mui/material";
import { style } from './style';

type Props = {
  title: string;
  description: string;
  selected?: boolean;
  onClick: () => void;
}

function CampaignGoalCard({ title, description, selected = false, onClick }: Props) {
  return (
    <Box sx={[style.card, selected && style.selectedCard]} onClick={onClick}>
      <Typography sx={style.title}>{title}</Typography>
      <Typography sx={style.description}>{description}</Typography>
      <Box sx={[style.circle, selected && style.selectedCircle]} />
    </Box>
  )
}

CampaignGoalCard.displayName = 'CampaignGoalCard';

export default CampaignGoalCard;