/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 12/14/2023
 * All Rights Reserved.
 */
import { Box, SxProps, Theme, Typography } from "@mui/material";
import { AddIcon, TickIcon } from "../../Icons";
import { style } from './style';
import { ChangeEvent } from "react";

type Props = {
  label?: string;
  classes?: {
    root?: SxProps<Theme>;
    label?: SxProps<Theme>;
    icon?: SxProps<Theme>;
    tickIcon?: SxProps<Theme>;
  };
  hasError?: boolean;
  accept?: string;
  uploaded?: boolean;
  onFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

function IntUploadFile({
  label = 'Upload File',
  accept = 'image/*',
  onFileChange,
  classes = {},
  uploaded = false,
  hasError = false,
}: Props) {
  const rootSx = Array.isArray(classes?.root) ? [style.uploadFile(hasError), ...classes.root]
    : [style.uploadFile(hasError), classes?.root || null];
  const addIconSx = Array.isArray(classes?.icon) ? [style.icon, ...classes.icon]
    : [style.icon, classes.icon || null];
  const labelSx = Array.isArray(classes?.label) ? [style.label, ...classes.label]
    : [style.label, classes.label || null];
  const tickIconSx = Array.isArray(classes?.tickIcon) ? [style.icon, ...classes.tickIcon]
    : [style.icon, classes.tickIcon || null];

  return (
    <Box sx={rootSx}>
      {uploaded ? (
        <TickIcon sx={tickIconSx} />
      ) : <AddIcon sx={addIconSx} />}
      <Typography sx={labelSx}>{label}</Typography>
      <input
        type='file'
        style={style.uploadInput}
        onChange={onFileChange}
        accept={accept}
      />
    </Box>
  )
}

IntUploadFile.displayName = 'IntUploadFile';

export default IntUploadFile;