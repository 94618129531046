/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 9/1/2023
 * All Rights Reserved.
 */
import { Box, Button, Divider, InputAdornment, Radio, TextField, Tooltip, Typography } from "@mui/material";
import { style } from "./style";
import { CopyIcon, InfoIcon, IntFieldController, IntRadioGroup, IntSelect, SmallCrossIcon } from "../../../../global";
import { useTranslation } from "react-i18next";
import { Control, SetFieldValue, useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import { NotificationService } from "../../../../global/services";
import { CampaignObjective } from "../../../types";
import { generateWebsitePromotionCustomHTML } from "../../../utils";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { CampaignService } from "../../../services";
type Props = {
  control: Control<any>;
  setValue: SetFieldValue<any>;
  errors: any;
}
type App = {
  icon: string;
  name: string;
}

function WebsitesForm({ control, setValue, errors }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'campaign.goal' });
  const [customHTML, setCustomHTML] = useState(generateWebsitePromotionCustomHTML(''));
  const [secondaryErrors, setSecondaryErrors] = useState<any>({})

  const [type, name, urlType, secondary_events = [], platform, bundleID] = useWatch({
    control,
    name: [
      'bidding_strategy.goal',
      'bidding_strategy.website_promotion.custom_action_name',
      'bidding_strategy.website_promotion.url_type',
      'bidding_strategy.website_promotion.secondary_events',
      'bidding_strategy.app_promotion.app_platform_type',
      'bidding_strategy.app_promotion.bundle_id',
    ],
  });



  const [appInfo, setAppInfo] = useState<App | null>(null);

  const APP_PLATFORMS = [
    { label: t('ios'), value: 'ios'},
    { label: t('android'), value: 'android'},
  ];

  const MMP_OPTIONS = [
    { label: t('adjust'), value: 'adjust', },
    { label: t('appsflyer'), value: 'appsflyer', }
  ];

  useEffect(() => {
    if (appInfo?.name) {
      setValue('bidding_strategy.app_promotion.app_name', appInfo.name);
    }
  }, [appInfo?.name, setValue]);

  function getAppInfo(platform: string, bundleID: string) {
    CampaignService.getAppInfo(bundleID, platform)
      .then(({ data }) => {
        if (data) {
          setAppInfo({
            icon: data.app_icon,
            name: data.app_name,
          });
          setValue('bidding_strategy.app_promotion.metadata', {
            headline: data.app_name || '',
            body: data.body || '',
            price: data.price || 0,
            starRating: data.star_rating || 1,
            cta: data.call_to_action || '',
            advertiser: data.advertiser || '',
          });
        } else {
          setAppInfo(data);
        }
      });
  }

  useEffect(() => {
    if (platform && bundleID) {
      getAppInfo(platform, bundleID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bundleID, platform]);

  function clearBundleID() {
    setAppInfo(null);
    setValue('bundle_id', '');
  }

  function copyToClipboard() {
    navigator.clipboard.writeText(`<script src="https://cdn1.intent.ai/pixel/${sessionStorage.getItem('spaceID')}/intent.js"></script>`);
    NotificationService.handleSuccess(t('copied'))
  }





  const addSecondaryEvent = () => {
    const secondaryList = secondary_events ? [...secondary_events] : [];
    secondaryList?.push("")
    setValue('bidding_strategy.website_promotion.secondary_events', secondaryList)
  }
  const removeSecondaryEvent = (index: number) => {
    const secondaryList = [...secondary_events];
    secondaryList.splice(index, 1);
    const secondaryErrorsList = {...secondaryErrors};
    delete secondaryErrorsList[index];
    setSecondaryErrors(secondaryErrorsList);
    setValue('bidding_strategy.website_promotion.secondary_events', secondaryList)
  }

  const setSecondaryEventName = (name: string, index: number) => {
    const secondaryList = [...secondary_events];
    const secondaryErrorsList = {...secondaryErrors};
    delete secondaryErrorsList[index];
    setSecondaryErrors(secondaryErrorsList);
    secondaryList[index] = name;
    setValue('bidding_strategy.website_promotion.secondary_events', secondaryList)
  }

  useEffect(() => {
    if(errors?.bidding_strategy?.website_promotion?.secondary_events &&
    Array.isArray(errors?.bidding_strategy?.website_promotion?.secondary_events)) {
      const err: any = {};
      errors?.bidding_strategy?.website_promotion?.secondary_events.forEach((item: any, i: number) => {
        err[i.toString()] = item;
      })
      setSecondaryErrors(err);
    }
  }, [errors]);

  useEffect(() => {
    setCustomHTML(generateWebsitePromotionCustomHTML(name));
  }, [name]);


  function copyCustomHTMLToClipboard() {
    navigator.clipboard.writeText(customHTML);
    NotificationService.handleSuccess(t('copied'));
  }

  function copySecondaryHtmlToClipboard(code: string) {
    navigator.clipboard.writeText(code);
    NotificationService.handleSuccess(t('copied'));
  }

  const CONDITION_OPTIONS = [
    { value: 'contains', label: t('contains') },
    { value: 'does_not_contain', label: t('doesNotContain') },
  ];

  return (
    <Box sx={style.websitesForm}>
      <Typography sx={[style.label, style.radioGroupLabel]}>{t('selectType')}</Typography>
      <IntFieldController
        control={control}
        name='bidding_strategy.goal'
        Component={IntRadioGroup}
        sx={style.radioGroup}
      >
        <Box sx={style.radio}>
          <Radio value={CampaignObjective.WEB_STANDARD_CONVERSION} />
          <Typography sx={style.radioLabel}>
            {t('webStandardConversion')}
            <Tooltip title={t<string>('standardConversionTooltip')}>
              <InfoIcon />
            </Tooltip>
          </Typography>
        </Box>
        <Box sx={style.radio}>
          <Radio value={CampaignObjective.WEBSITE_CUSTOM_EVENT} />
          <Typography sx={style.radioLabel}>
            {t('websiteCustomEvent')}
            <Tooltip title={t<string>('websiteCustomEventTooltip')}>
              <InfoIcon />
            </Tooltip>
          </Typography>
        </Box>
        <Box sx={style.radio}>
          <Radio value={CampaignObjective.APP_CUSTOM_EVENT} />
          <Box>
            <Typography sx={style.radioLabel}>
              {t('appCustomEvent')}
            </Typography>
            {/*<Typography sx={style.comingSoon}>{t('comingSoon')}</Typography>*/}
          </Box>
        </Box>
      </IntFieldController>
      {type === CampaignObjective.WEB_STANDARD_CONVERSION && (
        <Box sx={style.field}>
          <Box sx={style.withTooltip}>
            <Typography sx={style.label}>{t('landingURL')}</Typography>
            <Tooltip title={t('landingURLTooltip')}>
              <InfoIcon />
            </Tooltip>
          </Box>
          <IntFieldController
            control={control}
            name='bidding_strategy.click_url'
            placeholder={t<string>('https')}
          />
        </Box>
      )}
      {type === CampaignObjective.WEB_STANDARD_CONVERSION && (
        <>
          <Divider sx={style.divider}/>
          <Box sx={style.fields}>
            <Box sx={[style.field, style.multipleLabelsField]}>
              <Typography sx={style.pasteGTM}>{t('pasteGTM')}</Typography>
              <TextField
                disabled
                multiline
                rows={4}
                sx={style.customHTML}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <CopyIcon
                        viewBox='0 0 36 36'
                        sx={style.copyIcon}
                        onClick={copyToClipboard}
                      />
                    </InputAdornment>
                  )
                }}
                defaultValue={`<script src="https://cdn1.intent.ai/pixel/${sessionStorage.getItem('spaceID')}/intent.js"></script>`}
              />
              <Typography sx={style.optimizeCampaign}>{t('optimizeCampaign')}</Typography>
            </Box>
            <Box sx={style.urlTypes}>
              <Typography
                sx={[style.urlType, urlType === 'conversion' && style.activeURLType]}
                onClick={() => setValue('bidding_strategy.website_promotion.url_type', 'conversion')}
              >{t('conversionURL')}</Typography>
              <Typography
                onClick={() => setValue('bidding_strategy.website_promotion.url_type', 'parameter')}
                sx={[style.urlType, urlType === 'parameter' && style.activeURLType]}
              >{t('urlParameter')}</Typography>
            </Box>
            {urlType === 'conversion' && (
              <Box sx={style.field}>
                <Box sx={style.withTooltip}>
                  <Typography sx={style.label}>{t('conversionURL')}</Typography>
                  <Tooltip title={t('conversionURLTooltip')}>
                    <InfoIcon />
                  </Tooltip>
                </Box>
                <IntFieldController
                  control={control}
                  name='bidding_strategy.website_promotion.conversion_url'
                  placeholder={t<string>('https')}
                />
              </Box>
            )}
            {urlType === 'parameter' && (
              <Box sx={style.rule}>
                <Typography sx={style.customRule}>{t('setupCustomRule')}</Typography>
                <Box sx={style.field}>
                  <Typography sx={style.label}>{t('domain')}</Typography>
                  <IntFieldController
                    control={control}
                    name='bidding_strategy.website_promotion.optionDomain'
                    placeholder='https://'
                  />
                </Box>
                <Box sx={style.field}>
                  <Typography sx={style.label}>{t('conversionURL')}</Typography>
                  <Box sx={style.conversionURLCondition}>
                    <IntFieldController
                      control={control}
                      name='bidding_strategy.website_promotion.optionCondition'
                      Component={IntSelect}
                      options={CONDITION_OPTIONS}
                      sx={style.condition}
                    />
                    <IntFieldController
                      control={control}
                      name='bidding_strategy.website_promotion.optionValue'
                      sx={style.conditionValueInput}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </>
      )}
      {type == CampaignObjective.WEBSITE_CUSTOM_EVENT && ( // eslint-disable-line
        <Box sx={style.fields}>
          <Divider sx={[style.divider, style.customActionDivider]}/>
          <Box sx={style.field}>
            <Box sx={style.withTooltip}>
              <Typography sx={style.label}>{t('landingURL')}</Typography>
              <Tooltip title={t('landingURLTooltip')}>
                <InfoIcon />
              </Tooltip>
            </Box>
            <IntFieldController
              control={control}
              name='bidding_strategy.click_url'
              placeholder={t<string>('https')}
              helperText=''
            />
          </Box>
          <Box sx={style.field}>
            <Typography sx={style.label}>{t('customActionName')}</Typography>
            <IntFieldController
              control={control}
              name='bidding_strategy.website_promotion.custom_action_name'
            />
          </Box>
          <Box sx={[style.field, style.multipleLabelsField]}>
            <Typography sx={style.pasteGTM}>{t('customHTMLTagGoogleManager')}</Typography>
            <TextField
              disabled
              multiline
              rows={5}
              sx={style.customHTML}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <CopyIcon
                      viewBox='0 0 36 36'
                      sx={style.copyIcon}
                      onClick={copyCustomHTMLToClipboard}
                    />
                  </InputAdornment>
                )
              }}
              value={customHTML}
            />
            <Typography sx={style.optimizeCampaign}>{t('customHTMLDescription')}</Typography>
          </Box>


          <Box>
            <Typography sx={style.secondaryTitle}>{'Secondary Events'}</Typography>
            {
              secondary_events?.map((item: string, index: number) => (
                <TextField
                  sx={secondaryErrors[index] ? style.secondaryEventNameErr : style.secondaryEventName}
                  key={index}
                  helperText={secondaryErrors[index]?.message}
                  InputProps={{
                    endAdornment: (
                      <>
                        <InputAdornment
                          title={generateWebsitePromotionCustomHTML(item)}
                          position='end'>
                          <CopyIcon
                            viewBox='0 0 36 36'
                            sx={style.copyIconSecondary}
                            onClick={() => copySecondaryHtmlToClipboard(generateWebsitePromotionCustomHTML(item))}
                          />
                        </InputAdornment>
                        <InputAdornment position='end'>
                          <DeleteOutlineIcon
                            viewBox='0 0 36 36'
                            sx={style.deleteIconSecondary}
                            onClick={() => removeSecondaryEvent(index)}
                          />
                        </InputAdornment>
                      </>
                    )
                  }}
                  value={item}
                  onChange={(e) => setSecondaryEventName(e.target.value, index)}
                />
              ))
            }
            <Button disabled={secondary_events && secondary_events.length > 3} sx={style.addSecondary} variant={'outlined'} onClick={addSecondaryEvent}>Add</Button>
          </Box>


        </Box>
      )}
      {type == CampaignObjective.APP_CUSTOM_EVENT && ( // eslint-disable-line
        <Box sx={style.fields}>
          <Box sx={style.rowFields}>
            <Box sx={[style.field, style.autocompleteField]}>
              <Typography sx={style.label}>{t('choosePlatform')}</Typography>
              <IntFieldController
                control={control}
                name='bidding_strategy.app_promotion.app_platform_type'
                Component={IntSelect}
                options={APP_PLATFORMS}
              />
            </Box>
            <Box sx={[style.field, style.autocompleteField]}>
              <Typography sx={style.label}>{t('mmp')}</Typography>
              <IntFieldController
                control={control}
                name='bidding_strategy.app_promotion.mmp'
                Component={IntSelect}
                options={MMP_OPTIONS}
              />
            </Box>
          </Box>
          <Box sx={style.rowFields}>
            <Box sx={[style.field, style.bundleID]}>
              <Typography sx={style.label}>{t('bundleID')}</Typography>
              <IntFieldController
                control={control}
                name='bidding_strategy.app_promotion.bundle_id'
              />
            </Box>
            {appInfo && (
              <Box sx={style.app}>
                <Box component='img' src={appInfo.icon} width={24} height={24} />
                <Typography sx={style.appName}>{appInfo.name}</Typography>
                <SmallCrossIcon sx={style.crossIcon} onClick={clearBundleID} />
              </Box>
            )}
          </Box>
          <Box sx={style.field}>
            <Typography sx={style.label}>{t('clickURL')}</Typography>
            <IntFieldController
              control={control}
              name='bidding_strategy.click_url'
              multiline
              rows={3}
            />
          </Box>
          <Box sx={style.field}>
            <Typography sx={style.label}>{t('trackingURL')}</Typography>
            <IntFieldController
              control={control}
              name='bidding_strategy.app_promotion.tracking_url'
              multiline
              rows={3}
            />
          </Box>
          <Box sx={style.field}>
            <Typography sx={style.label}>{t('customActionName')}</Typography>
            <IntFieldController
              control={control}
              name='bidding_strategy.app_promotion.custom_action_name'
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}

WebsitesForm.displayName = 'WebsitesForm';

export default WebsitesForm;