import { Theme } from "@mui/material";

export const style = {
  emptyTable: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'translateY(20%)',
  },
  noCampaigns: {
    color: 'rgb(41, 47, 65)',
    fontSize: 28,
    fontWeight: 600,
    letterSpacing: '-0.2px',
    lineHeight: '36px',
    textAlign: 'center',
  },
  description: {
    color: 'rgba(41, 47, 65, 0.5)',
    fontSize: 14,
    letterSpacing: '0.2px',
    lineHeight: '20px',
    textAlign: 'center',
    maxWidth: 600,
  },
  add: (theme: Theme) => ({
    borderRadius: 5,
    alignSelf: 'center',
    marginTop: theme.spacing(4),
  }),
  addIcon: {
    stroke: '#fff',
  },
  image: (theme: Theme) => ({
    marginBottom: theme.spacing(4),
  }),
}