import { Box, Button, Divider, Typography } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IntFieldController, IntSelect } from "../../../global";
import { style } from './style';
import { IUser } from "../../../../types";
import { ProfileService } from "../../services";

type Props = {
  user: IUser;
}

function ProfileOtherForm({ user }: Props) {
  const {
    handleSubmit,
    control,
    formState,
  } = useForm<Record<string, string>>({
    defaultValues: {
      language: String(user.language_key),
    }
  });

  const { t } = useTranslation();

  async function onSubmit(data: Record<string, string>) {
    const response = await ProfileService.updateUserLanguage({
      ...user,
      language_key: +data.language,
    });
    if (response.data.type?.code == 0) { // eslint-disable-line
      window.location.reload();
    }
  }

  const LANGUAGE_OPTIONS = [
    { label: t('profile.otherForm.languageOptions.en'), value: 1 },
    { label: t('profile.otherForm.languageOptions.ru'), value: 2 },
  ];

  return (
    <Box component='form' sx={style.form} onSubmit={handleSubmit(onSubmit)}>
      <Typography sx={style.formTitle}>{t('profile.otherForm.title')}</Typography>
      <Divider />
      <Box>
        <Box sx={style.field}>
          <Typography sx={style.fieldLabel}>{t('profile.otherForm.language')}</Typography>
          <Box sx={style.selectWrapper}>
            <IntFieldController
              name='language'
              control={control}
              placeholder={t<string>('profile.otherForm.language')}
              sx={style.fieldSelect}
              Component={IntSelect}
              options={LANGUAGE_OPTIONS}
            />
          </Box>
        </Box>
        <Divider />
      </Box>
      <Box sx={style.submitForm}>
        <Button
          disabled={!formState.isDirty || formState.isSubmitting}
          sx={style.submitButton}
          type='submit'
        >{t('profile.otherForm.submitTitle')}</Button>
      </Box>
    </Box>
  )
}

export default ProfileOtherForm;
