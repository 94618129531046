import React from "react";
import Color from "./Color";

const presets = [
  "3A77EB",
  "6CA1F2",
  "13D5E6",
  "84EBFD",
  "6CD387",
  "87E09E",
  "EF902E",
  "FAC76D",
  "E14B4B",
  "EB6C6C",
  "292F41",
  "BFD0E3",
];

export default function DefaultPresets({ onClick }) {
  const colorPresents = presets.map(color => (
    <Color key={color} color={`${color}`} onClick={() => onClick(color)} />
  ));
  return <div className="color-presets">{colorPresents}</div>;
}
