/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 12/13/2023
 * All Rights Reserved.
 */
import { Box, Button } from "@mui/material";
import  { style } from './style';
import { useTranslation } from "react-i18next";

type Props = {
  onCreate: () => void;
}

function AudienceListHeader({ onCreate }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'audience' });
  return (
    <Box sx={style.header}>
      <Button variant='contained' sx={style.create} onClick={onCreate}>{t('create')}</Button>
    </Box>
  );
}

AudienceListHeader.displayName = 'AudienceListHeader';

export default AudienceListHeader;