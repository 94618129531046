import React from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Modal } from "@intent-ai/mandal-npm-lib-v2/dist/providers";
// import { closeDialog, dialogStateSelector } from "../../modules/store/reducers/dialog";

interface IProps {
  children: JSX.Element | JSX.Element[];
}

const InfoDialogContainer = (props: IProps) => {

  const { children } = props;

  // const dispatch = useDispatch();
  // const dialogState = useSelector(dialogStateSelector);

  return (
    <div>
      {/*{dialogState.open && (*/}
      {/*  <Modal*/}
      {/*    modalType="warningModal"*/}
      {/*    isOpen={dialogState.open}*/}
      {/*    closeModal={() => {*/}
      {/*      dispatch(closeDialog());*/}
      {/*      if (dialogState.closeDialogCallback) {*/}
      {/*        dialogState.closeDialogCallback();*/}
      {/*      }*/}
      {/*    }}*/}
      {/*    handleConfirmNavigationClick={() => {*/}
      {/*      dialogState.onConfirm();*/}
      {/*      dispatch(closeDialog());*/}
      {/*    }}*/}
      {/*    title={dialogState.title}*/}
      {/*    description={dialogState.description}*/}
      {/*    data={dialogState.data}*/}
      {/*    closeModlaText={dialogState.closeModlaText}*/}
      {/*    confirmText={dialogState.confirmText}*/}
      {/*    buttonReverse*/}
      {/*  />*/}
      {/*)}*/}
      {children}
    </div>
  );
}

export default InfoDialogContainer;
