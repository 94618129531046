import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "Transactions.title",
    defaultMessage: "Transactions",
  },
  orderId: {
    id: "Transactions.orderId",
    defaultMessage: "Order ID",
  },
  authCode: {
    id: "Transactions.authCode",
    defaultMessage: "Auth. Code",
  },
  date: {
    id: "Transactions.date",
    defaultMessage: "Date",
  },
  contactPerson: {
    id: "Transactions.contactPerson",
    defaultMessage: "Contact Person",
  },
  companyName: {
    id: "Transactions.companyName",
    defaultMessage: "Company Name",
  },
  entity: {
    id: "Transactions.entity",
    defaultMessage: "Legal Entity",
  },
  tinNumber: {
    id: "Transactions.tinNumber",
    defaultMessage: "Tin Number",
  },
  cardholder: {
    id: "Transactions.cardholder",
    defaultMessage: "Cardholder",
  },
  cardNumber: {
    id: "Transactions.cardNumber",
    defaultMessage: "Card number",
  },
  amount: {
    id: "Transactions.amount",
    defaultMessage: "Amount",
  },
  invoice: {
    id: "Transactions.invoice",
    defaultMessage: "Invoice",
  },
  searchPlaceholder: {
    id: "Transactions.searchPlaceholder",
    defaultMessage: "Search by Name, Email, Company or Payment Method",
  },
  email: {
    id: "Transactions.email",
    defaultMessage: "Email",
  },
  phone: {
    id: "Transactions.phone",
    defaultMessage: "Phone",
  },
  individual: {
    id: "Transactions.individual",
    defaultMessage: "Individual Account",
  },
});
