export const style = {
  container: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 25,
    flexDirection: 'column',
    position: 'absolute',
    width: 400,
    left: '50%',
    right: '50%',
    transform: 'translateX(-50%)',
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.33,
    color: '#292f41',
    marginTop: 5,
    marginBottom: 2,
  },
  description: {
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: 0.2,
    color: 'rgba(41, 47, 65, 0.5)',
    textAlign: 'center',
  },
  create: {
    marginTop: 6,
    borderRadius: 5,
  }
}