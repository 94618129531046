import React from "react";
import { defineMessages, injectIntl, IntlShape } from "react-intl";

import "./style.scss";
import { getPercentageOfValue, numberToStringWithCommas } from "../../../modules";

interface IProps {
  data: any;
  intl: IntlShape;
  onClick?: Function;
}

const messages: any = defineMessages({
  adaptive: {
    id: "DashboardChartSection.adaptiveAds",
    defaultMessage: "Adaptive Ads",
  },
  banner: {
    id: "DashboardChartSection.bannerAds",
    defaultMessage: "Banner (Image) ads",
  },
  rich_media: {
    id: "DashboardChartSection.htmlAds",
    defaultMessage: "HTML Ads",
  },
});

const PercentageGraph = ({
  data,
  onClick,
  intl: { formatMessage },
}: IProps) => {
  const maxValueOfY = Math.max(...data.map((o: any) => o.value), 0);
  return (
    <div className="percentage-graph">
      <div className="percentage-graph-container">
        {data
          .filter(({ value }: any) => !!value)
          .map(({ value, name, type }: any, index: number) => {
            if (value === 0) {
              return null;
            }
            const percentage = getPercentageOfValue(maxValueOfY, value);

            const handelClick = () => {
              if (onClick) {
                onClick({ value, name, type });
              }
            };

            return (
              <div
                style={{
                  width: `${percentage * 100}`,
                  opacity: index * 0.2 === 0 ? 1 : index * 0.2,
                }}
                className="percentage-graph-item"
                onClick={handelClick}
              >
                <p className="percentage-graph-item-text">
                  <span className="percentage-graph-item-text-value">
                    {formatMessage(messages[name])}
                  </span>
                  <span className="percentage-graph-item-text-percentage">
                    {numberToStringWithCommas(percentage * 100)}%
                  </span>
                </p>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default injectIntl(PercentageGraph);
