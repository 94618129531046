import { createSlice } from '@reduxjs/toolkit';
import { IDictionaryField } from "../../../types";

export interface DictionaryState {
  isLoaded: boolean;
  data: { [key: string]: IDictionaryField[] };
  dictFiledLoading: boolean;
}

export const dictionarySlice = createSlice({
  name: 'dictionaryStore',
  initialState: {
    isLoaded: false,
    data: {},
    dictFiledLoading: false,
  } as DictionaryState,
  reducers: {
    dictLoaded: (state: DictionaryState, action): DictionaryState => ({
      ...state,
      isLoaded: action.payload,
    }),
    dictFiledLoadingStart: (state: DictionaryState): DictionaryState => ({
      ...state,
      dictFiledLoading: true,
    }),
    dictFiledLoadingFinish: (state: DictionaryState): DictionaryState => ({
      ...state,
      dictFiledLoading: false,
    }),
    dictFieldGet: (state: DictionaryState, action
    ): DictionaryState => ({
      ...state,
      data: { ...state.data, [action.payload.id]: action.payload.data },
    }),
  },
});

export const {
  dictLoaded,
  dictFiledLoadingStart,
  dictFiledLoadingFinish,
  dictFieldGet
} = dictionarySlice.actions;

export const selectDictionaryField = () => {
  return (rootState: any, ...fieldName: any): IDictionaryField[] => {
    const name = fieldName[0];
    if (rootState.dictionaryStore.data[name]) {
      return rootState.dictionaryStore.data[name];
    }
    return [];
  };
};

export default dictionarySlice.reducer;
