export const AuthRoutes = (Comp: any) => {
  // const termOfServiceUrl = useSelector(select.admin.termsOfServiceLinkSelector); // todo remove after checking
  return (props: any) => {
    // @ts-ignore
    return (
      <div className="dsp-auth-container">
        <div className="main-content">
          <div>
            {Comp && <Comp {...props} />}
          </div>
        </div>
      </div>
    );
  };
};

export const AdminRoutes = (Comp: any) => (props: any) => {
  return (
    <div className="dsp-admin-container">
      <div className="main-content">
        {Comp && <Comp {...props} />}
      </div>
    </div>
  );
};