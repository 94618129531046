import { IUser } from "../../../../../../types";
import { profileMessages } from "../../../../../../messages";
import { injectIntl, IntlShape } from "react-intl";
import { USER_STATUSES_SELECT_LIST } from "../../../../../../_constants";
import { Loading } from "../../../../../global";
import InputRow from "../../../../../global/components/InputRow";
import SelectRow from "../../../../../global/components/SelectRow";
import DateRow from "../../../../../global/components/DateRow";
import "./style.scss";

interface IProps {
  user: IUser;
  intl: IntlShape;
  onChange?: (name: string, value: any) => void;
  isLegalEntityForm?: boolean;
}

const ModerationUserForm = (props:IProps) => {

  const { intl: { formatMessage },
    user, isLegalEntityForm,onChange} = props;

  return !user ? <Loading /> : (
    <div>
      <form>
        <section className={'mai-content-wrapper'}>
           <div className={'header'}>
             <p>{formatMessage(profileMessages.contactPerson)}</p>
           </div>
          <div className={'body'}>
            <div className={'mai-form-group-container'}>
              <InputRow
                label={formatMessage(profileMessages.firstName)}
                disabled
                input={{
                  name: "first_name",
                  value: user['first_name'],
                }}
              />
            </div>
            <div className={'mai-form-group-container'}>
              <InputRow
                label={formatMessage(profileMessages.lastName)}
                disabled
                input={{
                  name: "last_name",
                  value: user['last_name'],
                }}
              />
            </div>
            <div className={'mai-form-group-container'}>
              <InputRow
                label={formatMessage(profileMessages.email)}
                disabled
                input={{
                  name: "email",
                  value: user['email'],
                }}
              />
            </div>
            <div className={'mai-form-group-container'}>
              <InputRow
                label={formatMessage(profileMessages.phone)}
                disabled
                input={{
                  name: "phone",
                  value: user['phone'],
                }}
              />
            </div>
            <div className={'mai-form-group-container'}>
              <SelectRow
                label={formatMessage(profileMessages.status)}
                disabled
                options={USER_STATUSES_SELECT_LIST}
                input={{
                  name: "status_key",
                  value: user['status_key'],
                }}
              />
            </div>
            <div className={'mai-form-group-container'}>
              <DateRow
                label={formatMessage(profileMessages.registrationDate)}
                disabled
                input={{
                  name: "created_at",
                  value: user['created_at'],
                  format: 'MMMM dd, yyyy h:mm a',
                }}
              />
            </div>
            {isLegalEntityForm && (
              <>
                <div className={'mai-form-group-container'}>
                  <InputRow
                    label={formatMessage(profileMessages.legalEntityName)}
                    input={{
                      name: "legal_entity_name",
                      value: user['legal_entity_name'],
                      onChange: (e) => onChange && onChange('legal_entity_name', e.target.value)
                    }}
                  />
                </div>
                <div className={'mai-form-group-container'}>
                  <InputRow
                    label={formatMessage(profileMessages.tinNumber)}
                    input={{
                      name: "tin_number",
                      value: user['tin_number'],
                      onChange: (e) => onChange && onChange('tin_number', e.target.value)
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </section>
      </form>
    </div>
  );
}

export default injectIntl(ModerationUserForm);

