/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 8/16/2023
 * All Rights Reserved.
 */
import { useFormContext, useWatch } from "react-hook-form";
import { Box, Divider, Typography } from "@mui/material";
import { style } from "./style";
import { useTranslation } from "react-i18next";
import { IntFieldController, IntSwitch } from "../../../global";
import { CampaignTargetingForm } from "../index";
import { CampaignFormDTO } from "../../types";
import targetingImage from '../../../../assets/images/targeting.svg';
import { ReactNode } from "react";

type Props = {
  footer: ReactNode;
}

function CampaignTargeting({ footer }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'campaign.targeting' });

  const {
    control,
  } = useFormContext<CampaignFormDTO>();

  const [isAutomaticTargeting] = useWatch({
    name: ['targeting.automaticTargeting'],
    control,
  });

  return (
    <Box sx={style.targeting}>
      <Typography sx={style.title}>{t('title')}</Typography>
      <Typography sx={style.description}>{t('description')}</Typography>
      <Divider />
      <Box sx={[style.automaticTargetingBox(!!isAutomaticTargeting), !isAutomaticTargeting && style.manualTargeting]}>
        <Box
          component='img'
          src={targetingImage}
          width={isAutomaticTargeting ? 150 : 100}
          height={isAutomaticTargeting ? 150 : 100}
          sx={style.image}
        />
        <Typography sx={style.automaticTargeting}>{t('automaticTargeting')}</Typography>
        <Typography sx={style.automaticTargetingDescription}>{t('automaticTargetingDescription')}</Typography>
        <IntFieldController control={control} name='targeting.automaticTargeting' Component={IntSwitch} />
      </Box>
      {!isAutomaticTargeting && (
        <CampaignTargetingForm />
      )}
      {footer}
    </Box>
  );
}

CampaignTargeting.displayName = 'CampaignTargeting';

export default CampaignTargeting;