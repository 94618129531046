import { numberToStringWithCommas } from "../../../global";

export const reportListHeadConfig: IReportHeadCell[] = [
  {
    key: 'dimension',
    parser: (field) => field || 'n/a',
  },  
  {
    key: 'impression_count',
    orderField: 'IMPRESSION_COUNT',
    parser: (field) => numberToStringWithCommas(field || 0),
  }, 
  {
    key: 'cpm',
    orderField: 'CPM',
    parser: (field, currency) => `${currency} ${(field || 0).toFixed(2)}`,
  },
  {
    key: 'click_count',
    orderField: 'CLICK_COUNT',
    parser: (field) => numberToStringWithCommas(field || 0),
  },
  {
    key: 'cpc',
    orderField: 'CPC',
    parser: (field, currency) => `${currency} ${(field || 0).toFixed(2)}`,
  },
  {
    key: 'ctr',
    orderField: 'CTR',
    parser: (field) => `${(field || 0).toFixed(2)}%`,
  },
  {
    key: 'engaged_session_count',
    orderField: 'ENGAGED_SESSION_COUNT',
    parser: (field) => numberToStringWithCommas(field || 0),
  },
  {
    key: 'engagementsRate',
    parser: (field) => `${(field || 0).toFixed(2)}%`,
  },
  {
    key: 'bounces',
    parser: (field) => numberToStringWithCommas(field || 0),
  },
  {
    key: 'bounce_rate',
    orderField: 'BOUNCE_RATE',
    parser: (field) => `${(field || 0).toFixed(2)}%`,
  },
  {
    key: 'conversion_count',
    orderField: 'CONVERSION_COUNT',
    parser: (field) => numberToStringWithCommas(field || 0),
  },
  {
    key: 'cpa',
    orderField: 'CPA',
    parser: (field, currency) => `${currency} ${(field || 0).toFixed(2)}`,
  },
  {
    key: 'cvr',
    orderField: 'CVR',
    parser: (field) => `${(field || 0).toFixed(2)}%`,
  },
  {
    key: 'spent',
    orderField: 'SPENT',
    parser: (field, currency) => `${currency} ${Math.floor(field || 0)}`,
  },
]

