import './style.scss'

interface IProps {
  sidebarToggle: () => void;
  sidebarOpen: boolean;
  icon: string;
}

const Hamburger = (props: IProps) => {
  const {sidebarToggle, sidebarOpen, icon} = props;

  return (
    <div className={`hamburger-container ${sidebarOpen && "open"}`}>
      <div
        id={`nav-${icon}`}
        className={`hamburger-icon ${sidebarOpen && "open"}`}
        onClick={sidebarToggle}
      >
        <span />
        <span />
        <span />
      </div>
    </div>
  )
}

export default Hamburger;