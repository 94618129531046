/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 9/4/2023
 * All Rights Reserved.
 */
import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { style } from './style';
import { ReactNode } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

type Props = {
  footer?: ReactNode;
}

const defaultAttribution = {
  name: "",
  value: "",
}

function AttributionForm({ footer }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'campaign.attribution' });

  const { control, setValue } = useFormContext();
  const [attribution = []] = useWatch({
    control,
    name: [
      'attribution',
    ],
  });

  const addAttribution = () => {
    const secondaryList = attribution ? [...attribution] : [];
    secondaryList?.push(defaultAttribution)
    setValue('attribution', secondaryList)
  }
  const removeAttribution = (index: number) => {
    const secondaryList = [...attribution];
    secondaryList.splice(index, 1);
    setValue('attribution', secondaryList)
  }

  const setAttributionName = (name: string, index: number) => {
    const secondaryList = [...attribution];
    secondaryList[index].name = name;
    setValue('attribution', secondaryList)
  }
  const setAttributionValue = (value: string, index: number) => {
    const secondaryList = [...attribution];
    secondaryList[index].value = value;
    setValue('attribution', secondaryList)
  }

  return (
    <Box sx={style.attribution}>
      <Typography sx={style.title}>{t('title')}</Typography>
      <Typography sx={style.label}>
        {t('attributionLabel')}
        <Typography component='span' sx={style.params}>
          {t('params')}
        </Typography>
      </Typography>
      <Divider />
      <Box>
        {/*<Typography sx={style.secondaryTitle}>{'Secondary Events'}</Typography>*/}
        {
          attribution?.map((item: any, index: number) => (
            <Box key={index} sx={style.attributionRow}>
              <TextField
                sx={style.secondaryEventName}
                placeholder={'name'}
                value={item.name}
                onChange={(e) => setAttributionName(e.target.value, index)}
              />
              <TextField
                sx={style.secondaryEventName}
                placeholder={'value'}
                value={item.value}
                onChange={(e) => setAttributionValue(e.target.value, index)}
              />
              <DeleteOutlineIcon
                viewBox='0 0 36 36'
                sx={style.deleteIconSecondary}
                onClick={() => removeAttribution(index)}
              />
            </Box>
          ))
        }
        <Button disabled={attribution && attribution.length > 9} sx={style.addSecondary} variant={'outlined'} onClick={addAttribution}>Add</Button>
      </Box>

      {/*<Divider />*/}
      {/*<Box sx={style.field}>*/}
      {/*  /!*<Typography sx={style.label}>*!/*/}
      {/*  /!*  {t('attributionLabel')}*!/*/}
      {/*  /!*  <Typography component='span' sx={style.params}>*!/*/}
      {/*  /!*    {t('params')}*!/*/}
      {/*  /!*  </Typography>*!/*/}
      {/*  /!*</Typography>*!/*/}
      {/*  <IntFieldController*/}
      {/*    control={control}*/}
      {/*    name='attributionTags'*/}
      {/*    multiline*/}
      {/*    sx={style.textarea}*/}
      {/*    rows={4}*/}
      {/*  />*/}
      {/*</Box>*/}
      {/*{footer}*/}
    </Box>
  );
}

AttributionForm.displayName = 'AttributionForm';

export default AttributionForm;