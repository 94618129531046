import { Theme } from "@mui/material";

export const style = {
  item: (theme: Theme) => ({
    height: 24,
    width: 16,
    border: '1px solid #dfe7f0',
    backgroundColor: '#f5f7f8',
    borderRadius: 0.5,
    marginTop: '0.2rem',
    [theme.breakpoints.down(1100)]: {
      minWidth: 16,
    },
  }),
  row: {
    display: 'flex',
    gap: 2,
  },
  hours: {
    paddingLeft: 21,
    gap: 7,
    marginBottom: 2,
  },
  weekday: (theme: Theme) => ({
    width: 65,
    userSelect: 'none',
    [theme.breakpoints.down(1100)]: {
      minWidth: 65,
    },
  }),
  seperatedItem: {
    marginLeft: 3,
  },
  hour: (theme: Theme) => ({
    width: 56,
    '&:not-first-child': {
      marginLeft: 2,
    },
    [theme.breakpoints.down(1100)]: {
      minWidth: 56,
    },
  }),
  activeItem: {
    backgroundColor: 'primary.main',
  },
  scheduling: (theme: Theme) => ({
    borderRadius: 1,
    padding: 3,
    border: '1px solid #f4f6fa',
    width: '100%',
    [theme.breakpoints.down(1100)]: {
      overflowX: 'auto',
    },
  }),
  allDays: (theme: Theme) => ({
    width: 65,
    fontWeight: 'bold',
    [theme.breakpoints.down(1100)]: {
      minWidth: 65,
    },
  }),
}