/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 9/29/2023
 * All Rights Reserved.
 */
import { Box, Button, Divider, Modal, Paper, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { InfoIcon, IntFieldController, IntSelect, NEW_ROUTES, withStopPropagation } from "../../../global";
import { style } from './style';
import { CampaignStatus } from "../../types";
import { useSelector } from "react-redux";
import { UserRole } from "../../../profile/types";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { StatusCode } from "../../../global/types";
import { NotificationService } from "../../../global/services";
import { AccountService } from "../../../adminApp/modules/account/services";
import { SelectOption } from "../../../global/components/IntForm/IntSelect";
import IntAutocomplete from "../../../global/components/IntForm/IntAutocomplete";
import { selectCurrentUserRole } from "../../../store/reducers/authentication";
import { getDictionaryByIdRequest } from "../../../../api";

type Props = {
  status?: number;
  id: string;
  exchangeIds: string[];
  categoryKeys: any[];
}

function CampaignModerationStatus({ status, id, exchangeIds, categoryKeys }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'campaign.moderation' });
  const role = useSelector(selectCurrentUserRole);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const history = useHistory();
  const [categories, setCategories] = useState<SelectOption[]>([]);

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      reason: '',
      categories: categoryKeys || [],
      exchangeIds: exchangeIds || [],
    },
  });

  function handleRejectModalOpen() {
    setRejectModalOpen(true);
  }

  function closeRejectModal() {
    setRejectModalOpen(false);
  }

  function closeApproveModal() {
    setApproveModalOpen(false);
  }

  function openApproveModal() {
    setApproveModalOpen(true);
  }

  useEffect(() => {
    setValue('exchangeIds', exchangeIds);
    setValue('categories', categoryKeys);
    // eslint-disable-next-line
  }, [exchangeIds, categoryKeys]);

  useEffect(() => {
    getDictionaryByIdRequest('iab_categories')
      .then((res: any) => {
        setCategories(res?.data?.data.map(({ value, title }) => ({
          label: title,
          value: value,
        })));
      });
  }, []);
// eslint-disable-next-line
  if (status != CampaignStatus.IN_MODERATION || role != UserRole.WL_OWNER) {
    return null;
  }

  const AD_EXCHANGES = [
    { label: t('google'), value: 'google' },
    { label: t('verve'), value: 'verve' },
    { label: t('taboola'), value: 'taboola' },
    { label: t('yandex'), value: 'yandex' },
    { label: t('3rdpresence'), value: '3rdpresence' }
  ];

  async function rejectCampaign(
    formValues: { reason: string; },
  ) {
    const response = await AccountService.rejectCampaign(id, formValues.reason);
    if (response?.data.type?.code === StatusCode.OK) {
      history.replace(NEW_ROUTES.campaign.list.fullPath);
      NotificationService.handleSuccess(t('rejectedSuccessfully'))
    }
    setRejectModalOpen(false);
  }

  async function approveCampaign(
    formValues: { categories: Array<string>; exchangeIds: Array<string> },
  ) {
    let exchangeIds = formValues.exchangeIds;
    if (exchangeIds.length === 0) {
      exchangeIds = AD_EXCHANGES.map(({ value }) => value);
    }
    const response = await AccountService.approveCampaign(id, formValues.categories, exchangeIds);
    if (response?.data?.type?.code === StatusCode.OK) {
      history.replace(NEW_ROUTES.campaign.list.fullPath);
      NotificationService.handleSuccess(t('approvedSuccessfully'))
    }
    setApproveModalOpen(false);
  }

  return (
    <Box sx={style.moderation}>
      <Box>
        <Typography sx={style.title}>{t('title')}</Typography>
        <Typography sx={style.label}>{t('checkComponents')}</Typography>
        <Box sx={style.components}>
          <Box sx={style.component}>
            <Typography sx={style.unit}>{t('visuals')}</Typography>
            <Tooltip title={t('visualsTooltipText')}>
              <InfoIcon />
            </Tooltip>
          </Box>
          <Box sx={style.component}>
            <Typography sx={style.unit}>{t('clickURL')}</Typography>
            <Tooltip title={t('clickURLTooltipText')}>
              <InfoIcon />
            </Tooltip>
          </Box>
        </Box>
        <Divider sx={style.divider} />
        <Box sx={style.exchange}>
          <Typography sx={style.chooseExchange}>{t('chooseExchange')}</Typography>
          <IntFieldController
            control={control}
            name='exchangeIds'
            Component={IntSelect}
            options={AD_EXCHANGES}
            multiple
            sx={style.adExchange}
          />
        </Box>
      </Box>
      <Box sx={style.actions}>
        <Button
          variant='outlined'
          sx={[style.action, style.reject]}
          onClick={handleRejectModalOpen}
        >{t('reject')}</Button>
        <Button
          variant='contained'
          sx={style.action}
          onClick={openApproveModal}
        >{t('approve')}</Button>
      </Box>

      <Modal open={rejectModalOpen} onClose={closeRejectModal}>
        <Paper sx={style.paper} component='form' onSubmit={withStopPropagation(handleSubmit(rejectCampaign))}>
          <Typography variant='h4' sx={style.modalTitle}>{t('reason')}</Typography>
          <Divider />
         <Box sx={style.content}>
           <Typography>
             {t('reasonOfRejection')}
           </Typography>
           <IntFieldController
             control={control}
             name='reason'
             multiline
             rows={3}
             sx={style.input}
             placeholder={t<string>('reasonInputPlaceholder')}
           />
           <Button
             variant='contained'
             sx={style.button}
             type='submit'
           >{t('confirm')}</Button>
         </Box>
        </Paper>
      </Modal>
      <Modal open={approveModalOpen} onClose={closeApproveModal}>
        <Paper
          sx={[style.paper, style.paperApprove]}
          component='form'
          onSubmit={withStopPropagation(handleSubmit(approveCampaign))}
        >
          <Typography variant='h4' sx={style.modalTitle}>{t('category')}</Typography>
          <Divider />
          <Box sx={style.content}>
            <Typography>
              {t('websitesAndCategories')}
            </Typography>

            <IntFieldController
              control={control}
              name='categories'
              Component={IntAutocomplete}
              options={categories}
              placeholder={t('searchCategory') as string}
              extractValue
              multiple
              sx={style.input}
            />
            <Button
              variant='contained'
              sx={style.button}
              type='submit'
            >{t('confirm')}</Button>
          </Box>
        </Paper>
      </Modal>
    </Box>
  )
}

CampaignModerationStatus.displayName = 'CampaignModerationStatus';

export default CampaignModerationStatus;