import { ApiService } from "../../global/services";

class UploadService {
  static ENDPOINT = `${window.REACT_APP_BASE_URL}/v1/file`;

  static uploadFile2(file: File, width: number, height: number) {
    const formData = new FormData();
    formData.append('file', file);
    return ApiService.post(`${UploadService.ENDPOINT}/upload2?width=${width}&height=${height}`, formData, {'Content-Type': 'text/csv'});
  }
  static uploadFile3(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return ApiService.post(`${UploadService.ENDPOINT}/upload3`, formData, {'Content-Type': 'multipart/form-data'});
  }

  static uploadNativeFile(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return ApiService.post(`${UploadService.ENDPOINT}/native`, formData, {'Content-Type': 'text/csv'});
  }

  static uploadAudioFile(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return ApiService.post(`${UploadService.ENDPOINT}/audio`, formData, {'Content-Type': 'multipart/form-data'});
  }

  static uploadVideoAdResource(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return ApiService.post(`${UploadService.ENDPOINT}/video`, formData, {'Content-Type': 'multipart/form-data'});
  }
}

export default UploadService;
