import React from "react";
import { Redirect, Switch, BrowserRouter, Route } from "react-router-dom";

import { RouteBuilder } from "./config/routes/route-builder";


import {store1} from "./config/store";
import {
  adminRouterConfig,
  authRouterConfig, defaultPath, notFoundConfig,
} from "./config/routes/route-config";
import { useSelector } from "react-redux";
import { userRoles } from "./_constants";
import { selectCurrentUserRole } from "./modules/store/reducers/authentication";

const redirectToNotfound = () => <Redirect to={defaultPath} />;

export const Routes = () => {

  const userRole = useSelector(selectCurrentUserRole);

  return (
    <BrowserRouter>
      <Switch>
        {userRole === userRoles.WL_OWNER && RouteBuilder(adminRouterConfig, store1)}
        {localStorage.getItem('DSP_AUTH_TOKEN') && RouteBuilder(authRouterConfig, store1)}
        <Route exact path="/notfound" component={notFoundConfig} />
        <Route render={redirectToNotfound} />
      </Switch>
    </BrowserRouter>
  );
};
