import { ProfileNotificationForm } from "../types";

const IMAGE_TYPE_MAPPINGS = {
  'image/png': 1,
  'image/jpg': 2,
  'image/jpeg': 2,
};

const BASE_64_PREFIX_LIST = [
  'data:image/png;base64,',
  'data:image/jpg;base64,',
  'data:image/jpeg;base64,',
];

const PROFILE_PASSWORD_FORM_DEFAULT_VALUES = {
  currentPassword: '',
  newPassword: '',
  repeatNewPassword: '',
}


const PROFILE_LEGAL_FORM_DEFAULT_VALUES = {
  tinNumber: '',
  legalEntityName: '',
}

const PROFILE_NOTIFICATIONS_FORM_DEFAULT_VALUES: ProfileNotificationForm = {
  campaignRejected: true,
  outOfBudget: true,
  closeToThreshold: true,
  spaceOutOfBudget: true,
  campaignApproved: true,
  endDateReached: true,
};

export {
  IMAGE_TYPE_MAPPINGS,
  BASE_64_PREFIX_LIST,
  PROFILE_PASSWORD_FORM_DEFAULT_VALUES,
  PROFILE_LEGAL_FORM_DEFAULT_VALUES,
  PROFILE_NOTIFICATIONS_FORM_DEFAULT_VALUES,
}