import { Skeleton, TableCell, TableRow } from "@mui/material";

type Props = {
  cellCount: number;
  rowCount?: number;
}

function TableBodySkeleton({ rowCount = 10, cellCount }: Props) {

  const rows = Array(rowCount).fill(undefined);
  const cells = Array(cellCount).fill(undefined);

  return (
    <>
      {rows.map((row, idx) => (
        <TableRow className={'table-skeleton'} key={idx}>
          {cells.map((cell, idx) => (
            <TableCell className={`td-${idx}`} key={idx}>
              <Skeleton variant="rectangular" />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
}

TableBodySkeleton.displayName = 'TableBodySkeleton';

export default TableBodySkeleton;