import { Theme } from "@mui/material";

export const style = {
  crossIcon: {
    position: 'absolute' as const,
    left: 0,
    cursor: 'pointer',
    display: 'none'
  },
  image: (theme: Theme) => ({
    width: 34,
    height: 34,
    padding: theme.spacing(2),
    boxSizing: 'content-box',
    borderRadius: 1.5,
    backgroundColor: '#f4f6fa',
  }),
  images: {
    display: 'flex',
    gap: 2,
  },
  imageBox: {
    position: 'relative',
    '&:hover > svg': {
      display: 'unset !important',
    },
    '&:hover > img': {
      opacity: 0.1,
    }
  }
}