const BANNER_HTML = `<!doctype html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport"
        content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0">
  <meta http-equiv="X-UA-Compatible" content="ie=edge">
  <title>Ad</title>
  <style>
      @import url('https://fonts.cdnfonts.com/css/source-sans-pro');
      html {
          box-sizing: border-box;
      }

      *,
      *::after,
      *::before {
          box-sizing: inherit;
      }

      * {
          padding: 0;
          margin: 0;
          font-family: "Source Sans Pro", sans-serif;
      }
      .banner-description {
          color: rgba(41, 47, 65, 0.5);
          word-wrap: break-word;
      }
      .banner-title {
          color: #292f41;
          max-width: 274px;
          word-wrap: break-word;
      }
      .banner-logo-icon {
          width: 60px;
          height: 60px;
      }
      .banner-logo-title {
          font-size: 12px;
          font-weight: 600;
          color: #292f41;
      }
      .banner-cta {
          border-radius: 20px;
          background-image: linear-gradient(256deg, #65abf6, #2e6bde);
          font-size: 14px;
          line-height: 1.33;
          letter-spacing: 0.69px;
          color: rgba(255, 255, 255, 0.9);
          border: none;
          text-transform: uppercase;
          max-width: 150px;
          height: 40px;
          padding: 8px 12px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
      }

      .banner {
          max-height: 100vh;
          max-width: 100vw;
          background-color: #ffffff;
          overflow: hidden;
      }

      .banner-image-wrapper {
          max-height: 100vh;
      }

      .banner-image {
          width: 100%;
          object-fit: cover;
          max-height: 100%;
      }

      @media (orientation: landscape) {
          .banner {
              display: grid;
              grid-template-columns: 42.6% 31% 56px 156px;
              gap: 12px;
              align-items: center;
          }
          .banner-description {
              display: none;
          }
          .banner-logo {
              display: flex;
              flex-direction: column;
              align-items: center;
          }
          .banner-title {
              margin-left: 12px;
              font-size: 20px;
              font-weight: 600;
              color: #292f41;
          }
      }
      @media (orientation: landscape) and (max-width: 925px) {
          .banner {
              grid-template-columns: 42.6% 31% 56px 120px;
          }
      }
      @media (orientation: landscape) and (max-width: 800px) {
          .banner {
              grid-template-columns: 30% 31% 1fr 1fr;
          }
          .banner-logo {
              margin-left: auto;
          }

          .banner-cta {
              margin-right: 20px;
          }
      }
      @media (orientation: landscape) and (max-width: 400px) and (max-height: 99px) {
          .banner {
              grid-template-columns: 50px 140px auto;
          }

          .banner-title {
              font-size: 12px;
              font-weight: 600;
              line-height: 1.17;
              color: #292f41;
              grid-column: 2/3;
              grid-row: 2/3;
              margin-left: 10px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
          }

          .banner-logo {
              grid-row: 1/2;
              grid-column: 2/3;
              align-items: flex-start;
              margin-left: 10px;
          }

          .banner-logo-icon {
              display: none;
          }

          .banner-cta {
              font-size: 12px;
              padding: 0;
              height: 32px;
              grid-column: 3/4;
              grid-row: 1/3;
              margin-left: auto;
              margin-right: 12px;
              width: unset;
              padding: 0 12px;
          }

          .banner {
              height: 100vh;
              gap: 0;
          }

          .banner-image-wrapper {
              height: 100%;
              grid-column: 1/2;
              grid-row: 1/3;
          }

          .banner-image {
              height: 100%;
          }
      }
      @media (orientation: landscape) and (max-width: 300px) and (max-height: 150px) {
          .banner {
              grid-template-columns: 96px auto;
              gap: 0;
              height: 100vh;
          }

          .banner-image-wrapper {
              grid-column: 1/2;
              grid-row: 1/3;
              height: 100%;
          }

          .banner-image {
              height: 100%;
          }

          .banner-title {
              grid-column: 2/4;
              grid-row: 1/2;
              font-size: 14px;
              margin-left: 18px;
              margin-right: 16px;
              margin-top: 12px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
          }

          .banner-cta {
              grid-column: 2/3;
              grid-row: 2/3;
              margin-right: 0;
              width: 70px;
              padding: 0;
              font-size: 12px;
              height: 32px;
              margin-left: 18px;
              margin-bottom: 12px;
          }

          .banner-logo-title {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              max-width: 50px;
          }

          .banner-logo {
              grid-column: 3/4;
              grid-row: 2/3;
              flex-direction: row-reverse;
              gap: 4px;
              margin-right: 16px;
              margin-bottom: 12px;
          }

          .banner-logo-icon {
              width: 40px;
              height: 40px;
          }
      }

      @media (max-width: 300px) and (min-height: 250px) {
          .banner {
              grid-template-columns: unset;
              gap: 0;
          }

          .banner-image-wrapper {
              height: 122px;
              grid-row: 1/2;
              grid-column: 1/3;
          }

          .banner-image {
              height: 100%;
          }

          .banner-title {
              grid-row: 2/3;
              grid-column: 1/3;
              margin-left: 16px;
              margin-top: 12px;
              margin-bottom: 16px;
          }

          .banner-cta {
              grid-row: 3/4;
              grid-column: 1/2;
              width: 120px;
              margin-left: 16px;
              margin-bottom: 20px;
              margin-top: auto;
          }

          .banner-logo {
              grid-row: 3/4;
              grid-column: 2/3;
              flex-direction: row-reverse;
              margin-right: 16px;
              margin-bottom: 20px;
              margin-top: auto;
          }

          .banner-logo-title {
              margin-right: 4px;
              font-size: 10px;
          }

          .banner-logo-icon {
              width: 40px;
              height: 40px;
          }
      }

      @media (orientation: portrait) {
          .banner {
              display: flex;
              flex-direction: column;
              height: 100vh;
          }

          .banner-image-wrapper {
              height: 225px;
          }

          .banner-title {
              font-size: 28px;
              line-height: 1.29;
              font-weight: bold;
              margin: 16px 24px 0;
          }

          .banner-description {
              font-size: 16px;
              line-height: 1.25;
              letter-spacing: 0.23px;
              margin: 4px 24px 0;
          }

          .banner-logo {
              margin-left: 24px;
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 8px;
              margin-top: auto;
          }

          .banner-cta {
              width: calc(100% - 48px);
              max-width: unset;
              margin: 0 24px 24px;
          }
      }

      @media (orientation: portrait) and (max-width: 240px) and (max-height: 400px) {
          .banner-image {
              max-height: 191px;
              height: 191px;
          }

          .banner-title {
              font-size: 20px;
              margin: 10px 16px 8px;
          }

          .banner-description {
              margin: 0 16px;
          }

          .banner-cta {
              display: none;
          }

          .banner-logo-icon {
              height: 40px;
              width: 40px;
          }

          .banner-logo {
              margin: auto 16px 16px;
          }

          .banner-image-wrapper {
              height: unset;
          }
      }

      @media (orientation: portrait) and (max-width: 180px) {
          .banner-title {
              font-size: 20px;
              margin: 18px 16px 16px;
          }

          .banner-description {
              margin: 0 16px;
          }

          .banner-logo {
              margin: auto 16px 8px;
              flex-direction: column;
              width: fit-content;
              align-items: center;
          }

          .banner-image-wrapper {
              min-height: 212px;
              height: 212px;
          }

          .banner-cta {
              margin: 0 20px 20px;
              display: unset;
          }
      }

      @media (aspect-ratio: 1/1) and (max-height: 400px) {
          .banner-image-wrapper {
              height: 120px;
              grid-row: 1/2;
              grid-column: 1/4;
          }

          .banner-title {
              font-size: 20px;
              grid-row: 2/3;
              grid-column: 1/4;
              margin: 12px 16px 0;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              height: 50px;
          }

          .banner-description {
              display: none;
          }

          .banner-cta {
              display: unset;
              grid-row: 3/4;
              grid-column: 1/3;
              width: 154px;
              margin: 18px 0 20px 16px;
          }

          .banner-logo {
              grid-row: 3/4;
              grid-column: 3/4;
              margin: 0;
              flex-direction: column;
              gap: 2px;
              margin-top: auto;
              margin-bottom: 18px;
          }

          .banner-logo-icon {
              width: 40px;
              height: 40px;
          }

          .banner {
              display: grid;
          }
      }
      @media (aspect-ratio: 1/1) and (max-width: 400px) and (min-width: 400px) {
          .banner-image-wrapper {
              height: 250px;
          }
      }
      @media (orientation: portrait) and (max-height: 500px) and (max-aspect-ratio: 0.99/1) {
          .banner-cta {
              display: none;
          }
      }
      @media (orientation: portrait) and (max-height: 400px) and (min-height: 300px) {
          .banner-logo {
              display: none;
          }
      }
      @media (orientation: portrait) and (max-height: 350px) and (min-height: 300px) {
          .banner-description {
              display: none;
          }
      }
      @media (orientation: landscape) {
          .banner-image-wrapper {
              height: 100vh;
          }

          .banner-image {
              height: 100vh;
          }
      }
      @media (orientation: landscape) and (max-width: 500px) {
          .banner-cta {
              display: none;
          }
      }
      @media (width: 240px) and (height: 400px) {
        .banner-logo {
          display: flex;
        }
      }
      @media (width: 300px) and (min-height: 249px) and (max-height: 251px) {
        .banner-image-wrapper {
          height: 122px;  
        } 
        .banner-cta {
          display: unset;
        }
      }
      @media (width: 320px) and (min-height: 49px) and (max-height: 51px) {
        .banner-cta {
          display: unset;
        }
      }
      @media (width: 300px) and (height: 100px) {
        .banner-cta {
          display: unset;
        }
        .banner-title {
          font-size: 14px;
          font-weight: bold;
          line-height: 1.14;
          letter-spacing: 0.2px;
          color: #292f41;
        }
      } 
  </style>
</head>
<body>
<div class="banner">
  <div class="banner-image-wrapper">
    <img src="$$PHOTO_URL$$" alt="Banner Image" class="banner-image">
  </div>
  <p class="banner-title">$$TITLE$$</p>
  <p class="banner-description">$$DESCRIPTION$$</p>
  <div class="banner-logo">
    <img src="$$ICON_URL$$" alt="Banner Icon" class="banner-logo-icon">
    <p class="banner-logo-title">$$COMPANY_NAME$$</p>
  </div>
  <button class="banner-cta">$$CALL_TO_ACTION$$</button>
  <img style="position:absolute;top:0;right:0;" src="https://cdn1.intent.ai/intent.svg">
  <a href="$$INTENT_CLICK$$" target="_blank" style="position:absolute;top:0;left:0;right: 0;bottom: 0;z-index: 9999 !important;"></a>
</div>
</body>
</html>`

const NATIVE_APP_AD_HTML = `<!doctype html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport"
        content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0">
  <meta http-equiv="X-UA-Compatible" content="ie=edge">
  <title>Document</title>
  <style>
      @import url('https://fonts.cdnfonts.com/css/source-sans-pro');
      html {
          box-sizing: border-box;
          width: 268px;
          height: 298px;
          background-color: #fff;
      }

      *,
      *::after,
      *::before {
          box-sizing: inherit;
      }

      * {
          padding: 0;
          margin: 0;
          font-family: "Source Sans Pro", sans-serif;
      }

      .ad_header {
          margin-top: 12px;
          margin-left: 12px;
          margin-right: 12px;
          display: flex;
          align-items: center;
      }
      .app_name {
          font-size: 14px;
          font-weight: 600;
          color: #292f41;
          margin-left: 12px;
      }
      .ad_description {
          font-size: 13px;
          color: #292f41;
          margin: 4px 12px;
          text-overflow: ellipsis;
          overflow: hidden;
          -webkit-line-clamp: 2;
      }
      .cta {
         border-radius: 20px;
         background-image: linear-gradient(263deg, #65abf6, #2e6bde);
         height: 32px;
         width: calc(100% - 24px);
         margin-right: 12px;
         border: none;
         margin-left: 12px;
         margin-top: 12px;
         margin-bottom: 14px;
         font-size: 14px;
         line-height: 1.14;
         letter-spacing: 0.8px;
         color: rgba(255, 255, 255, 0.9);
      }
      .none {
        display: none;
      }
  </style>
</head>
<body>
  <div>
    <div class="ad_header">
      <img src="" alt="App Icon" class="app_icon" width="48px" height="48px" />
      <p class="app_name"></p>
    </div>
    <p class="ad_description"></p>
    <video controls autoplay muted width='100%' height="135px">
      <source src="" type="video/mp4" class='video' />
    </video>
    <img src="" alt="Banner Image" class="image" width="100%" height="135px" />
    <button class="cta"></button>
  </div>
</body>
</html>`;

const VIDEO_AD_PREVIEW_HTML = `<!doctype html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport"
        content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0">
  <meta http-equiv="X-UA-Compatible" content="ie=edge">
  <title>Document</title>
</head>
<body>
  <style>
        @import url('https://fonts.cdnfonts.com/css/source-sans-pro');
      html {
          box-sizing: border-box;
          background-color: #fff;
      }

      *,
      *::after,
      *::before {
          box-sizing: inherit;
      }

      * {
          padding: 0;
          margin: 0;
          font-family: "Source Sans Pro", sans-serif;
      }
     html {
      overflow: hidden;
     } 
     body {
         height: 265px;
         width: 300px;
     }
     * {
      box-sizing: border-box;
     }
  </style>
  <div>
    <video controls autoplay muted width='300' height="265">
      <source src="" type="video/mp4" class='video' />
    </video>
  </div>
</body>
</html>`;

export {
  NATIVE_APP_AD_HTML,
  VIDEO_AD_PREVIEW_HTML,
};

export default BANNER_HTML;
