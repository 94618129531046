import React, { useCallback, useEffect, useState } from "react";
import { IAdminAccountsUser, } from "../../../../../types";
import { Modal } from "../../../../../containers";
import "./style.scss";
import { debounce } from "@mui/material";

import AdminAccountsTotals from "../components/adminAccountTotals";
import { useForm } from "react-hook-form";
import { StatusCode } from "../../../../global/types";
import { AccountsList } from "../types";
import { AccountService } from "../services";
import { ADMIN_ACCOUNTS_LIST_DEFAULT_FILTER } from "../uitls";
import AdminAccountsFilter from "../components/AdminAccountsFilter";
import { CoreTable } from "../../../../global/components/core-table";
import { numberToStringWithCommas } from "../../../../global";
import { TableNotFound } from "../../../../../components";
import { getAdminAccountsTotalsRequest } from "../../../../../api";


const headCells = [
  {key: 'full_name', parser: (data: string) => data || "" },
  {key: 'company_name', parser: (data: string) => data || "" },
  {key: 'entity_name', parser: (data: string) => data || "-" },
  {key: 'running_campaigns', parser: (data: string) => data || "0" },
  {key: 'balance', parser: (data: number) => numberToStringWithCommas(data, 2)}
];

const AdminAccountsContainer = (props: any) => {
  const {
    toggle,
    modalTypes,
    openModal,
  } = props;


  const [accounts, setAccounts] = useState<AccountsList>({
    data: [],
    total_size: 0,
    isLoading: true,
  });

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [account, setAccount] = useState<any>(null);
  const [accountsUsersIsLoading, setAccountsUsersIsLoading] = useState<boolean>(false);
  const [accountsTotalsIsLoading, setAccountsTotalsIsLoading] = useState<boolean>(false);
  const [accountsTotals, setAccountsTotals] = useState<Nullable<any>>(null);

  const [requestData, setRequestData] = useState<any>(
    ADMIN_ACCOUNTS_LIST_DEFAULT_FILTER
  );

  const { control } = useForm({
    defaultValues: ADMIN_ACCOUNTS_LIST_DEFAULT_FILTER,
  });

  const onRowClick = (account: IAdminAccountsUser) => {
    setAccount(account);
    setIsOpen(true);
  };

  const renderNotfound = () => {
    return (
      <TableNotFound
        titleId="Admin.accountsNotFoundTitle"
        titleDefaultMessage="No Accounts found"
        descriptionId="Admin.accountsNotFoundDescription"
        descriptionDefaultMessage="Try adjusting filters or choosing different date range."
      />
    );
  };

  const getAccounts = useCallback(debounce(function getAccounts(data) {
    setAccountsUsersIsLoading(true)
    setRequestData(data);
    setAccounts(data => ({
      ...data,
      isLoading: true,
      data: [],
    }));
    AccountService.getAccounts({ ...data })
      .then(({ data }) => {
        if (data.type?.code === StatusCode.OK) {
          setAccounts({
            data: data.data.data,
            total_size: data.data.total_size,
            page_size: data.data.page_size,
            current_page: data.data.current_page,
            isLoading: false,
          });
        }
        setAccountsUsersIsLoading(false);
      });
    // eslint-disable-next-line
  }, 200), []);

  const onPageChange = (newPage: number) => {
    setAccountsUsersIsLoading(true)
    AccountService.getAccounts({ ...requestData, page: newPage })
      .then(({ data }) => {
        if (data.type?.code === StatusCode.OK) {
          setAccounts({
            data: data.data.data,
            total_size: data.data.total_size,
            page_size: data.data.page_size,
            current_page: data.data.current_page,
            isLoading: false,
          });
        }
        setAccountsUsersIsLoading(false);
      });
  }

  const getAdminAccountsTotals = () => {
    setAccountsTotalsIsLoading(true);
    getAdminAccountsTotalsRequest().then(({data}) => {
      if(data.type.code === 0) {
        setAccountsTotals(data.data);
      }
      setAccountsTotalsIsLoading(false);
    })
  }

  useEffect(() => {
    getAdminAccountsTotals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={'admin-accounts-wrap'}>
        <div>
          <AdminAccountsTotals
            totals={accountsTotals}
            loading={accountsTotalsIsLoading}
          />
        </div>
        <AdminAccountsFilter
          getAccounts={getAccounts}
          control={control}
        />

        <CoreTable
          data={accounts}
          translationPrefix={'admin.account.list'}
          loading={accountsUsersIsLoading}
          headCells={headCells}
          hasPagination={true}
          noData={renderNotfound()}
          onPageChange={(page) => onPageChange(page)}
          onRowClick={(row) => onRowClick(row)}
        />
      </div>
      {isOpen && (
        <Modal
          modalData={{
            account: account,
          }}
          modalType={"adminAccountsModal"}
          toggle={toggle}
          isOpen={isOpen}
          openModal={openModal}
          closeModal={() => setIsOpen(false)}
          modalTypes={modalTypes}
        />
      )}
    </>
  );
}

export default AdminAccountsContainer;
