import { Alert, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { NotificationService, SubscriberFnParam } from "../../services";

function NotificationProvider() {
  const [snackbarParams, setSnackbarParams] = useState<SubscriberFnParam>();

  function handleNotification(params: SubscriberFnParam) {
    setSnackbarParams(params);
  }

  useEffect(() => {
    NotificationService.subscribe(handleNotification);
    return () => {
      NotificationService.unSubscribe(handleNotification);
    }
  }, []);

  function handleNotificationClose() {
    setSnackbarParams(undefined);
  }

  return (
    <Snackbar open={!!snackbarParams} autoHideDuration={snackbarParams?.autoHideDuration} onClose={handleNotificationClose}>
      {snackbarParams && (
        <Alert onClose={handleNotificationClose} severity={snackbarParams.severity}>{snackbarParams.message}</Alert>
      )}
    </Snackbar>
  );
}

NotificationProvider.displayName = 'NotificationProvider';

export default NotificationProvider;
