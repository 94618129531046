export enum CampaignObjective {
  ENHANCED_EXPOSURE = 'enhanced_exposure',
  MAXIMIZED_REACH = 'maximized_reach',
  MORE_CLICKS = 'more_clicks',
  WEB_STANDARD_CONVERSION = 'web_standard_conversion', //
  CUSTOM_CONVERSIONS = 'custom_conversions',
  APP_INSTALLS = 'app_installs',//
  LANDING_PAGE_TRAFFIC = 'landing_page_traffic',
  WEBSITE_CUSTOM_EVENT = 'website_custom_event', //
  APP_CUSTOM_EVENT = 'app_custom_event',
  AUDIO_ADVERTISING = 'audio_advertising',
}

export enum CampaignObjectiveType {
  AWARENESS = 'awareness',
  CONVERSION = 'conversion',
  OTHER = 'other',
  CONSIDERATION = 'consideration',
}
