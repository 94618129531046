import React from "react";
import AdminHeader from "../../components/AdminHeader";
import { useTranslation } from "react-i18next";
import AdminCampaignList from "./components/AdminCampaignList";


const AdminCampaigns = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.campaigns' });

  return (
    <div>
      <AdminHeader title={t("title")} >
        <div>
          <AdminCampaignList />
        </div>
      </AdminHeader>
    </div>
  );
}

export default AdminCampaigns;
