import { Controller, ControllerProps, Control } from "react-hook-form";
import { ComponentType } from "react";
import { AutocompleteProps, MenuItemProps, SelectProps, SliderProps, TextFieldProps } from "@mui/material";
import { IntTextField } from "../index";
import { SelectOption } from "../IntSelect";
import { getFieldErrorMessage } from "../../../utils";
import { IntDateRangePickerProps } from "../IntDateRangePicker";

type Props = Omit<Omit<ControllerProps, 'render'>, 'control'> &{
  Component?: ComponentType<any>;
  render?: ControllerProps['render'];
  options?: SelectOption[] | any[];
  menuItemProps?: MenuItemProps;
  control: Control<any>;
  sitekey?: string;
  dirtyText?: string;
  hideErrorMessage?: boolean;
  minDate?: Date;
  disableClearable?: boolean;
  extractValue?: boolean;
}
& Partial<TextFieldProps>
& Partial<SelectProps>
& Partial<SliderProps>
& Partial<AutocompleteProps<any, any, any, any>>
& Partial<IntDateRangePickerProps>;

function IntFieldController({
  Component = IntTextField,
  name,
  control,
  render,
  children = null,
  ...componentProps
}: Props) {
  return (
    <Controller
      render={render ? render : ({
         field: {
           ref,
           ...field
         },
         fieldState,
         formState: state,
         ...controlProps
      }) => (
        <Component
          {...field}
          {...controlProps}
          {...componentProps}
          inputRef={ref}
          errorMessage={getFieldErrorMessage(state.errors, name)}
        >{children}</Component>
      )}
      name={name}
      control={control}
    />
  )
}

IntFieldController.displayName = 'IntFieldController';

export default IntFieldController;
