import React, { Component } from "react";
import "./style.scss";
import clsx from "clsx";
class AudienceSizeIndicator extends Component<any> {
  render() {
    const {
      props: { estimate },
    } = this;
    const red1 = estimate <= 10000 && estimate !== 0;
    const yellow1 = estimate > 10000 && estimate < 50000;
    const green = estimate > 50000 && estimate <= 100000;
    const yellow2 = estimate > 100000 && estimate < 250000;
    const red2 = estimate >= 250000;
    return (
      <>
        <div className="audience-size-indicator">
          <span
            className={clsx("indicator", {
              "active-3": red1,
            })}
          />
          <span
            className={clsx("indicator", {
              "active-1": yellow1,
            })}
          />
          <span
            className={clsx("indicator", {
              "active-2": green,
            })}
          />{" "}
          <span
            className={clsx("indicator", {
              "active-1": yellow2,
            })}
          />{" "}
          <span
            className={clsx("indicator", {
              "active-3": red2,
            })}
          />
        </div>
        <div className="audience-size-indicator-text">
          <p>Too Specific</p>
          <p>Too broad</p>
        </div>
      </>
    );
  }
}

export default AudienceSizeIndicator;
