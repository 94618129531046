// import { IStore } from "../../../../store";

export const CURRENCIES: Record<string, string> = {
  'NGN': '₦',
  'USD': '$',
  undefined: '$',
  null: '$',
};
export const CURRENCYICONS: Record<string, string> = {
  'NGN': '₦',
  'USD': '$',
  undefined: 'n/a',
  null: 'n/a',
};

// function getCurrentAccountCurrency(store: IStore) {
//   const storedCurrency: string | null = sessionStorage.getItem('currentAccountCurrency');
//   const currency: string = !storedCurrency ? store.authentication?.user?.currency : storedCurrency;
//   return CURRENCIES[currency];
// }
//
// function getWhitelabelCurrency(store: IStore) {
//   return CURRENCIES[store.authentication?.user?.currency] || '₦';
// }
//
// function getCurrentSpaceIdSelector(store: IStore) {
//   return store.authentication?.space?.id;
// }

