import { Theme } from "@mui/material";

export const style = {
  selectType: {
    textTransform: 'uppercase',
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: '.8px',
    color: '#292f41',
    marginBottom: 2,
    marginLeft: 2,
  },
  label: {
    textTransform: 'uppercase',
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: '.8px',
    color: '#292f41',
  },
  radioLabel: {
    fontSize: 14,
    color: '#292f41',
    letterSpacing: '0.2px',
    lineHeight: 1.14,
    display: 'flex',
    gap: 1,
  },
  radio: {
    display: 'flex',
    alignItems: 'center',
  },
  radioGroup: {
    flexDirection: 'row',
    gap: 11,
    rowGap: 0,
  },
  awarenessForm: (theme: Theme) => ({
    padding: theme.spacing(0, 12),
    width: '100%',
  }),
  field: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1.5,
  },
  multipleLabelsField: {
    gap: 2,
  },
  pasteGTM: (theme: Theme) => ({
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: '0.2px',
    color: '#292f41',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(3),
  }),
  withTooltip: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
  },
  customHTML: {
    '&&': {
      background: '#fff',
      '& textarea.Mui-disabled:disabled': {
        fontSize: 14,
        position: 'relative',
        webkitTextFillColor: '#292f41 !important',
      },
      '& > div': {
        padding: 3,
      }
    }
  },
  copyIcon: {
    width: 36,
    height: 36,
    cursor: 'pointer',
  },
  optimizeCampaign: {
    fontSize: 11,
    lineHeight: 1.18,
    color: 'rgba(41, 47, 65, 0.5)',
  },
  divider: (theme: Theme) => ({
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
  }),
  adornment: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: 36,
    height: 36,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  }
}