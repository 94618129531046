import { Theme } from "@mui/material";

export const style = {

  tableRow: (theme: Theme) => ({
    cursor: 'pointer',
    '&:hover': {
      background: '#f2f8fc',
      transition: '.3s',
    },
    '& > td': {
      padding: theme.spacing(1, 0),
      // width: '300px !important',
    },
  }),
  logDate: {
    fontSize: 16,
    padding: '0 16px',
    textWrap: 'nowrap',
  },
  logNew: {
    fontSize: 16,
    padding: '0 16px',
    maxWidth: 550,
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    textWrap: 'nowrap'
,  },

  logUser: {
    fontSize: 16,
    padding: '0 16px',
  },

};
