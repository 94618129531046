import {
  IAdminSearch,
  IWhitelabel,
  IModerationAction,
  IBaseFilters,
} from "../types";
import { ApiService } from "../modules/global/services";

const EMPTY_OBJECT = {};
const HEADER_PLAIN_TEXT = {
  "Content-Type": "text/plain",
};

export const getAdminSearchResultRequest = (data: IAdminSearch) =>
  ApiService.post("/v1/whitelabel/search", data);

export const getAdminAccountSearchResultRequest = (data: IAdminSearch) =>
  ApiService.post("/v1/whitelabel/account-search", data);

export const setAdminNewSpaceRequest = (id: string) =>
  ApiService.post(`/v1/user/account/switch/${id}`,{});

export const getWhitelabelCustomizationDataRequest = (id: string) =>
  ApiService.get(`/v1/whitelabel/${id}`);

export const setWhitelabelCustomizationDataRequest = (data: IWhitelabel) =>
  ApiService.put(`/v1/whitelabel`, data);

export const getWhiteLabelWithdrawHistoryRequest = (data = EMPTY_OBJECT) =>
  ApiService.post("/v1/cash-out/history", data);

export const getWithdrawBalanceRequest = () =>
  ApiService.get(`/v1/cash-out/withdraw/balance`);

export const getIncomeOverviewRequest = (data = EMPTY_OBJECT) =>
  ApiService.post(`/v1/reports/whitelabel/income/history`, data);

export const sendWithdrawAmountRequest = (amount = 0) =>
  ApiService.get(`/v1/cash-out/${amount}`);

export const approveCampaignRequest = (data = EMPTY_OBJECT) =>
  ApiService.post(`/v1/whitelabel/admin/campaign/approve`, data);

export const rejectCampaignRequest = (data = EMPTY_OBJECT) =>
  ApiService.post(`/v1/whitelabel/admin/campaign/reject`, data);

export const getModerationRegistrationsRequest = (data = EMPTY_OBJECT) =>
  ApiService.post(`/v1/whitelabel/admin/registrations`, data);

export const getModerationLegalUsersRequest = (data = EMPTY_OBJECT) =>
  ApiService.post(`/v1/whitelabel/admin/legal/users`, data);

export const setModerationLegalUsersApproveRequest = (data: IModerationAction) =>
  ApiService.post(`/v1/whitelabel/admin/user/approve`, data);

export const setModerationLegalUsersRejectRequest = (data: IModerationAction) =>
  ApiService.post(`/v1/whitelabel/admin/user/reject`, data);

export const getModerationRegistrationRequest = (id: string) =>
  ApiService.get(`/v1/whitelabel/admin/user/${id}`);

export const setModerationRegistrationApproveRequest = (data = EMPTY_OBJECT) =>
  ApiService.post(`/v1/whitelabel/admin/registration/approve`, data);

export const resendConfirmationEmailRequest = (id: string) =>
  ApiService.post(`/v1/whitelabel/user/confirmation/email/resend`, id, HEADER_PLAIN_TEXT,true,false);

export const getModerationCampaignRequest = (data = EMPTY_OBJECT) =>
  ApiService.post(`/v1/whitelabel/admin/campaigns`, data);

export const getCompanyWhitelabelRequest = (companyId: string) =>
  ApiService.get(`/v1/whitelabel/${companyId}`);

export const getCompanyWhitelabelDataRequest = () =>
  ApiService.baseGet("/v1/whitelabel/info");

export const getAdminAccountsListRequest = (filters: IBaseFilters) =>
  ApiService.post(`/v1/whitelabel/admin/accounts/list`, filters);

export const getAdminPublisherListRequest = (filters: IBaseFilters) =>
  ApiService.post(`/v1/whitelabel/admin/publishers/list`, filters);

export const getAdminAccountsExcelReportRequest = (filters: IBaseFilters) =>
  ApiService.post(`/v1/whitelabel/admin/accounts/excel`, filters, );

export const getAdminAccountsTotalsRequest = () =>
  ApiService.post(`/v1/whitelabel/admin/accounts/totals`, EMPTY_OBJECT);

export const adminNewSpaceRequest = (id: string) =>
 ApiService.post(`${window.REACT_APP_BASE_URL}/v1/user/account/switch/${id}`, {});
