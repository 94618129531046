import { Theme } from "@mui/material";

export const style = {
  player: (theme: Theme) => ({
    width: '100%',
    height: 60,
    backgroundColor: '#f4f6fa',
    padding: theme.spacing(3, 4),
    display: 'flex',
    alignItems: 'center',
    borderRadius: 2,
  }),
  filename: {
    fontSize: 14,
    color: '#292f41',
    letterSpacing: '0.2px',
    lineHeight: 1.14,
  },
  filesize: {
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: '0.2px',
    color: 'rgba(41, 47, 65, 0.5)',
  },
  playerState: {
    backgroundColor: '#dfe7f0',
    width: 160,
    height: 2,
    marginLeft: 3,
  },
  playerActions: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  duration: (theme: Theme) => ({
    marginLeft: theme.spacing(2),
    fontSize: 12,
    color: '#292f41',
    letterSpacing: '0.8px',
    lineHeight: 1,
    width: 30,
  }),
  playerIcon: (theme: Theme) => ({
    width: 36,
    height: 36,
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
  }),
  loadedPercentage: (width: number) => ({
    width: `${width}%`,
    height: '100%',
    backgroundColor: '#3a77eb',
  }),
  crossIcon: {
    cursor: 'pointer',
  }
};