import { Box, Button, MenuItem, Modal, Paper, Select, TextField, Typography } from "@mui/material";
import { Control, SetFieldValue, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { style } from './style';
import { lazy, Suspense, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import { NotificationService } from "../../../../../global/services";
import { CampaignService } from "../../../../../campaign/services";
import { AddIcon, downloadBlob, IntDateRangePicker, IntFieldController } from "../../../../../global";
import { CampaignStatus } from "../../../../../campaign/types";

const CampaignObjective = lazy(() => import('../../../../../campaign/components/CampaignObjective'));

type Props = {
  getCampaigns: Function;
  control: Control<any>;
  setValue: SetFieldValue<any>;
  userId: string;
  modalOpen: boolean;
  handleModalOpen: () => void;
  handleModalClose: () => void;
}

function AdminCampaignListFilter({
  getCampaigns,
  control,
  setValue,
  userId,
  modalOpen,
  handleModalOpen,
  handleModalClose
}: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'campaign.list.filter' });

  const fields = useWatch({
    control,
  });
  useEffect(() => {
    getCampaigns({
      order_field: "",
      order_asc: false,
      from_date: fields.date?.from,
      to_date: fields.date?.to,
      name: fields.name,
      status_filter: fields.status_filter || [],
      // status_filter: (fields.status_filter || []).map(({ value }: { value: string; }) => value),
      space_id: userId,
      fromFiler: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fields.name,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    fields.status_filter,
    fields.date?.from,
    fields.date?.to,
    getCampaigns,
    userId
  ]);

  async function exportToCSV() {
    const resp = await CampaignService.exportToCSV(userId, {
      statusFilter: (fields.status_filter || []).map(({ value }: { value: string; }) => value),
      fromDate: fields.date.from,
      toDate: fields.date.to,
    });

    downloadBlob(resp.data, 'campaigns.csv');
    NotificationService.handleSuccess(t('exportSuccess'));
  }

  return  <Box className={'report-list-filter top-radius'}>
    <SearchIcon fontSize={'medium'}/>
    <TextField
      className={'report-list-filter-search-input'}
      variant="standard"
      value={fields.name}
      onChange={(e) => setValue('name', e.target.value)}
      placeholder={t<string>('campaignPlaceholder')}
      InputProps={{
        disableUnderline: true,
      }}/>
    <Button
      variant='contained'
      startIcon={<AddIcon fontSize={'large'} sx={style.addIcon}/>}
      sx={style.add}
      onClick={handleModalOpen}
    >
    </Button>
    <Modal open={modalOpen} onClose={handleModalClose}>
      <Paper sx={style.paper}>
        <Suspense fallback={null}>
          <CampaignObjective handleModalClose={handleModalClose} />
        </Suspense>
      </Paper>
    </Modal>
    <Typography  className={'report-list-filter-csv'} sx={{...style.exportCSV, marginBottom: '12px',
      textAlign: 'end'}} onClick={exportToCSV}>
      {t('exportCSV')}
    </Typography>
    <Select
      sx={{ minWidth: 30, backgroundColor: 'transparent', marginRight: '30px',  float: 'right'}}
      variant="standard"
      displayEmpty
      IconComponent={ExpandMoreIcon}
      disableUnderline
      value={fields.status_filter}
      placeholder={t<string>('statusFilter')}
      onChange={(e) => {
        setValue('status_filter', e.target.value === "" ? [] : [e.target.value])
      }}
    >
      <MenuItem value={CampaignStatus.RUNNING }>{'Running'}</MenuItem>
      {/*{*/}
      {/* STATUS_LIST_CAMPAIGNS_FILTER.map(({label, value}: any) => (*/}
      {/*    <MenuItem key={value} value={value}>{label}</MenuItem>*/}
      {/*  ))*/}
      {/*}*/}
    </Select>
    <Typography className={'report-list-filter-dimensions-label'}>
      {t('statusFilter')}
    </Typography>

    <Box sx={style.dateRange}>
      <IntFieldController control={control} name='date' Component={IntDateRangePicker} />
    </Box>
    <Typography className={'campaign-list-filter-date-label'}>{t('showMetricsFor')}</Typography>
  </Box>
}


export default AdminCampaignListFilter;