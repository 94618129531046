const minDuration: number = 15;
const maxDuration: number = 30;

type TAudioValidateResult = {
  isValid: boolean,
  errorKey: string
}
export const validateAudioFile = async (file: File): Promise<TAudioValidateResult> => {
  if(file.type !== 'audio/mpeg' && !(/\.(mp3)$/i.test(file.name))){
    return {
      isValid: false,
      errorKey: 'invalidFileError'
    };
  }

  const audioContext = new AudioContext();
  const buffer = await file.arrayBuffer();
  const audioBuffer = await audioContext.decodeAudioData(buffer);
  const duration = audioBuffer.duration;

  if(+duration < minDuration) return {
    isValid: false,
    errorKey: 'minLengthError'
  };

  if(+duration > maxDuration) return {
    isValid: false,
    errorKey: 'maxLengthError'
  };

  return {
    isValid: true,
    errorKey: ''
  };
}
