import { ApiService } from "../../global/services";

class EventService {
  static ENDPOINT = `${window.REACT_APP_BASE_URL}/v1/events`;
  static SPACEID(){ return sessionStorage.getItem('spaceID');}

  static getEvents(advId: string, eventName?: string) {
    // return ApiService.get(`${EventService.ENDPOINT}/debug/list?${eventName ? 'event-name=' + eventName : ""}adv-id=${advId}`);
    return ApiService.get(`${EventService.ENDPOINT}/debug/list?adv-id=${EventService.SPACEID()}${eventName ? "&event-name=" + eventName : ""}`);
  }
  static getEventsPerMinute(minute: string, advId: string, eventName?: string) {
    return ApiService.get(`${EventService.ENDPOINT}/debug/slot?adv-id=${EventService.SPACEID()}&minute=${minute}${eventName ? "&event-name=" + eventName : ""}`);
  }

  static getEventsCount( advId: string, eventName?: string) {
    return ApiService.get(`${EventService.ENDPOINT}/debug/count?adv-id=${EventService.SPACEID()}${eventName ? "&event-name=" + eventName : ""}`);
  }
  static getEventDetails(id: string) {
    return ApiService.get(`${EventService.ENDPOINT}/debug/${id}`);
  }
  static getTopEvents(count: string) {
    return ApiService.get(`${EventService.ENDPOINT}/debug/top-events?count=${count}&adv-id=${EventService.SPACEID()}`);
  }
}

export default EventService;