/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 9/3/2023
 * All Rights Reserved.
 */
import { IntFieldController, Scheduling } from "../../../global";
import { Box, Divider, InputAdornment, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { style } from './style';
import { useFormContext, useWatch } from "react-hook-form";
import { CampaignFormDTO, CampaignObjective, CampaignObjectiveType } from "../../types";
import { ReactNode } from "react";

type Props = {
  footer: ReactNode;
}

function Frequency({ footer }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'campaign.frequency' })

  const {
    control,
    setValue,
  } = useFormContext<CampaignFormDTO>();

  const [schedule, frequencyCap, biddingStrategy] = useWatch({
    name: ['scheduling', 'frequencyCap', 'bidding_strategy'],
    control,
  });

  function setSchedule(data: { [key: string]: Array<string> } | Function) {
    if (typeof data === 'function') {
      setValue('scheduling', data(schedule));
    } else {
      setValue('scheduling', data);
    }
  }

  const daySelectDisabled = Boolean(frequencyCap['2'] || frequencyCap['3']);
  const weekSelectDisabled = Boolean(frequencyCap['1'] || frequencyCap['3']);
  const monthSelectDisabled = Boolean(frequencyCap['1'] || frequencyCap['2']);

  const isAwarenessCampaign = biddingStrategy?.type === CampaignObjectiveType.AWARENESS;
  const isMoreReach = biddingStrategy?.goal === CampaignObjective.MAXIMIZED_REACH;

  const frequencyCapDisabled = isAwarenessCampaign && isMoreReach;

  return (
    <Box sx={style.frequency}>
      <Typography sx={style.title}>{t(frequencyCapDisabled ? 'scheduling' : 'title')}</Typography>
      <Divider />
      <Box sx={style.frequencyFields}>
        {!frequencyCapDisabled && (
          <>
            <Typography sx={style.label}>{t('inputsLabel')}</Typography>
            <Box sx={style.fields}>
              <IntFieldController
                control={control}
                name='frequencyCap.1'
                disabled={daySelectDisabled}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      {t('daily')}
                    </InputAdornment>
                  )
                }}
                sx={style.input}
              />
              <IntFieldController
                control={control}
                name='frequencyCap.2'
                disabled={weekSelectDisabled}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      {t('weekly')}
                    </InputAdornment>
                  )
                }}
                sx={style.input}
              />
              <IntFieldController
                control={control}
                name='frequencyCap.3'
                disabled={monthSelectDisabled}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      {t('monthly')}
                    </InputAdornment>
                  )
                }}
                sx={style.input}
              />
            </Box>
          </>
        )}
        <Typography sx={style.label}>{t('schedulingDescription')}</Typography>
      </Box>
      <Scheduling schedule={schedule} setSchedule={setSchedule} />
      <Box sx={style.schedulingUsage}>
        <Typography sx={style.hours}>{t('scheduleHours')}</Typography>
        <Box sx={style.squareWrapper}>
          <Box sx={[style.square, style.selectedSquare]} />
          <Typography>{t('scheduled')}</Typography>
        </Box>
        <Box sx={style.squareWrapper}>
          <Box sx={[style.square, style.unselectSquare]} />
          <Typography>{t('nonScheduled')}</Typography>
        </Box>
      </Box>
      {footer}
    </Box>
  )
}

export default Frequency;
