import React from "react";

interface IProps {
  alignItems?: string;
  children?: any;
  direction?: string;
  flex?: string | number;
  flexWrap?: string;
  justifyContent?: string;
  onClick?: () => void;
  style?: any;
  customClassName?: string;
};

export const Flex = (props: IProps) => {

  const {
    alignItems = "stretch",
    children,
    direction = "row",
    flex = 1,
    flexWrap = "no-wrap",
    justifyContent = "flex-start",
    onClick,
    style = {},
    customClassName,
  } = props;

   return <div
      className={customClassName}
      onClick={onClick}
      role="presentation"
      style={{
        ...style,
        alignItems,
        display: "flex",
        flex,
        flexDirection: direction,
        flexWrap,
        justifyContent,
      }}
    >
      {children}
    </div>
}

