import { defineMessages } from "react-intl";

export default defineMessages({
  cpm: {
    id: "DashboardChartSection.CPM",
    defaultMessage: "CPM",
  },
  cpmTooltip: {
    id: "DashboardChartSection.CPMTooltip",
    defaultMessage:
      "Average cost per 1000 impressions for the selected time period.",
  },
  ctr: {
    id: "DashboardChartSection.CTR",
    defaultMessage: "CTR",
  },
  ctrTooltip: {
    id: "DashboardChartSection.CTRTooltip",
    defaultMessage:
      "The ratio of users who clicked on the Ad to the number of total users who viewed the Ad.",
  },
  reach: {
    id: "DashboardChartSection.reach",
    defaultMessage: "Reach",
  },
  reachTooltip: {
    id: "DashboardChartSection.reachTooltip",
    defaultMessage: "The number of unique visitors who viewed your Ad.",
  },

  cpc: {
    id: "DashboardChartSection.CPC",
    defaultMessage: "CPC",
  },
  cpcTooltip: {
    id: "DashboardChartSection.CPCTooltip",
    defaultMessage: "Average cost per click for the selected time period.",
  },
  totalSpent: {
    id: "DashboardChartSection.totalSpent",
    defaultMessage: "Total Spend",
  },
  winRate: {
    id: "DashboardChartSection.winRate",
    defaultMessage: "Win Rate",
  },
  winRateTooltip: {
    id: "DashboardChartSection.winRateTooltip",
    defaultMessage:
      "The number of impressions won over the number of impressions bid.",
  },
  totalBids: {
    id: "DashboardChartSection.totalBids",
    defaultMessage: "Total Bids",
  },
  totalClicks: {
    id: "DashboardChartSection.totalClicks",
    defaultMessage: "Total Clicks",
  },
  totalImpression: {
    id: "DashboardChartSection.totalImpression",
    defaultMessage: "Total impression",
  },
  spentVsImpression: {
    id: "DashboardChartSection.spendVsImpression",
    defaultMessage: "Spend vs Impression",
  },
  impression: {
    id: "DashboardChartSection.impression",
    defaultMessage: "Impression",
  },
  impressionTooltip: {
    id: "DashboardChartSection.impressionTooltip",
    defaultMessage:
      "The number of times at least half of the ad has been viewed by the visitor.",
  },
  spent: {
    id: "DashboardChartSection.spend",
    defaultMessage: "SPENT",
  },
  spentTooltip: {
    id: "DashboardChartSection.spendTooltip",
    defaultMessage:
      "The amount you spent on the campaign based on the CPM set and  the ad visuals provided.",
  },
  spentVsClicks: {
    id: "DashboardChartSection.spendVsClicks",
    defaultMessage: "Spend vs Clicks",
  },
  clicks: {
    id: "DashboardChartSection.clicks",
    defaultMessage: "Clicks",
  },
  clicksTooltip: {
    id: "DashboardChartSection.clicksTooltip",
    defaultMessage: "The number of times the visitors clicked on the ad.",
  },
  bidsVsImpression: {
    id: "DashboardChartSection.bidsVsImpression",
    defaultMessage: "Bids vs Impression",
  },
  bids: {
    id: "DashboardChartSection.bids",
    defaultMessage: "Bids",
  },
  bidsTooltip: {
    id: "DashboardChartSection.bidsTooltip",
    defaultMessage: "The number of times your ad participated in the bidding",
  },
  CPMvsImpression: {
    id: "DashboardChartSection.CPMvsImpression",
    defaultMessage: "CPM vs Impressions",
  },
  spendGraphTooltip: {
    id: "DashboardChartSection.spendGraphTooltip",
    defaultMessage:
      "See how your budget is spent as your campaign remains active",
  },
  bidsGraphTooltip: {
    id: "DashboardChartSection.bidsGraphTooltip",
    defaultMessage:
      "The Bids for each period of time allow to understand the available traffic on the websites based on your Targeting, CPM and preferred placement settings.",
  },
  clicksGraphTooltip: {
    id: "DashboardChartSection.clicksGraphTooltip",
    defaultMessage:
      "See when and how many visitors get interested in your campaign by clicking on it.",
  },
  impressionGraphTooltip: {
    id: "DashboardChartSection.impressionGraphTooltip",
    defaultMessage:
      "Follow the impressions graph to view the most active days for your ad campaign.",
  },
  reachGraphTooltip: {
    id: "DashboardChartSection.reachGraphTooltip",
    defaultMessage:
      " Reach is typically measured as a count of unique (or distinct) users.",
  },
  all: {
    id: "Filters.all",
    defaultMessages: "All",
  },
  other: {
    id: "Filters.other",
    defaultMessage: "Other",
  },
  websiteSearchPlaceholder: {
    id: "Dashboard.websiteSearchPlaceholder",
    defaultMessage: "Input website",
  },
  selectCampaign: {
    id: "DashboardChartSection.selectCampaign",
    defaultMessage: "Select Campaign",
  },
  savedAmount: {
    id: "DashboardChartSection.savedAmount",
    defaultMessage: "Saved amount",
  },
  customRange: {
    id: "Filters.customRange",
    defaultMessage: "Custom Range",
  },
  allBannerSizes: {
    id: "Dashboard.allBannerSizes",
    defaultMessage: "All banner sizes",
  },
  detailsPlatformAndOS: {
    id: "DashboardChartSection.detailsPlatformAndOS",
    defaultMessage: "PLATFORM / OS",
  },
  detailsPlatformAndOSTooltip: {
    id: "DashboardChartSection.detailsPlatformAndOSTooltip",
    defaultMessage:
      "The platforms and operating systems your ad has been viewed on.",
  },
  detailsTopPublishers: {
    id: "DashboardChartSection.detailsTopPublishers",
    defaultMessage: "TRAFFIC SOURCE",
  },
  detailsTopPublishersTooltip: {
    id: "DashboardChartSection.detailsTopPublishersTooltip",
    defaultMessage:
      "Websites where your campaign has been viewed or clicked on. ",
  },
  detailsTopBannerSizesAndTypes: {
    id: "DashboardChartSection.detailsTopBannerSizesAndTypes",
    defaultMessage: "BANNER SIZES & TYPES",
  },
  detailsTopBannerSizesAndTypesTooltip: {
    id: "DashboardChartSection.detailsTopBannerSizesAndTypesTooltip",
    defaultMessage:
      "The banner sizes and types which have been viewed or clicked on by the visitors.",
  },
  views: {
    id: "DashboardChartSection.views",
    defaultMessage: "VIEWS",
  },
  viewsTooltip: {
    id: "DashboardChartSection.viewsTooltip",
    defaultMessage:
      "number of times people watched more than 5 seconds of the video ad. If the video duration is less than 5 seconds, the view is counted when all video has been watched.",
  },
  viewRate: {
    id: "DashboardChartSection.viewRate",
    defaultMessage: "VIEW RATE",
  },
  viewRateTooltip: {
    id: "DashboardChartSection.viewRateTooltip",
    defaultMessage:
      "number of views divided by the number of times the ad is shown.",
  },
  avgCPV: {
    id: "DashboardChartSection.avgCPV",
    defaultMessage: "Avg. CPV",
  },
  avgCPVTooltip: {
    id: "DashboardChartSection.avgCPVTooltip",
    defaultMessage:
      "the average amount paid for every 5-second watch of the video ad or engagement (clicks).",
  },
  watchTime: {
    id: "DashboardChartSection.watchTime",
    defaultMessage: "Watch Time",
  },
  watchTimeTooltip: {
    id: "DashboardChartSection.watchTimeTooltip",
    defaultMessage:
      "the total amount of time people watched the video ad, shown in seconds, including replays.",
  },
  avgViewDuration: {
    id: "DashboardChartSection.avgViewDuration",
    defaultMessage: "Avg. View Duration",
  },
  avgViewDurationTooltip: {
    id: "DashboardChartSection.avgViewDurationTooltip",
    defaultMessage: "the average duration a person watched the video ad.",
  },
  inVideoClicksTooltip: {
    id: "DashboardChartSection.inVideoClicksTooltip",
    defaultMessage: "The number of times the audience clicked on the video ad.",
  },
  pauseClose: {
    id: "DashboardChartSection.pausesClose",
    defaultMessage: "Pauses/Closes",
  },
});
