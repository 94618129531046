import { visa_card, visa, master } from "../assets";
import { ICreditCardType } from "../types";

export const creditCardTypes: { [key: string]: ICreditCardType } = {
  'AMEX': {
    image: visa_card,
    name: "American Express",
  },
 'CARTE_BLANCHE': {
    image: visa_card,
    name: "Carte Blanche",
  },
  'CHINA_UNION_PAY': {
    image: visa_card,
    name: "China UnionPay",
  },
 'DINERS_CLUB_INTERNATIONAL': {
    image: visa_card,
    name: "Diners Club",
  },
  'DISCOVER': {
    image: visa_card,
    name: "Discover",
  },
  'JCB': {
    image: visa_card,
    name: "JCB",
  },
  'LASER': {
    image: visa_card,
    name: "Laser",
  },
  'UK_MAESTRO': {
    image: visa_card,
    name: "UK Maestro",
  },
  'MAESTRO': {
    image: visa_card,
    name: "Maestro",
  },
  'MASTER_CARD': {
    image: master,
    name: "MasterCard",
  },
  'SOLO': {
    image: visa_card,
    name: "Solo",
  },
  'SWITCH': {
    image: visa_card,
    name: "Switch",
  },
  'VISA': {
    image: visa,
    name: "Visa",
  }, 1: {
    image: visa_card,
    name: "American Express",
  },
  2: {
    image: visa_card,
    name: "Carte Blanche",
  },
  3: {
    image: visa_card,
    name: "China UnionPay",
  },
  4: {
    image: visa_card,
    name: "Diners Club",
  },
  5: {
    image: visa_card,
    name: "Discover",
  },
  6: {
    image: visa_card,
    name: "JCB",
  },
  7: {
    image: visa_card,
    name: "Laser",
  },
  8: {
    image: visa_card,
    name: "UK Maestro",
  },
  9: {
    image: visa_card,
    name: "Maestro",
  },
  10: {
    image: master,
    name: "MasterCard",
  },
  11: {
    image: visa_card,
    name: "Solo",
  },
  12: {
    image: visa_card,
    name: "Switch",
  },
  13: {
    image: visa,
    name: "Visa",
  },
  14: {
    image: visa_card,
    name: "Unrecognized Card",
  },
};
