class _ColorUtils {
  hexRegexp = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
  constructor() {
    this.hexRegexp = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
  }

  hexValidator = val => this.hexRegexp.test(val);

  convertHexToRGB = hex => {
    if (!hex) {
      return "";
    }

    const convertedHex = hex.includes("#") ? hex : "#" + hex;
    const result = this.hexRegexp.exec(convertedHex);
    if (!result) {
      return null;
    }
    return {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    };
  };

  componentToHex = c => {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  convertRGBToHex = (r, g, b) =>
    [Number(r), Number(g), Number(b)]
      .map(x => {
        const hex = x.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      })
      .join("");
}

export const ColorUtils = new _ColorUtils();

