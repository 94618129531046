/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 9/1/2023
 * All Rights Reserved.
 */

/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 9/1/2023
 * All Rights Reserved.
 */
import { Box, Divider, InputAdornment, Radio, TextField, Tooltip, Typography } from "@mui/material";
import { style } from "./style";
import {
  CopyIcon,
  InfoIcon,
  IntFieldController,
  IntRadioGroup,
  IntSelect,
  SmallCrossIcon,
} from "../../../../global";
import { useTranslation } from "react-i18next";
import { Control, SetFieldValue, useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import { CampaignService } from "../../../services";
import { CampaignObjective } from "../../../types";
import { NotificationService } from "../../../../global/services";
type Props = {
  control: Control<any>;
  setValue: SetFieldValue<any>;
}

type App = {
  icon: string;
  name: string;
}

function AppsForm({ control, setValue }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'campaign.goal' });
  const [appInfo, setAppInfo] = useState<App | null>(null);

  const [platform, bundleID, goal] = useWatch({
    name: ['bidding_strategy.app_promotion.app_platform_type', 'bidding_strategy.app_promotion.bundle_id', 'bidding_strategy.goal'],
    control,
  });

  const APP_PLATFORMS = [
    { label: t('ios'), value: 'ios'},
    { label: t('android'), value: 'android'},
  ];

  const MMP_OPTIONS = [
    { label: t('adjust'), value: 'adjust', },
    { label: t('appsflyer'), value: 'appsflyer', }
  ];

  useEffect(() => {
    if (appInfo?.name) {
      setValue('bidding_strategy.app_promotion.app_name', appInfo.name);
    }
  }, [appInfo?.name, setValue]);

  function getAppInfo(platform: string, bundleID: string) {
    CampaignService.getAppInfo(bundleID, platform)
      .then(({ data }) => {
        if (data) {
          setAppInfo({
            icon: data.app_icon,
            name: data.app_name,
          });
          setValue('bidding_strategy.app_promotion.metadata', {
            headline: data.app_name || '',
            body: data.body || '',
            price: data.price || 0,
            starRating: data.star_rating || 1,
            cta: data.call_to_action || '',
            advertiser: data.advertiser || '',
          });
        } else {
          setAppInfo(data);
        }
      });
  }

  useEffect(() => {
    if (platform && bundleID) {
      getAppInfo(platform, bundleID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bundleID, platform]);

  function clearBundleID() {
    setAppInfo(null);
    setValue('bundle_id', '');
  }

  function copyToClipboard() {
    navigator.clipboard.writeText(`<script src="https://cdn1.intent.ai/pixel/${sessionStorage.getItem('spaceID')}/intent.js"></script>`);
    NotificationService.handleSuccess(t('copied'))
  }

  return (
    <Box sx={style.websitesForm}>
      <Typography sx={[style.label, style.radioGroupLabel]}>{t('selectType')}</Typography>
      <IntFieldController
        control={control}
        name='bidding_strategy.goal'
        Component={IntRadioGroup}
        sx={style.radioGroup}
      >
        <Box sx={style.radio}>
          <Radio  checked={goal === CampaignObjective.LANDING_PAGE_TRAFFIC} value={CampaignObjective.LANDING_PAGE_TRAFFIC} />
          <Box>
            <Typography sx={style.radioLabel}>{t('landingPageTraffic')}</Typography>
          </Box>
          <Tooltip title={t('landingPageTrafficTooltip')}>
            <InfoIcon />
          </Tooltip>
        </Box>
        <Box sx={style.radio}>
          <Radio checked={goal === CampaignObjective.APP_INSTALLS} value={CampaignObjective.APP_INSTALLS} />
          <Typography sx={style.radioLabel}>{t('appInstalls')}</Typography>
        </Box>
      </IntFieldController>
      <Divider sx={style.divider} />
      {goal == CampaignObjective.LANDING_PAGE_TRAFFIC && ( // eslint-disable-line
        <Box sx={style.content}>
          <Box sx={[style.field, style.multipleLabelsField]}>
            <Typography sx={style.pasteGTM}>{t('pasteGTM')}</Typography>
            <TextField
              disabled
              multiline
              rows={4}
              sx={style.customHTML}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end' sx={style.adornment}>
                    <CopyIcon
                      viewBox='0 0 36 36'
                      sx={style.copyIcon}
                      onClick={copyToClipboard}
                    />
                  </InputAdornment>
                )
              }}
              defaultValue={`<script src="https://cdn1.intent.ai/pixel/${sessionStorage.getItem('spaceID')}/intent.js"></script>`}
            />
          </Box>
          <Box sx={style.field}>
            <Box sx={style.withTooltip}>
              <Typography sx={style.label}>{t('landingURL')}</Typography>
              <Tooltip title={t('landingURLTooltip')}>
                <InfoIcon />
              </Tooltip>
            </Box>
            <IntFieldController
              control={control}
              name='bidding_strategy.click_url'
              placeholder={t<string>('https')}
            />
          </Box>
        </Box>
      )}
      {goal == CampaignObjective.APP_INSTALLS && ( // eslint-disable-line
        <Box sx={style.fields}>
          <Box sx={style.rowFields}>
            <Box sx={[style.field, style.autocompleteField]}>
              <Typography sx={style.label}>{t('choosePlatform')}</Typography>
              <IntFieldController
                control={control}
                name='bidding_strategy.app_promotion.app_platform_type'
                Component={IntSelect}
                options={APP_PLATFORMS}
              />
            </Box>
            <Box sx={[style.field, style.autocompleteField]}>
              <Typography sx={style.label}>{t('mmp')}</Typography>
              <IntFieldController
                control={control}
                name='bidding_strategy.app_promotion.mmp'
                Component={IntSelect}
                options={MMP_OPTIONS}
              />
            </Box>
          </Box>
          <Box sx={style.rowFields}>
            <Box sx={[style.field, style.bundleID]}>
              <Typography sx={style.label}>{t('bundleID')}</Typography>
              <IntFieldController
                control={control}
                name='bidding_strategy.app_promotion.bundle_id'
              />
            </Box>
            {appInfo && (
              <Box sx={style.app}>
                <Box component='img' src={appInfo.icon} width={24} height={24} />
                <Typography sx={style.appName}>{appInfo.name}</Typography>
                <SmallCrossIcon sx={style.crossIcon} onClick={clearBundleID} />
              </Box>
            )}
          </Box>
          <Box sx={style.field}>
            <Typography sx={style.label}>{t('clickURL')}</Typography>
            <IntFieldController
              control={control}
              name='bidding_strategy.click_url'
              multiline
              rows={3}
            />
          </Box>
          <Box sx={style.field}>
            <Typography sx={style.label}>{t('trackingURL')}</Typography>
            <IntFieldController
              control={control}
              name='bidding_strategy.app_promotion.tracking_url'
              multiline
              rows={3}
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}

AppsForm.displayName = 'AppsForm';

export default AppsForm;