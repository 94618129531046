// Form names
export const SIGNUP_FORM = "signupForm";
export const CONFIRM_REGISTRATION_FORM = "confirmRegistrationForm";
export const INVITEE_REGISTRATION_FORM = "inviteeRegistrationForm";
export const LOGIN_FORN = "loginForm";
export const FORGOT_PASSWORD_FORM = "forgotPasswordForm";
export const RESET_PASSWORD_FORM = "resetPasswordForm";
export const CAMPAIGN_FORM = "CAMPAIGN_FORM";
export const AUDIENCE_FORM = "AUDIENCE_FORM";
export const CAMPAIGN_VISUALS_FORM = "CAMPAIGN_VISUALS_FORM";
export const CAMPAIGN_VISUALS_CHILDREN_FORM = "CAMPAIGN_VISUALS_CHILDREN_FORM";
export const CAMPAIGN_HTML_BANNERS_FORM = "campaignHTMLBannersForm";
export const HTML_BANNER_FORM = "htmlBannerForm";
export const MY_ACCOUNT_PERSONAL_FORM = "myAccountPersonalForm";
export const MY_ACCOUNT_SECURITY_FORM = "myAccountSecurityForm";
export const MY_ACCOUNT_OTHER_FORM = "myAccountOtherForm";
export const MY_ACCOUNT_CUSTOMIZE_FORM = "MY_ACCOUNT_CUSTOMIZE_FORM";
export const MY_ACCOUNT_NOTIFICATION_FORM = "MY_ACCOUNT_NOTIFICATION_FORM";
export const MY_ACCOUNT_COMPANY_DETAILS_FORM =
  "MY_ACCOUNT_COMPANY_DETAILS_FORM";
export const UPLOAD_AUDIENCE_FORM = "uploadAudienceForm";
export const SAVE_AUDIENCE_FORM = "saveAudienceForm";
export const INVITED_USERS_FORM = "invitedUsersForm";
export const CREATE_SPACE_FORM = "CREATE_SPACE_FORM";
export const PROFILE_PERSONAL_FORM = "PROFILE_PERSONAL_FORM";
export const PROFILE_LEGAL_FORM = "PROFILE_LEGAL_FORM";
export const LEGAL_ENTITY_FORM = "LEGAL_ENTITY_FORM";
export const PROFLE_SECURITY_FORM = "PROFLE_SECURITY_FORM";
export const REPORTS_FORM = "REPORTS_FORM";
export const INCLUDE = "include";
export const EXCLUDE = "exclude";
export const MODERATION_USER_FORM = "MODERATION_USER_FORM";

export const ADS_TAGGING = {
  none: 0,
  automat: 1,
  manual: 2,
};

// Form statuses
export const addCampaignStatuses = {
  start: 1,
  incomplete: 2,
  final: 3,
};
