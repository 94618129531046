import { Theme } from "@mui/material";

export const style = {
  header: (theme: Theme) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: theme.spacing(4),
    marginBottom: theme.spacing(8),
  }),
  create: {
    borderRadius: 5,
  }
};