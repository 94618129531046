/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 10/17/2023
 * All Rights Reserved.
 */
import { style } from "./style";
import { Box, Button, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { LoadingButton } from "../../../global";

type Props = {
  cancel: () => void;
  saveForm: () => void;
  saveLabel?: string;
  loading: boolean;
}

function CampaignFormFooter({ cancel, saveForm, saveLabel, loading, }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'campaign.form.footer' });

  const { formState: { isValid, isSubmitting } } = useFormContext();

  return (
    <Box sx={style.footer}>
      <Divider />
      <Box sx={style.footerContent}>
        <Button
          sx={[style.action, style.secondaryAction]}
          onClick={cancel}
        >
          {t('cancel')}
        </Button>
        <LoadingButton
          loading={loading}
          disabled={loading}
          onClick={saveForm}
          sx={[style.action, style.secondaryAction]}
        >
          {saveLabel || t('saveAndNext')}
        </LoadingButton>
        <Button
          variant='contained'
          disabled={!isValid || isSubmitting}
          type='submit'
          sx={style.action}
        >
          {t('publish')}
        </Button>
      </Box>
    </Box>
  )
}

CampaignFormFooter.displayName = 'CampaignFormFooter';

export default CampaignFormFooter;
