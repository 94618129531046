import { Tab, Tabs } from "@mui/material";


interface IProps {
  className?: string;
  config: {label: string}[];
  selectedTab: number;
  variant?: 'fullWidth' | 'standard' | 'scrollable';

  onTabSelect: (tabIndex: any) => void;
}

export const CoreTabs = (props: IProps) => {
  const {className, config, selectedTab = 0, variant = 'standard', onTabSelect } = props;

  return (
    <Tabs className={className} variant={variant} value={selectedTab} onChange={(e,i) => onTabSelect(i)}>
      {config.map((item, index) => {
        return (
          <Tab
            key={index}
            label={item.label}
            id={`simple-tab-${index}`}
            aria-controls={`simple-tabpanel-${index}`}
          />
        )
      })}
    </Tabs>
  )
}

export const TabBody = (props: any) => {
  const { children, selectedTab, tabKey } = props;

  return (
    <div
      role="tabpanel"
      hidden={selectedTab !== tabKey}
      id={`simple-tabpanel-${tabKey}`}
      aria-labelledby={`simple-tab-${tabKey}`}
    >
      {selectedTab === tabKey && children}
    </div>
  );
}