import {
  TableCell,
  TableRow, Typography,
} from "@mui/material";
import { style } from "./style";
import moment from "moment";

interface IProps {
  data: any;
  onRowClick(): void;
}

function ChangeLogListRow({data, onRowClick}: IProps) {

  return (
    <TableRow
      onClick={ onRowClick }
      sx={[style.tableRow]}
    >
      <TableCell>
        <Typography sx={style.logDate}>{moment(data.date).format('MMM DD, YYYY HH:mm')}</Typography>
      </TableCell>
      <TableCell>
        <Typography sx={style.logNew}>{data.difference}</Typography>
      </TableCell>
      <TableCell>
        <Typography sx={style.logUser}>{data.authorName}</Typography>
      </TableCell>
    </TableRow>
  );
}

ChangeLogListRow.displayName = 'ChangeLogListRow';

export default ChangeLogListRow;