import React from "react";
import "./style.scss";
import { FormattedMessage } from "react-intl";
import { ImageUtils } from "../../../../utils/imageUtils";
import { InputErrorMessage } from "../inputErrorMessage";
import { Button } from "@mui/material";


const { getImageObject } = ImageUtils;

const FileRow = (props: any) => {
  const {
    input: { onChange, value, name },
    info,
    label,
    withLabel = true,
    validHeight,
    validWidth,
    supportedFormats,
    supportedSizes,
    validateSize = true,
  } = props;

  const { file_name: fileName, deleted, error } = value;

  // static defaultProps = {
  //   validateSize: true,
  // };

  const onChangeHandler = async e => {

    const image = await getImageObject(e.target.files[0]);
    const i = new Image();
    i.onload = function() {
      // const { width, height } = this;
      const imageSize = `${image.width}x${image.height}`;
      if (validateSize === false) {
        onChange({ ...image, error: false });
        return;
      }

      if (validHeight && validWidth) {
        if (validHeight === image.height && validWidth === image.width) {
          onChange({ ...image, error: false });
        } else {
          onChange({ error: true });
        }
      } else {
        if (
          supportedSizes.includes(imageSize) &&
          supportedFormats.includes(image.file_type)
        ) {
          onChange({ ...image, error: false });
        } else {
          onChange({ error: true });
        }
      }
    };
    i.src = image.preview;
  };

  const onDelete = () => {
    onChange({ ...value, deleted: true });
  };

  // render() {
  //   const { props, onChange, onDelete } = this;
  //   const {
  //     input: {
  //       name,
  //       value: { file_name: fileName, deleted, error },
  //     },
  //     info,
  //   } = props;


    return (
      <div className={'file-row'}>
        { withLabel ?
          <div className={'label-wrapper'}>
            <label htmlFor="undefined" className="label">{label}</label>
          </div> : null
        }

        <div className={'input-wrapper'}>
          <div className="file-input-filed-container">
            <div className="file-input-filed-input-container">
              <input
                onChange={onChangeHandler}
                type="file"
                id={name}
                className="file-input-filed"
              />
              <label
                htmlFor={name}
                className="mai-form-control file-input-filed-label"
              >
                {fileName && !deleted ? ( <span>{fileName}</span>) : (<FormattedMessage
                  id="Uploadfile.selectAFile"
                  defaultMessage="Select a File"
                />)}
              </label>
              {fileName && !deleted ?
                <Button
                  sx={{
                    borderRadius: '20px',
                    backgroundColor: "#e14b4b",
                    marginTop: 0,
                    marginLeft: "1rem",
                    '&:hover': {backgroundColor: '#e14b4bc4'}
                }}
                  onClick={onDelete}
                  variant={"contained"}
                >{'Remove'} </Button> : null
              }
            </div>
            {info && <span className="info">{info}</span>}
            {error && (
              <InputErrorMessage
                id="MyAccount.customizeImageError"
                defaultMessage="Image is mot matching format and/or size requirements."
              />
            )}
          </div>
        </div>
      </div>
    );
  // }
}

export default FileRow;
