import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { injectIntl, IntlShape, defineMessages } from "react-intl";
import { ModerationUserForm } from "../ModerationUserForm";
import AdminHeader from "../../../../components/AdminHeader";
import { match, useHistory, useParams } from "react-router-dom";
import { IUser, IModalProps } from "../../../../../../types";
import { History } from "history";
import { adminMessages } from "../../../../../../messages";
import { routerPaths } from "../../../../../../config/routes/paths";
import { getModerationRegistrationRequest, updateUserLegalEntityRequest } from "../../../../../../api";
import { setIsAllowMakePayment } from "../../../../../store/reducers/payment";
import { NotificationService } from "../../../../../global/services";
import { Box, Button } from "@mui/material";

interface IMatchParams {
  id: string;
}

interface IProps extends IModalProps {
  isModerationRegistrationApproved: boolean;
  intl: IntlShape;
  match: match<IMatchParams>;
  moderationUser: IUser;
  getModerationRegistration: Function;
  setModerationLegalUsersApprove: Function;
  setModerationLegalUsersReject: Function;
  history: History;
  APIResponseFail: Function;
  APIResponseSuccess: Function;
  updateUserLegalEntity: Function;
  currentData: IUser;
}

const messages = defineMessages({
  title: {
    id: "Admin.approveAccount",
    defaultMessage: "Approve Account",
  },
});

const LegalEntityUser = (props: IProps) => {
  const {intl: { formatMessage },
    // modalData,
    // modalType,
    // toggle,
    // isOpen,
    // openModal,
    // closeModal,
    // modalTypes,
    } = props;

  const { id }: any = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [legalUser, setLegalUser] = useState<any>();


  const handleChange = (name: string, value: any) => {
    setLegalUser({...legalUser, [name]: value})
  }

  const getModerationRegistration = () => {
    getModerationRegistrationRequest(id).then(({data}) => {
      setLegalUser(data.data)
    })
  }

  const handleUpdateUserData = () => {
    updateUserLegalEntityRequest({
      user_id: legalUser.id,
      moderation_start_at: new Date(),
      ...legalUser,
    }).then(({data}) => {

      if(data.type.code === 0){
        if (data.data.tos_status_type_key === 2) {
          dispatch(setIsAllowMakePayment(true));
        }
        NotificationService.handleSuccess(formatMessage(adminMessages.accountLegalEntityVerified))
      } else {
        NotificationService.handleError(data.type.message);
      }
      history.push(routerPaths.Moderation);
    })
  };

  useEffect(() => {
    getModerationRegistration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return legalUser ? (
    <>
      <AdminHeader title={formatMessage(messages.title)}>
        <div>
          <ModerationUserForm
            user={legalUser}
            isLegalEntityForm
            onChange={(name, value) => handleChange(name, value)}
          />
          <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <Button
              sx={{ width: "30%", borderRadius: '22px', backgroundColor: '#ffffff' }}
              variant="outlined"
              onClick={handleUpdateUserData}
            >{'Save Changes'}</Button>
          </Box>
        </div>
      </AdminHeader>
      {/*{isOpen && (*/}
      {/*  // <Modal*/}
      {/*  //   modalData={modalData}*/}
      {/*  //   modalType={modalType}*/}
      {/*  //   toggle={toggle}*/}
      {/*  //   isOpen={isOpen}*/}
      {/*  //   openModal={openModal}*/}
      {/*  //   closeModal={closeModal}*/}
      {/*  //   modalTypes={modalTypes}*/}
      {/*  // />*/}
      {/*)}*/}
    </>
  ) : null;
}

// const mapStateToProps = (state: IRootState) => ({
//   currentData: getFormValues(MODERATION_USER_FORM)(state),
// });

// const mapDispatchToProps = (dispatch: Dispatch) => ({
//   APIResponseFail: bindActionCreators(APIResponseFail, dispatch),
//   APIResponseSuccess: bindActionCreators(APIResponseSuccess, dispatch),
//   getModerationRegistration: dispatch.admin.getModerationRegistration,
//   setModerationLegalUsersApprove: dispatch.admin.setModerationLegalUsersApprove,
//   setModerationLegalUsersReject: dispatch.admin.setModerationLegalUsersReject,
//   updateUserLegalEntity: dispatch.authentication.updateUserLegalEntity,
// });

// const LegalEntityUser = connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(injectIntl(WithModal(WrappedLegalEntityUser)));
//
// LegalEntityUser.displayName = "LegalEntityUser";

export default  injectIntl(LegalEntityUser);
