import { Theme } from "@mui/material";

export const style = {
  tabWrapper: {
    borderLeft: '9px solid transparent',
    height: 48,
    display: 'flex',
    position: 'relative',
  },
  tab: (theme: Theme) => ({
    fontSize: 14,
    lineHeight: '1.14',
    letterSpacing: '.8px',
    color: 'rgba(255, 255, 255, 0.9)',
    textTransform: 'uppercase',
    padding: theme.spacing(0, 9, 0, 7),
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  }),
  collapsed: {
    backgroundColor: '#303a4f',
  },
  selectedTab: {
    borderColor: '#3a77eb',
    transition: 'background-color .3s',
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
  },
  childSelected: {
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
  },
  infoRoundedIcon: {
    width: 30,
    height: 30,
  },
  arrowIcon: (expanded: boolean) => ({
    '& path': {
      stroke: '#E1E7ED',
    },
    transform: `rotate(${expanded ? '180deg' : '0deg'})`,
    transition: 'transform .3s',
    width: 48,
    height: 48,
  }),
  tabWithChildren: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    alignItems: 'center',
    borderLeft: '9px solid transparent'
  },
  stateInfo: {
    parent: {
      padding: '4px 5px',
      height: '20px',
      minWidth: '20px',
      textAlign: 'center',
      lineHeight: '12px',
      fontSize: '12px',
      fontWeight: 300,
      borderRadius: '5px',
      backgroundColor: '#5b6679',
      color: '#FFFFFF',
      position: 'absolute',
      top: '14px',
      right: '16px',
    },
    children: {
      padding: '4px 5px',
      minWidth: '20px',
      height: '20px',
      textAlign: 'center',
      lineHeight: '12px',
      fontSize: '12px',
      fontWeight: 300,
      borderRadius: '5px',
      backgroundColor: '#5b6679',
      color: '#FFFFFF',
      position: 'absolute',
      top: '14px',
      right: '48px',
    },
    warning: {
      padding: '2px 4px',
      minWidth: '20px',
      height: '20px',
      textAlign: 'center',
      lineHeight: '12px',
      fontSize: '12px',
      fontWeight: 300,
      borderRadius: '5px',
      // backgroundColor: '#5b6679',
      // color: '#FFFFFF',
      position: 'absolute',
      top: '14px',
      right: '48px',
    },


  }
}