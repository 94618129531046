import { Theme } from "@mui/material";

export const style = {
  form: (theme: Theme) => ({
    background: '#fff',
    boxShadow: '0 12px 8px -10px rgba(0,0,0,.12)',
    width: '100%',
    borderRadius: 1,
    border: '1px solid #dfe7f2',
    padding: theme.spacing(4, 3),
  }),
  formTitle: (theme: Theme) => ({
    fontSize: 24,
    lineHeight: '28px',
    fontWeight: 200,
    textTransform: 'uppercase',
    padding: theme.spacing(3, 4),
  }),
  field: (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(8),
    margin: theme.spacing(4, 0),
  }),
  fieldLabel: {
    flex: 1,
    fontSize: 14,
    letterSpacing: '.8px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    fontWeight: 600,
    color: '#000',
  },
  fieldInput: {
    flex: 2,
    '& .MuiInputBase-root': {
      maxWidth: 500,
    }
  },
  submitForm: (theme: Theme) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  }),
  submitButton: (theme: Theme) => ({
    border: '1px solid #3A77EB',
    backgroundColor: '#fff',
    boxShadow: '0 12px 8px -10px rgba(58,119,235,.2)',
    minHeight: 32,
    fontSize: 14,
    borderRadius: 5,
    padding: theme.spacing(3, 5),
    letterSpacing: '.8px',
    lineHeight: '16px',
    color: '#3A77EB',
    textTransform: 'uppercase',
    '&:disabled': {
      opacity: 0.5,
      color: '#3A77EB',
    }
  })
}