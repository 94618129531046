// campaign statuses
export const DRAFT = 0;
export const IN_MODERATION = 1;
export const REJECTED = 2;
export const RUNNING = 3;
export const DAILY_BUDGET_SPENT = 31;
export const WAITING_TO_START = 40;
export const PAUSED = 41;
export const FINISHED_BUDGET_SPENT = 42;
export const FINISHED_OVERDUE = 43;
export const PAUSED_DUE_TO_SPACE_BALANCE_SPENT = 44;
export const ARCHIVED = 5;
export const SPACE_BALANCE_EMPTY = 99;
export const BALANCE_REFILL_HISTORY_SUCCESS_ATTACHED = 20;
export const BALANCE_REFILL_HISTORY_SUCCESS = 2;
export const BALANCE_REFILL_HISTORY_FAIL = 30;
export const PENDING = 2;
export const WAITING_FOR_AD_EXCHANGE = 6;

export const SUCCESSFULLY_CHARGED = "SUCCESSFULLY_CHARGED";
export const STATUS_PENDING = "PENDING";
export const FAILED_TO_CHARGE = "FAILED_TO_CHARGE";
export const NEW = "NEW";

export const notificationTypes: any = {
  campaign_is_rejected: 1,
  campaign_runs_out_of_budget: 2,
  campaign_end_date_is_reached: 3,
  space_runs_out_of_budget: 4,
  campaign_is_approved: 5,
  space_balance_close_to_end: 6,
  account_is_registered: 7,
  campaign_is_published: 8,
};

export const CAMPAIGN_STATUS_COLORS: any = {
  [DRAFT]: "rgba(41,47,65,0.5)",
  [IN_MODERATION]: "#EF902E",
  [WAITING_FOR_AD_EXCHANGE]: "#EF902E",
  [REJECTED]: "#E14B4B",
  [RUNNING]: "#6CD387",
  [DAILY_BUDGET_SPENT]: "#6CD387",
  [WAITING_TO_START]: "rgba(41,47,65,0.5)",
  [PAUSED]: "rgba(41,47,65,0.5)",
  [FINISHED_BUDGET_SPENT]: "rgba(41,47,65,0.5)",
  [FINISHED_OVERDUE]: "rgba(41,47,65,0.5)",
  [PAUSED_DUE_TO_SPACE_BALANCE_SPENT]: "rgba(41,47,65,0.5)",
};

export const PAYMENT_STATUS_COLORS: any = {
  [SUCCESSFULLY_CHARGED]: "#2edf9a",
  [FAILED_TO_CHARGE]: "#e14b4b",
  [STATUS_PENDING]: "#ef902e",
};

export const CAMPAIGN_STATUSES: any = {
  [ARCHIVED]: "archived",
  [DRAFT]: "draft",
  [IN_MODERATION]: "inModeration",
  [REJECTED]: "rejected",
  [RUNNING]: "running",
  [DAILY_BUDGET_SPENT]: "dailyBudgetSpent",
  [WAITING_TO_START]: "waitingToStart",
  [PAUSED]: "paused",
  [FINISHED_BUDGET_SPENT]: "finishedBudgetSpent",
  [FINISHED_OVERDUE]: "finishedOverdue",
  [PAUSED_DUE_TO_SPACE_BALANCE_SPENT]: "finishedSpaceBalanceSpent",
  [WAITING_FOR_AD_EXCHANGE]: "adExchangeReview",
};

export const PAYMENT_STATUSES: any = {
  [SUCCESSFULLY_CHARGED]: "Paid",
  [STATUS_PENDING]: "Pending",
  [FAILED_TO_CHARGE]: "Not paid",
  [NEW]: "New",
};

// audience statuses

export const PROCESSING = 1;
export const READY = 2;
export const INVALID = 3;

export const AUDIENCE_STATUSES: any = {
  [PROCESSING]: "processing",
  [DRAFT]: "draft",
  [INVALID]: "invalid",
  [READY]: "ready",
};
export const AUDIENCE_STATUS_COLORS: any = {
  [PROCESSING]: "#EF902E",
  [READY]: "#6CD387",
  [INVALID]: "#E14B4B",
};

export const INVITED_USER_ACCEPTED = 1;
export const INVITED_USER_PENDING = 2;

export const TOS_STATUSES = {
  PENDING: 1,
  APPROVED: 2,
  REJECTED: 3,
};
