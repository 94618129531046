import { defineMessages } from "react-intl";

export default defineMessages({
  saveLinkedCards: {
    id: "Payments.saveLinkedCards",
    defaultMessage: "Date",
  },
  paymentDate: {
    id: "Payments.tableHeadPaymentDate",
    defaultMessage: "Date",
  },
  amount: {
    id: "Payments.tableHeadAmount",
    defaultMessage: "Amount",
  },
  method: {
    id: "Payments.tableHeadPaymentMethod",
    defaultMessage: "Payment Method",
  },
  status: {
    id: "Payments.tableHeadStatus",
    defaultMessage: "status",
  },
  paidBy: {
    id: "Payments.tableHeadPaidBy",
    defaultMessage: "Paid By",
  },
  title: {
    id: "Billing.title",
    defaultMessage: "Billing",
  },
  dateTitle: {
    id: "Payments.showTransactionsFor",
    defaultMessage: "Show Transactions For:",
  },
  invoice: {
    id: "Payments.invoice",
    defaultMessage: "Invoice",
  },
  bankGatewayError: {
    id: "Payments.bankGatewayConnectionError",
    defaultMessage:
      "The payment failed because of an issue in bank gateway, please try again later.",
  },
  paymentBackendFail: {
    id: "Payments.paymentBackendFail",
    defaultMessage:
      "We were unable to charge your payment method. Please check the details or contact support.",
  },
  paymentFailure: {
    id: "Payments.failure",
    defaultMessage: "Payment Failure",
  },
  successfulDescription: {
    id: "Payments.successfulDescription",
    deafultMesssage:
      "You have refilled your account balance by {amount}. Your current balance is {totalAmount}.",
  },
  successfulCardAdded: {
    id: "Payments.successfulAddCard",
    deafultMesssage: "Payment Method Added",
  },
  successfulCardAddedDescription: {
    id: "Payments.successfulAddCardDescription",
    deafultMesssage: "Your card has been successfully added!",
  },
  linkedCards: {
    id: "Payments.LinkedCards",
    deafultMesssage: "Linked Cards",
  },
  addNewCard: {
    id: "Payments.addNewCard",
    deafultMesssage: "New Card",
  },
  successful: {
    id: "Payments.successful",
    deafultMesssage: "BALANCE REFILLED",
  },
  linkedCardError: {
    id: "Payments.linkedCardError",
    deafultMesssage: "Issue with Linked Card",
  },
  linkedCardErrorDescription: {
    id: "Payments.linkedCardErrorDescription",
    deafultMesssage:
      "We were unable to charge your payment method. Please check the details or contact support.",
  },
  selectYourPaymentMethod: {
    id: "Payments.selectYourPaymentMethod",
    defaultMessage: "select your payment method",
  },
  paymentMethodsTitle: {
    id: "AddPaymentMethods.title",
    defaultMessage: "Payment Methods",
  },
  paymentMethodRemoved: {
    id: "Payments.paymentMethodRemoved",
    defaultMessage: "The payment method has been removed!",
  },
  defaultRemoveFaild: {
    id: "Payments.defaultRemoveFaild",
    defaualtMessage: "Choose another card as default before removing {card}",
  },
  balanceRefillHistory: {
    id: "Payments.sectionTitleBalanceRefillHistory",
    defaultMessage: "Account Billing History",
  },
  paymentCardAddBackendFail: {
    id: "Payments.paymentAddBackendFail",
    defaultMessage:
      "We were unable to add the payment method. Please, try again or contact support.",
  },
  dialogNotice: {
    id: "Payments.dialogNotice",
    defaultMessage: "NOTICE",
  },
  dialogAreYouSure: {
    id: "Payments.dialogAreYouSure",
    defaultMessage: "ARE YOU SURE?",
  },
  dialogAddNewCardDescription: {
    id: "Payments.dialogAddNewCardDescription",
    defaultMessage:
      "You will be redirected to Ameria for payment method confirmation. After conformation, you will be charged {amount} Դ.",
  },
  dialogBindCardDescription: {
    id: "Payments.dialogBindCardDescription",
    defaultMessage:
      "You will be charged {amount} Դ from {cardNumber} payment method.",
  },
  dialogRemoveCardDescription: {
    id: "Payments.dialogRemoveCardDescription",
    defaultMessage:
      "Are you sure you want to delete {cardNumber} payment method?",
  },
  lowBalance: {
    id: "Payments.lowBalance",
    defaultMessage: "The minimum amount to refill the balance is 500 AMD.",
  },
  success: {
    id: "Payments.success",
    defaultMessage: "Success",
  },
  updatedSuccessfully: {
    id: "Payments.updatedSuccessfully",
    defaultMessage: "Updated Successfully",
  },
});
