import { Theme } from "@mui/material";

export const style = {
  view: (theme: Theme) => ({
    background: `linear-gradient(225deg,${theme.palette.secondary.main},${theme.palette.primary.main}) center center/cover`,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: theme.spacing(6),
    paddingTop: theme.spacing(6),
    minHeight: '100vh',
  }),
  form: (theme: Theme) => ({
    backgroundColor: '#fff',
    padding: theme.spacing(8),
    borderRadius: theme.spacing(3),
    boxShadow: '0 4px 8px 0 rgba(0,0,0,.08)',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.625rem',
  }),
  formTitle: {
    color: '#292f41',
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: '-.2px',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  adCampaignsText: {
    color: 'rgba(41,47,65,.5)',
    textAlign: 'center',
  },
  success: {
    maxWidth: 450,
    minWidth: 450,
  },
  login: (theme: Theme) => ({
    color: 'hsla(0,0%,100%,.5)',
    textTransform: 'uppercase',
    marginTop: 'auto',
    marginBottom: theme.spacing(6),
    cursor: 'pointer',
  }),
};