export const style = {
  inputAdornment: {
    '&&': {
      width: 36,
    }
  },
  icon: {
    width: 48,
    height: 48,
  }
}