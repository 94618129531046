import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ReactNode, useEffect, useState } from "react";
import { CampaignService } from "../../services";
import { AppCategory, CampaignFormDTO, CategoryApp } from "../../types";
import {
  Box,
  Divider,
  Tooltip,
  Typography,
} from "@mui/material";
import { style } from "./style";
import "../AppsSitesSelectBox/style.scss";
import { InfoIcon, IntCheckbox, IntFieldController, IntSelect } from "../../../global";
import IntAutocomplete from "../../../global/components/IntForm/IntAutocomplete";
import AppsSitesSelectBox from "../AppsSitesSelectBox";
import { filtersConfig } from "../../utils/config";
import '../../styles/style.scss'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ENVIRONMENT } from "../../utils";
import AppsSitesCategoriesSelectBox from "../AppsSitesCategoriesSelectBox";
import { getDictionaryByIdRequest } from "../../../../api";

type Props = {
  footer: ReactNode;
}

function PlacementForm({ footer }: Props) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'campaign.placement',
  });
  const [, setWhiteLists] = useState<any[]>([]);
  const [appCategories, setAppCategories] = useState([]);
  const [gameCategories, setGameCategories] = useState([]);
  const [deviceTypes, setDeviceTypes] = useState<Array<{ value: number; label: string }>>([]);
  const [languages, setLanguages] = useState<Array<{ value: string; label: string }>>([]);

  function getDeviceTypes() {
    getDictionaryByIdRequest('device_types')
      .then((res: any) => {
        setDeviceTypes(res?.data?.data.map(({ key }) => ({
          value: key,
          label: t(`deviceTypes.${key}`),
        })));
      });
    getDictionaryByIdRequest('languages')
      .then((res: any) => {
        setLanguages(res?.data?.data.map(({ value, title }) => ({
          value,
          label: title,
        })));
      });
  }

  const {
    control,
    setValue,
  } = useFormContext<CampaignFormDTO>();

  const [excludeAllGames, campaignId] = useWatch({
    control,
    name: ['placement.excludeAllGames', 'campaignId'],
  });

  // const isAppCampaign = type === CampaignObjectiveType.CONSIDERATION
  //   && goal === CampaignObjective.APP_INSTALLS;

  function getAppCategories() {
    CampaignService
      .getCategories()
      .then(response => {
        setAppCategories((response.data.data || []).map(function transferToOption(app: AppCategory) {
          return {
            label: app.category_name,
            value: app.category_id,
          };
        }));
      });
  }

  function getGameCategories() {
    CampaignService
      .getCategories(CategoryApp.GAME)
      .then(response => {
        setGameCategories((response.data.data || []).map(function transferToOption(app: AppCategory) {
          return {
            label: app.category_name,
            value: app.category_id,
          }
        }))
      });
  }

  const getUploadedWhiteLists = () => {
    CampaignService.getWhitelists(campaignId as string).then(response => {
      if(response.data.type.code === 0) {
        setWhiteLists(response.data.data);
      }
    })
  }

  useEffect(() => {
    getAppCategories();
    getDeviceTypes();

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(campaignId) {
      getUploadedWhiteLists();
    }
  }, [campaignId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(appCategories.length) {
      filtersConfig[0].dataList = appCategories;
    }
  }, [appCategories]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!excludeAllGames && !gameCategories.length) {
      getGameCategories();
    }
  }, [excludeAllGames, gameCategories.length]);

  return (
    <Box sx={style.placement}>
      <Typography sx={style.title}>
        {t('title')}
      </Typography>
      <Box>
        <Divider />
        <Box sx={style.placementInputs}>
          <Box sx={style.appCategories}>
            <Box sx={style.labelWithTooltip}>
              <Typography sx={style.labelWithTooltip.fieldLabel}>{t('appCategories')}</Typography>
              <Tooltip title={t('appCategoriesTooltip')}>
                <InfoIcon />
              </Tooltip>
            </Box>
            <AppsSitesCategoriesSelectBox data={appCategories} control={control} setValue={setValue}/>
          </Box>

          <Box sx={[style.field, style.devicesToDisplay, {position: 'relative'}]}>
            <Box sx={style.labelWithTooltip}>
              <Typography sx={style.labelWithTooltip.fieldLabel}>{t('SearchForSpecificAppsSites')}</Typography>
              <Tooltip title={t('SearchForSpecificAppsSitesTooltip')}>
                <InfoIcon />
              </Tooltip>
            </Box>
            <AppsSitesSelectBox filtersConfig={filtersConfig} control={control} setValue={setValue} />
          </Box>
          <Divider sx={{width: '100%'}}/>


          <Box sx={style.placementSelectFields}>
            <Box sx={[style.field, style.devicesToDisplay]}>
              <Typography sx={style.fieldLabel}>{t('devicesToDisplay')}</Typography>
              <IntFieldController
                className={'border-color-E6E9ED radius-8 int-select'}
                control={control}
                name='placement.deviceType'
                Component={IntSelect}
                IconComponent={ExpandMoreIcon}
                // disabled={isAppCampaign}
                sx={[style.field, style.selectField]}
                options={deviceTypes}
              />
            </Box>

            <Box sx={[style.field, style.languageField]}>
              <Typography sx={style.fieldLabel}>{t('languages')}</Typography>
              <IntFieldController
                className={'border-color-E6E9ED radius-8 int-select'}
                control={control}
                name='placement.languages'
                Component={IntAutocomplete}
                popupIcon={<ExpandMoreIcon />}
                sx={[style.field, style.selectField]}
                options={languages}
                placeholder={t<string>('selectLanguage')}
                multiple
                extractValue
              />
            </Box>
          </Box>

          <Box sx={style.placementSelectFields}>
            <Box sx={[style.field, style.devicesToDisplay]}>
              <Typography sx={style.fieldLabel}>{t('environment')}</Typography>
              <IntFieldController
                className={'border-color-E6E9ED radius-8 int-select'}
                control={control}
                name='placement.environment'
                Component={IntSelect}
                IconComponent={ExpandMoreIcon}
                // disabled={isAppCampaign}
                sx={[style.field, style.selectField]}
                options={ENVIRONMENT}
              />
            </Box>
          </Box>

          <Divider sx={{width: '100%'}}/>

          <Box sx={style.excludeAllGames}>
            <IntFieldController
              control={control}
              name='placement.exclude_non_gambling_publishers'
              Component={IntCheckbox}
            />
            <Typography sx={style.excludeAllGamesLabel}>{t('excludeNonGamblingPublishers')}</Typography>
          </Box>
        </Box>
      </Box>
      {footer}
    </Box>
  )
}

PlacementForm.displayName = 'PlacementForm';

export default PlacementForm;