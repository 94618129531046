import { Theme } from "@mui/material";

export const style = {
  chart: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    paddingTop: theme.spacing(4),
    height: 550,
  }),
  tabs: {
    border: '1px solid #dfe7f0',
    borderRadius: 1,
    width: 'fit-content',
  },
  tab: (theme: Theme) => ({
    fontSize: 18,
    padding: theme.spacing(3, 6),
    textTransform: 'none',
    '&.Mui-selected': {
      backgroundColor: 'primary.main',
      color: '#fff',
    }
  }),
  viewBy: (theme: Theme) => ({
    fontSize: 14,
    letterSpacing: '.8px',
    lineHeight: '16px',
    color: 'rgba(41,47,65,.5)',
    textTransform: 'uppercase',
    marginBottom: theme.spacing(2),
  }),
  progress: {
    margin: 'auto',
  },
  exportCSV: {
    marginLeft: 'auto',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.14,
    letterSpacing: '0.2px',
    color: '#3a77eb',
    cursor: 'pointer',
    marginRight: 2,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  }
}