import { style } from "./style";
import { Box, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import { InfoIcon } from "../../../global";
import React, { ReactNode, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  title: string;
  titleTooltipText: string;
  render: (tab: 'impressions' | 'clicks') => ReactNode;
  header?: ReactNode;
}

function ReportDetailedBox({ title, titleTooltipText, render, header = null }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'reporting' });

  const [selectedTab, setSelectedTab]  = useState<'impressions' | 'clicks'>('impressions');

  function handleTabChange(e: SyntheticEvent, tabName: 'impressions' | 'clicks') {
    setSelectedTab(tabName);
  }

  return (
    <Box sx={style.reportDetailedBox}>
      <Box sx={style.header}>
        <Typography sx={style.title}>{title}</Typography>
        {header}
        <Tooltip title={titleTooltipText}>
          <InfoIcon />
        </Tooltip>
      </Box>
      <Box sx={style.content}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          sx={style.tabs}
          TabIndicatorProps={{ sx: style.indicator }}
        >
          <Tab label={t('impressions')} value='impressions' sx={style.tab}/>
          <Tab label={t('clicks')} value='clicks' sx={style.tab}/>
        </Tabs>
        {render(selectedTab)}
      </Box>
    </Box>
  );
}

ReportDetailedBox.displayName = 'ReportDetailedBox';

export default ReportDetailedBox;