import React from "react";
import { IAdminSearchResultAccount } from "../../../types";
import "./style.scss";
import { injectIntl, IntlShape } from "react-intl";
import { adminMessages } from "../../../messages";
import clsx from "clsx";
import { ArrowRightIcon, getSpaceImage } from "../../../modules";
import { formatDateToDMY } from "../../../modules/global/utils/date-utils";

interface IProps {
  account: IAdminSearchResultAccount;
  onAdminChange: Function;
  isAdmin?: boolean;
  intl: IntlShape;
}

const AdminAccount = (props: IProps) => {

  const {
    intl: { formatMessage },
    account,
    onAdminChange,
    isAdmin,
  } = props;


  return (
    <li
      className={clsx("admin-account", {
        "is-admin": isAdmin,
      })}
      onClick={onAdminChange(account.space_id)}
    >
      <div className="admin-account-image">{account.picture_id}
        <img src={getSpaceImage(account.picture_id) as string} alt="Profile" />
      </div>
      <div className="admin-account-text">
        <p className="admin-account-text-name">
          {account.space_name}{" "}
          {isAdmin && `(${formatMessage(adminMessages.myAccount)})`}
        </p>
        <p className="admin-account-text-date">
          {formatDateToDMY(account.created_at)}
        </p>
      </div>
      <div className="admin-account-icon">
        <ArrowRightIcon />
        {/* <img src={arrow_right_large} alt="Arrow Right" /> */}
      </div>
    </li>
  );
};

export default injectIntl(AdminAccount);
