import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import './SetupForm.scss';
import { Button } from "@mui/material";

type Props = {
    onSuccess?: () => void;
};

const SetupForm = ({ onSuccess }: Props) => {
    const stripe = useStripe();
    const elements = useElements();

    const [errorMessage, setErrorMessage] = useState<string | null | undefined>(null);

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const { error } = await stripe.confirmSetup({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: 'http://dsp.intent.ai//payments/history',
            },

            redirect: 'if_required'
        });

        if (error) {
            setErrorMessage(error.message);
        } else {
            onSuccess && onSuccess();
        }
    };

    return (
        <form className='setup-form' onSubmit={handleSubmit}>
            <PaymentElement />
            <Button sx={{borderRadius: '20px', marginTop: '16px'}} variant={'contained'} type="submit" disabled={!stripe}>{'Add Payment Method'}</Button>
            {errorMessage && <div>{errorMessage}</div>}
        </form>
    )
};

export default SetupForm;