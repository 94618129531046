import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import englishTranslation from '../../../assets/translations/en.json';
import russianTranslation from '../../../assets/translations/ru.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: englishTranslation,
      },
      ru: {
        translation: russianTranslation,
      }
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    }
  });

export default i18n;
