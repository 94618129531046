/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 11/3/2023
 * All Rights Reserved.
 */
import { style } from "./style";
import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";
import { CampaignFormDTO } from "../../types";
import { setNativeAppIframeContent } from "../../utils";
import { NATIVE_APP_AD_HTML } from "../../utils/banner-html";

//TODO I know that having two component for one task is not a good idea, but I don't have time to refactor it

function NativeAdPreview () {
  const { t } = useTranslation('translation', { keyPrefix: 'campaign.native' });

  const { control } = useFormContext<CampaignFormDTO>();

  const nativeAd = useWatch({
    name: 'nativeAd',
    control,
  });

  const showPreview = (nativeAd?.video || nativeAd?.images?.length) && nativeAd.logo?.url;

  function loadIframeContent(nativeAd: CampaignFormDTO['nativeAd']) {
    if (!showPreview) {
      return;
    }
    const iframe = document.querySelector<HTMLIFrameElement>('.iframe');
    if (iframe) {
      setNativeAppIframeContent(iframe, nativeAd);
    }
  }

  useEffect(() => {
    if (showPreview) {
      loadIframeContent(nativeAd);
    }
  }, [nativeAd]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={style.adPreview}>
      <Box sx={style.header}>
        <Typography sx={style.adPreviewType}>{t('adPreviewTitle')}</Typography>
      </Box>
      <Box sx={style.previewIframe}>
        <Box sx={style.centerIframe}>
          {!!showPreview && ( // eslint-disable-next-line
            <iframe
              width={268}
              height={298}
              srcDoc={NATIVE_APP_AD_HTML}
              style={{ alignSelf: 'center', }}
              className='iframe'
              onLoad={() => loadIframeContent(nativeAd)}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

NativeAdPreview.displayName = 'NativeAdPreview';

export default NativeAdPreview;