import React from "react";
import "./style.scss";
import { FormattedMessage } from "react-intl";
import { TERMS_AND_SERVICE_URL } from "../../../_constants";

interface IProps {
  termOfServiceUrl: string;
}
 const Footer = ({ termOfServiceUrl }: IProps) => {

  return (
    <footer  className={"mai-footer"}>
      <div className={"mai-footer-inner"}>
        <p className={ "mai-footer-info"}>
          <span>
            <a
              href={termOfServiceUrl || TERMS_AND_SERVICE_URL}
              target={"_blank"}
              rel="noopener noreferrer"
              className={"mai-footer-link"}
              id={"Auth.termOfService"}
            >
              <FormattedMessage
                id="Auth.termOfService"
                defaultMessage="Terms of Service"
              />

            </a>
          </span> {" | "}
          <span>
             <FormattedMessage
               id="Footer.poweredBy"
               defaultMessage="Powered by"
             />
            <a href="https://intent.ai" target={"_blank"} rel="noopener noreferrer" className={"mai-footer-link"}>
              <strong>{" Intent.ai"}</strong>
            </a>
          </span>
        </p>
        <p>
          {`"\xA9" ${new Date().getFullYear()} `}
          <FormattedMessage
            id={"Footer.allRightsReserved"}
            defaultMessage={"All Rights Reserved"}
          />
        </p>
      </div>
    </footer>
  )
}
export default Footer;