import ReCAPTCHA from "react-google-recaptcha";
import { Ref } from "react";

type Props = {
  inputRef?: Ref<HTMLInputElement>;
  errorMessage?: string;
}

function IntRecaptcha({ inputRef, errorMessage, ...props }: Props) {
  return (
    <ReCAPTCHA
      sitekey={window.REACT_APP_RECAPTCHA_SITE_KEY}
      {...props}
    />
  )
}

IntRecaptcha.displayName = 'IntRecaptcha';

export default IntRecaptcha;