import { Theme } from "@mui/material";

export const style = {
  footer: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    width: 'calc(100% - 350px)',
    backgroundColor: '#fff',
  },
  footerContent: (theme: Theme) => ({
    padding: theme.spacing(5, 8),
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 2,
  }),
  action: (theme: Theme) => ({
    padding: theme.spacing(2, 3),
    borderRadius: 5,
    height: 32,
    width: 140,
  }),
  secondaryAction: {
    color: '#292f41',
    width: 100,
  },
}