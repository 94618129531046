import {
  ISpacePaymentRequest,
  IPaymentMethodsRequest,
  IPaymentCardRequest,
} from "../types";
import { ApiService } from "../modules/global/services";
import axios from "axios";

const EMPTY_OBJECT = {};

export const getCompanySpaceBalanceRequest = () =>
  ApiService.get(`/v1/company/spaces/balance`);

export const setCompanySpaceRefillRequest = (data: string) =>
  ApiService.post(`/v1/payment/space/finalize`, data);

export const getRefillPaymentIdRequest = (data: ISpacePaymentRequest) =>
  ApiService.post(`/v1/payment/space/init`, data);

export const setNewCardBindRequest = (data: ISpacePaymentRequest) =>
  ApiService.post(`/v1/payment/space/method/bind`, data);

export const savePaymentCardRequest = (data: ISpacePaymentRequest) =>
  ApiService.post(`/v1/payment/space/save`, data);

export const setSpacePaymentsMethodsRequest = (data: number) =>
  ApiService.post(`/v1/payment/space/method/add`, data);

export const getSpacePaymentsMethodsRequest = (data: IPaymentMethodsRequest) =>
  ApiService.get(`/v1/payment/braintree/method`);

// export const getSpacePaymentsMethodsRequest = (data: IPaymentMethodsRequest) =>
//   createRequest(`/payment/space/method`, POST, EMPTY_OBJECT, data);

export const getPaymentMethodsRequest = () => {
  const params: { space_id?: string } = {};
  const spaceID = sessionStorage.getItem('spaceID');
  if (spaceID) {
    params.space_id = spaceID;
  }
  return axios.get(`${window.REACT_APP_BASE_URL}/v1/payment/stripe/method${spaceID ? '?space_id=' + spaceID : ''}`, {headers: {
      // 'Content-Type': 'text/plain',
      authorization: `Bearer ${localStorage.getItem('DSP_AUTH_TOKEN')}`
    }}).then((resp) => {
    if (resp.data.type.code !== 0) {
      throw new Error(resp.data.type.message);
    }
    return resp.data.data;
  });
};

export const getBraintreeClientTokenRequest = () =>
  axios.get(`${window.REACT_APP_BASE_URL}/v1/payment/braintree/client-token`, {headers: {
      // 'Content-Type': 'text/plain',
      authorization: `Bearer ${localStorage.getItem('DSP_AUTH_TOKEN')}`
    }}).then((resp) => {
    if (!resp.data.ok) {
      throw new Error(resp.data.statusText);
    }
    return resp.data.text();
  });

export const getClientTokenRequest = () => {
  const params: { space_id?: string } = {};
  const spaceID = sessionStorage.getItem('spaceID');
  if (spaceID) {
    params.space_id = spaceID;
  }
  return axios.get(`${window.REACT_APP_BASE_URL}/v1/payment/stripe/client-token${spaceID ? '?space_id=' + spaceID : ''}`, {headers: {
      'Content-Type': 'text/plain',
      authorization: `Bearer ${localStorage.getItem('DSP_AUTH_TOKEN')}`
    }}).then((resp) => {
    // if (!resp.data.ok) {
    //   throw new Error(resp.data.statusText);
    // }
    return resp.data;
  });
}

export const addPaymentMethodRequest = (nonce: string) => {
  const data = { payment_method_nonce: nonce };
  let formBodyList: any[] = [];
  for (const property in data) {
    const encodedKey = encodeURIComponent(property);
    //@ts-ignore
    const encodedValue = encodeURIComponent(data[property]);
    formBodyList.push(encodedKey + "=" + encodedValue);
  }
  let formBody = formBodyList.join("&");
  return ApiService.post(`/v1/payment/braintree/method/add`, formBody,{ "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8" });
};

export const removePaymentMethodRequest = (method_id: string) => {
  const params: { space_id?: string } = {};
  const spaceID = sessionStorage.getItem('spaceID');
  if (spaceID) {
    params.space_id = spaceID;
  }
  return ApiService.delete(`/v1/payment/stripe/method/${method_id}${spaceID ? '?space_id=' + spaceID : ''}`);
};

export const getRefillPaymentHistoryRequest = (data = EMPTY_OBJECT) =>
  ApiService.post(`/v1/invoice`, data);

export const invoicePayRequest = (data, id) =>
  ApiService.post(`/v1/invoice/pay/${id}`, data);

export const invoiceSendRequest = (data, id) =>
  ApiService.post(`/v1/invoice/send/${id}`, data);

export const makeDefaultPaymentMethodRequest = (id: string) => {
  const params: { space_id?: string } = {};
  const spaceID = sessionStorage.getItem('spaceID');
  if (spaceID) {
    params.space_id = spaceID;
  }
  return ApiService.post(`/v1/payment/stripe/method/default/${id}${spaceID ? '?space_id=' + spaceID : ''}`)
};

export const getWithdrawHistoryRequest = (data = EMPTY_OBJECT) =>
  ApiService.post(`/v1/cash-out/history`, data);

export const setSpaceBindingRequest = (data: ISpacePaymentRequest) =>
  ApiService.post(`/v1/payment/space/binding`, data);

export const removeSpacePaymentMethod = (data: IPaymentCardRequest) =>
  ApiService.post(`/v1/payment/space/method/remove`, data);

export const setAsSpaceDefaultPaymentMethod = (data: IPaymentCardRequest) =>
  ApiService.post(`/v1/payment/space/method/default`, data);

export const makeBindingPaymentRequest = (data: any) =>
  ApiService.post(`/v1/payment/space/binding`, data);

export const checkPaymentRequestAccessRequest = () =>
  ApiService.post(`/v1/payment/user/check`, EMPTY_OBJECT);

export const getPaymentPDFInvoice = (id: string) =>
  ApiService.get(`/v1/payment/pdf/${id}`);

export const getInvoicePdfRequest = (id: string) =>
  ApiService.get(`/v1/invoice/pdf/${id}`);
