class OnceOnlySingleton {
  properties: { [key: string]: any };

  constructor() {
    this.properties = {};
  }

  setParam(paramName: string, body: any) {
    this.properties[paramName] = body;
  }

  getParam(paramName: string) {
    const result = this.properties[paramName];
    delete this.properties[paramName];
    return result;
  }
}

const onlyOnce = new OnceOnlySingleton();

export default onlyOnce;
