import React, { useEffect } from "react";
import { Field, reduxForm, InjectedFormProps } from "redux-form";
import { injectIntl, IntlShape } from "react-intl";
import {
  MY_ACCOUNT_CUSTOMIZE_FORM,
  FAVICON_SMALL_SIZE,
  FAVICON_MEDIUM_SIZE,
  FAVICON_BIG_SIZE,
  imageTypes,
  AUTH_SMALL_BACKGROUND,
  AUTH_BIG_BACKGROUND,
  EMAIL_SMALL_BACKGROUND,
  EMAIL_BIG_BACKGROUND,
  AD_LOGO_SIZE,
} from "../../../../../../_constants";
import { IUserCustomization } from "../../../../../../types";
import "./style.scss";
import  { ContentWrapper, ContentWrapperFooter } from "../../../../../global/components/ContentWrapper";
import InputRow from "../../../../../global/components/InputRow";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import FileRow from "../../../../../global/components/fileRow";
import ColorPickerField from "../../../../../global/components/colorPicker";

interface IProps {
  intl: IntlShape;
  companyName: string;
}

interface InjectedProps extends InjectedFormProps<IUserCustomization, IProps> {}
interface ComponentProps extends IProps, InjectedProps {}

const ProfileCustomizationForm = (props: ComponentProps) => {
  const { initialValues, initialize, handleSubmit, companyName  } = props;
  const { t } = useTranslation('translation', { keyPrefix: 'admin.customization' });

  useEffect(() => {
    initialize(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues])

    return (
      <ContentWrapper>
        <form onSubmit={handleSubmit}>
          <div>
            <InputRow
              className={'customization-company-name'}
              label={t('companyName') as string}
              disabled
              currentValue={companyName}
            />
            <Field
              name="company_logo"
              component={FileRow}
              label={t('logoLabel')}
              info={t('logoInfo')}
              withLabel
              bottomBorder
              supportedFormats={[imageTypes["png"], imageTypes["svg+xml"]]}
              supportedSizes={['600x600']}
              validateSize={true}
              validWidth={600}
              validHeight={600}
            />
            <Field
              name="main_background"
              component={FileRow}
              label={t('authBackgroundLabel')}
              info={t('authBackgroundInfo')}
              withLabel
              bottomBorder
              supportedSizes={[AUTH_SMALL_BACKGROUND, AUTH_BIG_BACKGROUND]}
              supportedFormats={[
                imageTypes["png"],
                imageTypes["webp"],
                imageTypes["jpg"],
                imageTypes["jpeg"],
              ]}
            />
            <Field
              name="email_header"
              component={FileRow}
              label={t('emailHeaderLabel')}
              info={t('emailHeaderInfo')}
              withLabel
              bottomBorder
              supportedSizes={[EMAIL_SMALL_BACKGROUND, EMAIL_BIG_BACKGROUND]}
              supportedFormats={[
                imageTypes["png"],
                imageTypes["webp"],
                imageTypes["jpg"],
                imageTypes["jpeg"],
              ]}
            />
            <Field
              name="favicon"
              component={FileRow}
              label={t('faviconLabel')}
              info={t('faviconInfo')}
              withLabel
              bottomBorder
              supportedFormats={[
                imageTypes["png"],
                imageTypes["vnd.microsoft.icon"],
              ]}
              supportedSizes={[
                FAVICON_SMALL_SIZE,
                FAVICON_MEDIUM_SIZE,
                FAVICON_BIG_SIZE,
              ]}
            />
            <Field
              className={'customization-company-name'}
              name="fb_page_id"
              component={InputRow}
              label={t('facebookPageIdLabel')}
              withLabel
              bottomBorder
            />
            <Field
              className={'customization-company-name'}
              name="term_of_service_url"
              component={InputRow}
              label={t('customizeTermsOfService')}
              bottomBorder
            />

            <Field
              className={'customization-company-name'}
              name="privacy_policy_url"
              component={InputRow}
              label={t('customizePrivacyPolicy')}
              bottomBorder
            />
            <Field
              name="ad_logo"
              component={FileRow}
              label={t('customizeAdLogo')}
              info={t('customizeAdLogoInfo')}
              withLabel
              bottomBorder
              validWidth={98}
              validHeight={32}
              supportedFormats={[
                imageTypes["png"],
                imageTypes["vnd.microsoft.icon"],
              ]}
              supportedSizes={[AD_LOGO_SIZE]}
            />

            <Box className={'my-account-customize-color-container'}>
                <Field
                  name="primary_color"
                  component={ColorPickerField}
                  label={t('color1')}
                />
                <Field
                  name="secondary_color"
                  component={ColorPickerField}
                  label={t('color2')}
                />
                <Field
                  name="gauge_color"
                  component={ColorPickerField}
                  label={t('color3')}
                  right
                />
                <Field
                  name="warning_color"
                  component={ColorPickerField}
                  label={t('color4')}
                  right
                />
            </Box>
          </div>
          <ContentWrapperFooter contentRight>
            <Button type="submit" sx={{ borderRadius: '25px', marginTop: '8px' }} variant={"contained"} >{"saveChanges"}</Button>
          </ContentWrapperFooter>
        </form>
      </ContentWrapper>
    );
}

export default injectIntl(
  reduxForm<IUserCustomization, IProps>({
    form: MY_ACCOUNT_CUSTOMIZE_FORM,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    // validate,
  })(ProfileCustomizationForm)
);
