import React, { Component } from "react";
import "./style.scss";
import { ReportDetailNotFound } from "../../../containers";
import clsx from "clsx";
import { getPercentageOfValue, numberToStringWithCommas } from "../../../modules";

interface IProps {
  data: any;
  onClick?: Function;
}

export default class VerticalBarGraph extends Component<IProps> {
  render() {
    const {
      props: { data, onClick },
    } = this;
    if (data.length === 1 && data[0].value === 0) {
      return <ReportDetailNotFound />;
    }

    const maxValueOfY =
      Math.max(...this.props.data.map((o: any) => o.value), 0) * 2;

    return (
      <div className="vertical-bar-graph">
        <div className="vertical-bar-graph-content">
          {data.map(({ name, value, clickable }: any) => {
            const handleClick = () => {
              if (onClick && clickable) {
                onClick({ name, value });
              }
            };

            const percentage = getPercentageOfValue(maxValueOfY, value);
            if (!value) {
              return null;
            }
            return (
              <div
                className={clsx("vertical-bar-graph-content-item", {
                  clickable,
                })}
                onClick={handleClick}
              >
                <p className="vertical-bar-graph-content-item-name">{name}</p>
                <div className="vertical-bar-graph-content-item-bar">
                  <div
                    className="vertical-bar-graph-content-item-color"
                    style={{ width: `${percentage * 100}%` }}
                  />

                  <div className="vertical-bar-graph-content-item-value">
                    {numberToStringWithCommas(value)}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
