import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import "./style.scss";
import { routerPaths } from "../../../../../../config/routes/paths";
import AdminService from "../../../../services/admin.service";
import { CoreTable } from "../../../../../global/components/core-table";
import { TableNotFound } from "../../../../../../components";
import { useHistory } from "react-router-dom";
import { goToRouteLinkWithId } from "../../../../../global";

const reqData: any = {
  date:{
    from_date: "2024-09-30T15:43:13.885Z",
    to_date: "2024-10-07T15:43:13.885Z",
    datePresetValue: {
      from_date: "2024-09-30T15:43:13.885Z",
      to_date: "2024-10-07T15:43:13.885Z"
    },
    key: "7_DAYS"
  },
  search: "",
  status: [],
  page: 1,
  dateCustomRange: false,
  dateLabel: "",
  from_date: "2024-09-30",
  to_date: "2024-10-07",
  status_filter: [1, 2, 3]
}

const headCells = [
  {key: 'name', parser: (data: string) => data || "" },
  {key: 'email', parser: (data: string) => data || "" },
  {key: 'legal_entity_name', parser: (data: string) => data || "" },
  {key: 'tin_number', parser: (data: string) => data || ""}
];

const AdminLegalModerationAccounts = () => {

  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [moderationLegalEntity, setModerationLegalEntity] = useState<any>();

  const renderNotfound = () => {
    return (
      <TableNotFound
        titleId="Admin.accountsNotFoundTitle"
        titleDefaultMessage="No Accounts found"
        descriptionId="Admin.accountsNotFoundDescription"
        descriptionDefaultMessage="Try adjusting filters or choosing different date range."
      />
    );
  };

  const getData = (reqData) => {
    setIsLoading(true);

    AdminService.getModerationLegalEntity(reqData).then(({data}) => {

      const _legalEntityUsers = data.data.data.map(
        ({
           first_name,
           id,
           last_name,
           email,
           legal_entity_name,
           tin_number,
         }) => {
          return {
            name: `${first_name} ${last_name}`,
            email,
            id,
            legal_entity_name,
            tin_number,
            link: goToRouteLinkWithId(routerPaths.LegalEntityUser, id),
          };
        }
      );
      setModerationLegalEntity({
        data: _legalEntityUsers,
        total_size: data.data.total_size,
        page_size: data.data.page_size,
        current_page: data.data.current_page,
        isLoading: false,
      })
      setIsLoading(false);
    })
  };

  const onPageChange = (newPage: number) => {
    getData({...reqData, page: newPage });
  }

  useEffect(() => {
    getData(reqData);
  }, []);

  return (
    <div className="accounts-moderation-table-wrapper">
      <CoreTable
        data={moderationLegalEntity}
        translationPrefix={'admin.moderation.legalEntityList'}
        loading={isLoading}
        headCells={headCells}
        hasPagination={true}
        noData={renderNotfound()}
        onPageChange={(page) => onPageChange(page)}
        onRowClick={(row) => history.push('/admin/legal-entity/user/' + row.id)}
      />
    </div>
  );

}

export default injectIntl(AdminLegalModerationAccounts);
